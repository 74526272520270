import React, { useMemo, useEffect } from "react";
import { Flex, Grid, Box } from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import { FormProvider, useForm } from "react-hook-form";
import ButtonType from "components/Button";
import {
  OPTIONS_RARITY,
  ALL_NFT_CHAINS,
  CHAIN_TYPE,
  TRAIT_NFT_OPTIONS,
  ALLOCATION_GAUNTLET_BB_OPTIONS,
  ALLOCATION_HUNTER_NFT_OPTIONS,
  ALLOCATION_GENESIS_NFT_OPTIONS,
} from "constants/constants";
import {
  DEFAULT_FILTER_PARAMS,
  NFT_MANAGEMENT_PARAMS,
  NFT_MANAGEMENT_TYPE,
} from "views/NFTsManagement/constants";
import { useSelector } from "react-redux";
import { nftsSelector } from "stores/nfts/selector";

const PLACEHOLDER_INPUT_SEARCH = {
  [NFT_MANAGEMENT_TYPE.OWNER]: "Search by keyword",
};

export default function NFTsFilterPanel({
  nftMngType,
  packageOptions,
  onUpdateFilterParams,
}) {
  const defaultValues = useMemo(
    () => NFT_MANAGEMENT_PARAMS[nftMngType] || DEFAULT_FILTER_PARAMS,
    [nftMngType]
  );
  const { indexTabFilter } = useSelector(nftsSelector);

  const form = useForm({
    defaultValues,
  });

  const { setValue, handleSubmit, reset } = form;

  const getAllocationsByType = useMemo(() => {
    switch (nftMngType) {
      case NFT_MANAGEMENT_TYPE.HUNTER:
        return ALLOCATION_HUNTER_NFT_OPTIONS;
      case NFT_MANAGEMENT_TYPE.GAUNTLET:
      case NFT_MANAGEMENT_TYPE.BOUNTY_BALL:
        return ALLOCATION_GAUNTLET_BB_OPTIONS;
      case NFT_MANAGEMENT_TYPE.GENESIS:
        return ALLOCATION_GENESIS_NFT_OPTIONS;
      default:
        return [];
    }
  }, [nftMngType]);

  const getChainOptions = useMemo(() => {
    const isProduction = process.env.REACT_APP_ENV === "production";
    return [
      { label: "All", value: "" },
      ...ALL_NFT_CHAINS.filter((chain) =>
        isProduction
          ? chain.type === CHAIN_TYPE.MAINNET
          : chain.type === CHAIN_TYPE.TESTNET
      ).map((chain) => ({
        label: chain.label,
        value: chain.chain_id.toString(),
      })),
    ];
  }, []);

  const onSubmit = (data) => {
    const trimmedData = {
      ...data,
      query: data?.query?.trim() || "",
      page: 1,
    };
    onUpdateFilterParams(trimmedData);
  };

  const handleReset = () => {
    reset(NFT_MANAGEMENT_PARAMS[nftMngType] || DEFAULT_FILTER_PARAMS);
    onUpdateFilterParams(
      NFT_MANAGEMENT_PARAMS[nftMngType] || DEFAULT_FILTER_PARAMS
    );
  };

  useEffect(() => {
    reset(defaultValues);
  }, [nftMngType, reset, defaultValues, indexTabFilter]);

  const settingsBox = () => {
    const defaultSettings = {
      borderRadius: "xl",
      bg: "white",
      boxShadow: "lg",
      p: 6,
    };
    const setting = {
      [NFT_MANAGEMENT_TYPE.OWNER]: {
        borderRadius: "unset",
        bg: "white",
        boxShadow: "unset",
        my: 5,
      },
    };
    return setting[nftMngType] || defaultSettings;
  };

  return (
    <Box {...settingsBox()}>
      <Flex direction="column" gap={6}>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex direction="column" gap={6}>
              <FormInput
                name="query"
                label="Search"
                placeholder={
                  PLACEHOLDER_INPUT_SEARCH[nftMngType] || "Search by name"
                }
                renderIconSearch
                onChange={(e) => {
                  setValue("query", e.target.value);
                }}
                onSearch={handleSubmit(onSubmit)}
              />
              {nftMngType !== NFT_MANAGEMENT_TYPE.OWNER && (
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  {nftMngType === NFT_MANAGEMENT_TYPE.GENESIS ? (
                    <FormSelect
                      name="package"
                      label="Filter by package"
                      options={packageOptions}
                      onChange={(e) => {
                        setValue("package", e.target.value);
                        handleSubmit(onSubmit)();
                      }}
                    />
                  ) : (
                    // <FormSelect
                    //   name="chain"
                    //   label="Filter by Chain"
                    //   options={getChainOptions}
                    //   onChange={(e) => {
                    //     setValue("chain", e.target.value);
                    //     handleSubmit(onSubmit)();
                    //   }}
                    // />
                    <></>
                  )}
                  {/* <FormSelect
                  name="sale-status"
                  label="Filter by State"
                  options={[
                    { label: "All", value: "" },
                    ...SALE_STATUS_OPTIONS,
                  ]}
                  onChange={(e) => {
                    setValue("sale-status", e.target.value);
                    handleSubmit(onSubmit)();
                  }}
                /> */}
                  <FormSelect
                    name="allocation"
                    label="Filter by Allocation"
                    options={[
                      { label: "All", value: "" },
                      ...getAllocationsByType,
                    ]}
                    onChange={(e) => {
                      setValue("allocation", e.target.value);
                      handleSubmit(onSubmit)();
                    }}
                  />
                  <FormSelect
                    name="rarity"
                    label="Filter by Rarity"
                    options={[{ label: "All", value: "" }, ...OPTIONS_RARITY]}
                    onChange={(e) => {
                      setValue("rarity", e.target.value);
                      handleSubmit(onSubmit)();
                    }}
                  />
                  {nftMngType !== NFT_MANAGEMENT_TYPE.GENESIS && (
                    <FormSelect
                      name="trait"
                      label="Filter by Trait"
                      options={[
                        { label: "All", value: "" },
                        ...TRAIT_NFT_OPTIONS,
                      ]}
                      onChange={(e) => {
                        setValue("trait", e.target.value);
                        handleSubmit(onSubmit)();
                      }}
                    />
                  )}
                </Grid>
              )}
            </Flex>
            {nftMngType !== NFT_MANAGEMENT_TYPE.OWNER && (
              <Flex justifyContent="flex-end" mt={6}>
                <ButtonType
                  type="button"
                  variant="outline"
                  onClick={handleReset}
                  mr={3}
                  fontSize="12px"
                  text="Reset"
                />
              </Flex>
            )}
          </form>
        </FormProvider>
      </Flex>
    </Box>
  );
}
