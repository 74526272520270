import { useState, useCallback, useEffect } from "react";
import { Select as ChakraSelect, chakraComponents } from "chakra-react-select";
import {
  Box,
  Flex,
  Tag,
  Text,
  VStack,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import ModalBase from "components/Modal/ModalBase";
import ButtonType from "components/Button";
import { ethers, Contract } from "ethers";
import { getProvider, getNativeToken, getContractABI } from "utils/web3";

export default function ModalConfirmTransfer({
  checkedIds,
  isOpen,
  onClose,
  handleSendNfts,
  userDetail
}) {
  const toast = useToast();
  const [gasFee, setGasFee] = useState(null);
  const [isLoadingGasFee, setIsLoadingGasFee] = useState(false);

  const fetchGasPrice = async (chainId, publicAddressTo) => {
    try {
      setIsLoadingGasFee(true);
      const provider = getProvider(chainId);
      const gasPrice = await provider.getGasPrice();

      // Get contract instance
      const contractABI = getContractABI(chainId); // Get ABI based on chain
      const contractAddress = checkedIds[0]?.nft_information?.contract_address;
      const publicAddressFrom = checkedIds[0]?.nft_information?.public_address;
      const contract = new Contract(contractAddress, contractABI, provider);
      // Verify token ownership
      const tokenId = checkedIds[0]?.nft_information?.token_id;

      if (!publicAddressTo) {
        throw new Error("Invalid recipient wallet address");
      }

      // Estimate gas for transfer function
      const estimatedGas = await contract.estimateGas.transferFrom(
        publicAddressFrom, // from address
        publicAddressTo, // to address
        tokenId // token ID
      );

      // Calculate total gas fee
      const totalGasFee = gasPrice.mul(estimatedGas);

      setGasFee(ethers.utils.formatEther(totalGasFee));
    } catch (error) {
      console.log(error, "error");
      toast({
        description: error?.message || "Failed to fetch gas price",
        status: "error",
        position: "top",
      });
    } finally {
      setIsLoadingGasFee(false);
    }
  };

  useEffect(() => {
    if (isOpen && checkedIds && checkedIds.length > 0 && userDetail?.length > 0) {
      const chainId = checkedIds[0]?.nft_information?.chain_id;
      if (chainId && userDetail) {
        fetchGasPrice(chainId, userDetail[0]?.public_address);
      }
    }
  }, [isOpen/* , checkedIds, userDetail */]);

  return (
    <>
      <ModalBase
        maxWContent={{
          base: "50%",
          sm: "90%",
          md: "50%",
        }}
        isOpen={isOpen}
        onClose={onClose}
        showBtn={false}
        titleHeader="CONFIRM"
      >
        <VStack spacing={4} align="stretch">
          <Box>
            <Text fontWeight="bold" mb={2}>
              Receiver
            </Text>
            <Flex wrap="wrap" gap={2}>
              <Tag bg="#fee687" color="gray.700">
                {userDetail[0]?.email}
              </Tag>
            </Flex>
          </Box>

          <Box>
            <Text fontWeight="bold" mb={2}>
              Selected NFTs
            </Text>
            <Flex wrap="wrap" gap={2}>
              {checkedIds?.map((item, index) => (
                <Tag key={index} bg="#a2cff5" color="gray.700">
                  {item?.name}
                </Tag>
              ))}
            </Flex>
          </Box>

          <Box>
            <Text fontWeight="bold" mb={2}>
              Gas fee:{" "}
              {isLoadingGasFee ? (
                <Spinner size="sm" />
              ) : gasFee ? (
                `${gasFee} ${getNativeToken(
                  checkedIds[0]?.nft_information?.chain_id
                )}`
              ) : (
                "n/a"
              )}
            </Text>
          </Box>

          <Flex justifyContent="center">
            <ButtonType
              w="28%"
              mt={4}
              type="button"
              onClick={() => handleSendNfts(checkedIds)}
              isDisabled={!userDetail || isLoadingGasFee}
              text="SEND"
            />
          </Flex>
        </VStack>
      </ModalBase>
    </>
  );
}
