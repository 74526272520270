import { Flex } from "@chakra-ui/react";
import Card from "components/Card/Card";

import NFTsSummaryPanel from "../components/panel/NFTsSummaryPanel";
import NFTsCardHeader from "../components/panel/NFTsCardHeader";
import NFTsList from "../components/list/NFTsList";
import { NFT_MANAGEMENT_TYPE } from "../constants";

const NFT_MNG_TYPE = NFT_MANAGEMENT_TYPE.BOUNTY_BALL;

export default function NftBountyBallManagementPage() {
  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="24px">
        {/* NFTsCardHeader */}
        <NFTsCardHeader title="Bounty Ball NFTs" />
        {/* NFTsSummaryPanel */}
        <NFTsSummaryPanel nftMngType={NFT_MNG_TYPE} />
        {/* NFTsList */}
        <NFTsList nftMngType={NFT_MNG_TYPE} />
      </Card>
    </Flex>
  );
}
