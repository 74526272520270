/* eslint-disable no-useless-catch */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { LIST_USERS_FETCH, USER_NAMESPACE } from "../constant";
import { getUsers } from "api/auth.api";


export const listUsersApi = createAsyncThunk(
  `${USER_NAMESPACE}/${LIST_USERS_FETCH}`,
  async () => {
    try {
      const res = await getUsers();
      return res?.data;
    } catch (error) {
      // if (error?.message || error?.messages[0]) {
      //   toast({
      //     description: error?.message || error?.messages[0],
      //     status: 'error',
      //     position: 'bottom-right',
      //   })
      // }
      throw error;
    }
  }
);
