import {
  Flex,
  FormLabel,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import ButtonType from "components/Button";
import FormSelect from "components/form/FormSelect";
import useWithToast from "hooks/useWithToast";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import schemaWallet from "./schema";
import { OPERATING_SYSTEMS } from "constants/constants";
import { NUMBER } from "constants/enum";
import { useTranslation } from "react-i18next";
import { uploadLinkFigure, uploadLinkFigureDone } from "api/digitaFigure.api";
import { FormFileUpload } from "components/form/FormFileUpload";
import axios from "axios";

export default function WalletCreateAndUpdate({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
}) {
  const { t } = useTranslation("prize");
  const toast = useToast();
  const { showToastSuccess } = useWithToast();
  const [selected, setSelected] = useState(null);
  const [selectedAndroid, setSelectedAndroid] = useState(null);
  const textColor = useColorModeValue("#000000", "white");
  const [nameImage, setNameImage] = useState("");
  const [nameImageAndroid, setNameImageAndroid] = useState("");

  const form = useForm({
    resolver: yupResolver(schemaWallet()),
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = form;

  const idEdit = useMemo(() => {
    return dataDetail?.target_hunter_id;
  }, [dataDetail]);

  const switchDirection = async (machine) => {
    const params = {
      device_type: machine.type,
      id: machine?.id
    }
    if (machine.image) {
      try {
        const request = new FormData();
        request.append("file", machine.image);
        const res = await uploadLinkFigure(params);
        if (res?.data?.success) {
          await axios.put(res?.data?.data, request, {
            headers: {
              'x-ms-blob-type': 'BlockBlob'
            }
          }).then(async (data) => {
            if (data?.status === 201) {
              try {
                const res = await uploadLinkFigureDone(params);
                if (res?.data?.success) {
                  return res?.data
                }
              } catch (error) {
                toast({
                  description: error?.message || error?.messages[0],
                  status: "error",
                  position: "bottom-right",
                });
              }
            }
          })
          return res.data
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
    }
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const listDigitals = [
      {
        type: "IOS",
        id: dataDetail?.target_hunter_id,
        image: selected
      },
      {
        type: "ANDROID",
        id: dataDetail?.target_hunter_id,
        image: selectedAndroid
      },
    ]
    const promise = listDigitals?.map((machine) => {
      return switchDirection(machine);
    });
    const res = await Promise.all(promise);
    console.log("🚀 ~ onSubmit ~ res:", res)
    if (res && (res[0]?.success || res[1]?.success)) {
      showToastSuccess({
        title: "Setting digital figure successfully!",
      });
      handleCreateOrUpdateSuccess()
    } 
  };

  const handleImageChange = async (event, type) => {
    if (type === "file_url_ios") {
      const image = event?.target?.files[0] || event[0];
      setSelected(image)
      setNameImage(image?.name)
      setValue(type, image)
    } else {
      const image = event?.target?.files[0] || event[0];
      setSelectedAndroid(image)
      setNameImageAndroid(image?.name)
      setValue(type, image)
    }
  };

  const handleDragImage = async (file, type) => {
    if (file.length > NUMBER.ONE) {
      toast({
        description: t("image_greater_one"),
        status: "error",
        position: "bottom-right",
      });
      return false;
    }
    if (type === "file_url_ios") {
      const image = file && file[0];
      setSelected(image)
      setNameImage(image?.name)
      setValue(type, image)
    } else {
      const image = file && file[0];
      setSelectedAndroid(image)
      setNameImageAndroid(image?.name)
      setValue(type, image)
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                System
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                IOS
              </Text>
            </div>
            <FormFileUpload
              label="File"
              isRequired={true}
              name="file_url_ios"
              textButton={nameImage}
              textDescription=""
              onChange={(e) => handleImageChange(e, "file_url_ios")}
              handleDragImage={(val) =>
                handleDragImage(val, "file_url_ios")
              }
            />
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                System
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                ANDROID
              </Text>
            </div>
            <FormFileUpload
              label="File"
              isRequired={true}
              name="file_url_android"
              textButton={nameImageAndroid}
              textDescription=""
              onChange={(e) => handleImageChange(e, "file_url_android")}
              handleDragImage={(val) =>
                handleDragImage(val, "file_url_android")
              }
            />
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="685px" overflowY="auto">
        <ModalHeader
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "Setting Digital Figure " : "Add a new wallet"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter w="100%" textAlign="center" margin="auto">
          <Flex alignItems="center" gap={4} w="100%">
            <ButtonType
              mt={4}
              type="submit"
              m="auto"
              w="100%"
              h="46px"
              borderRadius="5px"
              isLoading={isSubmitting}
              btnType="primary-new-outline"
              onClick={onClose}
            >
              Cancel
            </ButtonType>
            <ButtonType
              w="100%"
              mt={4}
              type="submit"
              m="auto"
              h="46px"
              borderRadius="5px"
              form="machine-form"
              isLoading={isSubmitting}
            >
              Update
            </ButtonType>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
