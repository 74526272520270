import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Flex,
  Tag,
  Text,
  VStack,
  Select,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import ModalBase from "components/Modal/ModalBase";
import ModalSuccessAndFail from "components/Modal/ModalSuccessAndFail";
import ButtonType from "components/Button";
import {
  getGenesisPackageList,
  sellMultipleGenesisNFTs,
} from "api/genesisNFTs.api";

export default function SellNFTGenesisModal({
  items,
  isOpen,
  onClose,
  onSellSuccess,
}) {
  const toast = useToast();
  const [packageName, setPackageName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);

  const {
    isOpen: isOpenFail,
    onOpen: onOpenFail,
    onClose: onCloseFail,
  } = useDisclosure();

  const getDataPackageList = async () => {
    const params = {
      page: 1,
      limit: 100,
    };

    const { data } = await getGenesisPackageList(params);
    if (data?.success) {
      const { records } = data.data;
      setPackages(records.map((nft) => ({ ...nft })));
    } else {
      throw new Error("Failed to fetch Packages");
    }
  };

  useEffect(() => {
    if (isOpen && items && items.length > 0) {
      getDataPackageList();
    }
  }, [isOpen, items]);

  const handleSell = async () => {
    setIsLoading(true);
    try {
      const paramsPackageSell = {
        package_id: packageName,
        product_ids: items?.map((item) => item?.product_model?.id),
      };
      const { data } = await sellMultipleGenesisNFTs(paramsPackageSell);
      // Successful sell
      if (data?.success) {
        onSellSuccess();
        onClose();
        toast({
          title: "Sell successful.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      } else {
        setErrorMessage(
          data?.messages || ["An error occurred. Please try again later!"]
        );
        onOpenFail(true);
      }
    } catch (error) {
      // Failed transfer
      onOpenFail(true);
    } finally {
      setIsLoading(false);
    }
  };

  const packageOptions = useMemo(
    () =>
      packages?.map((nftPackage) => ({
        value: nftPackage?.id,
        label: nftPackage?.name,
      })),
    [packages]
  );

  return (
    <>
      <ModalBase
        maxWContent={{
          base: "50%",
          sm: "90%",
          md: "50%",
        }}
        isOpen={isOpen}
        onClose={onClose}
        showBtn={false}
        titleHeader="Sell NFTs"
      >
        <VStack spacing={4} align="stretch">
          <Box>
            <Text fontWeight="bold" mb={2}>
              Selected NFTs
            </Text>
            <Flex wrap="wrap" gap={2}>
              {items?.map((item, index) => (
                <Tag key={index} bg="gray.200" color="gray.700">
                  {item?.name}
                </Tag>
              ))}
            </Flex>
          </Box>

          <Box>
            <Text fontWeight="bold" mb={2}>
              Package
            </Text>
            <Select
              placeholder="Select the package"
              value={packageName}
              onChange={(e) => setPackageName(e.target.value)}
            >
              {packageOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </Box>

          <Flex justifyContent="center">
            <ButtonType
              w="28%"
              mt={4}
              type="button"
              onClick={handleSell}
              isDisabled={!packageName}
              isLoading={isLoading}
              text="SELL"
            />
          </Flex>
        </VStack>
      </ModalBase>
      {isOpenFail && (
        <ModalSuccessAndFail
          isOpen={isOpenFail}
          type="error"
          onClose={onCloseFail}
          onSubmit={onCloseFail}
          description={
            <VStack align="start" spacing={2} alignItems="center">
              {errorMessage?.map((message, index) => (
                <Text key={index}>{message}</Text>
              ))}
            </VStack>
          }
        />
      )}
    </>
  );
}
