import { Icon } from "@chakra-ui/icons";
import { Flex, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import moment from "moment";
import { useMemo } from "react";
import { trimCharacter } from "utils/string";
import { STATUS_USER, STATUS_USER_OPTIONS } from "views/Users/UserManagement/constant";
import { ViewIcon } from "components/Icons/ViewIcon";
import { getDayLLLL } from "utils/time";

export default function UserManagementRow({
  item,
  checkedIds,
  setCheckedIds,
  toggle,
}) {
  const textColor = useColorModeValue("#000000", "white");

  const isChecked = useMemo(() => {
    return checkedIds.some((itemCheck) => itemCheck?.id === item?.id);
  }, [item?.id, checkedIds]);

  const handleCheckboxChange = () => {
    if (isChecked) {
      setCheckedIds(
        checkedIds.filter((itemCheck) => itemCheck?.id !== item?.id)
      );
    } else {
      setCheckedIds([...checkedIds, item]);
    }
  };

  const showBgItems = (index) => {
    switch (index) {
      case 0:
        return "#5ebfff"
      case 1:
        return "#ffb0be"
      case 2:
        return "#777d81"
      case 3:
        return "#ff9cff"
      default:
        return "#777d81"
    }
  }

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isChecked}
          onChange={handleCheckboxChange}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {trimCharacter(item?.id, 6)}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.name}
        </Text>
      </Td>
      <Td>
        <Flex flexDirection="column" gap="3px">
          {item?.wallets?.map((wallet, index) => (
            <Text key={index} p="2px 10px" fontSize="12px" background={showBgItems(index)} borderRadius="12px">{trimCharacter(wallet?.public_address)}</Text>
          ))}
        </Flex>
      </Td>
      <Td>
        {item?.status === STATUS_USER?.ACTIVE ? (
          <Text color="green" textAlign="center" fontSize="sm" fontWeight={500} p="4px 12px" borderRadius="16px" bgColor="green.200">
            Active
          </Text>
        ) : (
          <Text textAlign="center" color="red" fontSize="sm" fontWeight={500} p="4px 12px" borderRadius="16px" bgColor="red.200">
            Ban
          </Text>
        )}
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.current_level?.split("_")[1] || "0"}
          {/* Level */}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.title_rank}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.resource?.bcoin_total}
        </Text>
      </Td>
      <Td>
        <Flex color={textColor} backgroundColor="#9bf976" whiteSpace="nowrap" w="max-content" p="6px" borderRadius="8px" fontSize="sm" fontWeight={400}>
          Not open: {item?.treasure_not_opened}
        </Flex>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.resource?.sphere || 0}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.resource?.orb || 0}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.ticket?.total_ticket || 0}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.ticket?.non_nft_ticket?.bcoin_ticket_free || 0}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.user_contribute_point?.current_cp || 0}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.user_contribute_point?.transfered_cp || 0}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.user_attribute_point?.current_ap || 0}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.user_attribute_point?.transfered_ap || 0}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.referral || 0}
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {item?.first_login_at ? getDayLLLL(item?.first_login_at * 1000) : "N/A"}
        </Text>
      </Td>
      <Td>
        <Flex gap={6}>
          <Icon
            onClick={() => toggle("showDetail" ,item)}
            fontSize="30px"
            borderRadius="8px"
            cursor="pointer"
            as={ViewIcon}
          />
        </Flex>
      </Td>
    </Tr>
  );
}
