import END_POINT from './constants'
import http from './http'

export function getRules() {
  return http.get(`${END_POINT.RULE}`)
}

export function getRulesGroup() {
  return http.get(`${END_POINT.RULE_GROUP}`)
}
