import { Box, Flex, Menu, MenuButton, MenuList, MenuItem, Button, useToast } from "@chakra-ui/react";
import { IoIosArrowDown } from "react-icons/io";
import ButtonType from "components/Button";
import { STATUS_USER } from "../constant";
import { updateUser } from "api/userManagement";

export default function ActionUserDetail({ toggle, userDetail, getDetailUser }) {
  const toast = useToast();

  const handleBanUser = async () => {
    try {
      const userStatusChange = {
        status: userDetail?.status === STATUS_USER.ACTIVE ? STATUS_USER.BANNED : STATUS_USER.ACTIVE
      };
      const res = await updateUser(userDetail?.id, userStatusChange);
      if (res?.data?.success) {
        toast({
          title: "Update success.",
          description: "Update success.",
          status: "success",
          duration: 3000,
          position: "top",
        });
        getDetailUser()
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } 
  }
  
  return (
    <Box>
      <Flex alignItems="center" gap={4} mb="10px">
        <Menu>
          <MenuButton
            fontSize="12px"
            borderRadius="8px"
            boxShadow="0 4px 7px -1px rgba(0,0,0,.11),0 2px 4px -1px rgba(0,0,0,.07)"
            as={Button}
            rightIcon={<IoIosArrowDown />}
            bg="#383838"
            color="white"
            _hover={{ bg: "gray.700" }}
            _active={{ bg: "gray.600" }}
          >
            Transfer
          </MenuButton>
          <MenuList>
            <MenuItem
              minH="48px"
              onClick={() => {
                if (userDetail?.status !== STATUS_USER.ACTIVE) {
                  toggle("modalHaveBanned", userDetail)
                } else {
                  toggle("modalTransferNft", userDetail)
                }
              }}
            >
              Transfer NFTs
            </MenuItem>
            <MenuItem 
              minH="48px" 
              onClick={() => {
                if (userDetail?.status !== STATUS_USER.ACTIVE) {
                  toggle("modalHaveBanned", userDetail)
                } else {
                  toggle("modalTransferCoin", userDetail)
                }
              }}
            >
              Transfer point in game
            </MenuItem>
            <MenuItem
              minH="48px"
              onClick={() => {
                if (userDetail?.status !== STATUS_USER.ACTIVE) {
                  toggle("modalHaveBanned", userDetail)
                } else {
                  toggle("modalTransferTicket", userDetail)
                }
              }}
            >
              Transfer Ticket
            </MenuItem>
          </MenuList>
        </Menu>
        {userDetail?.status === STATUS_USER.ACTIVE ? (
          <ButtonType
            text="BAN"
            fontSize="12px"
            btnType="danger"
            width="100px"
            onClick={handleBanUser}
          />
        ) : (
          <ButtonType
            text="UNBAN"
            fontSize="12px"
            btnType="success"
            width="100px"
            onClick={handleBanUser}
          />
        )}
      </Flex>
    </Box>
  );
}
