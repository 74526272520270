import { useCallback, useEffect, useState } from "react";
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import TableCustom from "components/Tables/CustomTable";
import ItemsReward from "./row";
import useWithToast from "hooks/useWithToast";
import { getListRewardContainer } from "api/gacha.api";
import Paginate from "components/Paginate";

export default function ModalRewardContainer({
  isOpen,
  onClose,
  dataDetail
}) {
  const labels = [
    "ID",
    "Name",
    "Chain",
    "Small reward containers",
    "Weight",
    "Grand Prize",
    // "",
  ];

  const toast = useToast();
  const { showToastSuccess } = useWithToast();
  const [dataReward, setDataReward] = useState([]);
	const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState({
		page: 1,
		limit: 10,
    gacha_id: dataDetail?.id
	});
  const textColor = useColorModeValue("#000000", "white");

  const getSkillRank = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getListRewardContainer({
					...params,
					...newParams,
				});
        if (data?.success) {
          const res = data?.data;
          setDataReward(res?.records);
					setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  useEffect(() => {
    getSkillRank();
  }, [getSkillRank]);

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xxl">
      <ModalOverlay />
      <ModalContent maxW="900px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {dataDetail?.name} - large reward container(s)
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">
          <Flex flexDirection="column">
            <Card px="0px" pt="0px">
              <CardBody overflowX="auto">
                <TableCustom
                  isCheck={false}
                  labels={labels}
                  dataRow={dataReward}
                  isLoading={isLoading}
                >
                  <>
                    {dataReward?.map((item, index) => {
                      return (
                        <ItemsReward
                          key={index}
                          item={item}
                          index={index}
                        />
                      );
                    })}
                  </>
                </TableCustom>
              </CardBody>
              <Paginate
                page={params?.page}
                pageCount={totalPage}
                limit={params.limit}
                onChangeLimit={onChangeLimit}
                handleChangePage={handleChangePage}
              />
            </Card>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
