import React, { useCallback, useEffect, useState } from 'react'
import ActionMachineGroup from './actionGroups';
import { CloseButton, Flex, Text, useToast, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import TableCustom from 'components/Tables/CustomTable';
import Paginate from 'components/Paginate';
import CreateAndUpdateGroup from "./createAndUpdateGroup";
import {
  getMachineGroup,
  deleteMachineGroup,
} from "api/machine.api";
import MachineRow from './MachineRow';
import DeleteModal from './deleteModal';
import { ERROR_MACHINE_GROUPS } from '../constants';

export default function MachineGroups({ toggleModal }) {
  const [checkedGroups, setCheckedGroups] = useState([]);
  const [dataDetail, setDataDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [machineGroup, setMachineGroup] = useState([]);

  const toast = useToast();
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const textColor = useColorModeValue("#000000", "white");

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const labels = [
    "Group Label",
    "Group Size",
    "Action",
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { 
    isOpen: isOpenDelete, 
    onOpen: onOpenDelete, 
    onClose: onCloseDelete, 
  } = useDisclosure();

  const handleOpenCreateOrUpdate = (data = null) => {
    setDataDetail(data);
    onOpen();
  };

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setCheckedGroups(machineGroup);
    } else {
      setCheckedGroups([]);
    }
  };

  const getListMachineGroup = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        setCheckedGroups([]);
        const { data } = await getMachineGroup({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setMachineGroup(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  const handleCreateOrUpdateSuccess = useCallback(() => {
    onClose();
    setDataDetail(null);
    setParams({
      ...params,
      page: 1,
    });
  }, [params]);

  useEffect(() => {
    getListMachineGroup()
  }, [getListMachineGroup])

  const deleteItem = async (id) => {
    return deleteMachineGroup(id);
  };

  const handleDelete = async () => {
    try {
      const promise = checkedGroups?.map((machine) => {
        return deleteItem(machine?.machine_label);
      });
      const res = await Promise.all(promise);
      if (res && res[0]?.data?.success) {
        setCheckedGroups([]);
        toast({
          title: "Delete success.",
          description: "Delete success.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        const newParams = {
          ...params,
          page: 1,
        };
        setParams(newParams);
        onCloseDelete();
      } else {
        toast({
          description: ERROR_MACHINE_GROUPS[res?.data?.message] || ERROR_MACHINE_GROUPS[res[0]?.data?.messages[0]],
          status: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      onCloseDelete();
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Card px="0px">
      <CardHeader px="22px" mb="12px" display="flex" flexDirection="column" gap="20px">
        <Flex
          w="100%"
          gap={4}
          direction={{
            base: "column",
            md: "row",
          }}
          justifyContent={{
            base: "flex-start",
          }}
          alignItems="center"
        >
          <CloseButton size='lg' onClick={() => toggleModal("machineGroups")}/>
          <Text
            color={textColor}
            fontSize="lg"
            fontWeight="bold"
            whiteSpace="nowrap"
          >
            Machine Groups
          </Text>
        </Flex>
        <Flex
          w="100%"
          justifyContent={{
            base: "flex-start",
          }}
        >
          <ActionMachineGroup
            handleOpenCreateOrUpdate={handleOpenCreateOrUpdate}
            checkedGroups={checkedGroups}
            setCheckedGroups={setCheckedGroups}
            toggleModal={toggleModal}
            onOpenDelete={onOpenDelete}
          />
        </Flex>
      </CardHeader>
      <CardBody overflowX="auto">
        <TableCustom
          labels={labels}
          isLoading={isLoading}
          isCheck
          isChecked={
            machineGroup?.length > 0 && checkedGroups?.length === machineGroup?.length
          }
          onChangeChecked={onChangeChecked}
          dataRow={machineGroup}
        >
          <>
            {machineGroup?.map((item, index) => {
              return (
                <MachineRow
                  key={index}
                  setCheckedGroups={setCheckedGroups}
                  checkedGroups={checkedGroups}
                  handleOpenCreateOrUpdate={() => {
                    handleOpenCreateOrUpdate(item);
                  }}
                  machine={item}
                />
              );
            })}
          </>
        </TableCustom>
      </CardBody>
      <Paginate
        page={params?.page}
        pageCount={totalPage}
        limit={params.limit}
        onChangeLimit={onChangeLimit}
        handleChangePage={handleChangePage}
      />
      {isOpen && (
        <CreateAndUpdateGroup
          isOpen={isOpen}
          onClose={onClose}
          dataDetail={dataDetail}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
        />
      )}
      <DeleteModal
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        handleDelete={handleDelete}
      />
    </Card>
  )
}
