import { Box, Flex, Image, Text } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import bannerDefault from 'assets/img/banner-event-default.png'
import { convertDateLocalLLLL } from 'utils/time';
import EditIcon from 'assets/svg/edit.svg'
import RankingIcon from 'assets/svg/ranking.svg'
import RegisterIcon from 'assets/svg/registered_user.svg'
import { LIST_KEY_STATUS_GACHA } from '../constants';

export default function DetailGacha(props) {
  const { item, toggle } = props

  const renderBgItem = useMemo(() => {
    switch (item?.status) {
      case LIST_KEY_STATUS_GACHA.ON_GOING:
        return {
          bg: "linear-gradient(137deg, #EBFE95 0%, #B9EA29 100%)",
          color: "#000",
        };
      case LIST_KEY_STATUS_GACHA.INCOMING:
        return {
          bg: "linear-gradient(137deg, #FFEAAC 0%, #FFC515 100%)",
          color: "#000",
        };
      case LIST_KEY_STATUS_GACHA.TERMINATED:
        return {
          bg: "linear-gradient(137deg, #DBDBDB 0%, #747474 100%)",
          color: "#000",
        };
      default:
        return {
          bg: "linear-gradient(137deg, #EDE6E6 1.63%, #C0BDBD 98.37%)",
          color: "#2D377B",
        };
    }
  }, [item?.status]);

  return (
    <Flex 
      w="auto"
      height="auto"
      p="8px" 
      borderRadius="8px"
      direction="column"
      columnGap="4px"
      bg={renderBgItem.bg}
      minWidth="200px"
      maxWidth="200px"
    >
      <Flex direction="column" gap={2}>
        <Flex alignItems="center" gap={2}>
          <Box
            bg="#FFC515"
            w="22px"
            h="22px"
            borderRadius="100%"
            border={`3px solid #fff`}
          />
          <Box width="150px">
            <Text
              fontSize={{
                base: "16px",
              }}
              fontWeight="700"
              isTruncated
            >
              {item?.name}
            </Text>
          </Box>
        </Flex>
        <Flex alignItems="center" justifyContent="center" gap={10}>
          <Flex flexDirection="column" alignItems="center">
            <Image
              src={item?.banner_image_url || bannerDefault}
              w="100%"
              h="100px"
              alt={item?.id}
            />
          </Flex>
        </Flex>
        <Flex flexDirection="column" lineHeight="18px">
          <Text fontSize="14px" fontWeight="500">Feature: </Text>
          <Text fontSize="14px" fontWeight="500" width="180px" isTruncated>{item?.feature_description}</Text>
        </Flex>
        <Flex p="2px 8px" direction="column" gap="0" background="#fff" borderRadius="6px">
          <Text fontSize="12px" fontWeight="500">Start: {convertDateLocalLLLL(item?.start_date * 1000)}</Text>
          <Text fontSize="12px" fontWeight="500">End: {convertDateLocalLLLL(item?.end_date * 1000)}</Text>
        </Flex>
        <Flex alignItems="center" justifyContent="flex-end" gap="8px">
          <Flex 
            w="30px"
            h="30px"
            borderRadius="8px"
            background="#000"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            onClick={() => toggle('modalHistory', item)}
          >
            <Image src={RankingIcon} alignSelf='center' justifySelf='center' />
          </Flex>
          <Flex
            w="30px"
            h="30px"
            borderRadius="8px"
            background="#000"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            onClick={() => toggle('rewardContainer', item)}
          >
            <Image src={RegisterIcon} alignSelf='center' justifySelf='center' />
          </Flex>
          <Flex
            w="30px"
            h="30px"
            borderRadius="8px"
            background="#000"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            onClick={() => toggle("createAndEdit", item)}
          >
            <Image src={EditIcon} alignSelf='center' justifySelf='center' />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
