import React from 'react'
import { Flex, Image, Text, Box } from '@chakra-ui/react';
import { getDayLLLL } from 'utils/time';
import ICON_BICONOMY from "assets/img/card/biconomy.svg";
import WEB3 from "assets/img/card/web3_icon.svg";
import ICON_IMMUTABLE from "assets/img/card/passport_icon.svg";
import ICON_BNB from "assets/img/card/bnb_icon.svg";
import { trimCharacter } from 'utils/string';
import { STATUS_USER_SHOW } from '../../constant';

export default function BasicInfo({ userDetail }) {

  const iconType = (type) => {
    switch (type) {
      case "IMMUTABLE":
        return ICON_IMMUTABLE
      case "DM2":
        return WEB3
      case "BICONOMY":
      case "POLYGON":
        return ICON_BICONOMY
      case "BNB":
        return ICON_BNB
      default:
        break;
    }
  }

  const showBgItems = (index) => {
    switch (index) {
      case 1:
        return "#5ebfff"
      case 2:
        return "#f3cce6"
      case 3:
        return "#a7a7a7"
      default:
        return "#5ebfff"
    }
  }

  return (
    <Box p="24px">
      <Text fontSize="20px" fontWeight="500">Basic Info</Text>
      <Flex alignItems="center" gap="20px">
        <Flex w="40%" justifyContent="center">
          <Image
            src={userDetail?.avatar_url}
            alt={userDetail?.name}
            objectFit="contain"
          />
        </Flex>
        <Flex flexDirection="column" gap="8px">
          <Flex flexDirection="column" gap="4px">
            <Text fontSize="20px" fontWeight='600'>{userDetail?.name}</Text>
            <Text fontSize="18px" fontWeight='500'>ID: {userDetail?.id}</Text>
            <Flex alignItems="center" gap=" 16px">
              <Text fontSize="18px" fontWeight='500'>Lv: {userDetail?.current_level?.split("_")[1] || "1000"}</Text>
              <Text fontSize="18px" fontWeight='500'>Skill rank: {userDetail?.title_rank || "SS"}</Text>
            </Flex>
            <Flex fontSize="18px" fontWeight='500' gap="4px">
              Status: <Text color={`${userDetail?.status === "ACTIVE" ? "#5eca33" : "#e03636"}`}>{STATUS_USER_SHOW[userDetail?.status]}</Text>
            </Flex>
            <Text fontSize="18px" fontWeight='500'>First time login: {userDetail?.first_login_at ? getDayLLLL(userDetail?.first_login_at * 1000) : "N/A"}</Text>
          </Flex>
          <Flex flexDirection="column" gap="8px" py="8px" borderTop="1px solid #ccc" borderBottom="1px solid #ccc">
            {userDetail?.wallets?.map((wallet, index) => (
              <Flex key={index} background={showBgItems(index+1)} borderRadius="12px" fontWeight="500">
                <Text p="6px 20px">{userDetail?.email || userDetail?.name}</Text>
                <Flex alignItems="center" p="6px 10px" borderLeft="1px solid #FFF">
                  <Image src={iconType(wallet?.wallet_type)} boxSize="24px" mr={4} alt={`icon`} />
                  <Text>{trimCharacter(wallet?.public_address, 6)}</Text>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}
