import {
  Icon,
  ViewIcon,
  EditIcon,
  CheckIcon,
  CloseIcon,
  AddIcon,
} from "@chakra-ui/icons";
import {
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  Input,
  Select,
  Image,
  Box,
} from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import { useMemo, useState } from "react";
import {
  CHAIN_TYPE,
  ONLY_POLYGON_CHAINS,
  OPTIONS_RARITY,
  RARITY,
} from "constants/constants";
import { CHAIN_NATIVE_TOKEN } from "constants/constants";
import ButtonType from "components/Button";
import { getStatusLabel } from "views/NFTsManagement/utils/utils";

export default function PackageRow({
  item,
  checkedIds,
  setCheckedIds,
  onClickDetail,
  onUpdateItem,
}) {
  const textColor = useColorModeValue("#000000", "white");
  const [editMode, setEditMode] = useState({
    name: false,
    price: false,
    chain_id: false,
    package_settings: false,
  });
  const [editedValues, setEditedValues] = useState({
    name: item.name,
    price: item.price,
    chain_id: item.chain_id,
    package_settings: item.package_settings || [],
  });

  const isChecked = useMemo(() => {
    return checkedIds.some((itemCheck) => itemCheck?.id === item?.id);
  }, [item?.id, checkedIds]);

  const getChainOptions = useMemo(() => {
    const isProduction = process.env.REACT_APP_ENV === "production";
    return [
      ...ONLY_POLYGON_CHAINS.filter((chain) =>
        isProduction
          ? chain.type === CHAIN_TYPE.MAINNET
          : chain.type === CHAIN_TYPE.TESTNET
      ).map((chain) => ({
        label: chain.label,
        value: chain.chain_id.toString(),
      })),
    ];
  }, []);

  const getChainLabel = (chainId) => {
    const chain = getChainOptions.find((option) => option.value === chainId);
    return chain ? chain.label : chainId;
  };

  const handleCheckboxChange = () => {
    if (isChecked) {
      setCheckedIds(
        checkedIds.filter((itemCheck) => itemCheck?.id !== item?.id)
      );
    } else {
      setCheckedIds([...checkedIds, item]);
    }
  };

  const hasMaxTwoDecimals = (num) => {
    const decimalPart = String(num).split(".")[1];
    return !decimalPart || decimalPart.length <= 2;
  };

  const handleEdit = (field) => {
    setEditMode({ ...editMode, [field]: true });
  };

  const handleSave = (field) => {
    if (field === "price" && !hasMaxTwoDecimals(editedValues.price)) {
      return;
    }

    setEditMode({ ...editMode, [field]: false });
    const updatedItem = { ...item, [field]: editedValues[field] };
    if (field === "chain_id") {
      updatedItem.currentSize = 0;
    }
    const formattedItem = {
      ...updatedItem,
      name: updatedItem?.name?.trim().slice(0, 100) || "",
      price: Number(updatedItem?.price),
    };
    console.log(formattedItem, "updatedItem");
    onUpdateItem(formattedItem);
  };

  const handleCancel = (field) => {
    setEditMode({ ...editMode, [field]: false });
    setEditedValues({ ...editedValues, [field]: item[field] });
  };

  const renderEditableCell = (field, value) => (
    <Td>
      {editMode[field] ? (
        <Flex alignItems="center">
          {field === "chain_id" ? (
            <Select
              value={editedValues[field]}
              onChange={(e) =>
                setEditedValues({ ...editedValues, [field]: e.target.value })
              }
            >
              {getChainOptions.map((chain) => (
                <option key={chain.value} value={chain.value}>
                  {chain.label}
                </option>
              ))}
            </Select>
          ) : (
            <Input
              value={editedValues[field]}
              onChange={(e) =>
                setEditedValues({ ...editedValues, [field]: e.target.value })
              }
            />
          )}
          <Icon
            as={CheckIcon}
            cursor={
              (field === "name" && editedValues?.name?.length > 100) ||
              (field === "price" && !hasMaxTwoDecimals(editedValues?.price))
                ? "not-allowed"
                : "pointer"
            }
            onClick={() =>
              (field === "name" && editedValues?.name?.length > 100) ||
              (field === "price" && !hasMaxTwoDecimals(editedValues?.price))
                ? null
                : handleSave(field)
            }
            ml={2}
          />
          <Icon
            as={CloseIcon}
            cursor="pointer"
            onClick={() => handleCancel(field)}
            ml={4}
          />
        </Flex>
      ) : (
        <Flex alignItems="center">
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight={400}
            noOfLines={field === "name" ? 1 : undefined}
            maxWidth={field === "name" ? "200px" : undefined}
            overflow={field === "name" ? "hidden" : undefined}
            textOverflow={field === "name" ? "ellipsis" : undefined}
            whiteSpace={field === "name" ? "nowrap" : undefined}
            title={field === "name" ? value : undefined}
          >
            {field === "chain_id"
              ? getChainLabel(value)
              : field === "price"
              ? `${value} ${CHAIN_NATIVE_TOKEN[item?.chain_id]}`
              : value}
          </Text>
          <Icon
            as={EditIcon}
            cursor="pointer"
            onClick={() => handleEdit(field)}
            ml={2}
          />
        </Flex>
      )}
    </Td>
  );

  const renderSettingsCell = () => (
    <Td>
      {editMode.package_settings ? (
        <Flex direction="column" gap={2}>
          {editedValues.package_settings.map((setting, index) => (
            <Flex key={index} mb={2} alignItems="flex-start" gap={2}>
              <Box flex={1}>
                <Select
                  value={setting.rarity}
                  onChange={(e) => {
                    const newSettings = [...editedValues.package_settings];
                    newSettings[index].rarity = e.target.value;
                    setEditedValues({
                      ...editedValues,
                      package_settings: newSettings,
                    });
                  }}
                  isRequired
                >
                  {OPTIONS_RARITY.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box flex={1}>
                <Input
                  value={setting.rate}
                  onChange={(e) => {
                    const newValue = Math.max(1, Number(e.target.value));
                    const newSettings = [...editedValues.package_settings];
                    newSettings[index].rate = newValue;
                    setEditedValues({
                      ...editedValues,
                      package_settings: newSettings,
                    });
                  }}
                  type="number"
                  min={1}
                  step={1}
                  placeholder="Enter weight"
                  isRequired
                  onBlur={(e) => {
                    if (!e.target.value || Number(e.target.value) < 1) {
                      const newSettings = [...editedValues.package_settings];
                      newSettings[index].rate = 1;
                      setEditedValues({
                        ...editedValues,
                        package_settings: newSettings,
                      });
                    }
                  }}
                />
              </Box>
              {editedValues.package_settings.length > 1 && (
                <Icon
                  as={CloseIcon}
                  cursor="pointer"
                  onClick={() => {
                    const newSettings = editedValues.package_settings.filter(
                      (_, i) => i !== index
                    );
                    setEditedValues({
                      ...editedValues,
                      package_settings: newSettings,
                    });
                  }}
                />
              )}
            </Flex>
          ))}

          {editedValues.package_settings.length < OPTIONS_RARITY.length && (
            <ButtonType
              onClick={() => {
                const newSettings = [
                  ...editedValues.package_settings,
                  { rarity: RARITY.RARITY_COMMON, rate: 1 },
                ];
                setEditedValues({
                  ...editedValues,
                  package_settings: newSettings,
                });
              }}
              btnType="primary-new-outline"
              leftIcon={<AddIcon />}
              mt={2}
            >
              Add Setting
            </ButtonType>
          )}

          <Text fontWeight="bold" mb={2}>
            Total weight:{" "}
            {editedValues.package_settings.reduce(
              (sum, setting) => sum + Number(setting.rate),
              0
            )}
          </Text>

          <Flex mt={2}>
            <Icon
              as={CheckIcon}
              cursor="pointer"
              onClick={() => handleSave("package_settings")}
              mr={2}
            />
            <Icon
              as={CloseIcon}
              cursor="pointer"
              onClick={() => handleCancel("package_settings")}
            />
          </Flex>
        </Flex>
      ) : (
        <Flex alignItems="center">
          <Flex direction="column" gap={2}>
            <Text color={textColor} fontSize="sm" fontWeight={400}>
              {item.package_settings?.map((setting) => (
                <Flex key={setting.rarity} gap={2}>
                  {`${setting.rarity} (${setting.rate})`}
                </Flex>
              ))}
            </Text>
          </Flex>
          <Icon
            as={EditIcon}
            cursor="pointer"
            onClick={() => handleEdit("package_settings")}
            ml={2}
          />
        </Flex>
      )}
    </Td>
  );

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isChecked}
          onChange={handleCheckboxChange}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Image w="50px" lazyLoad={true} src={item?.image_url} alt="NFT" />
      </Td>
      {renderEditableCell("name", item.name)}
      {renderEditableCell("chain_id", item.chain_id)}
      {renderEditableCell("price", item.price)}
      {renderSettingsCell()}
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item.current_quantity}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {getStatusLabel(item?.status)}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.sold_quantity}
        </Text>
      </Td>
      <Td>
        <Flex gap={6}>
          <Icon
            onClick={() => onClickDetail(item)}
            fontSize="16px"
            cursor="pointer"
            color={textColor}
            as={ViewIcon}
          />
        </Flex>
      </Td>
    </Tr>
  );
}
