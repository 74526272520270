import {
  SimpleGrid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import ButtonType from "components/Button";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import CheckboxCommon from "./CheckboxCommon";
import { updateRoles } from "api/role.api";
import useWithToast from "hooks/useWithToast";

export default function ModalUpdateRole({
  isOpen,
  onClose,
  rules,
  dataDetail,
  getRulesAndRoles
}) {
  const textColor = useColorModeValue("#000000", "white");
  const { showToastSuccess } = useWithToast();
  const toast = useToast();

  const defaultValues = useMemo(() => {
    let list = [];

    dataDetail?.rule_groups?.map((data) => {
      const dataFromRule = data?.rules?.map((rule) => {
        return {
          [`${rule?.id}`]: true,
        };
      })
      list = list.concat(dataFromRule);
      return dataFromRule;
    });
    if (list?.length > 0) {
      const result = list.reduce((acc, obj) => {
        return { ...acc, ...obj };
      }, {});

      return result;
    }
  }, []);

  const form = useForm({
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { isSubmitting },
  } = form;

  const onSubmit = async (data) => {
    let listRule = [];
    for (const [key, value] of Object.entries(data)) {
      const ruleId = key;
      if (value) {
        listRule.push({
          id: ruleId,
        });
      }
    }
    try {
      const dataSubmit = {
        id: dataDetail?.id,
        name: dataDetail?.name,
        order_by: dataDetail?.order_by,
        rules: listRule,
      }
      const res = await updateRoles(dataSubmit);
      if (res) {
        getRulesAndRoles();
        showToastSuccess({
          title: "Role saved successfully!",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      onClose();
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <SimpleGrid columns={2} spacing={10} gap={8}>
            {rules?.map((listRule, index) => (
              <CheckboxCommon key={index} watch={watch} setValue={setValue} control={control} listRule={listRule} />
            ))}
          </SimpleGrid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="800px" overflowY="auto">
        <ModalHeader
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          Edit Permission
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px 36px" minW="500px">
          {renderForm()}
        </ModalBody>
        <ModalFooter w="100%" textAlign="center" margin="auto">
          <ButtonType
            mt={4}
            btnType="primary"
            type="submit"
            m="auto"
            w="120px"
            h="46px"
            fontSize="16px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
