import { Flex } from "@chakra-ui/react";
import FormSelect from "components/form/FormSelect";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { prizeSelector } from "stores/prize/selector";
import { LIST_CHAIN_TESTNET } from "views/Users/UserManagement/constant";

export default function ConditionSelectPrize({
  isLoading,
  params,
  setParams,
}) {
  const form = useForm();
  const { handleSubmit, setValue } = form;

  const onSubmit = (data) => {
    if (!isLoading) {
      const newParams = {
        ...params,
        ...data,
        page: 1,
      };
      setParams(newParams);
    }
  };

  return (
    <Flex
      gap={4}
      flexWrap="wrap"
      justifyContent="space-between"
      w="100%"
      alignItems="flex-end"
    >
      <FormProvider {...form}>
        <form
          style={{
            maxWidth: "100%",
            width: "100%"
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Flex w="100%" alignItems="flex-end" gap={4}>
            <FormSelect
              wrapperProps={{
                w: "50%",
              }}
              name="chain_id"
              label="Chain"
              defaultValue={""}
              w="100%"
              options={[{ label: "All", value: "" }, ...LIST_CHAIN_TESTNET]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("chain_id", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  );
}
