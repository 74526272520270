import { Icon } from "@chakra-ui/icons";
import {
  Flex,
  Image,
  Stack,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import { useMemo } from "react";
import { ViewIcon } from "components/Icons/ViewIcon";
import { convertDateLocalLLLL } from "utils/time";
import { TYPE_PRIZE } from "constants/constants";

export default function ItemsSendRow({
  item,
  checkedIds,
  setCheckedIds,
  setValue,
  type,
  handleOpenDetailUserModal,
}) {
  const textColor = useColorModeValue("#000000", "white");

  const isChecked = useMemo(() => {
    return checkedIds.some((itemCheck) => itemCheck?.id === item?.id);
  }, [item?.id, checkedIds]);

  const handleCheckboxChange = () => {
    if (isChecked) {
      const dataCheck = checkedIds.filter((itemCheck) => itemCheck?.id !== item?.id)
      setCheckedIds(dataCheck);
      setValue("prize_items", dataCheck?.length > 0 ? dataCheck : null)
    } else {
      if (type === "PVP") {
        setCheckedIds([item]);
        setValue("prize_items", [item])
      } else {
        setCheckedIds([...checkedIds, item]);
        setValue("prize_items", [...checkedIds, item])
      }
    }
  };

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isChecked}
          onChange={handleCheckboxChange}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.name}
        </Text>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Image
            src={item?.image_url}
            w="100px"
            alt={item?.image_url}
          />
        </Stack>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {TYPE_PRIZE[item?.prize_type]}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.prize_status}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {convertDateLocalLLLL(item?.start_sale_period * 1000)}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {convertDateLocalLLLL(item?.end_sale_period * 1000)}
        </Text>
      </Td>
      <Td>
        <Icon
          fontSize="30px"
          borderRadius="8px"
          cursor="pointer"
          as={ViewIcon}
          onClick={() => handleOpenDetailUserModal(item)}
        />
      </Td>
    </Tr>
  );
}
