import { AddIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { CancelIcon } from "components/Icons/CancelIcon";
import { useMemo } from "react";
import { GoArrowSwitch } from "react-icons/go";
import {
  IoBag,
  IoRepeat,
  IoOptions,
  IoArrowDown,
} from "react-icons/io5";
import { RiUserFollowLine, RiUserForbidLine } from "react-icons/ri";
import styled from "styled-components";
import BgBtn from "assets/img/card/btn_main.png";

const ButtonTypeStyled = styled(Button)`
  &[disabled] {
    &:hover {
      opacity: 0.6;
      transform: scale(1);
    }
  }
`;

export default function ButtonType({
  btnType = "primary",
  text = "SAVE",
  handleClick,
  iconType,
  sizeIcon = "12px",
  children,
  ...rest
}) {
  const stylesBtn = useMemo(() => {
    if (btnType === "primary-new") {
      return {
        backgroundImage: "linear-gradient(90deg, #FFC515 0%, #F79325 100%)",
        color: "#fff",
        borderRadius: "0.5rem",
      };
    }
    if (btnType === "primary-new-outline") {
      return {
        border: "1px solid #F79325",
        borderRadius: "0.5rem",
        color: "#F79325",
        backgroundColor: "#fff",
      };
    }
    if (btnType === "primary-outline") {
      return {
        border: "1px solid #cb0c9f",
        borderRadius: "0.5rem",
        color: "#cb0c9f",
        backgroundColor: "#fff",
      };
    }
    if (btnType === "success") {
      return {
        backgroundImage: "linear-gradient(310deg,#17ad37,#98ec2d)",
        color: "#fff",
        borderRadius: "0.5rem",
      };
    }
    if (btnType === "danger") {
      return {
        backgroundImage: "linear-gradient(310deg,#ea0606,#ff667c)",
        color: "#fff",
        borderRadius: "0.5rem",
      };
    }
    if (btnType === "warning") {
      return {
        backgroundImage: "linear-gradient(90deg, #FF512F 0%, #DD2476 100%)",
        color: "#fff",
        borderRadius: "0.5rem",
      };
    }
    if (btnType === "info") {
      return {
        backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
        color: "#fff",
        borderRadius: "0.5rem",
      };
    }
    if (btnType === "light") {
      return {
        bg: "#e9ecef",
        color: "#000000",
        borderRadius: "0.5rem",
      };
    }
    if (btnType === "dark") {
      return {
        backgroundImage: "linear-gradient(310deg,#141727,#3a416f)",
        color: "#fff",
        borderRadius: "0.5rem",
      };
    }
    if (btnType === "dark-outline") {
      return {
        border: "1px solid #000000",
        borderRadius: "0.5rem",
        color: "#000000",
        backgroundColor: "#fff",
      };
    }
    if (btnType === "cancel-sell") {
      return {
        backgroundImage: BgBtn,
        backgroundPosition: "center",
        border: "2px solid #000",
        color: "#000000",
      };
    }
    return {
      backgroundImage: "linear-gradient(90deg, #FFC515 0%, #F79325 100%)",
      color: "#fff",
      borderRadius: "0.5rem",
    };
  }, [btnType]);

  const iconBtn = useMemo(() => {
    if (iconType === "plus") {
      return <AddIcon fontSize={sizeIcon} />;
    }
    if (iconType === "ban") {
      return <RiUserForbidLine fontSize={sizeIcon} color="white" />;
    }
    if (iconType === "unban") {
      return <RiUserFollowLine fontSize={sizeIcon} color="white" />;
    }
    if (iconType === "switch") {
      return <GoArrowSwitch fontSize={sizeIcon} color="#000000" />;
    }
    if (iconType === "shop") {
      return <IoBag fontSize={sizeIcon} color="white" />;
    }
    if (iconType === "cancel") {
      return <CancelIcon fontSize={sizeIcon} color="white" />;
    }
    if (iconType === "transfer") {
      return <IoRepeat fontSize={sizeIcon} color="white" />;
    }
    if (iconType === "change-state") {
      return <IoOptions fontSize={sizeIcon} color="white" />;
    }
    if (iconType === "download") {
      return <IoArrowDown fontSize={sizeIcon} color="white" />;
    }
    return null;
  }, [iconType]);

  return (
    <ButtonTypeStyled
      {...stylesBtn}
      boxShadow="0 4px 7px -1px rgba(0,0,0,.11),0 2px 4px -1px rgba(0,0,0,.07)"
      leftIcon={iconBtn}
      _hover={{
        opacity: "1",
        transform: "scale(1.02)",
      }}
      _active={{
        opacity: "1",
      }}
      _disabled={{
        cursor: "not-allowed",
        opacity: 0.6,
      }}
      {...rest}
    >
      {children || text}
    </ButtonTypeStyled>
  );
}
