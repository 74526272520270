import {
  Box,
  Flex,
  Image,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import { capitalize, startCase, toLower } from "lodash";

const NFTsOwnerSummaryPanel = ({ isOwnerDetail, summaryByNftType = {} }) => {
  const textColor = useColorModeValue("#000000", "white");
  const bgColorOwner = useColorModeValue("gray.50", "gray.700");

  const getItemColor = (key) => {
    return toLower(key).includes("nfts") ? "blue.600" : "inherit";
  };

  const renderSummaryOwnerItem = (
    key,
    title,
    value = 0,
    icon,
    sold,
    typeDisplay
  ) => (
    <Flex
      key={key}
      alignItems={"center"}
      justifyContent={isOwnerDetail ? "center" : "inherit"}
    >
      <Text
        fontSize={isOwnerDetail ? "xs" : "md"}
        fontWeight="bold"
        color={getItemColor(title)}
        whiteSpace={"nowrap"}
      >
        {typeDisplay !== "chain" ? (
          `${capitalize(startCase(title))}:`
        ) : (
          <Box>
            {title ? (
              `${title}:`
            ) : (
              <Image src={icon} boxSize="20px" mr={1} alt={`${name} icon`} />
            )}
          </Box>
        )}
      </Text>
      <Text fontSize="md" fontWeight="bold" color="blue.600" ml={1}>
        {value || 0}
      </Text>
      {typeDisplay === "chain" && !isOwnerDetail && (
        <Text
          fontSize="xs"
          fontWeight="bold"
          color="#f7bf2e"
          ml={1}
          mt={0.5}
          whiteSpace={"nowrap"}
        >
          {`(sold: ${sold || 0})`}
        </Text>
      )}
    </Flex>
  );

  return (
    <Card
      px="10px"
      py="5px"
      bg={bgColorOwner}
      boxShadow="unset"
      borderRadius="8px"
    >
      <Flex direction="column">
        <Text
          color={textColor}
          fontSize={isOwnerDetail ? "md" : "xl"}
          fontWeight="bold"
          mb={isOwnerDetail ? 2 : 6}
        >
          Summary
        </Text>
        <Box>
          <SimpleGrid
            columns={{
              base: 1,
              md: 4,
            }}
            spacing={6}
            mb={isOwnerDetail ? 2 : 4}
            mx={16}
          >
            {summaryByNftType?.chainData?.map(
              ({ name, count, icon, sold }, index) =>
                renderSummaryOwnerItem(index, name, count, icon, sold, "chain")
            )}
          </SimpleGrid>
          <SimpleGrid
            columns={{
              base: 1,
              md: isOwnerDetail ? 1 : 3,
            }}
            spacing={6}
            mb={isOwnerDetail ? 2 : 4}
            mx={16}
          >
            {Object.entries(summaryByNftType?.summary)?.map(
              ([name, count], index) =>
                renderSummaryOwnerItem(index, name, count)
            )}
          </SimpleGrid>
        </Box>
      </Flex>
    </Card>
  );
};

export default NFTsOwnerSummaryPanel;
