import { Box, Flex, Text, useDisclosure, useToast } from '@chakra-ui/react'
import { getBountyBallNFTsUser } from 'api/bountyBall.api';
import { getGauntletNFTsUser } from 'api/gauntlet.api';
import { getHunterNFTsUser } from 'api/hunterNFTs.api';
import React, { useCallback, useEffect, useState } from 'react'
import { NFT_MANAGEMENT_TYPE } from 'views/NFTsManagement/constants';
import ItemNft from './itemNft';
import NFTDetailModal from "views/NFTsManagement/components/modal/NFTDetailModal";
import { useNFTList } from 'views/NFTsManagement/hooks/useNFTList';
import { getGenesisNFTsUser } from 'api/genesisNFTs.api';

export default function ListNfts(props) {
  const { nftMngType, userDetail } = props
  const {
    nftDetail,
    getNFTDetail,
  } = useNFTList(nftMngType);

  const [isLoading, setIsLoading] = useState(false);
  const [nftsUser, setNftsUser] = useState([]);
  const toast = useToast();

  const {
    isOpen: isOpenDetailUser,
    onOpen: onOpenDetailUser,
    onClose: onCloseDetailUser,
  } = useDisclosure();

  const handleOpenDetailUserModal = async (item) => {
    await getNFTDetail(item);
    onOpenDetailUser();
  };

  const getUsers = useCallback(
    async () => {
      try {
        setIsLoading(true);
        let data
        if (nftMngType === NFT_MANAGEMENT_TYPE.HUNTER) {
          data = await getHunterNFTsUser(userDetail?.id, {
            page: 1,
            limit: 999,
          });
        } else if (nftMngType === NFT_MANAGEMENT_TYPE.GAUNTLET) {
          data = await getGauntletNFTsUser(userDetail?.id, {
            page: 1,
            limit: 999,
          });
        } else if (nftMngType === NFT_MANAGEMENT_TYPE.GENESIS) {
          data = await getGenesisNFTsUser(userDetail?.id, {
            page: 1,
            limit: 999,
          });
        } else {
          data = await getBountyBallNFTsUser(userDetail?.id, {
            page: 1,
            limit: 999,
          });
        }
        if (data?.data?.success) {
          const res = data?.data?.data;
          setNftsUser(res?.records);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <Flex alignItems="center" gap="6px" overflowX="scroll" py="8px">
      {nftsUser?.length > 0 ? 
        nftsUser?.map((nfts, index) => (
          <ItemNft key={index} item={nfts} tabIndex="1" handleOpenDetailUserModal={handleOpenDetailUserModal}/>
        )) : (
        <Box width="100%" textAlign="center">
          <Text>No data</Text>
        </Box>
      )}
      {isOpenDetailUser && (
        <NFTDetailModal
          nftMngType={nftMngType}
          item={nftDetail}
          isOpen={isOpenDetailUser}
          onClose={onCloseDetailUser}
        />
      )}
    </Flex>
  )
}
