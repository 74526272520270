import { containSpecialRegex } from "utils/regex";
import * as yup from "yup";

const schemaNonNftGacha = () =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .required("Required")
      .max(30, "Name must be at most 30 characters")
      .matches(containSpecialRegex, "Name must not contain special characters"),
  });

export default schemaNonNftGacha;
