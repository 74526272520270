import {
  Flex,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { getHunterNFTs } from "api/hunterNFTs.api";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import NftsRow from "./row";
import { useCallback, useEffect, useState } from "react";
import { NFT_MANAGEMENT_TYPE } from "views/NFTsManagement/constants";
import { getGauntletNFTs } from "api/gauntlet.api";
import { getBountyBallNFTs } from "api/bountyBall.api";
import CardFooter from "components/Card/CardFooter";
import ButtonType from "components/Button";
import ModalConfirmTransfer from "./ModalConfirmTransfer";
import NFTDetailModal from "views/NFTsManagement/components/modal/NFTDetailModal";
import { useNFTList } from "views/NFTsManagement/hooks/useNFTList";
// import UserDetailComponent from "./components/detail";

export default function ListNft(props) {
  const { 
    params,
    setParams,
    nftMngType,
    isLoading,
    setIsLoading,
    handleSendNfts,
    userDetail, 
    isOpenConfirmTransfer,
    onOpenConfirmTransfer,
    onCloseConfirmTransfer
  } = props
  
  const labels = [
    "Image",
    "Name",
    "Chain",
    "Rarity",
    "Trait",
    "",
  ];
  const [users, setNfts] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  // const [nftDetail, setNftDetail] = useState(null);
  const toast = useToast();

  const {
    nftDetail,
    getNFTDetail,
  } = useNFTList(nftMngType);

  const {
    isOpen: isOpenDetailNft,
    onOpen: onOpenDetailNft,
    onClose: onCloseDetailNft,
  } = useDisclosure();

  const getUsers = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        setCheckedIds([]);
        let data
        if (nftMngType === NFT_MANAGEMENT_TYPE.HUNTER) {
          data = await getHunterNFTs({
            ...params,
            ...newParams,
          });
        } else if (nftMngType === NFT_MANAGEMENT_TYPE.GAUNTLET) {
          data = await getGauntletNFTs({
            ...params,
            ...newParams,
          });
        } else {
          data = await getBountyBallNFTs({
            ...params,
            ...newParams,
          });
        }
        if (data?.data?.success) {
          const res = data?.data?.data;
          setNfts(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  // detail user modal

  const handleOpenDetailUserModal = async (item) => {
    await getNFTDetail(item);
    // setNftDetail(item);
    onOpenDetailNft(true);
  };

  const handleCloseDetailUserModal = () => {
    onCloseDetailNft();
  };

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setCheckedIds(users);
    } else {
      setCheckedIds([]);
    }
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <Flex flexDirection="column">
      <Card p="0px">
        <CardBody overflowX="auto">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck
            isChecked={users?.length > 0 && checkedIds.length === users?.length}
            onChangeChecked={onChangeChecked}
            dataRow={users}
          >
            <>
              {users?.map((item) => {
                return (
                  <NftsRow
                    key={item?.id}
                    setCheckedIds={setCheckedIds}
                    checkedIds={checkedIds}
                    handleOpenDetailUserModal={handleOpenDetailUserModal}
                    item={item}
                    nftMngType={nftMngType}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
        <CardFooter
          w="100%"
          textAlign="center"
          mt="16px"
          gap="20px"
        >
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            disabled={!checkedIds?.length}
            onClick={(e) => {
              e.preventDefault()
              onOpenConfirmTransfer()
            }}
            // onClick={() => handleSendNfts(checkedIds)}
          >
            SEND
          </ButtonType>
        </CardFooter>
      </Card>
      {isOpenConfirmTransfer && (
        <ModalConfirmTransfer
          isOpen={isOpenConfirmTransfer}
          onClose={onCloseConfirmTransfer}
          checkedIds={checkedIds}
          handleSendNfts={handleSendNfts}
          userDetail={userDetail}
        />
      )}
      {isOpenDetailNft && (
        <NFTDetailModal
          nftMngType={nftMngType}
          item={nftDetail}
          isOpen={isOpenDetailNft}
          onClose={onCloseDetailNft}
        />
      )}
    </Flex>
  );
}
  