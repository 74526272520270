import { Box, Flex, Image, Text } from "@chakra-ui/react";
import ButtonType from "components/Button";
import CheckBoxType from "components/Checkbox";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";

import EditIcon from 'assets/svg/edit.svg'
import PrizeIcon from 'assets/svg/prize-game-mode.svg'
import machineIcon from 'assets/svg/machine.svg'

export default function ItemCheck({
  item,
  isClickAble = false,
  nftsChecked,
  setNFTsChecked,
  type = "hunter",
}) {
  const history = useHistory();
  const isChecked = useMemo(() => {
    if (isClickAble) {
      return nftsChecked?.some((itemCheck) => itemCheck?.id === item?.id);
    }
    return false;
  }, [item?.id, nftsChecked, isClickAble]);

  const handleClickCheck = () => {
    if (isClickAble) {
      if (isChecked) {
        setNFTsChecked(
          nftsChecked.filter((itemCheck) => itemCheck?.id !== item?.id)
        );
      } else {
        setNFTsChecked([...nftsChecked, item]);
      }
    } else {
      return history.push(`/admin/${type}/nft-detail/${item?.id}`);
    }
  };

  return (
    <Flex
      direction="column"
      gap={2}
      w="100%"
      maxW="100%"
      p="10px"
      borderRadius="10px"
      bg="linear-gradient(160deg, #69EAE3 40%, #18A69E 98.37%)"
      cursor="pointer"
      onClick={handleClickCheck}
      position="relative"
    >
      {isChecked && (
        <Box
          position="absolute"
          borderRadius="10px"
          top={0}
          left={0}
          w="100%"
          h="100%"
          bg="rgba(0, 0, 0, 0.6)"
        >
          <CheckBoxType isChecked position="absolute" top="10px" left="10px" />
        </Box>
      )}
      <Flex direction="column" gap={2}>
        <Flex alignItems="center" justifyContent="center">
          <Box width="150px"> {/* Thiết lập độ rộng cho Box */}
            <Text
              fontSize={{
                base: "16px",
              }}
              textAlign="center"
              fontWeight="700"
              isTruncated
            >
              {item?.mode_name}
            </Text>
          </Box>
        </Flex>
        <Flex
          p="2px 6px"
          w="100%"
          borderRadius="6px"
          textAlign="start"
          backgroundColor="#f82e38"
          color="#FFF"
          fontSize="14px"
          fontWeight="500"
          direction="column"
        >
          <Text>Lv. : 100-200</Text>
          <Text>Skill rank : E-A</Text>
          <Text>Arena Hunter: 200-400</Text>
        </Flex>
        <Box
          p="2px 6px"
          w="100%"
          borderRadius="6px"
          textAlign="center"
          backgroundColor="rgba(0, 0, 0, 0.4)"
          color="#FFF"
        >
          3 turns
        </Box>
        <Flex direction="row" gap="4px" borderRadius="6px">
          <Flex p="6px" background="#fff" borderRadius="6px">
            <Text fontSize="14px" fontWeight="500" color="#D32C35">150k BC</Text>
            <Text fontSize="14px" fontWeight="500"> /turn</Text>
          </Flex>
          <Flex p="6px" background="#fff" borderRadius="6px">
            <Text fontSize="14px" fontWeight="500" color="#D32C35">1 LG</Text>
            <Text fontSize="14px" fontWeight="500"> /turn</Text>
          </Flex>
        </Flex>
        <Flex alignItems="center" justifyContent="flex-end" gap="6px">
          <Flex w="30px" h="30px" borderRadius="8px" background="#000" alignItems="center" justifyContent="center">
            <Image src={machineIcon} alignSelf='center' justifySelf='center' />
          </Flex>
          <Flex w="30px" h="30px" borderRadius="8px" background="#000" alignItems="center" justifyContent="center">
            <Image src={PrizeIcon} alignSelf='center' justifySelf='center' />
          </Flex>
          <Flex w="30px" h="30px" borderRadius="8px" background="#000" alignItems="center" justifyContent="center">
            <Image src={EditIcon} alignSelf='center' justifySelf='center' />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
