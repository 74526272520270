import {
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";

export default function LeaderBoardRow({
  item,
}) {
  const textColor = useColorModeValue("#000000", "white");

  return (
    <Tr>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.username}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.current_free_ticket}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.current_bcoin1}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.current_bcoin1}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.current_bcoin1}
        </Text>
      </Td>
    </Tr>
  );
}
