import {
  Flex,
  Box,
  Spacer,
  Heading
} from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import ButtonType from "components/Button";
import FormSelect from "components/form/FormSelect";
import {SYSTEM_COINS} from "constants/constants"

export default function BonusPassItem ({ index, name, onSave, isEdit, setIsEdit }) {

  return (
    <Flex
      w="100%"
      flexDirection="column"
      mb="4"
    >
      <Flex w="100%" alignItems="center" mb="2">
        <Box>
          <Heading size="sm" textAlign="left">
            { name == 'BONUS_PASS_A' ? 'Bonus Pass A' : 'Bonus Pass B'}
          </Heading>
        </Box>
        <Spacer />
        <Box mr="2">
          { isEdit ? (
            <ButtonType
              text="SAVE"
              w="80px"
              fontSize="12px"
              onClick={() => onSave() }
            />
          ) : (
            <ButtonType
              text="EDIT"
              w="80px"
              fontSize="12px"
              onClick={() => setIsEdit(true)}
            />
          )}
        </Box>
      </Flex>
      <Flex w="100%" alignItems="center" justifyContent="space-between">
        <FormInput type="hidden" label="" name={`bonusPasses.${index}.name`} />
        <Box flex="1">
          <FormInput
            isDisabled={!isEdit}
            label="Price"
            step={1}
            name={`bonusPasses.${index}.price`}
            type="number"
          />
        </Box>
        <Box flex="1" ml="4">
          <FormSelect
            isDisabled={!isEdit}
            label="Currency"
            name={`bonusPasses.${index}.currency`}
            options={SYSTEM_COINS}
          />
        </Box>
      </Flex>
    </Flex>
  );
}
