import React from 'react'
import { Flex, Image, Text, Box } from '@chakra-ui/react';
import { STATUS_MACHINE } from 'views/Machine/MachineState/constants';
import { getDayLLLL } from 'utils/time';
import styled from "styled-components";

const WrapItem = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
`

export default function ItemUser({ userDetail }) {

  const showBgItems = (index) => {
    switch (index) {
      case 1:
        return "#5ebfff"
      case 2:
        return "#fff174"
      case 3:
        return "#8dff7e"
      case 4:
        return "#ff9cff"
      case 5:
        return "#dba6ff"
      case 6:
        return "#ffb0be"
      default:
        return "#5ebfff"
    }
  }

  return (
    <Box p="24px">
      <Text fontSize="20px" fontWeight="500">Items</Text>
      <Flex flexDirection='column' py="20px">
        <Flex>
          <Text Text width="30%" fontWeight="500">TYPE</Text>
          <Text Text width="70%" fontWeight="500">VALUE</Text>
        </Flex>
        <WrapItem>
          <Text width="30%" p="4px 10px">B-Coin</Text>
          <Flex flexDirection="column" gap="4px" py="8px">
            <Text p="2px 10px" fontSize="12px" background={showBgItems(1)} borderRadius="12px">Bcoin 1: {userDetail?.resource?.bcoin1}</Text>
            <Text p="2px 10px" fontSize="12px" background={showBgItems(6)} borderRadius="12px">Bcoin 2 locked: {userDetail?.resource?.bcoin2}</Text>
            <Text p="2px 10px" fontSize="12px" background={showBgItems(2)} borderRadius="12px">Bcoin 2 unlocked: {userDetail?.resource?.bcoin_locked}</Text>
          </Flex>
        </WrapItem>
        <WrapItem>
          <Text width="30%" p="4px 10px">Sphere</Text>
          <Text>{userDetail?.resource?.sphere}</Text>
        </WrapItem>
        <WrapItem>
          <Text width="30%" p="4px 10px">Orb</Text>
          <Text>{userDetail?.resource?.orb}</Text>
        </WrapItem>
        <WrapItem>
          <Text width="30%" p="4px 10px">Ticket</Text>
          <Flex flexDirection="column" gap="4px" py="8px">
            <Text p="2px 10px" fontSize="12px" background={showBgItems(1)} borderRadius="12px">Free Ticket: {userDetail?.ticket?.non_nft_ticket?.bcoin_ticket_free}</Text>
            <Text p="2px 10px" fontSize="12px" background={showBgItems(2)} borderRadius="12px">100BC Ticket: {userDetail?.ticket?.non_nft_ticket?.bcoin_ticket100}</Text>
            <Text p="2px 10px" fontSize="12px" background={showBgItems(3)} borderRadius="12px">500BC Ticket: {userDetail?.ticket?.non_nft_ticket?.bcoin_ticket500}</Text>
            <Text p="2px 10px" fontSize="12px" background={showBgItems(4)} borderRadius="12px">1000BC Ticket: {userDetail?.ticket?.non_nft_ticket?.bcoin_ticket1_k}</Text>
            <Text p="2px 10px" fontSize="12px" background={showBgItems(5)} borderRadius="12px">100k BC Ticket: {userDetail?.ticket?.non_nft_ticket?.bcoin_ticket100_k}</Text>
          </Flex>
        </WrapItem>
        {/* <WrapItem>
          <Text width="30%" p="4px 10px">NFT Ticket</Text>
          <Flex flexDirection="column" gap="4px" py="8px">
            <Text p="2px 10px" fontSize="12px" background={showBgItems(1)} borderRadius="12px">$1: {userDetail?.ticket?.nft_ticket?.nft1_dollar_ticket || 0}</Text>
            <Text p="2px 10px" fontSize="12px" background={showBgItems(6)} borderRadius="12px">$10: {userDetail?.ticket?.nft_ticket?.nft10_dollar_ticket || 0}</Text>
          </Flex>
        </WrapItem> */}
        <WrapItem>
          <Text width="30%" p="4px 10px">CP</Text>
          <Flex flexDirection="column" gap="4px" py="8px">
            <Text p="2px 10px" fontSize="12px" background={showBgItems(1)} borderRadius="12px">Current: {userDetail?.user_contribute_point?.current_cp}</Text>
            <Text p="2px 10px" fontSize="12px" background={showBgItems(6)} borderRadius="12px">Total: {userDetail?.user_contribute_point?.total_cp || 0}</Text>
          </Flex>
        </WrapItem>
        <WrapItem>
          <Text width="30%" p="4px 10px">AP</Text>
          <Flex flexDirection="column" gap="4px" py="8px">
            <Text p="2px 10px" fontSize="12px" background={showBgItems(1)} borderRadius="12px">Current: {userDetail?.user_attribute_point?.current_ap}</Text>
            <Text p="2px 10px" fontSize="12px" background={showBgItems(6)} borderRadius="12px">Total: {userDetail?.user_attribute_point?.lifetime_points || 0}</Text>
          </Flex>
        </WrapItem>
      </Flex>
    </Box>
  )
}
