import { containSpecialRegex } from "utils/regex";
import * as yup from 'yup'

const schemaPrize = () =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .matches(containSpecialRegex, "Name should not contain special characters")
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    name_jp: yup
      .string()
      .trim()
      .matches(containSpecialRegex, "Name should not contain special characters")
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    // prize_img: yup
    //   .string()
    //   .trim()
    //   .required("Required"),
    assist_gauge_limit: yup
      .string()
      .trim()
      .required("Required"),
    // bg_img: yup
    //   .string()
    //   .trim()
    //   .required("Required"),
    prize_items: yup
      .array()
      .required("Required"),
    start: yup
      .date()
      .required("Required"),
    end: yup
      .date()
      .test(
        "is-greater-start",
        "Result end time must be after start time",
        function (value) {
          const { start } = this.parent;
          return (
            start &&
            value &&
            new Date(value) > new Date(start)
          );
        }
      )
      .required(),
  });

export default schemaPrize
