import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Input, useDisclosure, useToast } from "@chakra-ui/react";
import { deleteMachineSettings } from "api/machine.api";
import ButtonType from "components/Button";
import { useMemo, useRef } from "react";
import DeleteModal from "./components/deleteModal";
import { Button } from "react-scroll";

export default function ActionMachineSetting({
  handleOpenCreateOrUpdate,
  checkedIds,
  setCheckedIds,
  setParams,
  params,
  toggleModal,
}) {
  const toast = useToast();

  const isHaveCheckedIds = useMemo(() => {
    return checkedIds?.length > 0;
  }, [checkedIds]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const deleteItem = async (id) => {
    return deleteMachineSettings(id);
  };

  const handleDelete = async () => {
    try {
      if (isHaveCheckedIds) {
        const promise = checkedIds?.map((machine) => {
          return deleteItem(machine?.id);
        });
        const res = await Promise.all(promise);
        if (res && res[0]?.data?.success) {
          setCheckedIds([]);
          toast({
            title: "Delete success.",
            description: "Delete success.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          const newParams = {
            ...params,
            page: 1,
          };
          setParams(newParams);
          onClose();
        }
      }
    } catch (error) {
      onClose();
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Flex gap={4} flexWrap="wrap" flexDirection="column" w="100%" >
      <Flex alignItems="center" justifyContent="space-between" flexWrap="wrap" gap={4} m="10px 0">
        <Flex alignItems="center" gap={4} m="10px 0">
          <ButtonType
            text="NEW"
            w="120px"
            fontSize="12px"
            onClick={() => {
              handleOpenCreateOrUpdate();
            }}
          />
          {/* <ButtonType
            text="REMOVE"
            btnType="primary-new-outline"
            fontSize="12px"
            disabled={!isHaveCheckedIds}
            w="120px"
            onClick={onOpen}
          /> */}
          <ButtonType
            text="STATE"
            fontSize="12px"
            w="120px"
            btnType="primary-new-outline"
            disabled={!isHaveCheckedIds}
            onClick={() => toggleModal("state")}
          />
          <ButtonType
            w="120px"
            fontSize="12px"
            text="DIRECTION"
            btnType="primary-new-outline"
            disabled={!isHaveCheckedIds}
            onClick={() => toggleModal("direction")}
          />
        </Flex>
        <ButtonType
          w="120px"
          fontSize="12px"
          text="MACHINE GROUPS"
          btnType="primary-new-outline"
          onClick={() => toggleModal("machineGroups")}
        />
      </Flex>
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        handleDelete={handleDelete}
      />
    </Flex>
  );
}
