import { Flex, Text, FormLabel, Grid } from '@chakra-ui/react';
import FormDatePicker from 'components/form/FormDatePicker';
import FormInput from 'components/form/FormInput';
import FormLabelComponent from 'components/form/FormLabel';
import EditorLink from 'components/EditorAddLink/EditorLink'
import FormUpload from 'components/form/FormUpload';
import React from 'react'
import CheckBoxType from "components/Checkbox";
import FormCheckbox from "components/form/FormCheckbox";
import { useState, useEffect } from "react";

export default function FormEng(props) {
  const [isCheckedPublishNow, setIsCheckedPublishNow] = useState(false);
  const [checkedCategory, setCheckedCategory] = useState([]);
  const [descriptions, setDescriptions] = useState(null);
  const {
    transLabel,
    disableWhenJP,
    handleImageChange,
    handleDragImage,
    deleteImage,
    nameImage,
    setValue,
    dateFormat,
    onOpenCoutryItems,
    checkedIds,
    setCheckedIds,
    countries,
    categories,
    dataDetail,
    getValues
  } = props

  useEffect(() => {
    if (dataDetail) {
      setDescriptions(dataDetail.content_jp)
      setCheckedIds(dataDetail.restricted_countries);
      setCheckedCategory(dataDetail.categories);
    }
  }, [dataDetail]);

  const handleCheckboxChange = (isChecked) => {
    setIsCheckedPublishNow(!isCheckedPublishNow)
    if (isChecked) {
      var now = new Date()
      setValue("publish_at", now);
    } else {
      setValue("publish_at", null);
    }
  };

  const handleCheckboxChangeAll = (isChecked) => {
    if (isChecked) {
      setCheckedIds(countries.map(country => country.country_code))
    } else {
      setCheckedIds([]);
    }
  };

  const handleCheckboxChangeCategory = (isChecked, category) => {
    if (isChecked) {
      setValue("value", [category]);
      setCheckedCategory([category])
    } else {
      setValue("value", []);
      setCheckedCategory([])
    }
  };

  const categoryChecked = () => {
    if (!categories || !dataDetail?.categories) return [];
    return categories
        .filter(category => dataDetail.categories.includes(category.id))
        .map(category => category.id);
  };

  useEffect(() => {
    setDescriptions(getValues('content_jp') || dataDetail?.content_jp)
  }, []);

useEffect(() => {
  setCheckedCategory(getValues("type") || categoryChecked());
}, [categories, dataDetail]);

  return (
    <Flex flexDirection="column" gap="12px">
      <Flex w="100%" gap="8px">
        <Flex direction="column" gap="8px" w="100%">
          <FormLabelComponent
            m="0px"
            title={transLabel("publicDate")}
            isRequired
          />
          <Flex direction="row" gap="10px" w="100%" mr="3" alignItems="center">
            <Flex direction="column" w="50%">
              <FormDatePicker
                name="publish_at"
                showTimeSelect
                dateFormat={dateFormat}
                h="40px"
                disabled={isCheckedPublishNow || disableWhenJP}
              />
            </Flex>
            <Text fontSize="14px" fontWeight="400">OR</Text>
            <CheckBoxType
              isChecked={isCheckedPublishNow}
              onChange={(e) => handleCheckboxChange(e.target.checked)}
              colorScheme="teal"
              disabled={disableWhenJP}
            />
             <Text fontSize="14px" fontWeight="400">{transLabel("publicNow")}</Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex gap={2} textAlign="center">
        <FormLabel whiteSpace="nowrap" color="#000000">
          {transLabel("targetContries")}
        </FormLabel>
        <Text
          padding="4px 8px"
          borderRadius="12px"
          background="#faf871"
          fontSize="14px"
          fontWeight="400"
        >
          {checkedIds?.length}/{countries.length}
        </Text>
      </Flex>
      <Flex gap={2}>
        <CheckBoxType
          isChecked={checkedIds.length === countries.length && countries.length > 0}
          onChange={(e) => handleCheckboxChangeAll(e.target.checked)}
          colorScheme="teal"
          disabled={disableWhenJP}
        />
        <Text fontSize="14px" fontWeight="400">{transLabel("all")}</Text>
      </Flex>
      <FormInput
        maxW="100%"
        name="coutry_items"
        value=""
        label=""
        placeholder={transLabel("addContries")}
        onClick={() => onOpenCoutryItems()}
        autoComplete="off"
        disabled={disableWhenJP}
      />
      <Flex direction="column" gap="12px" w="100%" padding="8px 0">
        <FormLabelComponent
          m="0px"
          title={transLabel("category")}
          isRequired
        />
        <Grid templateColumns='repeat(2, 1fr)' gap='16px'>
          {categories.map((item) => (
            <Flex
              key={item.id}
              flexDirection="row"
              alignItems="center"
              width="calc(50% - 10px)"
              >
              <FormCheckbox
                name={`${item?.type}`}
                label=""
                isChecked={checkedCategory.includes(item.id)}
                onChange={(e) => {
                const isChecked = e.target.checked;
                handleCheckboxChangeCategory(isChecked, item.id);
                }}
                isRequired
                wrapperProps={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0"
                }}
                disabled={disableWhenJP}
              />
              <Text ml="2" >{ item.name} </Text>
            </Flex>
          ))}
        </Grid>
      </Flex>
      <FormInput
        maxW="100%"
        name="header_jp"
        label={transLabel("header")}
        placeholder={transLabel("enterHeader")}
        isRequired
      />
      <EditorLink
        placeholder={transLabel("enterDesc")}
        setValue={setValue}
        name="content_jp"
        descriptions={descriptions}
        lable={transLabel("description")}
      />
      <FormUpload
        label={transLabel("selectImage")}
        title={transLabel("banner")}
        name="banner_url_jp"
        type='IMAGE'
        accept={"image/*"}
        textButton={nameImage}
        isRequired
        handleOnChange={(e) =>
          handleImageChange(e, "banner_url_jp")
        }
        handleDragImage={(val) =>
          handleDragImage(val, "banner_url_jp")
        }
        deleteImage={() => deleteImage("banner_url_jp")}
      />
      <Text fontSize='xs' mt={1} color="#000000">
        <span
          style={{
            color: 'red',
          }}
        >
          *
        </span>{' '}
        {transLabel("imgageType")}
      </Text>
    </Flex>
  )
}
