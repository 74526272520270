import { useEffect} from "react";
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import ButtonType from "components/Button";
import CheckBoxType from "components/Checkbox"

export default function ModalSelectCountries({
  isOpen,
  onClose,
  setCheckedIds,
  checkedIds,
  setValue,
  clearErrors,
  watch,
  countries
}) {

  const textColor = useColorModeValue("#000000", "white");

  const handleClose = () => {
    onClose();
    setCheckedIds(watch("restricted_countries") ? watch("restricted_countries") : []);
  }

  const handleSelect = () => {
    onClose()
    setValue("restricted_countries", checkedIds)
    clearErrors("restricted_countries")
  }

  const handleCheckboxChange = (isChecked, country_code) => {
    if (isChecked) {
      setCheckedIds([...checkedIds, country_code]);
    } else {
      setCheckedIds((checkedIds) => checkedIds.filter(checkedId => checkedId !== country_code));
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} closeOnOverlayClick={false} size="xxl">
      <ModalOverlay />
      <ModalContent maxW="900px" overflowY="auto" maxHeight="90vh" overflow="hidden">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          COUNTRIES
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px" height="calc(100% - 150px)" overflowY="auto">
          <Flex flexDirection="column">
            <Card px="0px">
              <CardBody overflowX="auto">
                <Flex wrap="wrap" gap={2} width="100%" justifyContent="center">
                  {countries.map((item) => (
                    <Flex
                      key={item.country_code}
                      flexDirection="row"
                      alignItems="center"
                      width="calc(33.33% - 10px)"
                    >
                      <CheckBoxType
                        colorScheme="teal"
                        isChecked={checkedIds.includes(item.country_code)}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          handleCheckboxChange(isChecked, item.country_code);
                        }}
                      ></CheckBoxType>
                      <Text ml="2" >{ item.country_name} </Text>
                    </Flex>
                  ))}
                </Flex>
              </CardBody>
            </Card>
          </Flex>
        </ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            m="auto"
            w="160px"
            h="46px"
            borderRadius="5px"
            disabled={!checkedIds?.length}
            onClick={handleSelect}
          >
            Select
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
