import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import ButtonType from "components/Button";
import { convertParams, omitParams } from "utils/object";
import { downloadCSV } from "utils/downloadCSV";
import { getPlayingHistoryUser, getPrizeHistoryUser } from "api/userManagement";
import PrizeRow from "./row";
import END_POINT from "api/constants";

export default function PrizeHistory({
  userDetail
}) {
  const labels = [
    "Mode",
    "Machine Id",
    "Prize",
    "Prize Type",
    "Obtained At",
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [listHistory, setListHistory] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const toast = useToast();

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };
  
  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const getPlayingHistory = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getPrizeHistoryUser(userDetail?.id ,{
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setListHistory(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  useEffect(() => {
    getPlayingHistory();
  }, [getPlayingHistory]);

  const downloadlistHistory = async () => {
    try {
      const param = convertParams(omitParams(params));
      const baseUrl = `${END_POINT.USER_PLAYING}/export-csv?userId=${userDetail?.id}&${param}`;
      await downloadCSV(baseUrl);
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Box>
      <Box>
        <Flex flexDirection="column">
          <Card px="0px">
            {/* <Flex alignItems="center" justifyContent="space-between" pb="24px">
              <Text fontSize="20px" fontWeight="500">Play History</Text>
              <ButtonType
                text="DOWNLOAD CSV"
                fontSize="12px"
                sizeIcon="16px"
                btnType="dark"
                onClick={downloadlistHistory}
              />
            </Flex> */}
            <CardBody overflowX="auto">
              <TableCustom
                isCheck={false}
                labels={labels}
                isLoading={isLoading}
                dataRow={listHistory}
              >
                <>
                  {listHistory?.map((item, index) => {
                    return (
                      <PrizeRow
                        key={item?.id}
                        item={item}
                        index={(params.page - 1) * params?.limit + index + 1}
                      />
                    );
                  })}
                </>
              </TableCustom>
            </CardBody>
            <Paginate
              page={params?.page}
              pageCount={totalPage}
              limit={params.limit}
              onChangeLimit={onChangeLimit}
              handleChangePage={handleChangePage}
            />
          </Card>
        </Flex>
      </Box>
    </Box>
  );
}
  