import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import ICON_BOTTOM from 'assets/img/card/icon-bottom.png'
import ICON_POL from 'assets/img/card/matic.png'
import ICON_DM2 from 'assets/img/card/dm2c_icon.svg'
import styled from "styled-components";
import CommonBg from 'assets/img/card/common-bg.svg'
import UncommonBg from 'assets/img/card/uncommon-bg.svg'
import RareBg from 'assets/img/card/rare-bg.svg'
import LegendaryBg from 'assets/img/card/legendary-bg.svg'
import EpicBg from 'assets/img/card/epic-bg.svg'
import { RARITY } from "constants/constants";
import TextShadow from "components/common/text-shadow";
import Thumbnail from "components/common/thumbnail";
import { BoldTextWhiteWithShadow } from "components/common/text";
import Misc from "utils/misc";
import { ItemIcon } from "./ItemIcon";
import { Box } from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";

const StyledDiv = styled.div`
  position: relative;
  aspect-ratio: 168 / 226;
  background-image: ${props => `url(${`${props.bg}`})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  flex-direction: column;
  gap: 0;

  .group-image {
    position: relative;
    flex: 1;
    padding: 28px 7px 6px;
    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &__rank {
      width: 95%;
      position: absolute;
      bottom: 2px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2px 8px;
      position: absolute;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      gap: 8px;
      left: 4px;
      &-item {
        position: relative;
        width: 22px;
        display: flex;
        align-items: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .item-number {
          position: absolute;
          right: 2px;
          bottom: -2px;
          font-weight: 700;
          font-size: 9px;
          line-height: 13px;
          color: #FFFFFF;
          text-shadow: 2px 0 #000, -2px 0 #000, 0 2px #000, 0 -2px #000, 1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
        }
      }
    }
  }
  .group-buy {
    width: 97%;

    margin: 0 auto 7px;

    background-color: #60b1e9;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    &-layout {
      padding: 5px 7px; 
      background-color: #fff;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      .layout-params {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
        .icon-w {
          display: flex;
          align-items: center;
          .icon {
            width: 20px;
          }
        }
      }
    }
    &-price {
      height: 25px;
      background-color: #b3c3d7;

      display: flex;
      align-items: center;
      gap: 4px;
      border-radius: 5px;
      padding: 4px 8px;

      .price {
        color: #000000;
        font-weight: 700;
        font-size: 12px;
        position: relative;
        margin-top: 4px;

        &::after {
          content: attr(data-text);
          position: absolute;
          bottom: 2px;
          left: 0;
          color: white;
        }
        @supports (-webkit-text-stroke: 1px #000000) {
          -webkit-text-stroke: 1px #000000;
          paint-order: stroke fill;
        }
      }
    }
    &-btn {
      width: 67px;
      height: 25px;
    }
  }
`;

const StyledIconRarity = styled.div`
  width: auto;
  /* height: 31px; */
  border-radius: 4px;
  padding: 5px 12px;
  margin: auto;

  background-color: ${props => props.bgRarity};
  border: 2px solid #000000;
  box-shadow: 1px 3px 0px 2px #000000;
  transform: skew(-12deg);
  position: absolute;
  top: -10px;
  right: -8px;

  .rarity-content {
    display: flex;
    align-items: center;
    gap: 4px;

    transform: skew(14deg);
    .icon-rarity {
      width: 22.36px;
      height: 22.36px;
    }
  }
`;

export default function ItemNft({
  item,
  onSell,
  isClickAble = false,
  nftsChecked,
  setNFTsChecked,
  onCancelSell,
  type = "genesis",
  isSell = false,
  tabIndex
}) {
  const history = useHistory();
  const isChecked = useMemo(() => {
    if (isClickAble) {
      return nftsChecked?.some((itemCheck) => itemCheck?.id === item?.id);
    }
    return false;
  }, [item?.id, nftsChecked, isClickAble]);

  const IconCard = useMemo(() => {
    const rarity = item?.rarity;
    let iconCard = {
      // iconBox: Images.COMMON_BOX_ICON,
      // iconRarity: Images.RARITY_COMMON_ICON,
      iconBg: CommonBg,
      bgRarity: '#545f7b',
    };
    if (rarity === RARITY.RARITY_UNCOMMON) {
      iconCard = {
        // iconBox: Images.UNCOMMON_BOX_ICON,
        // iconRarity: Images.RARITY_UNCOMMON_ICON,
        iconBg: UncommonBg,
        bgRarity: '#008a17',
      };
    } else if (rarity === RARITY.RARITY_RARE) {
      iconCard = {
        // iconBox: Images.RARE_BOX_ICON,
        // iconRarity: Images.RARITY_RACE_ICON,
        iconBg: RareBg,
        bgRarity: '#005ddd',
      };
    } else if (rarity === RARITY.RARITY_EPIC) {
      iconCard = {
        // iconBox: Images.EPIC_BOX_ICON,
        // iconRarity: Images.RARITY_EPIC_ICON,
        iconBg: EpicBg,
        bgRarity: '#7612ff',
      };
    }
    if (rarity === RARITY.RARITY_LEGENDARY) {
      iconCard = {
        // iconBox: Images.LEGENDARY_BOX_ICON,
        // iconRarity: Images.RARITY_LEGENDARY_ICON,
        iconBg: LegendaryBg,
        bgRarity: '#ffd32f',
      };
    }

    return iconCard;
  }, [item?.rarity]);

  const handleClickCheck = () => {
    if (isClickAble) {
      if (isChecked) {
        setNFTsChecked(
          nftsChecked.filter((itemCheck) => itemCheck?.id !== item?.id)
        );
      } else {
        setNFTsChecked([...nftsChecked, item]);
      }
    } else {
      return history.push(`/admin/${type}/nft-detail/${item?.id}`);
    }
  };
  
  const getIconCurrency = useCallback(curr => {
    if (curr === 0) return ICON_BOTTOM;
    if (curr === 1) return ICON_DM2;
    return ICON_POL;
  }, [tabIndex]);

  return (
    <StyledDiv bg={IconCard.iconBg} /* bgPercent={Images.BG_PERCENT} */ className={`product-card-new`} onClick={handleClickCheck}>
      {isChecked && (
        <Box
          position="absolute"
          borderRadius="10px"
          top={0}
          left={0}
          w="100%"
          h="100%"
          bg="rgba(217, 217, 217, 0.6)"
          zIndex="2"
        >
          <CheckBoxType isChecked position="absolute" top="10px" left="10px" />
        </Box>
      )}
      <div className="group-image">
        <Thumbnail className="image" contain={!!item.padding} url={item?.image_url || item.imageUrl} />
        <div className="group-image__rank">
          <ItemIcon type="silver" size={24} number={item?.treasure_hunter_init_value}/>
          <ItemIcon type="gold" size={24} number={item?.prize_hunter_init_value}/>
          <ItemIcon type="platinum" size={24} number={item?.arena_hunter_init_value}/>
          <ItemIcon type="diamond" size={24} number={item?.merchant_init_value}/>
          <ItemIcon type="iron" size={24} number={item?.mechanic_init_value}/>
        </div>
      </div>
      <div className="group-buy">
        <div className="group-buy-layout">
          <BoldTextWhiteWithShadow size="11">
            {Misc.trimString(item?.nft_information?.name, 30)}
          </BoldTextWhiteWithShadow>
          <div className='layout-params'>
            <BoldTextWhiteWithShadow size="11">
              {item?.product_model?.price}
            </BoldTextWhiteWithShadow>
            <div className='icon-w'>
              <img className="icon" src={getIconCurrency(tabIndex)} alt="icon" />
            </div>
          </div>
        </div>
      </div>  
      <StyledIconRarity bgRarity={IconCard.bgRarity}>
        <div className="rarity-content">
          <TextShadow
            fontSize="12px"
            fontWeight="900"
            colorBox="#000000"
            colorText="#ffffff"
            data-text={item.rarity}
            className="text"
          >
            {item.rarity}
          </TextShadow>
        </div>
      </StyledIconRarity>
    </StyledDiv>
  );
}
