import { useState, useCallback, useEffect, useMemo } from "react";
import { Select as ChakraSelect, chakraComponents } from "chakra-react-select";
import {
  Box,
  Flex,
  Tag,
  Text,
  VStack,
  useToast,
  useDisclosure,
  Select,
  Spinner,
} from "@chakra-ui/react";
import ModalBase from "components/Modal/ModalBase";
import debounce from "lodash/debounce";
import ModalSuccessAndFail from "components/Modal/ModalSuccessAndFail";
import ButtonType from "components/Button";
import { transferProductNFTs } from "api/products.api";
import { getUsersManagement } from "api/userManagement";
import { Contract, ethers } from "ethers";
import { getProvider, getNativeToken, getContractABI } from "utils/web3";
import { getWallets } from "views/NFTsManagement/utils/utils";
import {
  getGenesisPackageList,
  transferGenesisToPackage,
} from "api/genesisNFTs.api";

export default function TransferNFTGenesisModal({
  items,
  isOpen,
  onClose,
  onTransferSuccess,
}) {
  const toast = useToast();
  const [userOptions, setUserOptions] = useState([]);
  const [target, setTarget] = useState("");
  const [packageName, setPackageName] = useState("");
  const [receiver, setReceiver] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isTransferring, setIsTransferring] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [packages, setPackages] = useState([]);
  const [gasFee, setGasFee] = useState(null);
  const [isLoadingGasFee, setIsLoadingGasFee] = useState(false);

  const {
    isOpen: isOpenFail,
    onOpen: onOpenFail,
    onClose: onCloseFail,
  } = useDisclosure();

  const fetchGasPrice = async (chainId, publicAddressTo) => {
    try {
      setIsLoadingGasFee(true);
      const provider = getProvider(chainId);
      const gasPrice = await provider.getGasPrice();

      // Get contract instance
      const contractABI = getContractABI(chainId); // Get ABI based on chain
      const contractAddress = items[0]?.nft_information?.contract_address;
      const publicAddressFrom = items[0]?.nft_information?.public_address;
      const contract = new Contract(contractAddress, contractABI, provider);
      // Verify token ownership
      const tokenId = items[0]?.nft_information?.token_id;
      const currentOwner = await contract.ownerOf(tokenId);

      if (currentOwner.toLowerCase() !== publicAddressFrom.toLowerCase()) {
        throw new Error("You are not the owner of this token");
      }

      if (!publicAddressTo) {
        throw new Error("Invalid recipient wallet address");
      }

      // Estimate gas for transfer function
      const estimatedGas = await contract.estimateGas.transferFrom(
        publicAddressFrom, // from address
        publicAddressTo, // to address
        tokenId, // token ID
      );

      // Calculate total gas fee
      const totalGasFee = gasPrice.mul(estimatedGas);

      setGasFee(ethers.utils.formatEther(totalGasFee));
    } catch (error) {
      console.log(error?.message, "errorerror");
      toast({
        description: error?.message || "Failed to fetch gas price",
        status: "error",
        position: "top",
      });
      setGasFee(null);
    } finally {
      setIsLoadingGasFee(false);
    }
  };

  const getDataPackageList = async () => {
    const params = {
      page: 1,
      limit: 100,
    };

    const { data } = await getGenesisPackageList(params);
    if (data?.success) {
      const { records } = data.data;
      setPackages(records.map((nft) => ({ ...nft })));
    } else {
      throw new Error("Failed to fetch Packages");
    }
  };

  useEffect(() => {
    if (isOpen && items && items.length > 0) {
      const chainId = items[0]?.nft_information?.chain_id;
      if (chainId && receiver) {
        fetchGasPrice(chainId, receiver?.public_address);
      }
      getDataPackageList();
    }
  }, [isOpen, items, receiver]);

  const fetchUsers = async (inputValue) => {
    setIsLoading(true);
    try {
      const params = {
        page: 1,
        limit: 100,
        status: "ACTIVE",
        keyword: inputValue,
      };
      const { data } = await getUsersManagement(params);
      if (data?.data?.records?.length > 0) {
        const options = data?.data?.records?.map((user) => {
          const chainId = items[0]?.nft_information?.chain_id;
          const wallets = getWallets(user?.wallets, chainId);
          return {
            value: user?.id,
            label: user?.name,
            public_address: wallets,
          };
        });
        setUserOptions(options);
      } else {
        toast({
          description: "No users found",
          status: "warning",
          position: "top",
        });
        setUserOptions([]);
      }
    } catch (error) {
      setUserOptions([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Debounce the API call to avoid excessive requests
  const debouncedFetchUsers = useCallback(
    debounce((inputValue) => fetchUsers(inputValue), 300),
    []
  );

  const handleInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      debouncedFetchUsers(inputValue);
    } else {
      setUserOptions([]);
    }
  };

  const handleTransfer = async () => {
    setIsTransferring(true);
    try {
      const paramsUserTransfer = {
        user_id: receiver?.value,
        product_ids: items?.map((item) => item?.product_model?.id),
      };
      const paramsPackageTransfer = {
        package_id: packageName,
        product_ids: items?.map((item) => item?.product_model?.id),
      };

      let response;
      if (target === "user") {
        response = await transferProductNFTs(paramsUserTransfer);
      } else {
        response = await transferGenesisToPackage(paramsPackageTransfer);
      }
      // Successful transfer
      if (response?.data?.success) {
        onTransferSuccess();
        onClose();
        toast({
          title: "Transfer successful.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      } else {
        setErrorMessage(
          response?.data?.messages || [
            "An error occurred. Please try again later!",
          ]
        );
        onOpenFail(true);
      }
    } finally {
      setIsTransferring(false);
    }
  };

  const packageOptions = useMemo(
    () =>
      packages?.map((nftPackage) => ({
        value: nftPackage?.id,
        label: nftPackage?.name,
      })),
    [packages]
  );

  return (
    <>
      <ModalBase
        maxWContent={{
          base: "50%",
          sm: "90%",
          md: "50%",
        }}
        isOpen={isOpen}
        onClose={onClose}
        showBtn={false}
        titleHeader="Transfer NFTs"
      >
        <VStack spacing={4} align="stretch">
          <Box>
            <Text fontWeight="bold" mb={2}>
              Select the target
            </Text>
            <Select
              placeholder="Select the target"
              value={target}
              onChange={(e) => setTarget(e.target.value)}
            >
              <option value="user">User</option>
              <option value="package">Package</option>
            </Select>
          </Box>
          {target === "user" && (
            <Box>
              <Text fontWeight="bold" mb={2}>
                Receiver
              </Text>
              <ChakraSelect
                options={userOptions}
                placeholder="Enter recipient email"
                value={receiver}
                onChange={(selectedOption) => setReceiver(selectedOption)}
                onInputChange={handleInputChange}
                isLoading={isLoading}
                noOptionsMessage={() => null}
                chakraStyles={{
                  placeholder: (provided) => ({
                    ...provided,
                    color: "gray.400",
                  }),
                  control: (provided) => ({
                    ...provided,
                    cursor: "text",
                  }),
                }}
                components={{
                  Option: ({ children, ...props }) => (
                    <chakraComponents.Option {...props}>
                      <Flex alignItems="center">
                        <Box mr={2}>{children}</Box>
                      </Flex>
                    </chakraComponents.Option>
                  ),
                  DropdownIndicator: () => null,
                }}
                isClearable
              />
            </Box>
          )}

          {target === "package" && (
            <Box>
              <Text fontWeight="bold" mb={2}>
                Package
              </Text>
              <Select
                placeholder="Select the package"
                value={packageName}
                onChange={(e) => setPackageName(e.target.value)}
              >
                {packageOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </Box>
          )}

          <Box>
            <Text fontWeight="bold" mb={2}>
              Selected NFTs
            </Text>
            <Flex wrap="wrap" gap={2}>
              {items?.map((item, index) => (
                <Tag key={index} bg="gray.200" color="gray.700">
                  {item?.name}
                </Tag>
              ))}
            </Flex>
          </Box>

          {target === "user" && (
            <Box>
              <Text fontWeight="bold" mb={2}>
                Gas fee:{" "}
                {isLoadingGasFee ? (
                  <Spinner size="sm" />
                ) : (
                  gasFee
                    ? `${gasFee} ${getNativeToken(
                      items[0]?.nft_information?.chain_id
                      )}`
                    : "n/a"
                )}
              </Text>
            </Box>
          )}

          <Flex justifyContent="center">
            <ButtonType
              w="28%"
              mt={4}
              type="button"
              onClick={handleTransfer}
              isDisabled={
                (target === "user" && !receiver) ||
                (target === "package" && !packageName)
              }
              isLoading={isTransferring}
              text="SEND"
            />
          </Flex>
        </VStack>
      </ModalBase>
      {isOpenFail && (
        <ModalSuccessAndFail
          isOpen={isOpenFail}
          type="error"
          onClose={onCloseFail}
          onSubmit={onCloseFail}
          description={
            <VStack align="start" spacing={2} alignItems="center">
              {errorMessage?.map((message, index) => (
                <Text key={index}>{message}</Text>
              ))}
            </VStack>
          }
        />
      )}
    </>
  );
}
