import {
  Box,
  Button,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createNewEventSkillRank } from "api/event.api";
import FormInput from "components/form/FormInput";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import schemaMachine from "./schema";
import ButtonType from "components/Button";
import FormTextAria from "components/form/FormTextAria";
import FormUpload from "components/form/FormUpload";
import { NUMBER } from "constants/enum";
import { uploadImageToServer } from "utils/uploadImage";
import FormLabelComponent from "components/form/FormLabel";
import FormDatePicker from "components/form/FormDatePicker";
import { convertToIsoString, convertToUTCTime, dateAfterNow } from "views/Event/help";
import moment from "moment";
import { MODE_TYPE } from "views/Machine/MachineSettings/constants";
import FormCheckbox from "components/form/FormCheckbox";
import { GAME_MODE_OPTIONS } from "constants/constants";
import FormSelect from "components/form/FormSelect";
import ModalSelectPrize from "views/NewGameMode/Common/SelectPrize";
import { SmallCloseIcon } from "@chakra-ui/icons";

const TRANS_LABEL = {
	en: {
		name: "Mode name",
		enterName: "Input Mode name",
		modeType: "Mode type",
		des: "Description",
    playFee: "Play fee per turn",
    prize: "Prize(s)",
    boothType: "Booth Type",
    gameType: "Gameplay Type",
    numberTurns: "Default number of turns",
    liftGauge: "Life gauge consumed per turn",
    minLevel: "Min. Level value",
    maxLevel: "Max. Level value",
    minSkill: "Min. Skill rank",
    maxSkill: "Max. Skill rank",
    minArena: "Min. Arena Hunter value",
    maxArena: "Max. Arena Hunter value",
	},
	jp: {
		name: "モード名",
		enterName: "入力モード名",
		modeType: "モードタイプ",
		des: "説明",
    playFee: "1ターンあたりのプレイ料金",
    prize: "賞品",
    boothType: "ブースタイプ",
    gameType: "ゲームプレイタイプ",
    numberTurns: "デフォルトのターン数",
    liftGauge: "ターンごとに消費されるライフゲージ",
    minLevel: "最小レベル値",
    maxLevel: "最大レベル値",
    minSkill: "最低スキルランク",
    maxSkill: "最大スキルランク",
    minArena: "アリーナハンターの最小値",
    maxArena: "アリーナハンターの最大値",
	},
};

export default function CreateAndUpdateCoopArena({
  isOpen,
  onClose,
  detailEvent,
  handleCreateOrUpdateSuccess,
}) {
  const formatResult = "YYYY-MM-DD HH:mm:ss";
  const dateFormat = "dd MMM. YYYY HH:mm:ss";
  const [tabIndex, setTabIndex] = useState(0);
  const [trans, setTrans] = useState("en");
  const [nameImage, setNameImage] = useState("");
  const [checkedIds, setCheckedIds] = useState([]);
  const [listModeType, setListModeType] = useState(() => {
    return MODE_TYPE?.reduce((a, v) => ({ ...a, [v.value]: false}), {}) 
  });
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const form = useForm({
    resolver: yupResolver(schemaMachine()),
  });
  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isSubmitting },
  } = form;

  const { 
    isOpen: openPrizes, 
    onOpen: onOpenPrizes, 
    onClose: onClosePrizes 
  } = useDisclosure();

  const disableWhenJP = useMemo(() => {
    return trans === "jp";
  }, [trans]);

  const idEdit = useMemo(() => {
    return detailEvent?.id;
  }, [detailEvent]);

  useEffect(() => {
    if (detailEvent) {
      reset({
        ...detailEvent,
      });
    } else {
      reset();
    }
  }, [detailEvent]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
    if (index === 1) {
      setTrans("jp");
    } else {
      setTrans("en");
    }
  };

  const transLabel = (label) => {
    return TRANS_LABEL[trans][label];
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const listModes = MODE_TYPE?.filter((mode) => listModeType[mode.value])?.map(item => item?.value)
    const dataSubmit = {
      name: data?.event_name,
      pre_register_start_time: convertToIsoString(data?.pre_register_start_time),
      event_start_time: convertToIsoString(data?.event_start_time),
      event_end_time: convertToIsoString(data?.event_end_time),
      result_end_time: convertToIsoString(data?.result_end_time),
      image_url: data?.icon_url,
      mode_types: listModes,
      event_type: "STANDARD_EVENT",
      status: "OPEN",
      translations: [
        {
          lang_key: "EN",
          name: data?.event_name,
          description: data?.description,
        },
        {
          lang_key: "JP",
          name: data?.event_name_jp,
          description: data?.description_jp,
        }
      ],
      min_matches_requirement: data?.players
    }
    try {
      if (idEdit) {
        console.log("🚀 ~ onSubmit ~ idEdit:", idEdit)
        // const res = await updateGamePlayType(detailEvent?.id, dataSubmit);
        // if (res?.data?.success) {
        //   toast({
        //     title: "Game Play updated.",
        //     description: "Game Play updated.",
        //     status: "success",
        //     duration: 3000,
        //     isClosable: true,
        //     position: "top",
        //   });
        //   handleCreateOrUpdateSuccess();
        // }
      } else {
        const res = await createNewEventSkillRank(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Event Skill Rank Created.",
            description: "Event Skill Rank Created.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose()
        } else {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });          
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleImageChange = async (event, type) => {
    const image = event[0];
    await uploadImageToServer(image, toast, setValue, "icon_url", setNameImage);
  };

   const handleDragImage = async (file, type) => {
    if (file.length > NUMBER.ONE) {
      toast({
        description: "The number of uploaded images is greater than one.",
        status: "error",
        position: "bottom-right",
      });
      return false;
    }
    const image = file && file[0];
    await uploadImageToServer(image, toast, setValue, "icon_url", setNameImage);
  };

  const deleteImage = (type) => {
    setValue('icon_url', null)
  }

  useEffect(() => {
    if (detailEvent?.id) {
      const itemJP = detailEvent?.translations?.find(
        (item) => item?.lang_key === "JP"
      );
      reset({
        name: detailEvent?.event_name,
        name_jp: itemJP?.event_name_jp,
        description: detailEvent?.description,
        description_jp: itemJP?.description,
      });
    } else {
      reset({
        pre_register_start_time: dateAfterNow(0),
        event_start_time: dateAfterNow(3),
        event_end_time: dateAfterNow(4),
        result_end_time: dateAfterNow(5),
      });
    }
  }, [reset, detailEvent]);

  const filterChecked = (itemRemove) => {
    const dataFilter = checkedIds?.filter((check) => check?.id !== itemRemove?.id)
    setCheckedIds(dataFilter)
  }

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            mb={4}
            index={tabIndex}
            variant="unstyled"
            onChange={handleTabsChange}
          >
            <TabList gap={2}>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                English
              </Tab>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                Japanese
              </Tab>
            </TabList>
          </Tabs>
          <Flex direction="column" w="100%" gap={4}>
            <Grid emplateColumns="repeat(1, 1fr)" gap={4}>
              <Flex direction="column" gap={4}>
                {trans === "en" ? (
                  <Box display="flex" flexDirection="column" gap="8px">
                    <FormInput
                      maxW="100%"
                      name="event_name"
                      label={transLabel("name")}
                      placeholder={transLabel("enterName")}
                      isRequired
                    />
                    <FormSelect
                      w="260px"
                      name="game_mode"
                      label={transLabel("modeType")}
                      defaultValue={GAME_MODE_OPTIONS[0]}
                      options={GAME_MODE_OPTIONS}
                    />
                    <FormTextAria
                      label={transLabel("des")}
                      placeholder=""
                      name="description"
                      h="100px"
                      borderRadius="8px"
                    />
                    <FormSelect
                      w="260px"
                      name="play_fee"
                      label={transLabel("playFee")}
                      defaultValue={GAME_MODE_OPTIONS[0]}
                      options={GAME_MODE_OPTIONS}
                      isRequired
                    />
                    <FormInput
                      type="text"
                      label={transLabel("prize")}
                      name="prize"
                      placeholder="Select prize(s) for game mode"
                      onClick={() => onOpenPrizes()}
                    />
                    {checkedIds?.length > 0 && !openPrizes && (
                      <Flex gap="4px">
                        {checkedIds?.map((check, index) => (
                          <Flex
                            key={index}
                            padding="4px 8px"
                            borderRadius="6px"
                            background="#faf871"
                            alignItems="center"
                          >
                            <Text fontSize="14px" fontWeight="400">{check?.username}</Text>
                            <SmallCloseIcon onClick={() => filterChecked(check)}/>
                          </Flex>
                        ))}
                      </Flex>
                    )}
                    <Flex alignItems="flex-start" gap="16px" w="100%">
                      <FormSelect
                        wrapperProps={{
                          w: "100%",
                        }}
                        name="booth_type"
                        label={transLabel("boothType")}
                        defaultValue={GAME_MODE_OPTIONS[0]}
                        options={GAME_MODE_OPTIONS}
                      />
                      <FormSelect
                        wrapperProps={{
                          w: "100%",
                        }}
                        name="game_type"
                        label={transLabel("gameType")}
                        defaultValue={GAME_MODE_OPTIONS[0]}
                        options={GAME_MODE_OPTIONS}
                      />
                    </Flex>
                    <Flex alignItems="flex-start" gap="16px" w="100%">
                      <FormInput
                        wrapperProps={{
                          w: "100%",
                        }}
                        type="number"
                        name="number_turn"
                        label={transLabel("numberTurns")}
                        isRequired
                      />
                      <FormInput
                        wrapperProps={{
                          w: "100%",
                        }}
                        type="number"
                        name="lift_gauge"
                        label={transLabel("liftGauge")}
                        isRequired
                      />
                    </Flex>
                    <Text fontSize={16} fontWeight={500}>Entry restriction values</Text>
                    <Grid templateColumns="repeat(2, 1fr)" gap="16px">
                      <FormInput
                        type="number"
                        name="min_level"
                        label={transLabel("minLevel")}
                        isRequired
                      />
                      <FormInput
                        type="number"
                        name="max_level"
                        label={transLabel("maxLevel")}
                        isRequired
                      />
                      <FormSelect
                        w="100%"
                        name="min_skill"
                        label={transLabel("minSkill")}
                        defaultValue={GAME_MODE_OPTIONS[0]}
                        options={GAME_MODE_OPTIONS}
                      />
                      <FormSelect
                        w="100%"
                        name="max_skill"
                        label={transLabel("maxSkill")}
                        defaultValue={GAME_MODE_OPTIONS[0]}
                        options={GAME_MODE_OPTIONS}
                      />
                      <FormInput
                        type="number"
                        name="min_arena"
                        label={transLabel("minArena")}
                        isRequired
                      />
                      <FormInput
                        type="number"
                        name="max_arena"
                        label={transLabel("maxArena")}
                        isRequired
                      />
                    </Grid>
                  </Box>
                ) : (
                  <Flex flexDirection="column" gap="8px">
                    <FormInput
                      maxW="100%"
                      name="event_name"
                      label={transLabel("name")}
                      placeholder={transLabel("enterName")}
                      isRequired
                    />
                    <FormSelect
                      w="260px"
                      name="game_mode"
                      label={transLabel("modeType")}
                      defaultValue={GAME_MODE_OPTIONS[0]}
                      options={GAME_MODE_OPTIONS}
                    />
                    <FormTextAria
                      label={transLabel("des")}
                      placeholder=""
                      name="description"
                      h="100px"
                      borderRadius="8px"
                    />
                    <FormSelect
                      w="260px"
                      name="play_fee"
                      label={transLabel("playFee")}
                      defaultValue={GAME_MODE_OPTIONS[0]}
                      options={GAME_MODE_OPTIONS}
                      isRequired
                    />
                    <FormInput
                      type="text"
                      label={transLabel("prize")}
                      name="prize"
                      placeholder="Select prize(s) for game mode"
                      onClick={() => onOpenPrizes()}
                    />
                    {checkedIds?.length > 0 && !isOpen && (
                      <Flex gap="4px">
                        {checkedIds?.map((check, index) => (
                          <Flex
                            key={index}
                            padding="4px 8px"
                            borderRadius="6px"
                            background="#faf871"
                            alignItems="center"
                          >
                            <Text fontSize="14px" fontWeight="400">{check?.username}</Text>
                            <SmallCloseIcon onClick={() => filterChecked(check)}/>
                          </Flex>
                        ))}
                      </Flex>
                    )}
                    <Flex alignItems="flex-start" gap="16px" w="100%">
                      <FormSelect
                        wrapperProps={{
                          w: "100%",
                        }}
                        name="booth_type"
                        label={transLabel("boothType")}
                        defaultValue={GAME_MODE_OPTIONS[0]}
                        options={GAME_MODE_OPTIONS}
                      />
                      <FormSelect
                        wrapperProps={{
                          w: "100%",
                        }}
                        name="game_type"
                        label={transLabel("gameType")}
                        defaultValue={GAME_MODE_OPTIONS[0]}
                        options={GAME_MODE_OPTIONS}
                      />
                    </Flex>
                    <Flex alignItems="flex-start" gap="16px" w="100%">
                      <FormInput
                        wrapperProps={{
                          w: "100%",
                        }}
                        type="number"
                        name="number_turn"
                        label={transLabel("numberTurns")}
                        isRequired
                      />
                      <FormInput
                        wrapperProps={{
                          w: "100%",
                        }}
                        type="number"
                        name="lift_gauge"
                        label={transLabel("liftGauge")}
                        isRequired
                      />
                    </Flex>
                    <Text fontSize={16} fontWeight={500}>Entry restriction values</Text>
                    <Grid templateColumns="repeat(2, 1fr)" gap="16px">
                      <FormInput
                        type="number"
                        name="min_level"
                        label={transLabel("minLevel")}
                        isRequired
                      />
                      <FormInput
                        type="number"
                        name="max_level"
                        label={transLabel("maxLevel")}
                        isRequired
                      />
                      <FormSelect
                        w="100%"
                        name="min_skill"
                        label={transLabel("minSkill")}
                        defaultValue={GAME_MODE_OPTIONS[0]}
                        options={GAME_MODE_OPTIONS}
                      />
                      <FormSelect
                        w="100%"
                        name="max_skill"
                        label={transLabel("maxSkill")}
                        defaultValue={GAME_MODE_OPTIONS[0]}
                        options={GAME_MODE_OPTIONS}
                      />
                      <FormInput
                        type="number"
                        name="min_arena"
                        label={transLabel("minArena")}
                        isRequired
                      />
                      <FormInput
                        type="number"
                        name="max_arena"
                        label={transLabel("maxArena")}
                        isRequired
                      />
                    </Grid>
                  </Flex>
                )}
              </Flex>
            </Grid>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="700px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "Edit Battle Arena game mode" : "New Battle Arena game mode"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
      {openPrizes && (
        <ModalSelectPrize
          isOpen={openPrizes}
          onClose={onClosePrizes}
          setCheckedIds={setCheckedIds}
          checkedIds={checkedIds}
        />
      )}
    </Modal>
  );
}
