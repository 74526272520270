import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { STATUS } from 'constants/constants';
import React, { useCallback, useMemo } from 'react'
import bannerDefault from 'assets/img/banner-event-default.png'
import { convertDateLocalLLLL } from 'utils/time';
import EditIcon from 'assets/svg/edit.svg'
import RankingIcon from 'assets/svg/ranking.svg'
import SendIcon from 'assets/svg/send_item.svg'
import RegisterIcon from 'assets/svg/registered_user.svg'

export default function DetailEvent(props) {
  const { item, handleOpenLeaderBoard, toggle, handleOpenCreateOrUpdate } = props

  const renderBgItem = useMemo(() => {
    switch (item?.new_status) {
      case STATUS.IN_EVENT:
        return {
          bg: "linear-gradient(137deg, #EBFE95 0%, #B9EA29 100%)",
          color: "#000",
        };
      case STATUS.IN_PRE_REGISTER:
      case STATUS.OPEN:
        return {
          bg: "linear-gradient(137deg, #FFEAAC 0%, #FFC515 100%)",
          color: "#000",
        };
      case STATUS.CLOSED:
        return {
          bg: "linear-gradient(137deg, #DBDBDB 0%, #747474 100%)",
          color: "#000",
        };
      default:
        return {
          bg: "linear-gradient(137deg, #EDE6E6 1.63%, #C0BDBD 98.37%)",
          color: "#2D377B",
        };
    }
  }, [item?.new_status]);

  const IconStatus = useCallback(() => {
    let bg = "#90FA87";
    let bgBorder = "#fff";
    if (item?.new_status === STATUS.IN_PRE_REGISTER || item?.new_status === STATUS.OPEN) {
      bg = "#FFC515";
      bgBorder = "#fff";
    } else if (item?.new_status === STATUS.CLOSED || item?.new_status === STATUS.IN_RESULT) {
      bg = "#747474";
      bgBorder = "#fff";
    }
    return (
      <Box
        bg={bg}
        w="22px"
        h="22px"
        borderRadius="100%"
        border={`3px solid ${bgBorder}`}
      />
    );
  }, [item?.new_status]);

  const isStatusUnactive = useMemo(() => {
    return item?.new_status === STATUS.CLOSED;
  }, [item?.new_status]);

  return (
    <Flex 
      w="auto"
      height="auto"
      p="8px" 
      borderRadius="8px"
      direction="column"
      columnGap="4px"
      bg={renderBgItem.bg}
      minWidth="180px"
    >
      <Flex direction="column" gap={2}>
        <Flex alignItems="center" gap={2}>
          <IconStatus />
          <Box width="150px"> {/* Thiết lập độ rộng cho Box */}
            <Text
              fontSize={{
                base: "16px",
              }}
              fontWeight="700"
              isTruncated
            >
              {item?.name}
            </Text>
          </Box>
        </Flex>
        <Flex alignItems="center" justifyContent="center" gap={10}>
          <Flex flexDirection="column" alignItems="center">
            <Image
              src={item?.image_url || bannerDefault}
              w="100%"
              h="100px"
              alt={item?.id}
            />
            <Text fontSize="16px" fontWeight="700">{item?.waiting_players}</Text>
          </Flex>
        </Flex>
        <Flex p="2px 8px" direction="column" gap="0" background="#fff" borderRadius="6px">
          <Text fontSize="12px" fontWeight="500">Start: {convertDateLocalLLLL(item?.event_start_time * 1000)}</Text>
          <Text fontSize="12px" fontWeight="500">End: {convertDateLocalLLLL(item?.event_end_time * 1000)}</Text>
        </Flex>
        <Flex alignItems="center" justifyContent="flex-end" gap="8px">
          <Flex 
            w="30px" 
            h="30px" 
            borderRadius="8px"
            background="#000"
            alignItems="center"
            justifyContent="center"
            onClick={() => toggle('itemSend', item)}
            opacity={isStatusUnactive ? 0.6 : 1}
            cursor={isStatusUnactive ? "not-allowed" : "pointer"}
            pointerEvents={isStatusUnactive ? "none" : ""}
          >
            <Image src={SendIcon} alignSelf='center' justifySelf='center' />
          </Flex>
          <Flex 
            onClick={() => handleOpenLeaderBoard(item)}
            w="30px"
            h="30px"
            borderRadius="8px"
            background="#000"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
          >
            <Image src={RankingIcon} alignSelf='center' justifySelf='center' />
          </Flex>
          {/* <Flex
            w="30px"
            h="30px"
            borderRadius="8px"
            background="#000"
            alignItems="center"
            justifyContent="center"
            cursor={isStatusUnactive ? "not-allowed" : "pointer"}
            pointerEvents={isStatusUnactive ? "none" : ""}
            opacity={isStatusUnactive ? 0.6 : 1}
            onClick={() => toggle('registered', item)}
          >
            <Image src={RegisterIcon} alignSelf='center' justifySelf='center' />
          </Flex> */}
          <Flex
            w="30px"
            h="30px"
            borderRadius="8px"
            background="#000"
            alignItems="center"
            justifyContent="center"
            cursor={isStatusUnactive ? "not-allowed" : "pointer"}
            pointerEvents={isStatusUnactive ? "none" : ""}
            opacity={isStatusUnactive ? 0.6 : 1}
            onClick={() => handleOpenCreateOrUpdate(item)}
          >
            <Image src={EditIcon} alignSelf='center' justifySelf='center' />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
