import {
  Flex,
  useToast
} from "@chakra-ui/react";
import {
  getBonusPassPurchaseSetting,
  getConsecutiveLoginBonusSetting,
  createBonusPassPurchaseSetting,
  createConsecutiveLoginBonusSetting
} from "api/loginBonus.api";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import BonusPassItem from "./bonusPassItem";
import TableCustom from "components/Tables/CustomTable";
import Paginate from "components/Paginate";
import ConsecutiveSettingRow from "./row";
import schemaBonusPass from "./schemaBonusPass";
import useWithToast from "hooks/useWithToast";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

export default function ConsecutiveBonus() {
  const { t } = useTranslation("error");
  const [isLoading, setIsLoading] = useState(false);
  const [bonusPassPurchaseSettings, setBonusPassPurchaseSettings] = useState([]);
  const [consecutiveSettings, setConsecutiveSettings] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const { showToastSuccess, showToastFail } = useWithToast();
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const toast = useToast();

  const methods = useForm({
    resolver: yupResolver(schemaBonusPass),
    defaultValues: {
      bonusPasses: [],
    },
  });

  const {
    handleSubmit,
  } = methods;

  const { fields } = useFieldArray({
    control: methods?.control,
    name: "bonusPasses"
  });

  const getBonusPassPurchaseSettings = useCallback(
    async () => {
      try {
        const { data } = await getBonusPassPurchaseSetting();
        if (data?.success) {
          const res = data?.data;
          setBonusPassPurchaseSettings(res);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
    },
    []
  );

  useEffect(() => {
    getBonusPassPurchaseSettings();
  }, [getBonusPassPurchaseSettings]);

  useEffect(() => {
    methods.reset({
      bonusPasses: bonusPassPurchaseSettings.map((bonusPass) => ({
        name: bonusPass.name,
        price: bonusPass.price || "",
        currency: bonusPass.currency || "",
      })),
    });
  }, [bonusPassPurchaseSettings]);

  const handleSave = (index) => {
    methods.handleSubmit(async () => {
      const data = methods.getValues();
      const singleItem = data.bonusPasses[index]; 
      try {
        const res = await createBonusPassPurchaseSetting(singleItem);
        if (res?.data?.success) {
          setIsEdit(false)
          showToastSuccess({
            title: "Bonus pass purchase created.",
          });
        } else {
          showToastFail({
            title: t(`${res?.data?.messages[0]}`) || "Create fail!",
          });
        }

        await getBonusPassPurchaseSettings()
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
    })()
  };

  const labels = [
    "DAY",
    "FREE TICKET",
    "CP",
    "BCOIN",
    "SPHERE",
    "BONUS ORB",
    "BONUS AP",
    "ACTION",
  ];

  const getconsecutiveSettings = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getConsecutiveLoginBonusSetting({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setConsecutiveSettings(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  useEffect(() => {
    getconsecutiveSettings();
  }, [params]);

  const handleSaveConsecutive = async (data) => {
    try {
      const res = await createConsecutiveLoginBonusSetting(data);
        if (res?.data?.success) {
          showToastSuccess({
            title: "Consecutive login bonus setting created.",
          });
          await getconsecutiveSettings();
        } else {
          showToastFail({
            title: t(`${res?.data?.messages[0]}`) || "Create fail!",
          });
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
  }

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  return (
    <Flex flexDirection="column">
      <Card p="0px">
        <CardHeader mb="32px">
          <FormProvider {...methods} w="100%">
            <form
              style={{
                width: "100%",
                minWidth: "600px",
                overflowX: "auto",
              }}
            >
              {fields?.map((bonusPass, index) => (
                <BonusPassItem
                  key={index}
                  index={index}
                  value={bonusPass}
                  name={bonusPass.name}
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  onSave={() => handleSave(index)}
                />
              ))}
            </form>
          </FormProvider>
        </CardHeader>
        <CardBody overflowX="auto">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck={false}
            dataRow={consecutiveSettings}
          >
            <>
              {consecutiveSettings?.map((item) => {
                return (
                  <ConsecutiveSettingRow
                    key={item?.id}
                    consecutive={item}
                    onSave={handleSaveConsecutive}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </Card>
    </Flex>
  );
}
