import { useRef } from "react";
import BASE_API from "constants/configUrl";

export const useCronJob = ({ timeCron = BASE_API.TIME_RECALL || 30000, task }) => {
  const cronId = useRef(null);
  const startJob = () => {
    cronId.current = setInterval(() => {
      task && task();
    }, timeCron);
  };

  const stopJob = () => {
    clearInterval(cronId?.current);
  };

  return { startJob, stopJob };
};
