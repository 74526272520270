import {
    Flex,
    Icon,
    Stack,
    Td,
    Text,
    Tr,
    useColorModeValue,
  } from "@chakra-ui/react";
  import CheckBoxType from "components/Checkbox";
  import { useMemo } from "react";
  import { EditIcon } from "components/Icons/EditIcon";
  import { SYSTEM_COINS_SHOW } from "constants/constants";
  
  const RowNftGacha = ({
    machine,
    checkedIds,
    setCheckedIds,
    handleOpenCreateOrUpdate,
  }) => {
    const textColor = useColorModeValue("#000000", "white");
    const isMachineChecked = useMemo(() => {
      return checkedIds.some((item) => item?.id === machine?.id);
    }, [machine?.id, checkedIds]);
  
    const handleCheckboxChange = () => {
      if (isMachineChecked) {
        setCheckedIds(checkedIds.filter((item) => item?.id !== machine?.id));
      } else {
        setCheckedIds([...checkedIds, machine]);
      }
    };
  
    return (
      <Tr>
        <Td width="10px">
          <CheckBoxType
            isChecked={isMachineChecked}
            onChange={() => handleCheckboxChange()}
            colorScheme="teal"
          />
        </Td>
        <Td>
          <Stack direction="row" spacing="16px">
            <Text color={textColor} fontSize="sm" fontWeight={400}>
              {machine?.name}
            </Text>
          </Stack>
        </Td>
        <Td>
          <Stack direction="row" spacing="16px">
            <Text color={textColor} fontSize="sm" fontWeight={400}>
              {SYSTEM_COINS_SHOW[machine?.type]}
            </Text>
          </Stack>
        </Td>
        <Td>
          <Stack direction="row" spacing="16px">
            <Text color={textColor} fontSize="sm" fontWeight={400} >
              {machine.value}
            </Text>
          </Stack>
        </Td>
        <Td>
          <Stack direction="row" spacing="16px">
            <Text color={textColor} fontSize="sm" fontWeight={400} >
              {machine.count}
            </Text>
          </Stack>
        </Td>
        <Td>
          <Stack direction="row" spacing="16px">
            <Text color={textColor} fontSize="sm" fontWeight={400} minW="40px">
              {machine.weight}
            </Text>
          </Stack>
        </Td>
        <Td>
          <Flex gap={6} align="center">
            <Icon
              onClick={() => handleOpenCreateOrUpdate()}
              fontSize="30px"
              borderRadius="8px"
              p="7px"
              cursor="pointer"
              bg="#1480FF"
              as={EditIcon}
            />
          </Flex>
        </Td>
      </Tr>
    );
  };
  
  export default RowNftGacha;
  