import { Flex, Td, Text, useColorModeValue } from "@chakra-ui/react";
import { BG_ITEM_NUM_OF_NFT } from "constants/constants";

export default function NumOfNFTsTd({ nfts = {} }) {
  const textColor = useColorModeValue("#000000", "white");
  return (
    <Td>
      <Flex flexDirection="column" gap="3px">
        {Object.keys(nfts)?.length &&
          Object.keys(nfts)?.map((key, index) => {
            if (nfts?.[key])
              return (
                <Text
                  key={index}
                  color={textColor}
                  p="2px 10px"
                  fontSize="12px"
                  background={BG_ITEM_NUM_OF_NFT[key]}
                  borderRadius="12px"
                  align={"center"}
                  maxWidth={150}
                >
                  {nfts?.[key]}
                </Text>
              );
          })}
      </Flex>
    </Td>
  );
}
