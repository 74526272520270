import { Icon } from "@chakra-ui/icons";
import {
  Flex,
  Image,
  Stack,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { ViewIcon } from "components/Icons/ViewIcon";
import { getChainIcon } from "views/NFTsManagement/utils/utils";

export default function ItemsReward({
  item,
}) {
  const textColor = useColorModeValue("#000000", "white");

  return (
    <Tr>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.id}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px" width="200px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.name}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Image
            boxSize="30px"
            src={getChainIcon(item?.chain_id).src}
            alt={getChainIcon(item?.chain_id).alt}
          />
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Flex alignItems="center" gap="4px" flexWrap="wrap">
            {item?.gacha_items?.map((gacha, index) => (
              index < 3 && <Text key={index} p="4px 6px" borderRadius="16px" bg="#95ff87">
                {gacha.id}
              </Text>
            ))}
            {item?.gacha_items?.length > 3 && <Text>...</Text>}
          </Flex>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.weight}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.grand_prize ? "Yes": "No"}
          </Text>
        </Stack>
      </Td>
      {/* <Td>
        <Flex gap={6} align="center">
          <Icon
            fontSize="30px"
            borderRadius="8px"
            cursor="pointer"
            as={ViewIcon}
          />
        </Flex>
      </Td> */}
    </Tr>
  );
}
