import { useState } from "react";
import {
  Box,
  Flex,
  Tag,
  Text,
  VStack,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import ModalBase from "components/Modal/ModalBase";
import ModalSuccessAndFail from "components/Modal/ModalSuccessAndFail";
import ButtonType from "components/Button";
import { removeGenesisFromPackage } from "api/genesisNFTs.api";
import { formatErrorMessage } from "views/NFTsManagement/utils/utils";

export default function RemovePackageGenesisModal({
  items,
  isOpen,
  onClose,
  onRemoveSuccess,
}) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);

  const {
    isOpen: isOpenFail,
    onOpen: onOpenFail,
    onClose: onCloseFail,
  } = useDisclosure();

  const handleRemove = async () => {
    setIsLoading(true);
    try {
      const paramsRemovePackage = {
        package_id: items?.[0]?.package_id,
        product_ids: items?.map((item) => item?.product_model?.id),
      };
      const { data } = await removeGenesisFromPackage(paramsRemovePackage);
      // Successful remove
      if (data?.success) {
        onRemoveSuccess();
        onClose();
        toast({
          title: "Remove successful.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      } else {
        const msgError = formatErrorMessage(data?.message || data?.messages[0]);
        setErrorMessage([msgError]);
        onOpenFail(true);
      }
    } catch (error) {
      // Failed transfer
      onOpenFail(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ModalBase
        maxWContent={{
          base: "50%",
          sm: "90%",
          md: "50%",
        }}
        isOpen={isOpen}
        onClose={onClose}
        showBtn={false}
        titleHeader="Remove from package"
      >
        <VStack spacing={4} align="stretch">
          <Box>
            <Text fontWeight="bold" mb={2}>
              Selected NFTs
            </Text>
            <Flex wrap="wrap" gap={2}>
              {items?.map((item, index) => (
                <Tag key={index} bg="gray.200" color="gray.700">
                  {item?.name}
                </Tag>
              ))}
            </Flex>
          </Box>

          <Box>
            <Text fontWeight="medium" mb={2}>
              Do you want to remove these selected NFTs from this package?
            </Text>
          </Box>

          <Flex justifyContent="center" mt={4} gap="16px">
            <ButtonType
              w="28%"
              btnType="primary-new-outline"
              onClick={onClose}
              text="CANCEL"
            />
            <ButtonType
              w="28%"
              type="button"
              onClick={handleRemove}
              isLoading={isLoading}
              text="YES"
            />
          </Flex>
        </VStack>
      </ModalBase>
      {isOpenFail && (
        <ModalSuccessAndFail
          isOpen={isOpenFail}
          type="error"
          onClose={onCloseFail}
          onSubmit={onCloseFail}
          description={
            <VStack align="start" spacing={2} alignItems="center">
              {errorMessage?.map((message, index) => (
                <Text key={index}>{message}</Text>
              ))}
            </VStack>
          }
        />
      )}
    </>
  );
}
