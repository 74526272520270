import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getGauntletNFTs = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.NFT_GAUNTLET}?${params}`);
};

export const getGauntletNFTsUser = (id, p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.NFT_GAUNTLET}?${params}&user-id=${id}&admin-flag=false`);
};

export const getGauntletSummary = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.NFT_GAUNTLET_SUMMARY}?${params}`);
};

export const getDetailGauntletNFT = (id) => {
  return http.get(`${END_POINT.NFT_GAUNTLET}/${id}`);
};

export const reAllocateGauntletNFT = (data) => {
  return http.put(`${END_POINT.NFT_GAUNTLET_RE_ALLOCATE}`, data);
};

export const importCSVGauntletNFT = (id, data) => {
  return http.post(`${END_POINT.NFT_GAUNTLET}/${id}/import-csv`, data, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
};
