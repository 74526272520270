import React, { useEffect, useMemo } from "react";
import CardBody from "components/Card/CardBody";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import NFTsManagementRow from "components/Tables/nft/nftRow";
import { Box, useDisclosure } from "@chakra-ui/react";
import NFTsActionPanel from "../panel/NFTsActionPanel";
import ReAllocateNFTModal from "../modal/ReAllocateNFTModal";
import TransferNFTModal from "../modal/TransferNFTModal";
import TransferNFTGenesisModal from "../modal/TransferNFTGenesisModal";
import SellNFTGenesisModal from "../modal/SellNFTGenesisModal";
import RemovePackageGenesisModal from "../modal/RemovePackageGenesisModal";
import {
  GENESIS_NFT_TABS,
  NFT_MANAGEMENT_TYPE,
  NFT_MANAGEMENT_TYPE_LABEL,
  NFT_MANAGEMENT_TYPE_LABEL_BOUNTY,
  NFT_MANAGEMENT_TYPE_LABEL_OWNER,
} from "views/NFTsManagement/constants";
import { useNFTList } from "views/NFTsManagement/hooks/useNFTList";
import FilterTabs from "../tabs/FilterTabs";
import NFTDetailModal from "../modal/NFTDetailModal";
import NFTsFilterPanel from "../panel/NFTsFilterPanel";
import ModalImportFile from "components/Modal/ModalImportFile";

const NFTsList = ({ nftMngType }) => {
  const {
    isLoading,
    isLoadingImport,
    nfts,
    nftDetail,
    checkedIds,
    total,
    totalPage,
    params,
    getNFTs,
    getSummaryNFTs,
    getNFTDetail,
    handleChangePage,
    handleChangeLimit,
    handleChangeChecked,
    handleDownloadCSV,
    handleImportCSV,
    handleUpdateDataNFT,
    setCheckedIds,
    setNFTParams,
    setNftDetail,
  } = useNFTList(nftMngType);

  const {
    isOpen: isOpenDetailUser,
    onOpen: onOpenDetailUser,
    onClose: onCloseDetailUser,
  } = useDisclosure();

  const {
    isOpen: isOpenChangeAllocate,
    onOpen: onOpenChangeAllocate,
    onClose: onCloseChangeAllocate,
  } = useDisclosure();

  const {
    isOpen: isOpenTransfer,
    onOpen: onOpenTransfer,
    onClose: onCloseTransfer,
  } = useDisclosure();

  const {
    isOpen: isOpenTransferGenesis,
    onOpen: onOpenTransferGenesis,
    onClose: onCloseTransferGenesis,
  } = useDisclosure();

  const {
    isOpen: isOpenSellGenesis,
    onOpen: onOpenSellGenesis,
    onClose: onCloseSellGenesis,
  } = useDisclosure();

  const {
    isOpen: isOpenRemovePackage,
    onOpen: onOpenRemovePackage,
    onClose: onCloseRemovePackage,
  } = useDisclosure();

  const {
    isOpen: isOpenImportCSV,
    onOpen: onOpenImportCSV,
    onClose: onCloseImportCSV,
  } = useDisclosure();

  const labelTable = useMemo(() => {
    const labels = {
      [NFT_MANAGEMENT_TYPE.BOUNTY_BALL]: NFT_MANAGEMENT_TYPE_LABEL_BOUNTY,
      [NFT_MANAGEMENT_TYPE.OWNER]: NFT_MANAGEMENT_TYPE_LABEL_OWNER,
    };
    return labels[nftMngType] || NFT_MANAGEMENT_TYPE_LABEL;
  }, [nftMngType]);

  const handleUpdateFilterParams = (params) => {
    setNFTParams(params);
  };

  const handleOpenDetailUserModal = async (item) => {
    if (nftMngType === NFT_MANAGEMENT_TYPE.OWNER && item) {
      setNftDetail(item);
    }
    await getNFTDetail(item);
    onOpenDetailUser();
  };

  const handleChangeAllocate = () => {
    onOpenChangeAllocate();
  };

  const handleTransfer = () => {
    onOpenTransfer();
  };

  const handleDownload = () => {
    handleDownloadCSV();
  };

  const handleTransferGenesis = () => {
    onOpenTransferGenesis();
  };

  const handleSellGenesis = () => {
    onOpenSellGenesis();
  };

  const handleRemoveFromPackage = () => {
    onOpenRemovePackage();
  };

  const handleAllocationSuccess = () => {
    getNFTs();
  };

  const handleChangeTaskStatus = (params) => {
    handleUpdateDataNFT(params);
  };

  const handleImport = (data) => {
    handleImportCSV(data, onCloseImportCSV);
  };

  useEffect(() => {
    getSummaryNFTs();
  }, []);

  const renderFilterTabs = () => {
    switch (nftMngType) {
      case NFT_MANAGEMENT_TYPE.OWNER:
        return (
          <NFTsFilterPanel
            nftMngType={nftMngType}
            onUpdateFilterParams={handleUpdateFilterParams}
          />
        );
      default:
        return (
          <FilterTabs
            dataTabs={GENESIS_NFT_TABS}
            nftMngType={nftMngType}
            onUpdateFilterParams={handleUpdateFilterParams}
          />
        );
    }
  };

  return (
    <>
      <Box>
        {renderFilterTabs()}
        {/* {nftMngType === NFT_MANAGEMENT_TYPE.GENESIS ? (
        ) : (
          <FilterAccordion
            nftMngType={nftMngType}
            onUpdateFilterParams={handleUpdateFilterParams}
          />
        )} */}
      </Box>
      <Box display="flex" justifyContent="flex-end" mb={4}>
        <NFTsActionPanel
          isLoading={isLoading}
          nftMngType={nftMngType}
          checkedIds={checkedIds}
          onChangeAllocate={handleChangeAllocate}
          onTransfer={handleTransfer}
          onDownload={handleDownload}
          onTransferGenesis={handleTransferGenesis}
          onSellGenesis={handleSellGenesis}
          onRemoveFromPackage={handleRemoveFromPackage}
          onImportCSV={onOpenImportCSV}
        />
      </Box>
      <CardBody overflowX="auto">
        <TableCustom
          labels={labelTable}
          isLoading={isLoading}
          isCheck={nftMngType !== NFT_MANAGEMENT_TYPE.OWNER}
          isChecked={nfts?.length > 0 && checkedIds.length === nfts?.length}
          onChangeChecked={handleChangeChecked}
          dataRow={nfts}
        >
          <>
            {nfts?.map((item) => (
              <NFTsManagementRow
                key={item?.id}
                nftMngType={nftMngType}
                setCheckedIds={setCheckedIds}
                checkedIds={checkedIds}
                handleOpenDetailUserModal={handleOpenDetailUserModal}
                handleChangeTaskStatus={handleChangeTaskStatus}
                item={item}
                limit={params.limit}
                currentPage={params.page}
              />
            ))}
          </>
        </TableCustom>
      </CardBody>
      <Paginate
        page={params?.page}
        pageCount={totalPage}
        limit={params.limit}
        total={total}
        isShowTotal={nftMngType === NFT_MANAGEMENT_TYPE.OWNER}
        onChangeLimit={handleChangeLimit}
        handleChangePage={handleChangePage}
      />
      {isOpenDetailUser && (
        <NFTDetailModal
          nftMngType={nftMngType}
          item={nftDetail}
          isOpen={isOpenDetailUser}
          onClose={onCloseDetailUser}
        />
      )}
      {isOpenChangeAllocate && (
        <ReAllocateNFTModal
          nftMngType={nftMngType}
          items={checkedIds}
          isOpen={isOpenChangeAllocate}
          onClose={onCloseChangeAllocate}
          onAllocationSuccess={handleAllocationSuccess}
        />
      )}
      {isOpenTransfer && (
        <TransferNFTModal
          items={checkedIds}
          isOpen={isOpenTransfer}
          onClose={onCloseTransfer}
          onTransferSuccess={handleAllocationSuccess}
        />
      )}
      {isOpenTransferGenesis && (
        <TransferNFTGenesisModal
          nftMngType={nftMngType}
          items={checkedIds}
          isOpen={isOpenTransferGenesis}
          onClose={onCloseTransferGenesis}
          onTransferSuccess={handleAllocationSuccess}
        />
      )}
      {isOpenSellGenesis && (
        <SellNFTGenesisModal
          items={checkedIds}
          isOpen={isOpenSellGenesis}
          onClose={onCloseSellGenesis}
          onSellSuccess={handleAllocationSuccess}
        />
      )}
      {isOpenRemovePackage && (
        <RemovePackageGenesisModal
          items={checkedIds}
          isOpen={isOpenRemovePackage}
          onClose={onCloseRemovePackage}
          onRemoveSuccess={handleAllocationSuccess}
        />
      )}
      {isOpenImportCSV && (
        <ModalImportFile
          isOpen={isOpenImportCSV}
          onClose={onCloseImportCSV}
          handleImport={handleImport}
          isLoading={isLoadingImport}
        />
      )}
    </>
  );
};

export default NFTsList;
