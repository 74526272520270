import { VStack, Flex, useDisclosure } from "@chakra-ui/react";
import CardBody from "components/Card/CardBody";
import ButtonType from "components/Button";
import { useCallback, useMemo, useState } from "react";
import TableCustom from "components/Tables/CustomTable";
import PackageRow from "../tableRow/packageRow";
import Paginate from "components/Paginate";

import { useGenesisPackageList } from "views/NFTsManagement/hooks/useGenesisPackageList";
import AddPackageGenesisModal from "../modal/AddPackageGenesisModal";
import GenesisPackageDetailModal from "../modal/GenesisPackageDetailModal";

const GenesisPackageList = () => {
  const {
    isLoading,
    packages,
    checkedIds,
    totalPage,
    params,
    getGenesisPackages,
    handleChangePage,
    handleChangeLimit,
    handleChangeChecked,
    handleDelistOrList,
    handleDelete,
    setCheckedIds,
    handleUpdateItem,
  } = useGenesisPackageList();

  const [selectedItem, setSelectedItem] = useState(null);

  const {
    isOpen: isOpenAddPackage,
    onOpen: onOpenAddPackage,
    onClose: onCloseAddPackage,
  } = useDisclosure();

  const {
    isOpen: isOpenDetailPackage,
    onOpen: onOpenDetailPackage,
    onClose: onCloseDetailPackage,
  } = useDisclosure();

  const tableLabels = useMemo(
    () => [
      "IMAGE",
      "NAME",
      "CHAIN",
      "PRICE",
      "SETTINGS",
      "CURRENT SIZE",
      "STATUS",
      "SOLD",
      "",
    ],
    []
  );

  const handleNew = useCallback(() => {
    onOpenAddPackage();
  }, []);

  const handleAddPackageSuccess = () => {
    getGenesisPackages();
  };

  const handleOpenDetailPackage = async (item) => {
    setSelectedItem(item);
    onOpenDetailPackage();
  };

  return (
    <>
      <VStack spacing={4} align="stretch">
        <Flex justifyContent="flex-end" alignItems="center">
          <Flex gap={2}>
            <ButtonType
              w="80px"
              type="button"
              btnType="success"
              fontSize="12px"
              onClick={handleNew}
              isDisabled={isLoading}
              text="New"
            />
            <ButtonType
              w="80px"
              type="button"
              fontSize="12px"
              onClick={handleDelistOrList}
              isDisabled={isLoading}
              text="List \ Delist"
            />
            <ButtonType
              w="80px"
              type="button"
              btnType="danger"
              fontSize="12px"
              onClick={handleDelete}
              isDisabled={isLoading}
              text="Delete"
            />
          </Flex>
        </Flex>
        <CardBody overflowX="auto">
          <TableCustom
            labels={tableLabels}
            isLoading={isLoading}
            isCheck
            isChecked={
              packages.length > 0 && checkedIds.length === packages.length
            }
            onChangeChecked={handleChangeChecked}
            dataRow={packages}
          >
            <>
              {packages?.map((item) => {
                return (
                  <PackageRow
                    key={item?.id}
                    item={item}
                    checkedIds={checkedIds}
                    setCheckedIds={setCheckedIds}
                    onClickDetail={handleOpenDetailPackage}
                    onUpdateItem={handleUpdateItem}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={handleChangeLimit}
          handleChangePage={handleChangePage}
        />
      </VStack>

      {isOpenDetailPackage && (
        <GenesisPackageDetailModal
          selectedItem={selectedItem}
          isOpen={isOpenDetailPackage}
          onClose={onCloseDetailPackage}
        />
      )}

      {isOpenAddPackage && (
        <AddPackageGenesisModal
          isOpen={isOpenAddPackage}
          onClose={onCloseAddPackage}
          onAddSuccess={handleAddPackageSuccess}
        />
      )}
    </>
  );
};

export default GenesisPackageList;
