import {
  Flex,
  FormLabel,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import ButtonType from "components/Button";
import { FormProvider, useForm } from "react-hook-form";
import FormInput from "components/form/FormInput";
import { SmallCloseIcon } from "@chakra-ui/icons";
import { transferNftTicket } from "api/userManagement";
export default function ModalTransferTicket({
  isOpen,
  onClose,
  filterChecked,
  checkedIds,
  setCheckedIds,
  handleCreateOrUpdateSuccess,
  userDetail
}) {
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");
  const form = useForm({
    defaultValues: {
      free_ticket: 1000,
      bcoin100_ticket: 1000,
      bcoin1_kticket: 1000,
      bcoin100_kticket: 1000,
      nft1_dollar_ticket: 0,
      nft10_dollar_ticket: 0,
    }
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const onSubmit = async (data) => {
    const listIds = checkedIds?.map((check) => check?.id)
    const dataSubmit = {
      user_ids: userDetail?.id ? [userDetail?.id] : listIds,
      free_ticket: data?.free_ticket,
      nft1_dollar_ticket: data?.nft1_dollar_ticket,
      nft10_dollar_ticket: data?.nft10_dollar_ticket,
      bcoin100_ticket: data?.bcoin100_ticket,
      bcoin1_kticket: data?.bcoin1_kticket,
      bcoin100_kticket: data?.bcoin100_kticket
    };
    try {
      const res = await transferNftTicket(dataSubmit);
      if (res?.data?.success) {
        toast({
          title: "Transfer Nft Ticket Successfully!",
          description: "Transfer Nft Ticket Successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setCheckedIds([])
        handleCreateOrUpdateSuccess()
        onClose()
      } else {
        toast({
          description: res?.data?.message || res?.data?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Text fontSize={16} fontWeight={600}>Receiver(s)</Text>
          {checkedIds?.length > 0 && (
            <Flex gap="4px" flexWrap="wrap" mt={4} pb={4} borderBottom="1px solid #eee">
              {checkedIds?.map((check, index) => (
                <Flex
                  key={index}
                  padding="4px 8px"
                  borderRadius="6px"
                  background="#c0c0c0"
                  alignItems="center"
                  gap="4px"
                >
                  <Text fontSize="14px" fontWeight="400">{check?.name}</Text>
                  <SmallCloseIcon onClick={() => filterChecked(check)}/>
                </Flex>
              ))}
            </Flex>
          )}
          {userDetail && (
            <Flex
              padding="4px 8px"
              borderRadius="6px"
              background="#c0c0c0"
              alignItems="center"
              gap="4px"
              width="max-content"
              mt={4}
            >
              <Text fontSize="14px" fontWeight="400">{userDetail?.name}</Text>
            </Flex>
          )}
          <Text fontSize={16} fontWeight={600} my={4}>Indicate the number of items</Text>
          <Grid templateColumns="repeat(2, 1fr)" alignItems="center" gap={4}>
            <Text fontWeight="500">Ticket type</Text>
            <Text fontWeight="500">Value</Text>
            <Text fontWeight="500" pl="20px">Free Ticket</Text>
            <FormInput
              label=""
              name="free_ticket"
              type="number"
              placeholder="Enter number ticket"
              min={0}
            />
            <Text fontWeight="500" pl="20px">100 BC Ticket</Text>
            <FormInput
              label=""
              name="bcoin100_ticket"
              type="number"
              placeholder="Enter number ticket"
              min={0}
            />
            <Text fontWeight="500" pl="20px">1000 BC Ticket</Text>
            <FormInput
              label=""
              name="bcoin1_kticket"
              type="number"
              placeholder="Enter number ticket"
              min={0}
            />
            <Text fontWeight="500" pl="20px">100K BC Ticket</Text>
            <FormInput
              label=""
              name="bcoin100_kticket"
              type="number"
              placeholder="Enter number ticket"
              min={0}
            />
            {/* <Text fontWeight="500" pl="20px">$1 NFT Ticket</Text>
            <FormInput
              label=""
              name="nft1_dollar_ticket"
              type="number"
              placeholder="Enter number ticket"
              min={0}
            />
            <Text fontWeight="500" pl="20px">$10 NFT Ticket</Text>
            <FormInput
              label=""
              name="nft10_dollar_ticket"
              type="number"
              placeholder="Enter number ticket"
              min={0}
            /> */}
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="800px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          Transfer Ticket
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
            onClick={() => {
              handleSubmit(onSubmit);
            }}
          >
            SEND
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
