import React, { useMemo } from "react";
import { Text, Image, Badge, Flex, Link, Td, Tr } from "@chakra-ui/react";
import { convertDateLocalLLLL } from "utils/time";
import TableCustom from "components/Tables/CustomTable";
import Paginate from "components/Paginate";
import CardBody from "components/Card/CardBody";
import { getRarityStyle } from "utils/rarity";
import { getChainIcon } from "views/NFTsManagement/utils/utils";
import CopyComponent from "components/Copy";
import { ETHERSCAN_URL } from "constants/constants";

export default function HistoryPackageList({
  orderHistory,
  params,
  totalPage,
  onChangeLimit,
  onChangePage,
}) {
  const getStatusColor = (status) => {
    switch (status.toUpperCase()) {
      case "SUCCESS":
        return {
          bg: "green",
          text: "Success",
        };
      case "NEW":
        return {
          bg: "gray",
          text: "New",
        };
      case "WAIT_TRANSFER":
        return {
          bg: "yellow",
          text: "Waiting",
        };
      case "FAIL":
        return {
          bg: "red",
          text: "Fail",
        };
      case "TRANSFER_FAIL":
        return {
          bg: "red",
          text: "Transfer Fail",
        };
      default:
        return {
          bg: "gray",
          text: "-",
        };
    }
  };

  const getPaymentMethod = (paymentMethod) => {
    switch (paymentMethod.toUpperCase()) {
      case "NATIVE_TOKEN":
        return {
          bg: "green",
          text: "Native Token",
        };
      case "CREDIT":
        return {
          bg: "blue",
          text: "Credit",
        };
      case "CRYPTO":
        return {
          bg: "purple",
          text: "Slash",
        };
      default:
        return {
          bg: "gray",
          text: "-",
        };
    }
  };

  const tableLabels = useMemo(
    () => [
      "Order Code",
      "User Email",
      "Package Name",
      "Chain",
      "NFTs Received",
      "Quantity",
      "Total Amount",
      "Payment Method",
      "Purchase Completed Time",
      "Status",
      "Error",
      "Transaction",
    ],
    []
  );

  const renderTableRow = (order, index) => (
    <Tr key={index}>
      <Td>{order.order_code}</Td>
      <Td>
        <Flex align="center" gap={2}>
          <Image
            src={order?.buyer_avatar}
            boxSize="24px"
            alt={`${order?.buyer_email} icon`}
          />
          <Text>{order?.buyer_email}</Text>
        </Flex>
      </Td>
      <Td>{order.package_name}</Td>
      <Td>
        <Image
          src={getChainIcon(order?.chain_id)?.src}
          boxSize="24px"
          alt={`${order?.chain_id} icon`}
        />
      </Td>
      <Td>
        {order?.product_order_models.length > 0 ? (
          <Flex direction="column" gap={2}>
            {order?.product_order_models.map((nft, index) => (
              <Flex key={index} align="center" gap={2}>
                <Image
                  src={nft?.genesis_model?.image_url}
                  alt={nft?.genesis_model?.name}
                  boxSize="25px"
                  borderRadius="md"
                />
                <Text fontSize="sm">{nft?.genesis_model?.name}</Text>
                <Badge
                  size="sm"
                  color={getRarityStyle(nft?.genesis_model?.rarity).bgRarity}
                >
                  {nft?.genesis_model?.rarity}
                </Badge>
              </Flex>
            ))}
          </Flex>
        ) : (
          <Text fontSize="sm" color="gray.500">
            No NFTs
          </Text>
        )}
      </Td>
      <Td>{order?.quantity}</Td>
      <Td>
        {order?.total_price} {`${order?.currency}`}
      </Td>
      <Td>
        <Badge colorScheme={getPaymentMethod(order?.payment_method).bg}>
          {getPaymentMethod(order?.payment_method).text}
        </Badge>
      </Td>
      <Td>
        {convertDateLocalLLLL(
          order?.product_order_models?.[0]?.update_time &&
            order?.product_order_models?.[0]?.update_time * 1000
        )}
      </Td>
      <Td>
        <Badge colorScheme={getStatusColor(order?.order_status).bg}>
          {getStatusColor(order?.order_status).text}
        </Badge>
      </Td>
      <Td>{order?.error || "-"}</Td>
      <Td>
        {order?.hash ? (
          <Flex align="center" gap={2}>
            <Link
              href={`${ETHERSCAN_URL[order?.chain_id]}/tx/${order?.hash}`}
              isExternal
              color="blue.500"
              fontSize="sm"
              _hover={{
                textDecoration: "none",
                color: "blue.600",
              }}
            >
              {order?.hash?.slice(0, 6)}...{order?.hash?.slice(-4)}
            </Link>
            <CopyComponent
              dataCopy={order?.hash}
              w="13px"
              h="16px"
              cursor="pointer"
            />
          </Flex>
        ) : (
          <Text fontSize="sm" color="gray.500">
            -
          </Text>
        )}
      </Td>
    </Tr>
  );

  return (
    <>
      <CardBody overflowX="auto">
        <TableCustom
          labels={tableLabels}
          isLoading={false}
          isCheck={false}
          dataRow={orderHistory}
        >
          <>{orderHistory?.map((order) => renderTableRow(order))}</>
        </TableCustom>
      </CardBody>
      <Paginate
        page={params?.page}
        pageCount={totalPage}
        limit={params.limit}
        onChangeLimit={onChangeLimit}
        handleChangePage={onChangePage}
      />
    </>
  );
}
