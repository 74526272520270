import { Flex, Icon, Image, Stack, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import { EditIcon } from "components/Icons/EditIcon";

const DigitalManagementRow = ({ item, handleOpenUpload, index }) => {
  const textColor = useColorModeValue("#000000", "white");
  return (
    <Tr>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {index}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.name}
        </Text>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Image
            src={item?.image_url}
            w="100px"
            alt={item?.image_url}
          />
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text 
            fontSize="sm" 
            fontWeight={400}
            padding="4px 8px"
            borderRadius="8px"
            color="#fff"
            background={`${item?.is_ar ? "#12b012ba" : "#ff4646"}`}
          >
            {item?.is_ar ? "AR" : "NONE AR"}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Flex>
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight={400}
            maxW="200px"
          >
            {item?.hunter_ar?.hunter_ardetails[0]?.base_url}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Flex>
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight={400}
            maxW="200px"
          >
            {item?.hunter_ar?.hunter_ardetails[1]?.base_url}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Flex gap={6} align="center">
          <Icon
            onClick={() => handleOpenUpload()}
            fontSize="30px"
            borderRadius="8px"
            p="7px"
            cursor="pointer"
            bg="#1480FF"
            as={EditIcon}
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default DigitalManagementRow;
