import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import ButtonType from "components/Button";
import { SmallCloseIcon } from "@chakra-ui/icons";

export default function ModalBanAndUnBan({
  isOpen,
  onClose,
  title = "CONFIRM",
  description,
  onSubmit,
  isLoadingSubmit,
  checkedIds,
  filterChecked,
}) {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} >
      <ModalOverlay />
      <ModalContent maxW="600px">
        <ModalHeader
          w="100%"
          textAlign="center"
          margin="auto"
          fontSize="24px"
          fontWeight="700"
        >
          {title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px" textAlign="start" fontSize="16px" fontWeight="500">
          {description}
          {checkedIds?.length > 0 && (
            <Flex gap="4px" flexWrap="wrap" mt={4}>
              {checkedIds?.map((check, index) => (
                <Flex
                  key={index}
                  padding="4px 8px"
                  borderRadius="6px"
                  background="#fca5ba"
                  alignItems="center"
                  gap="4px"
                >
                  <Text fontSize="14px" fontWeight="400">{check?.name}</Text>
                  <SmallCloseIcon onClick={() => filterChecked(check)}/>
                </Flex>
              ))}
            </Flex>
          )}
        </ModalBody>

        <ModalFooter w="100%" textAlign="center" margin="auto">
          <Flex
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap="16px"
            w="100%"
          >
            <ButtonType
              isLoading={isLoadingSubmit}
              mr={3}
              text="YES"
              onClick={onSubmit}
              w="100%"
            />
            <ButtonType
              onClick={onClose}
              mr={3}
              text="NO"
              btnType="primary-new-outline"
              w="100%"
            />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
  