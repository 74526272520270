import { Flex, useDisclosure, useToast } from "@chakra-ui/react";
import ButtonType from "components/Button";
import DeleteModal from "components/Modal/deleteModal";
import { deleteNotice } from "api/notice";
import useWithToast from "hooks/useWithToast";

export default function ActionNotice({
  handleOpenCreateOrUpdate,
  checkedIds,
  setCheckedIds,
  setParams,
  params,
}) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { showToastSuccess } = useWithToast();

  const handleDelete = async () => {
    try {
      if (checkedIds?.length) {
        const newParams = {
          ids: checkedIds,
        };
        const res = await deleteNotice(newParams);
        if (res) {
          showToastSuccess({
            title: "Delete Success",
          });
          onClose();
          setCheckedIds([])
          setParams({
            ...params,
            page: 1,
          });
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Flex gap={4} flexWrap="wrap" alignItems="flex-end">
      <Flex alignItems="flex-end" gap={6} mb="10px">
        <ButtonType
          text="NEW"
          fontSize="12px"
          width="100px"
          onClick={() => {
            handleOpenCreateOrUpdate();
          }}
        />
        <ButtonType
          text="DELETE"
          width="100px"
          fontSize="12px"
          btnType="danger"
          disabled={checkedIds?.length === 0}
          onClick={onOpen}
        />
      </Flex>
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        handleDelete={handleDelete}
        title="Delete Notice"
        content="Do you want delete Notice ?"
        btnYes="OK"
        btnNo="Cancel"
      />
    </Flex>
  );
}
