import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getBonusPassPurchaseSetting = () => {
  return http.get(`${END_POINT.BONUS_PASS_PURCHASE_SETTING}`);
};

export const createBonusPassPurchaseSetting = (data, option = {}) => {
  return http.post(END_POINT.BONUS_PASS_PURCHASE_SETTING, data, option);
};

export const getConsecutiveLoginBonusSetting = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.CONSECUTIVE_LOGIN_BONUS_SETTING}?${params}`);
};

export const createConsecutiveLoginBonusSetting = (data, option = {}) => {
  return http.post(END_POINT.CONSECUTIVE_LOGIN_BONUS_SETTING, data, option);
};

export const getCumulativeLoginBonusSetting = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.CUMULATIVE_LOGIN_BONUS_SETTING}?${params}`);
};

export const createCumulativeLoginBonusSetting = (data, option = {}) => {
  return http.post(END_POINT.CUMULATIVE_LOGIN_BONUS_SETTING, data, option);
};


export const getStartDashLoginBonusSetting = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.START_DASH_LOGIN_BONUS_SETTING}?${params}`);
};

export const createStartDashLoginBonusSetting = (data, option = {}) => {
  return http.post(END_POINT.START_DASH_LOGIN_BONUS_SETTING, data, option);
};
