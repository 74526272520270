import React, { useCallback, useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useToast,
  Center,
  HStack,
  Flex,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import FormInput from "components/form/FormInput";
import ButtonType from "components/Button";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import HistoryPackageList from "../list/HistoryPackageList";
import { getPackageOrderHistory } from "api/genesisNFTs.api";
import { DEFAULT_FILTER_PACKAGE_GENESIS_PARAMS } from "views/NFTsManagement/constants";
import END_POINT from "api/constants";
import { convertParams, omitParams } from "utils/object";
import { downloadCSV } from "utils/downloadCSV";

export default function PackageOrderHistoryModal({ isOpen, onClose }) {
  const [isLoading, setIsLoading] = useState(true);
  const [orderHistory, setOrderHistory] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState(DEFAULT_FILTER_PACKAGE_GENESIS_PARAMS);
  const toast = useToast();

  const form = useForm({
    defaultValues: {
      orderId: "",
      userEmail: "",
    },
  });
  const { setValue, handleSubmit, reset } = form;

  const getOrderHistory = useCallback(async () => {
    if (!isOpen) return;

    try {
      setIsLoading(true);
      const { data } = await getPackageOrderHistory(params);

      if (data?.success) {
        const { records, total_page } = data.data;
        setOrderHistory(records);
        setTotalPage(total_page || 0);
      } else {
        throw new Error("Failed to fetch order history");
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  }, [params, isOpen, toast]);

  const handleChangeLimit = useCallback((limit) => {
    setParams((prev) => ({ ...prev, limit, page: 1 }));
  }, []);

  const handleChangePage = useCallback((page) => {
    setParams((prev) => ({ ...prev, page }));
  }, []);

  const onSubmit = useCallback((data) => {
    setParams((prev) => ({
      ...prev,
      page: 1,
      "order-code": data?.orderId?.trim() || "",
      query: data?.userEmail?.trim() || "",
    }));
  }, []);

  const handleReset = useCallback(() => {
    reset({ orderId: "", userEmail: "" });
    setParams((prev) => ({
      ...prev,
      page: 1,
      "order-code": "",
      query: "",
    }));
  }, [reset]);

  const handleDownloadCSV = useCallback(async () => {
    try {
      setIsLoading(true);
      const csvParams = { ...params };
      delete csvParams.page;
      delete csvParams.limit;

      const param = convertParams(omitParams(csvParams));
      const baseUrl = `${END_POINT.NFT_GENESIS_PACKAGE_ORDER_HISTORY_EXPORT_CSV}?${param}`;
      await downloadCSV(baseUrl, true);
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  }, [params]);

  useEffect(() => {
    getOrderHistory();
  }, [getOrderHistory]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent maxW="90%">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
        >
          Package Order History
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px 32px">
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <HStack spacing={4} mb={6}>
                <FormInput
                  name="orderId"
                  label="Order ID"
                  placeholder="Search by order ID"
                  onChange={(e) => {
                    setValue("orderId", e.target.value);
                  }}
                />
                <FormInput
                  name="userEmail"
                  label="User Email"
                  placeholder="Search by user email"
                  onChange={(e) => {
                    setValue("userEmail", e.target.value);
                  }}
                />
              </HStack>
              <Flex justifyContent="flex-end" mb={6}>
                <ButtonType
                  mr={3}
                  type="submit"
                  fontSize="12px"
                  text="Search"
                />

                <ButtonType
                  type="button"
                  variant="outline"
                  onClick={handleReset}
                  mr={3}
                  fontSize="12px"
                  text="Reset"
                />
                <ButtonType
                  text="Download CSV"
                  fontSize="12px"
                  sizeIcon="8px"
                  iconType="download"
                  btnType="dark"
                  onClick={handleDownloadCSV}
                />
              </Flex>
            </form>
          </FormProvider>

          {isLoading ? (
            <Center minH="200px">
              <LoadingSpinner />
            </Center>
          ) : (
            <HistoryPackageList
              orderHistory={orderHistory}
              params={params}
              totalPage={totalPage}
              onChangeLimit={handleChangeLimit}
              onChangePage={handleChangePage}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
