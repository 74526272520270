import { useState, useCallback, useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { DEFAULT_FILTER_PACKAGE_GENESIS_PARAMS } from "views/NFTsManagement/constants";
import { getGenesisNFTs } from "api/genesisNFTs.api";
import { getDetailGenesisNFT } from "api/genesisNFTs.api";

export const useGenesisInPackageList = (selectedItem) => {
  const [isLoading, setIsLoading] = useState(false);
  const [genesis, setGenesis] = useState([]);
  const [params, setParams] = useState(DEFAULT_FILTER_PACKAGE_GENESIS_PARAMS);
  const [totalPage, setTotalPage] = useState(0);
  const [nftDetail, setNftDetail] = useState(null);
  const toast = useToast();

  const setGenesisPackageParams = useCallback((newParams) => {
    setParams((prevParams) => ({ ...prevParams, ...newParams }));
  }, []);

  const getGenesisInPackage = useCallback(
    async (selectedItem) => {
      try {
        setIsLoading(true);
        const req = {
          ...params,
          package: selectedItem.id,
        };

        const { data } = await getGenesisNFTs(req);

        if (data?.success) {
          const { records, total_page } = data.data;
          setGenesis(
            records.map((nft, index) => ({ ...nft, index: index + 1 }))
          );
          setTotalPage(total_page);
        } else {
          throw new Error("Failed to fetch Genesis in Package");
        }
      } catch (error) {
        showToast(
          "An error occurred while fetching Genesis in Package",
          "error"
        );
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  const getNFTDetail = async (item) => {
    try {
      const { data } = await getDetailGenesisNFT(item.id);
      if (data?.success) {
        setNftDetail(data.data);
      }
    } catch (error) {
      toast({
        description:
          error.message || "An error occurred while fetching NFT detail",
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const showToast = useCallback(
    (description, status = "error") => {
      toast({
        description,
        status,
        position:
          status === "error" || status === "warning" ? "bottom-right" : "top",
      });
    },
    [toast]
  );

  useEffect(() => {
    getGenesisInPackage(selectedItem);
  }, [getGenesisInPackage, selectedItem]);

  const handleChangePage = useCallback(
    (page) => {
      setGenesisPackageParams({ page });
    },
    [setGenesisPackageParams]
  );

  const handleChangeLimit = useCallback(
    (limit) => {
      setGenesisPackageParams({ limit, page: 1 });
    },
    [setGenesisPackageParams]
  );

  return {
    isLoading,
    genesis,
    totalPage,
    params,
    nftDetail,
    getNFTDetail,
    getGenesisInPackage,
    handleChangePage,
    handleChangeLimit,
    setGenesisPackageParams,
  };
};
