import { containSpecialRegex } from "utils/regex";
import * as yup from "yup";

const schemaStandardEvent = () =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .matches(containSpecialRegex, "Name should not contain special characters")
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    name_jp: yup
      .string()
      .trim()
      .matches(containSpecialRegex, "Name should not contain special characters")
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    prize_items: yup
      .array()
      .required("Required"),
    number_turn: yup
      .string()
      .max(6, "Maximum limit of 6 characters.")
      .required('Required')
      .test(
        'Is positive?', 
        'The number must be greater than or equal 0!', 
        (value) => value >= 0
      )
      .min(0),
    lift_gauge: yup
      .string()
      .max(6, "Maximum limit of 6 characters.")
      .required('Required')
      .test(
        'Is positive?', 
        'The number must be greater than or equal 0!', 
        (value) => value >= 0
      )
      .min(0),
    min_level: yup
      .string()
      .max(6, "Maximum limit of 6 characters.")
      .required('Required')
      .test(
        'Is positive?', 
        'The number must be greater than or equal 0!', 
        (value) => value >= 0
      )
      .min(0),
    max_level: yup
      .string()
      .max(6, "Maximum limit of 6 characters.")
      .required('Required')
      .test(
        'Is positive?', 
        'The number must be greater than or equal 0!', 
        (value) => value >= 0
      )
      .min(0),
    min_arena: yup
      .string()
      .max(6, "Maximum limit of 6 characters.")
      .required('Required')
      .test(
        'Is positive?', 
        'The number must be greater than or equal 0!', 
        (value) => value >= 0
      )
      .min(0),
    max_arena: yup
      .string()
      .max(6, "Maximum limit of 6 characters.")
      .required('Required')
      .test(
        'Is positive?', 
        'The number must be greater than or equal 0!', 
        (value) => value >= 0
      )
      .min(0),
  });

export default schemaStandardEvent;
