import { useState, useCallback, useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import {
  DEFAULT_FILTER_PACKAGE_GENESIS_PARAMS,
  PACKAGE_STATUS_VALUE,
} from "views/NFTsManagement/constants";
import {
  getGenesisPackageList,
  delistGenesisPackages,
  deleteGenesisPackage,
  updatePackageGenesis,
  listingGenesisPackages,
} from "api/genesisNFTs.api";
import { formatErrorMessage } from "../utils/utils";

export const useGenesisPackageList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);
  const [params, setParams] = useState(DEFAULT_FILTER_PACKAGE_GENESIS_PARAMS);
  const [totalPage, setTotalPage] = useState(0);
  const toast = useToast();

  const setPackageParams = useCallback((newParams) => {
    setParams((prevParams) => ({ ...prevParams, ...newParams }));
  }, []);

  const getGenesisPackages = useCallback(async () => {
    try {
      setIsLoading(true);
      setCheckedIds([]);

      const { data } = await getGenesisPackageList(params);

      if (data?.success) {
        const { records, total_page } = data.data;
        setPackages(records.map((nft) => ({ ...nft })));
        setTotalPage(total_page);
      } else {
        showToast(
          formatErrorMessage(data?.message || data?.messages[0]),
          "error"
        );
      }
    } catch (error) {
      showToast(error?.message || error?.messages[0], "error");
    } finally {
      setIsLoading(false);
    }
  }, [params]);

  const showToast = useCallback(
    (description, status = "error") => {
      toast({
        description,
        status,
        position:
          status === "error" || status === "warning" ? "bottom-right" : "top",
      });
    },
    [toast]
  );

  const handleDelistOrList = useCallback(async () => {
    if (checkedIds.length !== 1) {
      showToast(
        checkedIds.length === 0
          ? "Please select a package"
          : "Please select only one package",
        "warning"
      );
      return;
    }

    setIsLoading(true);
    try {
      const packageId = checkedIds[0].id;
      const action =
        checkedIds[0].status === PACKAGE_STATUS_VALUE.LISTING
          ? delistGenesisPackages
          : listingGenesisPackages;

      const { data } = await action(packageId);
      if (data?.success) {
        showToast(
          `Package ${
            action === delistGenesisPackages ? "delisted" : "listed"
          } successfully`,
          "success"
        );
        getGenesisPackages();
      } else {
        showToast(
          formatErrorMessage(data?.message || data?.messages[0]),
          "error"
        );
      }
    } catch (error) {
      showToast(
        error?.message ||
          `An error occurred while ${
            checkedIds[0].status === PACKAGE_STATUS_VALUE.LISTING
              ? "delisting"
              : "listing"
          } packages`,
        "error"
      );
    } finally {
      setIsLoading(false);
    }
  }, [checkedIds, showToast, getGenesisPackages]);

  const handleDelete = useCallback(async () => {
    if (checkedIds.length !== 1) {
      showToast(
        checkedIds.length === 0
          ? "Please select a package"
          : "Please select only one package",
        "warning"
      );
      return;
    }

    setIsLoading(true);
    try {
      const packageId = checkedIds[0].id;
      const { data } = await deleteGenesisPackage(packageId);
      if (data?.success) {
        showToast("Packages deleted successfully", "success");
        getGenesisPackages();
      } else {
        throw new Error("Failed to delete packages");
      }
    } catch (error) {
      showToast("An error occurred while deleting packages", "error");
    } finally {
      setIsLoading(false);
    }
  }, [checkedIds, showToast, getGenesisPackages]);

  const handleUpdateItem = useCallback(
    async (updatedPackage) => {
      setIsLoading(true);
      try {
        const payload = {
          name: updatedPackage.name,
          image_url: updatedPackage.image_url,
          chain_id: updatedPackage.chain_id,
          price: Number(updatedPackage.price),
          status: updatedPackage.status,
          setting: updatedPackage.package_settings,
        };
        const { data } = await updatePackageGenesis(updatedPackage.id, payload);
        if (data?.success) {
          showToast("Package updated successfully", "success");
          getGenesisPackages();
        } else {
          showToast(
            formatErrorMessage(data?.message || data?.messages[0]),
            "error"
          );
        }
      } catch (error) {
        showToast(
          error?.message || "An error occurred while updating package",
          "error"
        );
      } finally {
        setIsLoading(false);
      }
    },
    [showToast, getGenesisPackages]
  );

  useEffect(() => {
    getGenesisPackages();
  }, [getGenesisPackages]);

  const handleChangePage = useCallback(
    (page) => {
      setPackageParams({ page });
    },
    [setPackageParams]
  );

  const handleChangeLimit = useCallback(
    (limit) => {
      setPackageParams({ limit, page: 1 });
    },
    [setPackageParams]
  );

  const handleChangeChecked = useCallback(
    (isChecked) => {
      setCheckedIds(isChecked ? packages : []);
    },
    [packages]
  );

  return {
    isLoading,
    packages,
    checkedIds,
    totalPage,
    params,
    getGenesisPackages,
    handleChangePage,
    handleChangeLimit,
    handleChangeChecked,
    handleDelistOrList,
    handleDelete,
    handleUpdateItem,
    setCheckedIds,
    setPackageParams,
  };
};
