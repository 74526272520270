import { Flex, Icon, Td, Tr } from "@chakra-ui/react";
import { EditIcon } from "components/Icons/EditIcon";
import { CheckIcon } from "components/Icons/CheckIcon";
import { useState } from "react";
import FormInput from "components/form/FormInput";
import schemaConsecutiveSetting from "./schema";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export default function ConsecutiveSettingRow ({
  consecutive,
  onSave
}) {
  const [isEdit, setIsEdit] = useState(false);
  const form = useForm({
    resolver: yupResolver(schemaConsecutiveSetting()),
    defaultValues: {
      free_ticket: consecutive ? consecutive?.free_ticket : "",
      cp: consecutive ? consecutive?.cp : "",
      ap: consecutive ? consecutive?.ap : "",
      bcoin: consecutive ? consecutive?.bcoin : "",
      sphere: consecutive ? consecutive?.sphere : "",
      orb: consecutive ? consecutive?.orb : "",
      day: consecutive ? consecutive?.day : "",
    }
  });
  const {
    setValue,
    handleSubmit
  } = form;
  const onSubmit = form.handleSubmit((data) => {
    if (onSave) {
      onSave(data);
    }
    setIsEdit(false);
  });

  return (
    <>
      { isEdit ? (
        <FormProvider {...form}>
          <Tr>
              <Td
                width="12.5%"
                fontSize="sm"
                fontWeight={400}
                whiteSpace="nowrap"
              >
                {consecutive?.day}
              </Td>
              <Td
                width="12.5%"
                fontSize="sm"
                fontWeight={400}
                whiteSpace="nowrap"
              >
                <FormInput
                  label=""
                  name="free_ticket"
                  type="number"
                  placeholder="Enter number ticket"
                  onChange={(e) => {
                    setValue("free_ticket", e.target.value);
                  }}
                />
              </Td>
              <Td
                width="12.5%"
                fontSize="sm"
                fontWeight={400}
                whiteSpace="nowrap"
              >
                <FormInput
                  label=""
                  name="cp"
                  type="number"
                  placeholder="Enter number cp"
                  min={0}
                  onChange={(e) => {
                    setValue("cp", e.target.value);
                  }}
                />
              </Td>
              <Td
                width="12.5%"
                fontSize="sm"
                fontWeight={400}
                whiteSpace="nowrap"
              >
                <FormInput
                  label=""
                  name="bcoin"
                  type="number"
                  placeholder="Enter number bcoin"
                  min={0}
                  onChange={(e) => {
                    setValue("bcoin", e.target.value);
                  }}
                />
              </Td>
              <Td
                width="12.5%"
                fontSize="sm"
                fontWeight={400}
                whiteSpace="nowrap"
              >
                <FormInput
                  label=""
                  name="sphere"
                  type="number"
                  placeholder="Enter number sphere"
                  min={0}
                  onChange={(e) => {
                    setValue("sphere", e.target.value);
                  }}
                />
              </Td>
              <Td
                width="12.5%"
                fontSize="sm"
                fontWeight={400}
                whiteSpace="nowrap"
              >
                <FormInput
                  label=""
                  name="orb"
                  type="number"
                  placeholder="Enter number orb"
                  min={0}
                  onChange={(e) => {
                    setValue("orb", e.target.value);
                  }}
                />
              </Td>
              <Td
                width="12.5%"
                fontSize="sm"
                fontWeight={400}
                whiteSpace="nowrap"
              >
                <FormInput
                  label=""
                  name="ap"
                  type="number"
                  placeholder="Enter number ap"
                  min={0}
                  onChange={(e) => {
                    setValue("ap", e.target.value);
                  }}
                />
              </Td>
              <Td width="12.5%">
                <Flex gap="10px">
                <Icon
                    fontSize="30px"
                    borderRadius="8px"
                    cursor="pointer"
                    bg="#07CF84"
                    as={CheckIcon}
                    onClick={onSubmit}
                  />
                </Flex>
              </Td>
          </Tr>
        </FormProvider>
      ) : (
        <Tr>
          <Td
            width="12.5%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            {consecutive?.day}
          </Td>
          <Td
            width="12.5%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            {consecutive?.free_ticket}
          </Td>
          <Td
            width="12.5%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            {consecutive?.cp}
          </Td>
          <Td
            width="12.5%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            {consecutive?.bcoin}
          </Td>
          <Td
            width="12.5%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            {consecutive?.sphere}
          </Td>
          <Td
            width="12.5%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            {consecutive?.orb}
          </Td>
          <Td
            width="12.5%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            {consecutive?.ap}
          </Td>
          <Td width="12.5%">
            <Flex gap="10px">
              <Icon
                fontSize="30px"
                borderRadius="8px"
                p="7px"
                cursor="pointer"
                bg="#1480FF"
                as={EditIcon}
                onClick={() => setIsEdit(true)}
              />
            </Flex>
          </Td>
        </Tr>
      )}
    </>
  );
}