import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getSkillRankApi = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.SKILL_RANK}?${params}`);
};

export const updateSkillRankApi = (data, option = {}) => {
  return http.put(`${END_POINT.SKILL_RANK}`, data, option);
};
