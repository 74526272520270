import {
  Flex,
  Text,
  useToast
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import TableCustom from "components/Tables/CustomTable";
import Paginate from "components/Paginate";
import CostFusionRow from "./row";
import useWithToast from "hooks/useWithToast";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFusion, updateFusion } from "api/fusion";
import CardHeader from "components/Card/CardHeader";

export default function CostFusion() {
  const { t } = useTranslation("error");
  const [isLoading, setIsLoading] = useState(false);
  const [listCostFusion, setListCostFusion] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const { showToastSuccess, showToastFail } = useWithToast();
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const toast = useToast();

  const labels = [
    "Rarity",
    "FUSION COST (BCoin & sphere)",
    "AWAKENING COST (BCoin & sphere)",
    ""
  ];

  const getCostFusion = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getFusion({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setListCostFusion(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  useEffect(() => {
    getCostFusion();
  }, [params]);

  const handleSaveCumulative = async (id, data) => {
    try {
      const res = await updateFusion(id, data);
        if (res?.data?.success) {
          showToastSuccess({
            title: "Setting fusion cost and awakening cost successfully!",
          });
          await getCostFusion();
        } else {
          showToastFail({
            title: t(`${res?.data?.messages[0]}`) || "Create fail!",
          });
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
  }

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  return (
    <Flex flexDirection="column" w="100%">
      <Card p="0px">
        <CardHeader px="22px" mb="20px" w="100%">
          <Text
            fontSize="sm"
            fontWeight="bold"
            whiteSpace="nowrap"
          >
            Cost
          </Text>
        </CardHeader>
        <CardBody overflowX="auto">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck={false}
            dataRow={listCostFusion}
            textAlign="right"
          >
            <>
              {listCostFusion?.map((item) => {
                return (
                  <CostFusionRow
                    key={item?.id}
                    cumulative={item}
                    onSave={handleSaveCumulative}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </Card>
    </Flex>
  );
}
