import { Icon } from "@chakra-ui/icons";
import {
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import moment from "moment";
import { useMemo } from "react";

export default function ItemsSendRow({
  item,
  checkedIds,
  setCheckedIds,
}) {
  const textColor = useColorModeValue("#000000", "white");

  const isChecked = useMemo(() => {
    return checkedIds.some((itemCheck) => itemCheck?.id === item?.id);
  }, [item?.id, checkedIds]);

  const handleCheckboxChange = () => {
    if (isChecked) {
      setCheckedIds(
        checkedIds.filter((itemCheck) => itemCheck?.id !== item?.id)
      );
    } else {
      setCheckedIds([...checkedIds, item]);
    }
  };

  const convertTime = (time) => {
    return moment(time * 1000).utc().format("DD MMM. YYYY HH:mm:ss");
  };

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isChecked}
          onChange={handleCheckboxChange}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.user?.email}
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {convertTime(item?.update_time)}
        </Text>
      </Td>
    </Tr>
  );
}
