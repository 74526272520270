import { useState, useCallback, useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { nftsSelector } from "stores/nfts/selector";
import { setSummaryData } from "stores/nfts/nftsReducer";
import {
  DEFAULT_FILTER_PARAMS,
  GENESIS_NFT_TABS_VALUES,
  NFT_MANAGEMENT_PARAMS,
  NFT_MANAGEMENT_TYPE,
} from "views/NFTsManagement/constants";
import { CHAIN_ID_AVAILABLE } from "constants/constants";
import {
  getDetailHunterNFT,
  getHunterNFTs,
  getHunterSummary,
} from "api/hunterNFTs.api";
import {
  getDetailGauntletNFT,
  getGauntletNFTs,
  getGauntletSummary,
} from "api/gauntlet.api";
import {
  getBountyBallNFTs,
  getDetailBountyBallNFT,
  getBountyBallSummary,
} from "api/bountyBall.api";
import {
  getDetailGenesisNFT,
  getGenesisNFTs,
  getGenesisSummary,
} from "api/genesisNFTs.api";
import { convertParams, omitParams } from "utils/object";
import { downloadCSV } from "utils/downloadCSV";
import END_POINT from "api/constants";
import {
  changeTaskStatusOwner,
  getDetailOwnerNFTSummary,
  getOwnerNFTs,
  getOwnerSummary,
  importCSVOwnerNFT,
} from "api/ownerNFTs.api";
import useWithToast from "hooks/useWithToast";

export const useNFTList = (nftMngType) => {
  const dispatch = useDispatch();
  const { indexTabFilter } = useSelector(nftsSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImport, setIsLoadingImport] = useState(false);
  const [isRefetchNFTs, setIsRefetchNFTs] = useState(false);
  const [nfts, setNFTs] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);
  const [params, setParams] = useState(
    NFT_MANAGEMENT_PARAMS[nftMngType] || DEFAULT_FILTER_PARAMS
  );
  const [total, setTotal] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [nftDetail, setNftDetail] = useState(null);
  const toast = useToast();
  const { showToastSuccess, showToastFail } = useWithToast();

  const setNFTParams = (params) => {
    setParams((prevParams) => ({ ...prevParams, ...params }));
  };

  const getChainId = useCallback(() => {
    const isProduction = process.env.REACT_APP_ENV === "production";
    const chainIdMap = {
      [GENESIS_NFT_TABS_VALUES.IMMUTABLE]: isProduction
        ? CHAIN_ID_AVAILABLE.IMX_MAINNET
        : CHAIN_ID_AVAILABLE.IMX_TESTNET,
      [GENESIS_NFT_TABS_VALUES.DM2C]: isProduction
        ? CHAIN_ID_AVAILABLE.DM2_MAINNET
        : CHAIN_ID_AVAILABLE.DM2_TESTNET,
      [GENESIS_NFT_TABS_VALUES.POLYGON]: isProduction
        ? CHAIN_ID_AVAILABLE.POLYGON_MAINNET
        : CHAIN_ID_AVAILABLE.POLYGON_TESTNET,
      [GENESIS_NFT_TABS_VALUES.BNB_CHAIN]: isProduction
        ? CHAIN_ID_AVAILABLE.BNB_MAINNET
        : CHAIN_ID_AVAILABLE.BNB_TESTNET,
    };
    return chainIdMap[indexTabFilter] || "";
  }, [indexTabFilter]);

  const getNFTs = useCallback(async () => {
    try {
      setIsLoading(true);
      setCheckedIds([]);

      const getNFTsAndSummaryFunctions = {
        [NFT_MANAGEMENT_TYPE.HUNTER]: {
          getNFTs: getHunterNFTs,
          getSummary: getHunterSummary,
        },
        [NFT_MANAGEMENT_TYPE.GAUNTLET]: {
          getNFTs: getGauntletNFTs,
          getSummary: getGauntletSummary,
        },
        [NFT_MANAGEMENT_TYPE.BOUNTY_BALL]: {
          getNFTs: getBountyBallNFTs,
          getSummary: getBountyBallSummary,
        },
        [NFT_MANAGEMENT_TYPE.GENESIS]: {
          getNFTs: getGenesisNFTs,
          getSummary: getGenesisSummary,
        },
        [NFT_MANAGEMENT_TYPE.OWNER]: {
          getNFTs: getOwnerNFTs,
          getSummary: getOwnerSummary,
        },
      };

      const { getNFTs: getNFTsFunction, getSummary: getSummaryFunction } =
        getNFTsAndSummaryFunctions[nftMngType] || {};

      if (!getNFTsFunction || !getSummaryFunction) {
        throw new Error("Invalid NFT management type");
      }

      const requestParams = {
        ...params,
        .../* nftMngType === NFT_MANAGEMENT_TYPE.GENESIS &&  */ {
          chain: getChainId(),
        },
      };
      // Params OWNER
      if (nftMngType === NFT_MANAGEMENT_TYPE.OWNER) {
        requestParams.keyword = requestParams.query;
        delete requestParams.query;
        delete requestParams.chain;
        delete requestParams["mint-status"];
      }

      const summaryParams = { ...requestParams };
      delete summaryParams.page;
      delete summaryParams.limit;
      delete summaryParams.chain;
      // if (nftMngType === NFT_MANAGEMENT_TYPE.GENESIS) {
      // }

      const [nftsData] = await Promise.all([
        getNFTsFunction(requestParams),
        // getSummaryFunction(summaryParams),
      ]);

      if (nftsData?.data?.success) {
        const { records, total_page, total } = nftsData.data.data;
        setNFTs(records.map((nft, index) => ({ ...nft, index: index + 1 })));
        if (nftMngType === NFT_MANAGEMENT_TYPE.OWNER) {
          setTotal(total || 0);
        }
        setTotalPage(total_page || 0);
      } else {
        throw new Error("Failed to fetch NFTs");
      }
    } catch (error) {
      toast({
        description:
          error.message || "An error occurred while fetching NFTs and summary",
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  }, [params, toast, nftMngType, indexTabFilter, getChainId, isRefetchNFTs]);

  const getSummaryNFTs = useCallback(async () => {
    try {
      setIsLoading(true);

      const getNFTsAndSummaryFunctions = {
        [NFT_MANAGEMENT_TYPE.HUNTER]: {
          getSummary: getHunterSummary,
        },
        [NFT_MANAGEMENT_TYPE.GAUNTLET]: {
          getSummary: getGauntletSummary,
        },
        [NFT_MANAGEMENT_TYPE.BOUNTY_BALL]: {
          getSummary: getBountyBallSummary,
        },
        [NFT_MANAGEMENT_TYPE.GENESIS]: {
          getSummary: getGenesisSummary,
        },
        [NFT_MANAGEMENT_TYPE.OWNER]: {
          getSummary: getOwnerSummary,
        },
      };

      const { getSummary: getSummaryFunction } =
        getNFTsAndSummaryFunctions[nftMngType] || {};

      if (!getSummaryFunction) {
        throw new Error("Invalid NFT management type");
      }

      const requestParams = {
        ...params,
        .../* nftMngType === NFT_MANAGEMENT_TYPE.GENESIS &&  */ {
          chain: getChainId(),
        },
      };

      const summaryParams = { ...requestParams };
      delete summaryParams.page;
      delete summaryParams.limit;
      delete summaryParams.chain;

      const [summaryData] = await Promise.all([
        getSummaryFunction(summaryParams),
      ]);

      if (summaryData?.data?.success) {
        dispatch(setSummaryData(summaryData.data.data));
      } else {
        throw new Error("Failed to fetch summary data");
      }
    } catch (error) {
      toast({
        description:
          error.message || "An error occurred while fetching NFTs and summary",
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  }, [indexTabFilter]);

  const getNFTDetail = async (item) => {
    try {
      setIsLoading(true);
      const getNFTDetailFunction = {
        [NFT_MANAGEMENT_TYPE.HUNTER]: getDetailHunterNFT,
        [NFT_MANAGEMENT_TYPE.GAUNTLET]: getDetailGauntletNFT,
        [NFT_MANAGEMENT_TYPE.BOUNTY_BALL]: getDetailBountyBallNFT,
        [NFT_MANAGEMENT_TYPE.GENESIS]: getDetailGenesisNFT,
        [NFT_MANAGEMENT_TYPE.OWNER]: getDetailOwnerNFTSummary,
      }[nftMngType];

      const isOwnerNFT = nftMngType === NFT_MANAGEMENT_TYPE.OWNER;
      const { data } = isOwnerNFT
        ? await getNFTDetailFunction({
            nftHolderId: item?.nft_holder_id,
            email: !item?.nft_holder_id ? item?.email : undefined,
          })
        : await getNFTDetailFunction(item.id);
      if (data?.success) {
        setNftDetail((preState) => ({ ...preState, ...data.data }));
      }
    } catch (error) {
      toast({
        description:
          error.message || "An error occurred while fetching NFT detail",
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getNFTs();
  }, [getNFTs, params, isRefetchNFTs]);

  const handleDownloadCSV = useCallback(async () => {
    try {
      setIsLoading(true);
      const getNFTsCSVPath = {
        [NFT_MANAGEMENT_TYPE.HUNTER]: END_POINT.NFT_HUNTER_CSV,
        [NFT_MANAGEMENT_TYPE.GENESIS]: END_POINT.NFT_GENESIS_CSV,
        [NFT_MANAGEMENT_TYPE.GAUNTLET]: END_POINT.NFT_GAUNTLET_CSV,
        [NFT_MANAGEMENT_TYPE.BOUNTY_BALL]: END_POINT.NFT_BOUNTY_BALL_CSV,
        [NFT_MANAGEMENT_TYPE.OWNER]: END_POINT.NFT_OWNER_CSV,
      }[nftMngType];

      const csvParams = {
        ...params,
        chain: getChainId(),
      };
      delete csvParams.page;
      delete csvParams.limit;

      const param = convertParams(omitParams(csvParams));
      const baseUrl = `${getNFTsCSVPath}?${param}`;
      await downloadCSV(baseUrl, true);
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  }, [params]);

  const handleImportCSV = async (data, callback) => {
    setIsLoadingImport(true);
    try {
      const getImportCSVPath = {
        [NFT_MANAGEMENT_TYPE.OWNER]: {
          importCSV: importCSVOwnerNFT,
        },
      }[nftMngType];
      const res = await getImportCSVPath.importCSV(data);
      const result = res?.data;
      if (result?.success) {
        showToastSuccess({
          title: "Imported data successfully.",
        });
        if (callback) callback();
        setIsRefetchNFTs((preState) => !preState);
      } else {
        const message = Array.isArray(result?.data)
          ? result?.data[0]?.error_message
          : result?.messages[0];
        showToastFail({
          title: message,
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoadingImport(false);
    }
  };

  const handleUpdateDataNFT = async (params) => {
    setIsLoading(true);
    try {
      const changeDataNFTPath = {
        [NFT_MANAGEMENT_TYPE.OWNER]: changeTaskStatusOwner,
      }[nftMngType];

      const { data } = await changeDataNFTPath(params);
      if (data?.success) {
        setIsRefetchNFTs((preState) => !preState);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePage = useCallback(
    (page) => {
      setNFTParams({ ...params, page });
    },
    [params]
  );

  const handleChangeLimit = useCallback(
    (limit) => {
      setNFTParams({ ...params, limit, page: 1 });
    },
    [params]
  );

  const handleChangeChecked = useCallback(
    (isChecked) => {
      if (isChecked) {
        setCheckedIds(nfts);
      } else {
        setCheckedIds([]);
      }
    },
    [nfts]
  );

  return {
    isLoading,
    isLoadingImport,
    nfts,
    checkedIds,
    total,
    totalPage,
    params,
    nftDetail,
    getNFTs,
    getNFTDetail,
    handleChangePage,
    handleChangeLimit,
    handleChangeChecked,
    handleDownloadCSV,
    handleImportCSV,
    handleUpdateDataNFT,
    setCheckedIds,
    setNFTParams,
    setNftDetail,
    getSummaryNFTs,
  };
};
