// Chakra imports
import { Flex, Icon, Image, Stack, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import moment from "moment";
import { useMemo } from "react";
import { splitEclipseCharacter } from "utils/string";
import { convertDateLocalLLLL } from "utils/time";
import { EditIcon } from "components/Icons/EditIcon";

const MachineRowGamePlay = ({
  machine,
  checkedIds,
  setCheckedIds,
  handleOpenCreateOrUpdate,
}) => {
  const textColor = useColorModeValue("#000000", "white");

  const isMachineChecked = useMemo(() => {
    return checkedIds.some((item) => item?.id === machine?.id);
  }, [machine?.id, checkedIds]);

  const handleCheckboxChange = () => {
    if (isMachineChecked) {
      setCheckedIds(checkedIds.filter((item) => item?.id !== machine?.id));
    } else {
      setCheckedIds([...checkedIds, machine]);
    }
  };

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isMachineChecked}
          onChange={() => handleCheckboxChange()}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Image
            src={machine?.image_url}
            w="100px"
            h="100%"
            alt={machine?.image_url}
          />
        </Stack>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {splitEclipseCharacter(machine?.name)}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {machine?.duration}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {machine?.payout}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {machine?.translations[0]?.description}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {machine?.status}
        </Text>
      </Td>
      <Td>
        <Flex gap={6}>
          <Icon
            onClick={() => handleOpenCreateOrUpdate()}
            fontSize="30px"
            borderRadius="8px"
            p="7px"
            cursor="pointer"
            bg="#1480FF"
            as={EditIcon}
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default MachineRowGamePlay;
