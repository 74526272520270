import { Box, Divider, Flex, Text, useToast, VStack } from "@chakra-ui/react";
import { getAllActiveNFTs, getDetailOwnerNFT } from "api/ownerNFTs.api";
import ICON_GREEN_NFT from "assets/svg/green-nft-icon.svg";
import ICON_PINK_NFT from "assets/svg/pink-nfts-icon.svg";
import ICON_PURPLE_NFT from "assets/svg/purple-nfts-icon.svg";
import ButtonType from "components/Button";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { countNumberArray } from "utils/number";
import { splitEclipseCharacter } from "utils/string";
import NFTsOwnerSummaryPanel from "../panel/NFTsOwnerSummaryPanel";
import PayoutHistoryRow from "../tableRow/PayoutHistoryRow";
import ActiveNFTsRow from "../tableRow/ActiveNFTsRow";
import { convertParams, omitParams } from "utils/object";
import { downloadCSV } from "utils/downloadCSV";
import END_POINT from "api/constants";
import { debounce } from "lodash";

const LABELS_PAYOUT = ["NO.", "MONTH", "NUM OF NFT", "PAYOUT"];
const LABELS_ACTIVE_NFT = ["NFTS", "PURCHASED ON", "EXPIRED ON"];
const INIT_SUMMARY_OWNER_DATA = {
  summary: {
    total_payout: {},
  },
  chainData: [
    { name: "NFTs", count: 0 },
    { icon: ICON_PINK_NFT, count: 0 },
    { icon: ICON_GREEN_NFT, count: 0 },
    { icon: ICON_PURPLE_NFT, count: 0 },
  ],
};

const OwnerDetail = ({ item: summary }) => {
  const toast = useToast();
  const [isLoadingPayout, setIsLoadingPayout] = useState(false);
  const [isLoadingActiveNFTs, setIsLoadingActiveNFTs] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [payoutHistory, setPayoutHistory] = useState([]);
  const [allActiveNFTs, setAllActiveNFTs] = useState([]);
  const [paramsPayout, setParamsPayout] = useState({
    limit: 10,
    page: 1,
    total_page: 0,
  });
  const [paramsActiveNFTs, setParamsActiveNFTs] = useState({
    limit: 10,
    page: 1,
    total_page: 0,
  });

  const summaryByNftType = useMemo(() => {
    if (!summary || !Object.values(summary)?.length) {
      return INIT_SUMMARY_OWNER_DATA;
    }

    const totalNfts = countNumberArray(Object.values(summary?.nft_summary));
    return {
      email: summary?.email,
      username: summary?.username,
      summary: {
        total_payout:
          summary?.revenue_temp &&
          `${summary?.revenue_temp?.symbol}${summary?.revenue_temp?.amount}`,
      },
      chainData: [
        { name: "Active NFTs", count: totalNfts },
        {
          icon: ICON_PINK_NFT,
          count: summary?.nft_summary.pink_nft_quantity,
        },
        {
          icon: ICON_GREEN_NFT,
          count: summary?.nft_summary.green_nft_quantity,
        },
        {
          icon: ICON_PURPLE_NFT,
          count: summary?.nft_summary.purple_nft_quantity,
        },
      ],
    };
  }, [summary]);

  const handleChangeLimit = (limit, setParams) => {
    setParams((preState) => ({
      ...preState,
      page: 1,
      limit,
    }));
  };

  const handleChangePage = (page, setParams) => {
    setParams((preState) => ({
      ...preState,
      page,
    }));
  };

  const fetchDataOwnerDetail = async (
    params,
    callbackAPI,
    setList,
    setParams,
    setIsLoading
  ) => {
    if (!summary) return;
    setIsLoading(true);
    try {
      const paramsAPI = {
        ...params,
        nftHolderId: summary?.nft_holder_id,
        email: !summary?.nft_holder_id ? summary?.email : undefined,
      };
      const { data } = await callbackAPI(paramsAPI);
      if (data) {
        const { records, ...params } = data.data;
        setList(records);
        setParams((preState) => ({
          ...preState,
          total: params?.total,
          total_page: params?.total_page,
        }));
      }
    } catch (error) {
      toast({
        description:
          error?.message || "An error occurred while fetching Owner Details",
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadCSV = async () => {
    try {
      const csvParams = {
        ...paramsPayout,
        nftHolderId: summary?.nft_holder_id,
        email: !summary?.nft_holder_id ? summary?.email : undefined,
      };
      delete csvParams.page;
      delete csvParams.limit;

      const param = convertParams(omitParams(csvParams));
      const baseUrl = `${END_POINT.NFT_OWNER_DETAILS_CSV}?${param}`;
      await downloadCSV(baseUrl, true);
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const debouncedDownload = useCallback(
    debounce(() => handleDownloadCSV(), 500),
    []
  );

  // GET All Active NFTs
  useEffect(() => {
    fetchDataOwnerDetail(
      paramsActiveNFTs,
      getAllActiveNFTs,
      setAllActiveNFTs,
      setParamsActiveNFTs,
      setIsLoadingActiveNFTs
    );
  }, [summary, paramsActiveNFTs?.page, paramsActiveNFTs?.limit]);

  // GET Payout History
  useEffect(() => {
    fetchDataOwnerDetail(
      paramsPayout,
      getDetailOwnerNFT,
      setPayoutHistory,
      setParamsPayout,
      setIsLoadingPayout
    );
  }, [summary, paramsPayout?.page, paramsPayout?.limit]);

  return (
    <VStack spacing={8} mb={6} align="stretch">
      <Flex alignItems={"flex-end"}>
        <Flex flexDir={"column"} mr={"70px"}>
          <Text fontSize="md" fontWeight="bold" color="#013fb5" mb={2}>
            {splitEclipseCharacter(summaryByNftType?.email, true, 25)}
          </Text>
          <Text fontSize="xs" fontWeight="bold" mb={2}>
            {splitEclipseCharacter(summaryByNftType?.username, true, 25)}
          </Text>
          <Text fontSize="xs" fontWeight="bold" mb={2}>
            Task Status:{" "}
            {summaryByNftType?.is_complete_task ? (
              <Text as="span" color="#0db632">
                Done
              </Text>
            ) : (
              "Not Done"
            )}
          </Text>
        </Flex>
        <NFTsOwnerSummaryPanel
          isOwnerDetail
          summaryByNftType={summaryByNftType}
        />
      </Flex>
      <Divider />

      {/* ALL ACTIVE NFTs */}
      <Flex
        w="100%"
        gap={4}
        direction={{
          base: "column",
          md: "row",
        }}
        justifyContent={{
          base: "flex-start",
          md: "space-between",
        }}
        alignItems="center"
      >
        <Text fontSize="lg" fontWeight="bold">
          ALL ACTIVE NFTs
        </Text>
      </Flex>
      <Box>
        <TableCustom
          labels={LABELS_ACTIVE_NFT}
          isLoading={isLoadingActiveNFTs}
          isCheck={false}
          dataRow={allActiveNFTs}
        >
          {allActiveNFTs?.map((item, index) => {
            return <ActiveNFTsRow key={index} item={item} />;
          })}
        </TableCustom>
        <Paginate
          page={paramsActiveNFTs?.page}
          pageCount={paramsActiveNFTs?.total_page}
          limit={paramsActiveNFTs.limit}
          total={paramsActiveNFTs.total}
          isShowTotal
          onChangeLimit={(limit) =>
            handleChangeLimit(limit, setParamsActiveNFTs)
          }
          handleChangePage={(page) =>
            handleChangePage(page, setParamsActiveNFTs)
          }
        />
      </Box>

      {/* Payout History */}
      <Flex
        w="100%"
        gap={4}
        direction={{
          base: "column",
          md: "row",
        }}
        justifyContent={{
          base: "flex-start",
          md: "space-between",
        }}
        alignItems="center"
      >
        <Text fontSize="lg" fontWeight="bold">
          Payout History
        </Text>
        <ButtonType
          text="Download CSV"
          fontSize="12px"
          sizeIcon="8px"
          iconType="download"
          btnType="dark"
          onClick={debouncedDownload}
        />
      </Flex>
      <Box>
        <TableCustom
          labels={LABELS_PAYOUT}
          isLoading={isLoadingPayout}
          isCheck={false}
          dataRow={payoutHistory}
        >
          {payoutHistory?.map((item, index) => {
            return (
              <PayoutHistoryRow
                key={`${index}${item.month}`}
                item={item}
                index={
                  (paramsPayout?.page - 1) * paramsPayout?.limit + index + 1
                }
              />
            );
          })}
        </TableCustom>
        <Paginate
          page={paramsPayout?.page}
          pageCount={paramsPayout?.total_page}
          limit={paramsPayout.limit}
          total={paramsPayout.total}
          isShowTotal
          onChangeLimit={(limit) => handleChangeLimit(limit, setParamsPayout)}
          handleChangePage={(page) => handleChangePage(page, setParamsPayout)}
        />
      </Box>
    </VStack>
  );
};

export default OwnerDetail;
