import { Icon } from "@chakra-ui/icons";
import {
  Flex,
  Image,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import moment from "moment";
import { useMemo } from "react";
import { ViewIcon } from "components/Icons/ViewIcon";
import { RARITY, TRAIT } from "views/PrizeSettings/constant";

export default function ItemsSendRow({
  item,
  checkedIds,
  setCheckedIds,
  watch,
  handleOpenDetailUserModal,
}) {
  const textColor = useColorModeValue("#000000", "white");

  const isChecked = useMemo(() => {
    return checkedIds.some((itemCheck) => itemCheck?.id === item?.id);
  }, [item?.id, checkedIds]);

  const handleCheckboxChange = () => {
    if (isChecked) {
      setCheckedIds(
        checkedIds.filter((itemCheck) => itemCheck?.id !== item?.id)
      );
    } else {
      if (watch("prize_type") === "TREASURE_BOX") {
        setCheckedIds([item]);
      } else {
        setCheckedIds([...checkedIds, item]);
      }
    }
  };

  const convertTime = (time) => {
    return moment(time * 1000).utc().format("DD MMM. YYYY HH:mm:ss");
  };

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isChecked}
          onChange={handleCheckboxChange}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          <Image src={item?.image_url || item?.image_closed} width={50}/>
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.name}
        </Text>
      </Td>
      {watch("prize_type") !== "TREASURE_BOX" && (
        <>
          <Td>
            <Text color={textColor} fontSize="sm" fontWeight={400}>
              {RARITY[item?.rarity]}
            </Text>
          </Td>
          <Td>
            <Text color={textColor} fontSize="sm" fontWeight={400}>
              {TRAIT[item?.trait]}
            </Text>
          </Td>
          <Td>
            <Text color={textColor} fontSize="sm" fontWeight={400}>
              N/A
            </Text>
          </Td>
        </>
      )}
      <Td>
        <Icon
          fontSize="30px"
          borderRadius="8px"
          cursor="pointer"
          onClick={() => handleOpenDetailUserModal(item)}
          as={ViewIcon}
        />
      </Td>
    </Tr>
  );
}
