export const OWNER_NFTS_LIST_DUMMY = {
  success: true,
  code: 0,
  message: {
    code: "string",
    content: "string",
  },
  messages: ["string"],
  data: {
    total: 0,
    limit: 0,
    total_page: 0,
    page: 0,
    records: [
      {
        id: "1",
        index: "1",
        email: "email@example.com",
        username: "username example",
        nft_quantity: {
          pink_nft_quantity: 10,
          green_nft_quantity: 20,
          purple_nft_quantity: 30,
        },
        payout: {
          amount: 200,
          unit: "BCoin",
          symbol: "BCoin",
        },
        payout_temp: {
          amount: 50,
          unit: "$",
          symbol: "$",
        },
        is_complete_task: true,
      },
      {
        id: "2",
        index: "2",
        email: "email@example.com",
        username: "username example",
        nft_quantity: {
          pink_nft_quantity: 10,
          green_nft_quantity: 20,
          purple_nft_quantity: 30,
        },
        payout: {
          amount: 100,
          unit: "BCoin",
          symbol: "BCoin",
        },
        payout_temp: {
          amount: 50,
          unit: "$",
          symbol: "$",
        },
        is_complete_task: false,
      },
    ],
  },
  timestamp: 0,
};

export const OWNER_NFTS__SUMMARY_DUMMY = {
  success: true,
  code: 200,
  message: {
    code: "string",
    content: "string",
  },
  messages: ["string"],
  data: {
    nft_summary: {
      pink_nft_quantity: 10,
      green_nft_quantity: 10,
      purple_nft_quantity: 10,
    },
    nft_sold: {
      pink_nft_quantity: 10,
      green_nft_quantity: 10,
      purple_nft_quantity: 10,
    },
    current_month: "08/2024",
    active_machine: 20,
    revenue: {
      amount: 20000,
      unit: "BCoin",
      symbol: "BCoin",
    },
    revenue_temp: {
      amount: 5000,
      unit: "$",
      symbol: "$",
    },
  },
  timestamp: 1733365071416,
};

export const MONTHLY_PAYOUT_SETTINGS = {
  success: true,
  code: 0,
  message: {
    code: "string",
    content: "string",
  },
  messages: ["string"],
  data: {
    total: 0,
    limit: 0,
    total_page: 0,
    page: 0,
    records: [
      {
        id: "1",
        month_number: 1,
        year_order: 1,
        pink_nft_rate: 0.2,
        green_nft_rate: 0.3,
        purple_nft_rate: 0.1,
      },
      {
        id: "2",
        month_number: 2,
        year_order: 1,
        pink_nft_rate: 0.1,
        green_nft_rate: 0.2,
        purple_nft_rate: 0.3,
      },
    ],
  },
  timestamp: 0,
};
