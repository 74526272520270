import React from 'react'
import { STATE } from "../constants";
import { FormProvider, useForm } from 'react-hook-form';
import { Grid, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import FormSelect from 'components/form/FormSelect';
import ButtonType from 'components/Button';

export default function ModalChangeState({ isOpen, onClose, handleSwitchListStatus }) {
  
  const form = useForm();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const onSubmit = async (data, e) => {
    e.preventDefault();
    handleSwitchListStatus(data?.status)
    onClose()
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <FormSelect
              name="status"
              label="Set the state for the the selected machines"
              defaultValue={STATE[0].value}
              isRequired
              options={STATE}
            />
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
        >
          State
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            SET
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
