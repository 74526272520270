import { containSpecialRegex } from "utils/regex";
import * as yup from "yup";

const schemaStandardEvent = () =>
  yup.object().shape({
    event_name: yup
      .string()
      .trim()
      .matches(containSpecialRegex, "Name should not contain special characters")
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    event_name_jp: yup
      .string()
      .trim()
      .matches(containSpecialRegex, "Name should not contain special characters")
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    icon_url: yup
      .string()
      .trim()
      .required("Required"),
    players: yup
      .string()
      .trim()
      .required("Required"),
    pre_register_start_time: yup
      .date()
      .test(
        "is-future-minute",
        "Pre-register end time must be in the future",
        function (value) {
          const now = new Date();
          now.setSeconds(0, 0); // Ignoring seconds
          const inputTime = new Date(value);
          inputTime.setSeconds(0, 0); // Ignoring seconds
          return inputTime.getTime() >= now.getTime();
        }
      )
      .required(),
    event_start_time: yup
      .date()
      // .test(
      //   "is-greater-whitelist_end_time",
      //   "Event end time must be after whitelist end time",
      //   function (value) {
      //     const { whitelist_end_time } = this.parent;
      //     return (
      //       whitelist_end_time &&
      //       value &&
      //       new Date(value) > new Date(whitelist_end_time)
      //     );
      //   }
      // )
      .required(),
    event_end_time: yup
      .date()
      // .test(
      //   "is-greater-whitelist_end_time",
      //   "Event end time must be after whitelist end time",
      //   function (value) {
      //     const { whitelist_end_time } = this.parent;
      //     return (
      //       whitelist_end_time &&
      //       value &&
      //       new Date(value) > new Date(whitelist_end_time)
      //     );
      //   }
      // )
      .required(),
    result_end_time: yup
      .date()
      .test(
        "is-greater-event_end_time",
        "Result end time must be after event end time",
        function (value) {
          const { event_end_time } = this.parent;
          return (
            event_end_time &&
            value &&
            new Date(value) > new Date(event_end_time)
          );
        }
      )
      .required(),
  });

export default schemaStandardEvent;
