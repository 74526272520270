import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import {
  createMachineGroup,
  updateMachineGroup,
} from "api/machine.api";
import ButtonType from "components/Button";
import FormInput from "components/form/FormInput";
import { ERROR_MACHINE_GROUPS } from "../constants";

export default function CreateAndUpdateGroup({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
}) {
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");
  const form = useForm({
    defaultValues: {
      machine_label: dataDetail ? dataDetail?.machine_label : '',
      machine_size: dataDetail ? dataDetail?.machine_size : '',
    }
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const idEdit = useMemo(() => {
    return dataDetail?.machine_label;
  }, [dataDetail]);
  
  const onSubmit = async (data, e) => {
    e.preventDefault();
    const dataSubmit = {
      machine_label: data?.machine_label,
      machine_size: Number(data?.machine_size),
    };
    try {
      if (idEdit) {
        const res = await updateMachineGroup(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Machine updated.",
            description: "Machine updated.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
        } else {
          toast({
            description: ERROR_MACHINE_GROUPS[res?.data?.message] || ERROR_MACHINE_GROUPS[res?.data?.messages[0]],
            status: "error",
            position: "bottom-right",
          });
        }
      } else {
        const res = await createMachineGroup(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Machine created.",
            description: "Machine created.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
        } else {
          toast({
            description: ERROR_MACHINE_GROUPS[res?.data?.message] || ERROR_MACHINE_GROUPS[res?.data?.messages[0]],
            status: "error",
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      handleCreateOrUpdateSuccess()
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <Flex alignItems="flex-start" flexDirection="column" gap="16px" w="100%">
              <FormInput
                wrapperProps={{
                  w: "100%",
                }}
                label="Label"
                name="machine_label"
                placeholder="Enter the label for the group"
              />
              <FormInput
                wrapperProps={{
                  w: "100%",
                }}
                label="Size"
                name="machine_size"
                placeholder="Enter the size of the group"
              />
            </Flex>
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "EDIT MACHINE GROUPS" : "NEW MACHINE GROUPS"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
            // onClick={() => {
            //   handleSubmit(onSubmit);
            // }}
          >
            {idEdit ? "SAVE" : "ADD"}
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
