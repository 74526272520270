import {
  Box,
  Divider,
  Flex,
  Grid,
  HStack,
  Image,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { capitalize } from "lodash";
import CommonBg from "assets/img/card/common-bg.svg";
import UncommonBg from "assets/img/card/uncommon-bg.svg";
import RareBg from "assets/img/card/rare-bg.svg";
import LegendaryBg from "assets/img/card/legendary-bg.svg";
import EpicBg from "assets/img/card/epic-bg.svg";
import { RARITY } from "constants/constants";
import { useMemo } from "react";
import { trimCharacter } from "utils/string";
import {
  getAllocationLabel,
  getChainIcon,
} from "views/NFTsManagement/utils/utils";
import { getIconTrait } from "views/NFTsManagement/utils/utils";

const GauntletDetail = ({ item, chain }) => {
  const textColor = useColorModeValue("gray.700", "white");

  const IconCard = useMemo(() => {
    const rarity = item?.rarity;
    let iconCard = {
      iconBg: CommonBg,
      bgRarity: "#545f7b",
    };
    if (rarity === RARITY.RARITY_UNCOMMON) {
      iconCard = {
        iconBg: UncommonBg,
        bgRarity: "#008a17",
      };
    } else if (rarity === RARITY.RARITY_RARE) {
      iconCard = {
        iconBg: RareBg,
        bgRarity: "#005ddd",
      };
    } else if (rarity === RARITY.RARITY_EPIC) {
      iconCard = {
        iconBg: EpicBg,
        bgRarity: "#7612ff",
      };
    }
    if (rarity === RARITY.RARITY_LEGENDARY) {
      iconCard = {
        iconBg: LegendaryBg,
        bgRarity: "#ffd32f",
      };
    }

    return iconCard;
  }, [item?.rarity]);

  return (
    <VStack spacing={4} align="stretch">
      <Grid templateColumns="1fr 2fr" gap={4}>
        <Box
          bgImage={IconCard.iconBg}
          position="relative"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundSize="contain"
          borderRadius="md"
          overflow="hidden"
        >
          <Image
            src={item?.image_url}
            alt={item?.nft_information?.name}
            objectFit="contain"
            w="60%"
            h="100%"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          />
        </Box>
        <VStack align="start" spacing={2}>
          <HStack>
            <Image
              boxSize="50px"
              src={getChainIcon(item?.nft_information?.chain_id || chain).src}
              alt={getChainIcon(item?.nft_information?.chain_id || chain).alt}
            />
            <Box paddingLeft={2}>
              <Box>
                <HStack align="start" spacing={30}>
                  <Text fontSize="sm" fontWeight="bold" color={textColor}>
                    Contract Address
                  </Text>
                  <Text fontWeight="bold" color="blue.500">
                    {trimCharacter(
                      item?.nft_information?.contract_address || item?.contract_address,
                      6,
                      4
                    )}
                  </Text>
                </HStack>
              </Box>
              <Box>
                <HStack align="start" spacing={30}>
                  <Text fontSize="sm" fontWeight="bold" color={textColor}>
                    Token ID {item?.nft_information?.token_id || item?.token_id}
                  </Text>
                  <Text fontSize="sm" fontWeight="bold" color={textColor}>
                    Token Standard ERC-721
                  </Text>
                </HStack>
              </Box>
            </Box>
          </HStack>
          <Text fontWeight="bold" color={textColor}>
            Owner:{" "}
            <Text
              as="span"
              color={item?.user_model?.name ? "black" : "red.500"}
            >
              {item?.user_model?.name || "Admin"}
            </Text>
          </Text>
          <Divider />
          <Text fontSize="xl" fontWeight="bold" color="blue.500">
            {item?.nft_information?.name}
          </Text>
          <Text fontWeight="bold" color={textColor}>
            Rarity:{" "}
            <Text as="span" color={IconCard.bgRarity} fontWeight="bold">
              {capitalize(item?.rarity)}
            </Text>
          </Text>
          <Text fontWeight="bold" color={textColor}>
            <Flex gap={2} align="center">
              <Text>Boost:</Text>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                bg="rgba(0,0,0,0.4)"
                padding="6px 16px"
                borderRadius="16px"
              >
                <Box position="relative">
                  <Image w="40px" src={getIconTrait(item?.trait)} alt="icon" />
                  <Text
                    position="absolute"
                    bottom="-1px"
                    right="-6px"
                    fontSize="xs"
                    fontWeight="bold"
                    color="white"
                    textShadow="-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000"
                    px={1}
                    borderRadius="sm"
                  >
                    {`${item?.boost_value || 0}%`}
                  </Text>
                </Box>
              </Box>
            </Flex>
          </Text>
          <Text fontWeight="bold" color={textColor}>
            <Flex gap={4}>
              <Flex gap={2} align="center">
                <Text>Slots: {item?.number_slots_exception}</Text>
              </Flex>
              <Flex gap={2} align="center">
                <Text>Level Required: {item?.min_player_lv_to_equip}</Text>
              </Flex>
            </Flex>
          </Text>
          <Divider />
          <Text fontWeight="bold" color={textColor}>
            Allocation:{" "}
            <Text as="span" color="blue.500">
              {getAllocationLabel(item?.allocation)}
            </Text>
          </Text>
          <Divider />
        </VStack>
      </Grid>
    </VStack>
  );
};

export default GauntletDetail;
