import { Box, Flex, Menu, MenuButton, MenuList, MenuItem, Button } from "@chakra-ui/react";
import { IoIosArrowDown } from "react-icons/io";
import ButtonType from "components/Button";
import { useMemo } from "react";
import { STATUS_USER } from "./constant";

export default function ActionUser({ checkedIds, toggle }) {
  const isHaveCheckedIds = useMemo(() => {
    return checkedIds?.length > 0;
  }, [checkedIds]);

  const isHaveBanner = useMemo(() => {
    return checkedIds?.filter((check) => check?.status === STATUS_USER?.BANNED)?.length > 0;
  }, [checkedIds]);

  return (
    <Box>
      <Flex alignItems="center" gap={4} mb="10px">
        <Menu>
          <MenuButton
            fontSize="12px"
            borderRadius="8px"
            boxShadow="0 4px 7px -1px rgba(0,0,0,.11),0 2px 4px -1px rgba(0,0,0,.07)"
            disabled={!isHaveCheckedIds}
            as={Button}
            rightIcon={<IoIosArrowDown />}
            bg="#383838"
            color="white"
            _hover={{ bg: "gray.700" }}
            _active={{ bg: "gray.600" }}
          >
            Transfer
          </MenuButton>
          <MenuList>
            <MenuItem
              minH="48px"
              isDisabled={checkedIds?.length !== 1}
              onClick={() => {
                if (isHaveBanner) {
                  toggle("modalHaveBanned")
                } else {
                  toggle("modalTransferNft")
                }
              }}
            >
              Transfer NFTs
            </MenuItem>
            <MenuItem 
              minH="48px" 
              onClick={() => {
                if (isHaveBanner) {
                  toggle("modalHaveBanned")
                } else {
                  toggle("modalTransferCoin")
                }
              }}
            >
              Transfer point in game
            </MenuItem>
            <MenuItem
              minH="48px"
              onClick={() => {
                if (isHaveBanner) {
                  toggle("modalHaveBanned")
                } else {
                  toggle("modalTransferTicket")
                }
              }}
            >
              Transfer Ticket
            </MenuItem>
          </MenuList>
        </Menu>
        <ButtonType
          text="Reset B-Coin"
          fontSize="12px"
          btnType="primary-new-outline"
          disabled={!isHaveCheckedIds}
          onClick={() => {
            if (isHaveBanner) {
              toggle("modalHaveBanned")
            } else {
              toggle("confirmReset")
            }
          }}
        />
        <ButtonType
          text="BAN"
          fontSize="12px"
          btnType="danger"
          width="100px"
          disabled={!isHaveCheckedIds}
          onClick={() =>  toggle("confirmBan")}
        />
        <ButtonType
          text="UNBAN"
          fontSize="12px"
          btnType="success"
          width="100px"
          disabled={!isHaveCheckedIds}
          onClick={() => toggle("confirmUnBan")}
        />
      </Flex>
    </Box>
  );
}
