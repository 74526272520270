import {
	Flex,
	Text,
	useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import SummaryGachaHistory from "./SummaryGachaHistory";
import TableGachaHistory from "./TableGachaHistory";

export default function GachaHistory() {
	const textColor = useColorModeValue("#000000", "white");

  return (
		<Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
			<Card px="24px">
				<CardHeader mb="12px" display="flex" flexDirection="column" gap="20px">
					<Flex
						w="100%"
						gap={4}
						alignItems="flex-start"
					>
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							whiteSpace="nowrap"
						>
							Gacha History
						</Text>
					</Flex>
				</CardHeader>
        <SummaryGachaHistory />
        <TableGachaHistory />
			</Card>
		</Flex>
	);
}
