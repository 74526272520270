import http from "./http";
import END_POINT from "./constants";

export const getAllStatusIot = () => {
  return http.get(`${END_POINT.IOT_DEVICE}/status/all`);
};

export const updateStatusDetail = (data) => {
  return http.post(`${END_POINT.IOT_DEVICE}/thing/status`, data);
};

export const restartStatusMachine = (data) => {
  return http.post(`${END_POINT.IOT_DEVICE}/restart`, data);
}
