import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getNotices = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.NOTICE}?${params}`);
};

export const deleteNotice = (ids, option = {}) => {
  return http.delete(`${END_POINT.NOTICE}`, ids, option);
};

export const createNotice = (data, option = {}) => {
  return http.post(END_POINT.NOTICE, data, option);
};

export const updateNotice = (idEdit, data, option = {}) => {
  return http.put(`${END_POINT.NOTICE}/${idEdit}`, data, option);
};

export const getNoticeById = (id) => {
  return http.get(`${END_POINT.NOTICE}/${id}`);
};

export const categoriesNotice = () => {
  return http.get(`${END_POINT.NOTICE_CATEGORY}`);
};

export const masterCountries = () => {
  return http.get(`${END_POINT.MASTER_COUNTRY}`);
};
