import { useMemo, useState } from "react";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Icon,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import ModalBase from "components/Modal/ModalBase";
import ButtonType from "components/Button";
import FormUpload from "components/form/FormUpload";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import { useTranslation } from "react-i18next";
import { uploadImageToServer } from "utils/uploadImage";
import {
  CHAIN_TYPE,
  ONLY_POLYGON_CHAINS,
  OPTIONS_RARITY,
} from "constants/constants";
import { PACKAGE_STATUS_OPTIONS } from "views/NFTsManagement/constants";
import { addPackageGenesis } from "api/genesisNFTs.api";
import ModalSuccessAndFail from "components/Modal/ModalSuccessAndFail";
import { formatErrorMessage } from "views/NFTsManagement/utils/utils";

export default function AddPackageGenesisModal({
  isOpen,
  onClose,
  onAddSuccess,
}) {
  const { t } = useTranslation("prize");
  const methods = useForm({
    defaultValues: {
      packageName: "",
      packageChain: "",
      status: "",
      packageImage: null,
      packagePrice: "",
      settings: [{ rarity: "", rate: 1 }],
    },
    mode: "onChange",
  });
  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
    watch,
    control,
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "settings",
  });

  const packageName = watch("packageName");
  const packageChain = watch("packageChain");
  const packageImage = watch("packageImage");
  const status = watch("status");
  const packagePrice = watch("packagePrice");
  const settings = watch("settings");
  const toast = useToast();

  const [errorMessage, setErrorMessage] = useState([]);

  const {
    isOpen: isOpenFail,
    onOpen: onOpenFail,
    onClose: onCloseFail,
  } = useDisclosure();

  const maxRarityValue = useMemo(() => OPTIONS_RARITY.length, []);

  const totalRate = useMemo(() => {
    return settings.reduce((sum, setting) => sum + Number(setting.rate), 0);
  }, [settings]);

  const getChainOptions = useMemo(() => {
    const isProduction = process.env.REACT_APP_ENV === "production";
    return [
      ...ONLY_POLYGON_CHAINS.filter((chain) =>
        isProduction
          ? chain.type === CHAIN_TYPE.MAINNET
          : chain.type === CHAIN_TYPE.TESTNET
      ).map((chain) => ({
        label: chain.label,
        value: chain.chain_id.toString(),
      })),
    ];
  }, []);

  const addSetting = () => {
    append({ rarity: "", rate: 1 });
  };

  const removeSetting = (index) => {
    if (fields.length > 1) {
      remove(index);
    }
  };

  const handleImageChange = async (event) => {
    const image = event[0];
    await uploadImageToServer(image, toast, setValue, "packageImage");
  };

  const deleteImage = () => {
    setValue("packageImage", null);
  };

  const onSubmit = async (formData) => {
    try {
      const payload = {
        name: formData?.packageName?.trim() || "",
        chain_id: formData.packageChain,
        status: formData.status,
        price: formData.packagePrice,
        image_url: formData.packageImage,
        setting: formData.settings?.map((item) => ({
          ...item,
          rate: Number(item.rate),
        })),
      };
      const { data } = await addPackageGenesis(payload);
      // Successful transfer
      if (data?.success) {
        onAddSuccess();
        onClose();
        toast({
          title: "Package added successfully.",
          status: "success",
          position: "top",
        });
      } else {
        const msgError = formatErrorMessage(data?.message || data?.messages[0]);
        setErrorMessage([msgError]);
        onOpenFail(true);
      }
    } catch (error) {
      toast({
        description: "Failed to add package.",
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const hasMaxTwoDecimals = (num) => {
    const decimalPart = String(num).split(".")[1];
    return !decimalPart || decimalPart.length <= 2;
  };

  const isFormValid = useMemo(() => {
    return (
      packageName &&
      packageName?.length <= 100 &&
      packageChain &&
      packageImage &&
      status &&
      packagePrice &&
      hasMaxTwoDecimals(packagePrice) && // Add check for max 2 decimals
      settings.length > 0 &&
      settings.every(
        (setting) =>
          setting.rarity && setting.rate !== "" && Number(setting.rate) >= 1
      ) &&
      totalRate
    );
  }, [
    packageName,
    packageChain,
    packageImage,
    status,
    packagePrice,
    settings,
    totalRate,
  ]);

  return (
    <>
      <ModalBase
        maxWContent={{
          base: "90%",
          sm: "80%",
          md: "50%",
        }}
        isOpen={isOpen}
        onClose={onClose}
        showBtn={false}
        titleHeader="NEW PACKAGE"
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={4} align="stretch">
              <FormInput
                label="Package Name"
                name="packageName"
                placeholder="Enter package name"
                isRequired
                rules={{
                  required: "Package name is required",
                  maxLength: {
                    value: 100,
                    message: "Package name must be less than 100 characters",
                  },
                }}
              />

              <FormSelect
                label="Package Chain"
                name="packageChain"
                placeholder="Select a chain"
                options={getChainOptions}
                isRequired
                rules={{
                  required: "Package chain is required",
                }}
              />

              <Box>
                <FormUpload
                  label="Select image file to upload"
                  title="Package Image"
                  name="packageImage"
                  accept=".png,.jpg,.jpeg"
                  isRequired
                  type="IMAGE"
                  textDescription={t("note_upload_image")}
                  handleOnChange={(e) => handleImageChange(e)}
                  deleteImage={deleteImage}
                  rules={{
                    required: "Package image is required",
                  }}
                />
              </Box>

              <FormSelect
                label="Status"
                name="status"
                placeholder="Select a status"
                options={PACKAGE_STATUS_OPTIONS}
                isRequired
                rules={{
                  required: "Status is required",
                }}
              />

              <FormInput
                label="Package Price"
                name="packagePrice"
                placeholder="Enter the price value"
                type="number"
                isRequired
                rules={{
                  required: "Package price is required",
                  min: {
                    value: 0.0000000001,
                    message: "Price must be greater than 0",
                  },
                  validate: {
                    decimals: (value) => {
                      const decimalPlaces =
                        value.toString().split(".")[1]?.length || 0;
                      return (
                        decimalPlaces <= 2 ||
                        "Price can only have up to 2 decimal places"
                      );
                    },
                  },
                }}
              />

              <Box>
                <Text fontWeight="bold" mb={2}>
                  Settings{" "}
                  <Text as="span" color="red">
                    *
                  </Text>
                </Text>
                {fields.map((field, index) => (
                  <Flex key={field.id} mb={2} alignItems="flex-start">
                    <Box flex={1}>
                      <Text mb={1} fontWeight="medium">
                        Rarity
                      </Text>
                      <FormSelect
                        name={`settings.${index}.rarity`}
                        placeholder="Select rarity"
                        options={OPTIONS_RARITY}
                        isRequired
                        rules={{
                          required: "Rarity is required",
                        }}
                      />
                    </Box>
                    <Box flex={1} ml={2}>
                      <Text mb={1} fontWeight="medium">
                        Weight
                      </Text>
                      <FormInput
                        name={`settings.${index}.rate`}
                        placeholder="Enter weight"
                        type="number"
                        step={1}
                        isRequired
                        rules={{
                          required: "Weight is required",
                          min: {
                            value: 1,
                            message: "Weight must be at least 1",
                          },
                          validate: {
                            integer: (value) =>
                              Number.isInteger(Number(value)) ||
                              "Weight must be an integer",
                          },
                        }}
                      />
                    </Box>
                    {fields.length > 1 && (
                      <Box display="flex" alignItems="center" ml={2}>
                        <Icon
                          as={CloseIcon}
                          cursor="pointer"
                          onClick={() => removeSetting(index)}
                        />
                      </Box>
                    )}
                  </Flex>
                ))}
                {settings.length < maxRarityValue && (
                  <ButtonType
                    onClick={addSetting}
                    btnType="primary-new-outline"
                    leftIcon={<AddIcon />}
                    mt={2}
                  >
                    Add Setting
                  </ButtonType>
                )}
              </Box>
              <Text fontWeight="bold" mb={2}>
                Total weight: {totalRate}
              </Text>

              <Flex justifyContent="space-between" mt={4}>
                <ButtonType
                  w="48%"
                  type="button"
                  onClick={onClose}
                  btnType="primary-new-outline"
                  text="Cancel"
                />
                <ButtonType
                  w="48%"
                  type="submit"
                  isDisabled={!isFormValid || isSubmitting}
                  isLoading={isSubmitting}
                  text="Add"
                />
              </Flex>
            </VStack>
          </form>
        </FormProvider>
      </ModalBase>
      {isOpenFail && (
        <ModalSuccessAndFail
          isOpen={isOpenFail}
          type="error"
          onClose={onCloseFail}
          onSubmit={onCloseFail}
          description={
            <VStack align="start" spacing={2} alignItems="center">
              {errorMessage?.map((message, index) => (
                <Text key={index}>{message}</Text>
              ))}
            </VStack>
          }
        />
      )}
    </>
  );
}
