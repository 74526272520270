import { useState, useMemo } from "react";
import {
  Box,
  Flex,
  Select,
  Tag,
  Text,
  VStack,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import ModalBase from "components/Modal/ModalBase";
import {
  ALLOCATION_HUNTER_NFT_OPTIONS,
  ALLOCATION_GAUNTLET_BB_OPTIONS,
} from "constants/constants";
import { NFT_MANAGEMENT_TYPE } from "views/NFTsManagement/constants";
import ModalSuccessAndFail from "components/Modal/ModalSuccessAndFail";
import ButtonType from "components/Button";
import { reAllocateHunterNFT } from "api/hunterNFTs.api";
import { reAllocateGauntletNFT } from "api/gauntlet.api";
import { reAllocateBountyBallNFT } from "api/bountyBall.api";
import { reAllocateGenesisNFT } from "api/genesisNFTs.api";

export default function ReAllocateNFTModal({
  nftMngType,
  items,
  isOpen,
  onClose,
  onAllocationSuccess,
}) {
  const [selectedAllocation, setSelectedAllocation] = useState("");
  const [errorMessage, setErrorMessage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const {
    isOpen: isOpenFail,
    onOpen: onOpenFail,
    onClose: onCloseFail,
  } = useDisclosure();

  const getAllocationsByType = useMemo(() => {
    switch (nftMngType) {
      case NFT_MANAGEMENT_TYPE.HUNTER:
        return ALLOCATION_HUNTER_NFT_OPTIONS;
      case NFT_MANAGEMENT_TYPE.GAUNTLET:
      case NFT_MANAGEMENT_TYPE.BOUNTY_BALL:
        return ALLOCATION_GAUNTLET_BB_OPTIONS;
      default:
        return [];
    }
  }, [nftMngType]);

  const handleChangeAllocation = async () => {
    setIsLoading(true);
    try {
      const payload = {
        allocation: selectedAllocation,
        ids: items.map((item) => item.id),
      };
      // API call for allocation
      const callAllocationNFTFunction = {
        [NFT_MANAGEMENT_TYPE.HUNTER]: reAllocateHunterNFT,
        [NFT_MANAGEMENT_TYPE.GAUNTLET]: reAllocateGauntletNFT,
        [NFT_MANAGEMENT_TYPE.BOUNTY_BALL]: reAllocateBountyBallNFT,
        [NFT_MANAGEMENT_TYPE.GENESIS]: reAllocateGenesisNFT,
      }[nftMngType];

      const { data } = await callAllocationNFTFunction(payload);

      if (data?.success) {
        onAllocationSuccess();
        onClose();
        toast({
          title: "Re-allocate successful.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      } else {
        setErrorMessage(
          data.messages || ["An error occurred. Please try again later!"]
        );
        onOpenFail(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ModalBase
        maxWContent={{
          base: "50%",
          sm: "90%",
          md: "50%",
        }}
        isOpen={isOpen}
        onClose={onClose}
        showBtn={false}
        titleHeader="Re-allocate"
      >
        <VStack spacing={4} align="stretch">
          <Box>
            <Text fontWeight="bold" mb={2}>
              Selected NFTs
            </Text>
            <Flex wrap="wrap" gap={2}>
              {items?.map((item, index) => (
                <Tag key={index} bg="gray.200" color="gray.700">
                  {item?.nft_information?.name}
                </Tag>
              ))}
            </Flex>
          </Box>

          <Box>
            <Text fontWeight="bold" mb={2}>
              Allocate to
            </Text>
            <Select
              placeholder="Select an allocation"
              value={selectedAllocation}
              onChange={(e) => setSelectedAllocation(e.target.value)}
            >
              {getAllocationsByType.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </Box>

          <Flex justifyContent="center">
            <ButtonType
              w="28%"
              mt={4}
              type="button"
              onClick={handleChangeAllocation}
              isDisabled={!selectedAllocation}
              isLoading={isLoading}
              text="CHANGE"
            />
          </Flex>
        </VStack>
      </ModalBase>
      {isOpenFail && (
        <ModalSuccessAndFail
          isOpen={isOpenFail}
          type="error"
          onClose={onCloseFail}
          onSubmit={onCloseFail}
          description={
            <VStack align="start" spacing={2} alignItems="center">
              {errorMessage?.map((message, index) => (
                <Text key={index}>{message}</Text>
              ))}
            </VStack>
          }
        />
      )}
    </>
  );
}
