export const PRIZE_TYPE = [
  { value: "GAUNTLET", label: "Gauntlet" },
  { value: "TREASURE_BOX", label: "Treasure Box" },
  { value: "BOUNTY_BALL", label: "Bounty Ball" },
]

export const LIST_CHAIN = [
  { value: "137", label: "137" },
  { value: "80002", label: "80002" },
  { value: "13473", label: "13473" },
]

export const LIST_ALLOCATION = [
  { value: "FREE_PLAY", label: "Free Play" },
  { value: "SINGLE_PLAY", label: "Single Play" },
  { value: "BATTLE_ARENA", label: "Battle Arena" },
  { value: "CO_OP", label: "Co-op" },
]

export const LIST_PLAY_FEE = [
  { value: "50_B_COIN", label: "50 BCOIN" },
  { value: "100_B_CON", label: "100 BCOIN" },
]

export const RARITY = {
  COMMON: "Common",
  UNCOMMON: "Uncommon",
  EPIC: "Epic",
  RARE: "Rare",
  LEGENDARY: "Legendary",
  OFF_CHAIN: "Off Chain",
};

export const TRAIT = {
  ARENA_HUNTER: "Arena Hunter",
  MECHANIC: "Mechanic",
  MERCHANT: "Merchant",
  PRIZE_HUNTER: "Prize Hunter",
  TREASURE_HUNTER: "Treasure hunter",
};

export const OPTION_SALE = [
  { value: false, label: "None" },
  { value: true, label: "Sale" },
]
