import {
  Image,
  Stack,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import { RARITY, TOKEN_TYPE_LABEL } from "constants/constants";
import { useMemo } from "react";
import { getChainIcon } from "views/NFTsManagement/utils/utils";

const RowNftGacha = ({
  machine,
  checkedIds,
  setCheckedIds,
}) => {
  const textColor = useColorModeValue("#000000", "white");
  const isMachineChecked = useMemo(() => {
    return checkedIds.some((item) => item?.id === machine?.id);
  }, [machine?.id, checkedIds]);

  const handleCheckboxChange = () => {
    if (isMachineChecked) {
      setCheckedIds(checkedIds.filter((item) => item?.id !== machine?.id));
    } else {
      setCheckedIds([...checkedIds, {
        ...machine,
        related_type: machine?.token_type
      }]);
    }
  };

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isMachineChecked}
          onChange={() => !machine?.weight ? null : handleCheckboxChange()}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.id}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.name}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {TOKEN_TYPE_LABEL[machine?.token_type]}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            <Image src={machine?.img_url} width={100}/>
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Image
            boxSize="30px"
            src={getChainIcon(machine?.chain_id).src}
            alt={getChainIcon(machine?.chain_id).alt}
          />
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.rarity}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.count}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.weight}
          </Text>
        </Stack>
      </Td>
    </Tr>
  );
};

export default RowNftGacha;
