import React, { Suspense, useEffect } from "react";
import ReactDOM from "react-dom";
import { Route, Switch, Redirect, HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ChakraProvider } from "@chakra-ui/react";
import store from "stores/index";
import { UserProvider } from "context/userProvider";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import i18next from "./I18n";
import { I18nextProvider } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import "./assets/css/index.css";
import { IntlProvider } from "react-intl";
import { isTokenExpired } from "utils/utils";
import { clearLocalStorage } from "utils/storage";
import { StorageKeys } from "constants/enum";

const App = () => {
  const TOKEN = localStorage.getItem("access_token");
  useEffect(() => {
    const isExpired = TOKEN && isTokenExpired(TOKEN)
    if (isExpired) {
      clearLocalStorage(StorageKeys.TOKEN)
      return (window.location.href = '/#/auth/authentication/sign-in/cover')
    }
  }, [])

  return (
    <>
      <Suspense fallback={<LoadingSpinner />}>
        <HashRouter>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <Route path={`/admin`} component={AdminLayout} />
            <Redirect from={`/`} to="/admin/dashboard" />
          </Switch>
        </HashRouter>
      </Suspense>
    </>
  );
};

ReactDOM.render(
  <>
    <Provider store={store}>
      {/* <LoadingSpinnerProvider> */}
      <I18nextProvider i18n={i18next}>
        <UserProvider>
          <IntlProvider locale="en">
            <ChakraProvider>
              <App />
            </ChakraProvider>
          </IntlProvider>
        </UserProvider>
      </I18nextProvider>
      {/* </LoadingSpinnerProvider> */}
    </Provider>
  </>,
  document.getElementById("root")
);
