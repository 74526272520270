import http from "./http";
import END_POINT from "./constants";

export const sellMultipleProductNFTs = (data) => {
  return http.post(END_POINT.PRODUCT_SELL_MULTIPLE, data);
};

export const sellSingleProductNFTs = (data) => {
  return http.post(END_POINT.PRODUCT_SELL_SINGLE, data);
};

export const cancelSellProductNFTs = (id) => {
  return http.post(`${END_POINT.PRODUCT_CANCEL_SELL}/${id}`);
};

export const transferProductNFTs = (data) => {
  return http.post(END_POINT.PRODUCT_TRANSFER, data);
};
