import { Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import { formatTimestampToMonthYearDash } from "utils/time";
import NumOfNFTsTd from "./NumOfNFTsTd";

export default function ActiveNFTsRow({ item }) {
  const textColor = useColorModeValue("#000000", "white");
  return (
    <Tr>
      <NumOfNFTsTd nfts={item?.nft_quantity} />
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {formatTimestampToMonthYearDash(item?.contract_start)}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {formatTimestampToMonthYearDash(item?.contract_expire)}
        </Text>
      </Td>
    </Tr>
  );
}
