import React from "react";
import {
  Flex,
  Text,
  Image,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import { getChainIcon } from "views/NFTsManagement/utils/utils";
import { getStatusLabel } from "views/NFTsManagement/utils/utils";
import { CHAIN_NATIVE_TOKEN } from "constants/constants";

const SummaryItem = ({ label, value, icon }) => {
  const itemBgColor = useColorModeValue("gray.50", "gray.600");
  const shadowColor = useColorModeValue(
    "rgba(0, 0, 0, 0.1)",
    "rgba(255, 255, 255, 0.1)"
  );

  return (
    <Flex
      p={3}
      alignItems="center"
      justifyContent="space-between"
      bg={itemBgColor}
      borderRadius="md"
      boxShadow={`0 0 10px ${shadowColor}`}
      transition="all 0.3s"
      _hover={{
        transform: "translateY(-2px)",
        boxShadow: `0 0 15px ${shadowColor}`,
      }}
    >
      <Text fontSize="sm" fontWeight="bold">
        {label}
      </Text>
      {icon ? (
        <Image src={icon} boxSize="24px" alt={`${label} icon`} />
      ) : (
        <Text fontSize="sm" fontWeight="bold" color={"red.500"}>
          {value}
        </Text>
      )}
    </Flex>
  );
};

const GenesisPackageSummaryPanel = ({ summaryData }) => {
  const textColor = useColorModeValue("#000000", "white");
  const bgColor = useColorModeValue("white", "gray.700");

  return (
    <Card px={4} py={5} bg={bgColor} boxShadow="md" borderRadius="xl">
      <Text color={textColor} fontWeight="bold" mb={4}>
        Summary
      </Text>
      <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 5 }} spacing={4}>
        <SummaryItem
          label="Chain"
          icon={getChainIcon(summaryData?.chain_id)?.src}
          alt={getChainIcon(summaryData?.chain_id)?.alt}
        />
        <SummaryItem
          label="Current Size"
          value={summaryData?.current_quantity || 0}
        />
        <SummaryItem label="Sold" value={summaryData?.sold_quantity || 0} />
        <SummaryItem
          label="Price"
          value={`${summaryData?.price || 0} ${
            CHAIN_NATIVE_TOKEN[summaryData?.chain_id]
          } `}
        />
        <SummaryItem
          label="Status"
          value={getStatusLabel(summaryData?.status) || ""}
        />
      </SimpleGrid>
    </Card>
  );
};

export default GenesisPackageSummaryPanel;
