import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import ButtonType from "components/Button";
import FormUpload from "components/form/FormUpload";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ItemCSV from "views/Hunter/import/itemCSV";

export default function ModalImportFile({
  isOpen,
  onClose,
  title,
  handleImport,
  isLoading = false,
}) {
  const [fileSelect, setFileSelect] = useState(null);
  const form = useForm();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const debounced = useCallback(debounce((formData) => handleImport(formData), 500), [])

  const onSubmit = async () => {
    const formData = new FormData();
    formData.append("file", fileSelect);
    if (formData) {
      debounced(formData);
    }
  };

  const handleOnChangeCSV = (files) => {
    if (files?.length > 0) {
      setFileSelect(files[0]);
    }
  };

  const removeCSV = () => {
    setFileSelect(null);
  };

  const renderForm = () => {
    return (
      <FormProvider {...form} w="100%">
        <form
          id="form-import-white-list"
          onSubmit={handleSubmit(onSubmit)}
          style={{
            width: "100%",
            overflowX: "auto",
          }}
        >
          <FormUpload
            name="fileCSV"
            handleOnChange={handleOnChangeCSV}
            accept=".csv"
            multiple={false}
          />
          <Flex
            direction="column"
            gap={2}
            w="100%"
            mt={4}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            {fileSelect && (
              <ItemCSV name={fileSelect?.name} removeCSV={removeCSV} />
            )}
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent maxW="700px">
          <ModalHeader
            borderBottom="1px solid #EEEFF2"
            w="100%"
            textAlign="center"
            margin="auto"
            color="#000000"
          >
            {title || `IMPORT CSV`}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p="24px">{renderForm()}</ModalBody>
          <ModalFooter
            borderTop="1px solid #EEEFF2"
            w="100%"
            textAlign="center"
            margin="auto"
          >
            <ButtonType
              mt={4}
              type="submit"
              m="auto"
              w="120px"
              h="46px"
              borderRadius="5px"
              form="form-import-white-list"
              disabled={!fileSelect}
              isLoading={isSubmitting || isLoading}
              text="Import"
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
