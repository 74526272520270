import * as yup from "yup";

const schemaSendItems = () =>
  yup.object().shape({
    tickets: yup
      .number()
      .min(0)
      .nullable(true)
      .max(9999, "Number of Tickets must be at most 4 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .integer("Must be an integer")
      .positive("Number of Tickets must be greater than or equal to 0"),
    bcoins: yup
      .number()
      .min(0)
      .nullable(true)
      .max(9999, "Number of B-coins must be at most 4 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .integer("Must be an integer")
      .positive("Number of B-coins must be greater than or equal to 0"),
    spheres: yup
      .number()
      .min(0)
      .nullable(true)
      .max(9999, "Number of Spheres must be at most 4 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .integer("Must be an integer")
      .positive("The number of spheres must be greater than or equal to 0"),
  });

export default schemaSendItems;
