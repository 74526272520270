import {
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import ButtonType from "components/Button";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ListBatchSellHunter from "./listBatchSell";
import { deleteGacha, getListGacha } from "api/gacha.api";
import { LIST_ERROR_GACHA } from "views/GachaManagement/constants";

export default function ModalDelete({
  isOpen,
  onClose,
  handleCreateOrUpdateSuccess,
}) {
  const [nftsChecked, setNFTsChecked] = useState([]);
  const toast = useToast();
  const form = useForm();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const [isLoading, setIsLoading] = useState(true);

  const [listGacha, setListGacha] = useState([]);

  const getListGachaDelete = useCallback(async () => {
    try {
      const params = {
        page: 1,
        limit: 9999,
      }
      const { data } = await getListGacha(params);
      const list = data?.data?.records;
      setListGacha(list)
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      getListGachaDelete();
    } else {
      setListGacha([]);
    }
  }, [getListGachaDelete, isOpen]);

  const handleClose = () => {
    setNFTsChecked([]);
    onClose();
  };

  const onSubmit = async (data) => {
    try {
      const dataRemove = nftsChecked?.map((gacha) => gacha?.id)
      const res = await deleteGacha(dataRemove);
      if (res?.data?.success) {
        toast({
          title: "Delete Gacha Successfully!",
          description: "Delete Gacha Successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        handleCreateOrUpdateSuccess();
        onClose()
      } else {
        toast({
          description: LIST_ERROR_GACHA[res?.data?.message] || LIST_ERROR_GACHA[res?.data?.messages[0]] || res?.data?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form} w="100%">
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="list-sell-batch-nfts-hunter-form-id"
          style={{
            width: "100%",
            overflowX: "auto",
          }}
        >
          <ListBatchSellHunter
            data={listGacha}
            nftsChecked={nftsChecked}
            setNFTsChecked={setNFTsChecked}
          />
        </form>
      </FormProvider>
    );
  };

  return (
    <>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={() => {
          handleClose();
        }}
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent maxW="685px" p="28px 24px">
          <ModalHeader
            w="100%"
            textAlign="center"
            margin="auto"
            p="16px 0"
          >
            <Text fontSize="24px" fontWeight="700">
              DELETE GACHA
            </Text>
            <Flex justifyContent="flex-end">
              <ButtonType
                text="Select All"
                btnType="primary-new-outline"
                fontSize="16px"
                w="140px"
                onClick={() => setNFTsChecked(listGacha)}
              />
            </Flex>
            <Text fontSize="16px" fontWeight="500" mt="16px" textAlign="start">
              Select gacha(s) ({nftsChecked?.length})
            </Text>
          </ModalHeader>
          <ModalCloseButton top="30px" />
          <ModalBody p="0px">
            {isLoading ? (
              <Center minH="200px" m="auto">
                <LoadingSpinner />
              </Center>
            ) : (
              renderForm()
            )}
          </ModalBody>
          <ModalFooter w="100%" textAlign="center" margin="auto">
            <Flex
              w="100%"
              direction="column"
              alignItems="center"
              gap={4}
              pb="4px"
            >
              <Flex gap="16px" w="100%">
                <ButtonType
                  mt={4}
                  m="auto"
                  w="100%"
                  h="46px"
                  borderRadius="8px"
                  type="submit"
                  form="list-sell-batch-nfts-hunter-form-id"
                  fontSize="16px"
                  fontWeight="500"
                  disabled={nftsChecked?.length <= 0 || isSubmitting}
                  isLoading={isSubmitting}
                >
                  DELETE
                </ButtonType>
              </Flex>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
