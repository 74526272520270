import React, { useCallback, useEffect, useState } from 'react'
import { Box, CloseButton, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Paginate from 'components/Paginate';
import ActionUserDetail from './action';
import BasicInfo from './basicInfo';
import ItemUser from './itemUser';
import PlayHistory from './playHistory';
import PrizeHistory from './prizeHistory';
import { getUserById } from 'api/userManagement';
import NftsUser from './NftsUser';

const TAB_HISTORY = ["Play History", "Prize History",];

export default function DetailUser({ toggle, userDetail }) {
  const textColor = useColorModeValue("#000000", "white");
  const tabBg = useColorModeValue("gray.50", "gray.800");
  const activeTabBg = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const activeTabColor = useColorModeValue("blue.600", "blue.300");

  const [detailUser, setDetailUser] = useState();
  const toast = useToast();

  const getDetailUser = useCallback(async () => {
    try {
      if (userDetail?.id) {
        const res = await getUserById(userDetail?.id);
        if (res?.data?.success) {
          const user = res?.data?.data;
          setDetailUser(user);
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } 
  }, [userDetail?.id]);

  useEffect(() => {
    getDetailUser();
  }, [getDetailUser]);


  return (
    <Card px="0px">
      <CardHeader px="24px" mb="12px" display="flex" flexDirection="column" gap="20px">
        <Flex
          w="100%"
          gap={4}
          direction={{
            base: "column",
            md: "row",
          }}
          justifyContent={{
            base: "flex-start",
          }}
          alignItems="center"
        >
          <CloseButton size='lg' onClick={() => toggle("showDetail", null)}/>
          <Text
            color={textColor}
            fontSize="lg"
            fontWeight="bold"
            whiteSpace="nowrap"
          >
            {detailUser?.name}
          </Text>
        </Flex>
        <Flex
          w="100%"
          justifyContent={{
            base: "flex-start",
          }}
        >
          <ActionUserDetail
            toggle={toggle}
            userDetail={detailUser}
            getDetailUser={getDetailUser}
          />
        </Flex>
      </CardHeader>
      <BasicInfo userDetail={detailUser}/>
      <NftsUser userDetail={userDetail}/>
      <ItemUser userDetail={detailUser}/>
      <Tabs
        isLazy
        variant="enclosed"
        px="24px"
      >
        <TabList borderBottom={`1px solid ${borderColor}`}>
          {TAB_HISTORY.map((name) => (
            <Tab
              key={name}
              fontWeight="semibold"
              _selected={{
                color: activeTabColor,
                bg: activeTabBg,
                borderBottom: "3px solid",
                borderBottomColor: activeTabColor,
                marginBottom: "-1px",
              }}
              _hover={{
                color: activeTabColor,
              }}
              bg={tabBg}
              borderTopRadius="md"
              mr={1}
              py={3}
              px={5}
              w="100%"
            >
              {name}
            </Tab>
          ))}
        </TabList>
        <Box mt={4}>
          <TabPanels>
            <TabPanel padding="24px 0">
              <PlayHistory userDetail={userDetail}/>
            </TabPanel>
            <TabPanel padding="24px 0">
              <PrizeHistory userDetail={userDetail}/>
            </TabPanel>
          </TabPanels>
        </Box>
      </Tabs>
    </Card>
  )
}
