import { Box, Flex, Icon, Text, Image, Spinner } from "@chakra-ui/react";
import { Icon2Claws } from "components/Icons/Icon2Claws";
import { Icon3Claws } from "components/Icons/Icon3Claws";
import { NAIcon } from "components/Icons/NAIcon";
import { STATUS } from "constants/constants";
import { useCallback, useMemo } from "react";
import multipleUser from 'assets/img/multiple-user.png'
import btnReset from 'assets/svg/btn_reset.svg'
import {CSS} from '@dnd-kit/utilities';
import { useDraggable, useDroppable } from '@dnd-kit/core';

export default function MachineMapItem({
  item,
  machine,
  handleOpenDetail,
  toggle,
  id,
  idMachine,
  isLoadingRestart,
}) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: item,
  });

  const { isOver, setNodeRef: setNodeRefDrop } = useDroppable({
    id: idMachine ? idMachine : id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    cursor: 'move',
  };

  const styleOver = {
    opacity: isOver ? 1 : 0.5,
  };

  const IconStatus = useCallback(() => {
    let bg = "#90FA87";
    let bgBorder = "#fff";
    if (item?.status === STATUS.DISCONNECTED) {
      bg = "#FF003D";
      bgBorder = "#fff";
    }
    return (
      <Box
        bg={bg}
        w="22px"
        h="22px"
        borderRadius="100%"
        border={`3px solid ${bgBorder}`}
      />
    );
  }, [item?.status]);

  const isStatusActive = useMemo(() => {
    return (item?.status === STATUS.CONNECTED && (machine?.state === STATUS.FREE || machine?.state === STATUS.UNDER_MAINTENANCE || machine?.state === STATUS.PLAYING || machine?.state === STATUS.ERROR));
  }, [item?.status, machine?.state]);

  const renderBgItem = useMemo(() => {
    switch (machine?.state) {
      case STATUS.FREE:
        return {
          bg: "linear-gradient(137deg, #E7E0E0 0%, #C6C2C2 100%)",
          color: "#000",
        };
      case STATUS.PLAYING:
        return {
          bg: "linear-gradient(137deg, #EEFF9B 0%, #B4E81F 100%)",
          color: "#000",
        };
      case STATUS.PRIZE_ACQUIRED:
        return {
          bg: "linear-gradient(137deg, #9FFC99 0%, #32A817 100%)",
          color: "#000",
        };
      case STATUS.PRIZE_CONFIRMATION_REQUEST:
        return {
          bg: "linear-gradient(137deg, #D8B2FF 0%, #9955FF 100%)",
          color: "#000",
        };
      case STATUS.UNDER_MAINTENANCE:
        return {
          bg: "linear-gradient(137deg, #FFDAAE 0%, #FC9821 100%)",
          color: "#000",
        };
      case STATUS.ERROR:
        return {
          bg: "linear-gradient(137deg, #FF9597 0%, #FF4144 100%)",
          color: "#000",
        };
      default:
        return {
          bg: "linear-gradient(137deg, #EDE6E6 1.63%, #C0BDBD 98.37%)",
          color: "#2D377B",
        };
    }
  }, [item?.status, machine?.state]);

  const is3Claws = useMemo(() => {
    return machine?.machine_type === STATUS.THREE_CLAWS;
  }, [machine?.machine_type]);

  const IconClaw = useMemo(() => {
    if (machine) {
      return is3Claws ? Icon3Claws : Icon2Claws;
    }
    return NAIcon;
  }, [machine]);

  const handleOpen = (e) => {
    e.preventDefault()
    e.stopPropagation();
    if (isStatusActive) {
      handleOpenDetail()
    } else if (machine?.state === STATUS.PRIZE_ACQUIRED) {
      toggle("confirmNext", item)
    } else if (machine?.state === STATUS.PRIZE_CONFIRMATION_REQUEST) {
      toggle("confirmPrize", item)
    } 
  }

  return (
    item ? (
      <Box
        w="auto"
        h="100%"
        borderRadius="10px"
        boxShadow="0px 4px 4px 0px #00000040"
        p="16px"
        bg={renderBgItem.bg}
        // pointerEvents={isStatusActive ? "auto" : "none"}
        cursor={isStatusActive ? "pointer" : ""}
        onClick={(e) => handleOpen(e)}
        ref={setNodeRef}
        style={style}
        {...listeners} 
        {...attributes}
      >
        <Box
          ref={setNodeRefDrop}
        >
          <Flex direction="column" gap={2}>
            <Flex alignItems="center" justifyContent="space-between">
              <Flex alignItems="center" gap={2}>
                <IconStatus />
                <Text
                  fontSize={{
                    base: "18px",
                  }}
                  fontWeight="700"
                >
                  {item?.mac?.slice(-4)} - {item?.machine?.group_label}{item?.machine?.group_number}
                </Text>
              </Flex>
            </Flex>
            <Flex alignItems="center" justifyContent="center" gap={10}>
              <Icon fontSize="55px" as={IconClaw} />
              <Flex flexDirection="column" alignItems="center">
                <Image
                  src={multipleUser}
                  w="28px"
                  h="28px"
                  alt={machine?.id}
                />
                <Text fontSize="16px" fontWeight="700">{item?.waiting_players ? item?.waiting_players : 'N/A'}</Text>
            </Flex>
            </Flex>
            <Flex alignItems="center" justifyContent="space-between" gap={4}>
              <Text fontSize="16px" fontWeight="500">Machine plays</Text>
              <Text fontSize="16px" fontWeight="700">{machine?.machine_plays}</Text>
            </Flex>
            <Flex alignItems="center" justifyContent="space-between" gap={4}>
              <Text fontSize="16px" fontWeight="500">Player streak</Text>
              <Text fontSize="16px" fontWeight="700">{machine?.player_streak}</Text>
            </Flex>
            {item?.device_id && (
              <Box>
                <Flex alignItems="center" justifyContent="center" gap="6px">
                  {/* <Text fontSize="14px" fontWeight="600">Activity</Text>
                  <SwitchType
                    color="#217bc4"
                    colorThumb="#fff"
                    size="sm"
                    colorScheme="teal"
                    position="relative"
                    isChecked={listStatusIot?.[item?.device_id] === "ON" ? true : false}
                    onChange={(e) => {
                      handleRestartMachine(item?.device_id, e.target?.checked)
                    }}
                  /> */}
                  {isLoadingRestart ? (
                    <Spinner />
                  ) : (
                    <Box
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        toggle("confirmRestart", item)
                      }}
                      cursor="pointer"
                    >
                      <Image
                        src={btnReset}
                        w="30px"
                        h="30px"
                        alt="restart"
                      />
                    </Box>
                  )}
                </Flex>
              </Box>
            )}
          </Flex>
        </Box>
      </Box>
    ) : (
      <Box
        w="auto"
        h="auto"
        minWidth="160px"
        minHeight="220px"
        borderRadius="10px"
        boxShadow="0px 4px 4px 0px #00000040"
        border="1px dashed #ccc"
        p="16px"
        ref={setNodeRefDrop}
        style={styleOver}
      >
        <Text
          fontSize={{
            base: "18px",
          }}
          fontWeight="700"
          textAlign="center"
        >
          {id}
        </Text>
      </Box>
    )
  );
}
