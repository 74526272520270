import React from 'react'
import { DIRECTION_RADIO } from "../constants";
import { FormProvider, useForm } from 'react-hook-form';
import { Flex, Grid, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import ButtonType from 'components/Button';
import FormRadio from 'components/form/FormRadio';

export default function ModalChangeDirection({ isOpen, onClose, handleSwitchListDirection }) {
    const form = useForm();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const onSubmit = async (data, e) => {
    e.preventDefault();
    handleSwitchListDirection(data?.status)
    onClose()
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <Text fontSize="16px" fontWeight="500">
              Set the direction for the selected machines
            </Text>
            <Flex alignItems="center" gap="70px">
              <FormRadio 
                name="role" 
                options={DIRECTION_RADIO} 
                defaultValue={DIRECTION_RADIO[0].value}
                isRequired
              />
            </Flex>
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
        >
          Direction
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            SET
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
