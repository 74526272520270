import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { SmallCloseIcon } from "@chakra-ui/icons";
import { useState } from "react";
import ListNft from "./listNft";
import FormSelect from "components/form/FormSelect";
import { LIST_CHAIN } from "../constant";
import { NFT_MANAGEMENT_TYPE } from "views/NFTsManagement/constants";
import { transferProductNFTs } from "api/products.api";

const TAB_NFTS = ["Hunter", "Gauntlet", "Bounty Ball"];

export default function ModalTransferNft({
  isOpen,
  onClose,
  filterChecked,
  checkedIds,
  setCheckedIds,
  userDetail,
  handleCreateOrUpdateSuccess,
}) {
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");
  const tabBg = useColorModeValue("gray.50", "gray.800");
  const activeTabBg = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const activeTabColor = useColorModeValue("blue.600", "blue.300");
  const [isLoading, setIsLoading] = useState(false)
  const form = useForm();
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  
  const {
    isOpen: isOpenConfirmTransfer,
    onOpen: onOpenConfirmTransfer,
    onClose: onCloseConfirmTransfer,
  } = useDisclosure();

  const {
    handleSubmit,
    setValue,
  } = form;

  const onSubmit = (data) => {
    if (!isLoading) {
      const newParams = {
        ...params,
        ...data,
        page: 1,
      };
      setParams(newParams);
    }
  };

  const handleSendNfts = async (listNfts) => {
    try {
      const listIds = checkedIds?.map((check) => check?.id)
      const params = {
        user_id: userDetail ? userDetail?.id : listIds[0],
        product_ids: listNfts?.map((item) => item?.product_model?.id),
      };
      const { data } = await transferProductNFTs(params);
      // Successful transfer
      if (data?.success) {
        toast({
          title: "Transfer successful.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        handleCreateOrUpdateSuccess()
        setCheckedIds([])
        onCloseConfirmTransfer()
        onClose()
      } else {
        toast({
          description: data?.message || data?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  }

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Text fontSize={16} fontWeight={600}>Receiver(s)</Text>
          {checkedIds?.length > 0 && (
            <Flex gap="4px" flexWrap="wrap" mt={4} pb={4} borderBottom="1px solid #eee">
              {checkedIds?.map((check, index) => (
                <Flex
                  key={index}
                  padding="4px 8px"
                  borderRadius="6px"
                  background="#c0c0c0"
                  alignItems="center"
                  gap="4px"
                >
                  <Text fontSize="14px" fontWeight="400">{check?.name}</Text>
                  <SmallCloseIcon onClick={() => filterChecked(check)}/>
                </Flex>
              ))}
            </Flex>
          )}
          {userDetail && (
            <Flex
              padding="4px 8px"
              borderRadius="6px"
              background="#c0c0c0"
              alignItems="center"
              gap="4px"
              width="max-content"
              mt={4}
            >
              <Text fontSize="14px" fontWeight="400">{userDetail?.name}</Text>
            </Flex>
          )}
          <Text fontSize={16} fontWeight={600} my={4}>Select NFTs</Text>
          <Flex w="100%" flexWrap="wrap" alignItems="flex-end" gap={4}>
            <FormSelect
              name="chain"
              label="Chain"
              defaultValue={""}
              w="240px"
              options={[{ label: "All", value: "" }, ...LIST_CHAIN]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("chain", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent 
        maxW={{
          base: "90%",
          sm: "1000",
          lg: "1200",
        }} 
        overflowY="auto"
      >
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          Transfer NFTs
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <Tabs
          isLazy
          variant="enclosed"
          px="24px"
          onChange={(e) => {
            setParams({
              page: 1,
              limit: 10,
            })
            setValue("chain", "");
          }}
        >
          <TabList borderBottom={`1px solid ${borderColor}`}>
            {TAB_NFTS.map((name) => (
              <Tab
                key={name}
                fontWeight="semibold"
                _selected={{
                  color: activeTabColor,
                  bg: activeTabBg,
                  borderBottom: "3px solid",
                  borderBottomColor: activeTabColor,
                  marginBottom: "-1px",
                }}
                _hover={{
                  color: activeTabColor,
                }}
                bg={tabBg}
                borderTopRadius="md"
                mr={1}
                py={3}
                px={5}
                w="100%"
              >
                {name}
              </Tab>
            ))}
          </TabList>
          <Box mt={4}>
            <TabPanels>
              <TabPanel padding="24px 0">
                <ListNft
                params={params}
                setParams={setParams}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                userDetail={checkedIds?.length > 0 ? checkedIds : [userDetail]}
                handleSendNfts={handleSendNfts}
                nftMngType={NFT_MANAGEMENT_TYPE.HUNTER}
                onOpenConfirmTransfer={onOpenConfirmTransfer}
                onCloseConfirmTransfer={onCloseConfirmTransfer}
                isOpenConfirmTransfer={isOpenConfirmTransfer}
              />
              </TabPanel>
              <TabPanel padding="24px 0">
                <ListNft
                params={params}
                setParams={setParams}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                userDetail={checkedIds?.length > 0 ? checkedIds : [userDetail]}
                handleSendNfts={handleSendNfts}
                nftMngType={NFT_MANAGEMENT_TYPE.GAUNTLET}
                onOpenConfirmTransfer={onOpenConfirmTransfer}
                onCloseConfirmTransfer={onCloseConfirmTransfer}
                isOpenConfirmTransfer={isOpenConfirmTransfer}
              />
              </TabPanel>
              <TabPanel padding="24px 0">
                <ListNft
                params={params}
                setParams={setParams}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                userDetail={checkedIds?.length > 0 ? checkedIds : [userDetail]}
                handleSendNfts={handleSendNfts}
                nftMngType={NFT_MANAGEMENT_TYPE.BOUNTY_BALL}
                onOpenConfirmTransfer={onOpenConfirmTransfer}
                onCloseConfirmTransfer={onCloseConfirmTransfer}
                isOpenConfirmTransfer={isOpenConfirmTransfer}
              />
             </TabPanel>
            </TabPanels>
          </Box>
        </Tabs>
      </ModalContent>
    </Modal>
  );
}
