export const UPDATE_CONFIG = 'UPDATE_CONFIG'

// login
export const LOGIN_FETCH = 'LOGIN_FETCH'
export const CHANGE_PASSWORD_FETCH = 'CHANGE_PASSWORD_FETCH'

// user
export const USER_NAMESPACE = 'USER'
export const USERINFO_FETCH = 'USERINFO_FETCH'
export const LIST_USERS_FETCH = 'LIST_USERS_FETCH'
export const ADD_USERS_FETCH = 'ADD_USERS_FETCH'
export const GET_USER_BY_ID_FETCH = 'GET_USER_BY_ID_FETCH'
export const EDIT_USER_BY_ID_FETCH = 'EDIT_USER_BY_ID_FETCH'
export const DELETE_USER_BY_ID_FETCH = 'DELETE_USER_BY_ID_FETCH'

// prize
export const PRIZE_NAMESPACE = 'PRIZE'
export const LIST_PRIZES_FETCH = 'LIST_PRIZES_FETCH'
export const LIST_PRIZES_GAME_MODE_FETCH = 'LIST_PRIZES_GAME_MODE_FETCH'
export const ADD_PRIZES_FETCH = 'ADD_PRIZES_FETCH'
export const GET_PRIZE_BY_ID_FETCH = 'GET_PRIZE_BY_ID_FETCH'
export const EDIT_PRIZE_BY_ID_FETCH = 'EDIT_PRIZE_BY_ID_FETCH'
export const DELETE_PRIZE_BY_ID_FETCH = 'DELETE_PRIZE_BY_ID_FETCH'
export const DELETE_PRIZE_ALL_FETCH = 'DELETE_PRIZE_ALL_FETCH'
export const LIST_PRIZE_TYPE_FETCH = 'LIST_PRIZE_TYPE_FETCH'
export const LIST_PRIZE_MODE_TYPE_FETCH = 'LIST_PRIZE_MODE_TYPE_FETCH'
export const LIST_PRIZE_RARITY = 'LIST_PRIZE_RARITY'
export const LIST_PRIZE_TRAITS = 'LIST_PRIZE_TRAITS'
export const LIST_PLAY_FEES = 'LIST_PLAY_FEES'

// GamePlay Type
export const GAME_PLAY_TYPE_NAMESPACE = 'GAME_PLAY_TYPE_NAMESPACE'
export const GET_GAME_PLAY_TYPE_FETCH = 'GET_GAME_PLAY_TYPE_FETCH'

// Play Fee
export const PLAY_FEE_NAMESPACE = 'PLAY_FEE_NAMESPACE'
export const GET_PLAY_FEE_FETCH = 'GET_PLAY_FEE_FETCH'
export const ADD_PLAY_FEE_FETCH = 'ADD_PLAY_FEE_FETCH'
export const GET_PLAY_FEE_BY_ID_FETCH = 'GET_PLAY_FEE_BY_ID_FETCH'
export const EDIT_PLAY_FEE_BY_ID_FETCH = 'EDIT_PLAY_FEE_BY_ID_FETCH'
export const DELETE_PLAY_FEE_BY_ID_FETCH = 'DELETE_PLAY_FEE_BY_ID_FETCH'

// Project Category
export const PROJECT_CATEGORY_NAMESPACE = 'PROJECT_CATEGORY_NAMESPACE'
export const GET_PROJECT_CATEGORY_FETCH = 'GET_PROJECT_CATEGORY_FETCH'
export const ADD_PROJECT_CATEGORY_FETCH = 'ADD_PROJECT_CATEGORY_FETCH'
export const GET_PROJECT_CATEGORY_BY_ID_FETCH = 'GET_PROJECT_CATEGORY_BY_ID_FETCH'
export const EDIT_PROJECT_CATEGORY_BY_ID_FETCH = 'EDIT_PROJECT_CATEGORY_BY_ID_FETCH'
export const DELETE_PROJECT_CATEGORY_BY_ID_FETCH = 'DELETE_PROJECT_CATEGORY_BY_ID_FETCH'

// Revenue Sharing
export const REVENUE_SHARING_NAMESPACE = 'REVENUE_SHARING_NAMESPACE'
export const GET_REVENUE_SHARING_FETCH = 'GET_REVENUE_SHARING_FETCH'
export const ADD_REVENUE_SHARING_FETCH = 'ADD_REVENUE_SHARING_FETCH'
export const GET_REVENUE_SHARING_BY_ID_FETCH = 'GET_REVENUE_SHARING_BY_ID_FETCH'
export const EDIT_REVENUE_SHARING_BY_ID_FETCH = 'EDIT_REVENUE_SHARING_BY_ID_FETCH'
export const DELETE_REVENUE_SHARING_BY_ID_FETCH = 'DELETE_REVENUE_SHARING_BY_ID_FETCH'

// Cabinet Type
export const CABINET_TYPE_NAMESPACE = 'CABINET_TYPE_NAMESPACE'
export const GET_CABINET_TYPE_FETCH = 'GET_CABINET_TYPE_FETCH'

// maintenance
export const MAINTENANCE_NAMESPACE = 'MAINTENANCE_NAMESPACE'
export const GET_MAINTENANCE_FETCH = 'GET_MAINTENANCE_FETCH'
export const ADD_MAINTENANCE_FETCH = 'ADD_MAINTENANCE_FETCH'
export const GET_MAINTENANCE_BY_ID_FETCH = 'GET_MAINTENANCE_BY_ID_FETCH'
export const EDIT_MAINTENANCE_BY_ID_FETCH = 'EDIT_MAINTENANCE_BY_ID_FETCH'
export const DELETE_MAINTENANCE_BY_ID_FETCH = 'DELETE_MAINTENANCE_BY_ID_FETCH'


// notice
export const NOTICE_NAMESPACE = 'NOTICE'
export const LIST_NOTICE_FETCH = 'LIST_NOTICE_FETCH'
export const ADD_NOTICES_FETCH = 'ADD_NOTICES_FETCH'
export const GET_NOTICE_BY_ID_FETCH = 'GET_NOTICE_BY_ID_FETCH'
export const EDIT_NOTICE_BY_ID_FETCH = 'EDIT_NOTICE_BY_ID_FETCH'
export const DELETE_NOTICE_ALL_FETCH = 'DELETE_NOTICE_ALL_FETCH'
