import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getBountyBallNFTs = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.NFT_BOUNTY_BALL}?${params}`);
};

export const getBountyBallNFTsUser = (id, p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.NFT_BOUNTY_BALL}?${params}&user-id=${id}&admin-flag=false`);
};

export const getBountyBallSummary = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.NFT_BOUNTY_BALL_SUMMARY}?${params}`);
};

export const getDetailBountyBallNFT = (id) => {
  return http.get(`${END_POINT.NFT_BOUNTY_BALL}/${id}`);
};

export const reAllocateBountyBallNFT = (data) => {
  return http.put(`${END_POINT.NFT_BOUNTY_BALL_RE_ALLOCATE}`, data);
};

export const importCSVBountyBallNFT = (id, data) => {
  return http.post(`${END_POINT.NFT_BOUNTY_BALL}/${id}/import-csv`, data, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
};
