import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
// import { configReducer } from './updateConfig/configReducer'
import { userSliceReducer } from './user/userReducer'
import { prizeSliceReducer } from './prize/prizeReducer'
import { gamePlaySliceReducer } from './gamePlay/gamePlayTypeReducer'
import { playFeeSliceReducer } from './playFee/playFeeReducer'
import { projectCategorySliceReducer } from './projectCategory/projectCategoryReducer'
import { cabinetTypeSliceReducer } from './cabinetType/cabinetTypeReducer'
import { revenueSharingSliceReducer } from './revenueSharing/revenueSharingReducer'
import { maintenanceSliceReducer } from './mantenance/maintenaceReducer'
import { nftsSliceReducer } from './nfts/nftsReducer'

const reducers = combineReducers({
  // globalConfig: configReducer,
  userReducer: userSliceReducer,
  prizeReducer: prizeSliceReducer,
  gamePlayReducer: gamePlaySliceReducer,
  playFeeReducer: playFeeSliceReducer,
  projectCategoryReducer: projectCategorySliceReducer,
  cabinetTypeReducer: cabinetTypeSliceReducer,
  revenueSharingReducer: revenueSharingSliceReducer,
  maintenanceReducer: maintenanceSliceReducer,
  nftsReducer: nftsSliceReducer,
})

const store = configureStore({
  reducer: reducers,
})

export default store
