import {
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import ButtonType from "components/Button";
import FormSelect from "components/form/FormSelect";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import FormInput from "components/form/FormInput";
import { ERROR_NON_NFT, TYPE_COIN } from "../constants";
import { yupResolver } from "@hookform/resolvers/yup";
import schemaNonNftGacha from "./schema";
import { createNonNftGacha, updateNonNftGacha } from "api/gacha.api";

export default function CreateAndUpdateGachaNonNft({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
}) {
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");

  const form = useForm({
    resolver: yupResolver(schemaNonNftGacha()),
    defaultValues: {
      name: dataDetail ? dataDetail?.name : null,
      type: dataDetail ? dataDetail?.type : TYPE_COIN[0].value,
      value: dataDetail ? dataDetail?.value : 1,
      count: dataDetail ? dataDetail?.count : 1,
      weight: dataDetail ? dataDetail?.weight : 1,
    }
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  const onSubmit = async (data) => {
    const dataSubmit = {
        name: data?.name,
        type: data?.type,
        value: Number(data?.value) || 0,
        count: Number(data?.count) || 0,
        weight: Number(data?.weight) || 0,
      };
    try {
      if (idEdit) {
        const res = await updateNonNftGacha(dataDetail?.id, dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Machine updated.",
            description: "Machine updated.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
        } else {
          toast({
            description: ERROR_NON_NFT[res?.data?.message] || ERROR_NON_NFT[res?.data?.messages[0]],
            status: "error",
            position: "bottom-right",
          });
        }
      } else {
        const res = await createNonNftGacha(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Machine created.",
            description: "Machine created.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
        } else {
          toast({
            description: ERROR_NON_NFT[res?.data?.message] || ERROR_NON_NFT[res?.data?.messages[0]],
            status: "error",
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <FormInput
              label="Name"
              name="name"
              placeholder="Enter Name"
            />
            <FormSelect
              name="type"
              label="Type"
              defaultValue={TYPE_COIN[0].value}
              options={TYPE_COIN}
            />
            <FormInput
              label="Value"
              name="value"
              placeholder="Enter Value"
              type="number"
              min={0}
              max={1000000}
            />
            <FormInput
              label="Count"
              name="count"
              placeholder="Enter Count"
              type="number"
              min={0}
              max={1000000}
            />
            <FormInput
              label="Weight"
              name="weight"
              placeholder="Enter Weight"
              type="number"
              min={0}
              max={1000000}
            />
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="800px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "EDIT GACHA NON-NFT ITEM" : "NEW GACHA NON-NFT ITEM"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100px"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
            onClick={() => {
              handleSubmit(onSubmit);
            }}
          >
            {idEdit ? "SAVE" : "ADD"}
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
