import { ALLOCATION_LABEL, CHAIN_ID_AVAILABLE } from "constants/constants";
import ICON_POL from "assets/img/card/matic.png";
import ICON_DM2 from "assets/img/card/dm2c_icon.svg";
import ICON_IMMUTABLE from "assets/img/card/passport_icon.svg";
import ICON_BNB from "assets/img/card/bnb_icon.svg";
import { PACKAGE_STATUS_OPTIONS } from "views/NFTsManagement/constants";
import { TRAIT } from "constants/constants";

import ICON_RANK_SILVER from "assets/img/card/silver.png";
import ICON_RANK_GOLD from "assets/img/card/gold.png";
import ICON_RANK_PLATINUM from "assets/img/card/platinum.png";
import ICON_RANK_DIAMOND from "assets/img/card/diamond.png";
import ICON_RANK_IRON from "assets/img/card/iron.png";

import { WALLETS_TYPE } from "../constants";

export const getChainIcon = (chain) => {
  switch (chain) {
    case CHAIN_ID_AVAILABLE.POLYGON_MAINNET:
    case CHAIN_ID_AVAILABLE.POLYGON_TESTNET:
      return {
        src: ICON_POL,
        alt: "POLYGON_MAINNET",
      };
    case CHAIN_ID_AVAILABLE.DM2_MAINNET:
    case CHAIN_ID_AVAILABLE.DM2_TESTNET:
      return {
        src: ICON_DM2,
        alt: "DM2_MAINNET",
      };
    case CHAIN_ID_AVAILABLE.IMX_MAINNET:
    case CHAIN_ID_AVAILABLE.IMX_TESTNET:
      return {
        src: ICON_IMMUTABLE,
        alt: "IMX_MAINNET",
      };
    case CHAIN_ID_AVAILABLE.BNB_MAINNET:
    case CHAIN_ID_AVAILABLE.BNB_TESTNET:
      return {
        src: ICON_BNB,
        alt: "BNB_MAINNET",
      };
    default:
      return {
        src: "",
        alt: "Unknown",
      };
  }
};

export const getAllocationLabel = (allocation) => {
  return ALLOCATION_LABEL[allocation] || "None";
};

export const getStatusLabel = (status = "") => {
  const statusOption = PACKAGE_STATUS_OPTIONS.find(
    (option) => option.value === status
  );
  return statusOption ? statusOption?.label : status;
};

export const formatErrorMessage = (message) => {
  if (!message) return "";

  // Convert SNAKE_CASE to Sentence case (e.g., "Snake case")
  const sentence = message
    .split("_")
    .map((word) => word.toLowerCase())
    .join(" ");

  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
};

export const getIconTrait = (trait) => {
  switch (trait) {
    case TRAIT.MERCHANT:
      return ICON_RANK_SILVER;
    case TRAIT.ARENA_HUNTER:
      return ICON_RANK_GOLD;
    case TRAIT.PRIZE_HUNTER:
      return ICON_RANK_PLATINUM;
    case TRAIT.TREASURE_HUNTER:
      return ICON_RANK_DIAMOND;
    case TRAIT.MECHANIC:
      return ICON_RANK_IRON;
    default:
      return "";
  }
};

export const getWallets = (wallets, chainId) => {
  if (!wallets || !chainId) return "";

  let type = "";
  switch (chainId) {
    case CHAIN_ID_AVAILABLE.POLYGON_MAINNET:
    case CHAIN_ID_AVAILABLE.POLYGON_TESTNET:
      type = WALLETS_TYPE.BICONOMY;
      break;
    case CHAIN_ID_AVAILABLE.DM2_MAINNET:
    case CHAIN_ID_AVAILABLE.DM2_TESTNET:
      type = WALLETS_TYPE.DM2;
      break;
    case CHAIN_ID_AVAILABLE.IMX_MAINNET:
    case CHAIN_ID_AVAILABLE.IMX_TESTNET:
      type = WALLETS_TYPE.IMMUTABLE;
      break;
  }
  const walletAddress = wallets.find((wallet) => wallet?.wallet_type === type);
  return walletAddress?.public_address || "";
};
