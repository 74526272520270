import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import ButtonType from "components/Button";

export default function DeleteModal({ isOpen, onClose, handleDelete }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
        >
          Delete Machine Group
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">Do you want delete Machine Group ?</ModalBody>

        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
        >
          <ButtonType
            btnType="dark"
            borderRadius="5px"
            onClick={onClose}
            mr={3}
          >
            Cancel
          </ButtonType>
          <ButtonType
            borderRadius="5px"
            colorScheme="teal"
            onClick={handleDelete}
          >
            OK
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
