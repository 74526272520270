/* eslint-disable react/prop-types */
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { useController, useFormContext } from "react-hook-form";
import { splitEclipseCharacter } from "utils/string";

const FormSelect = ({
  t,
  label,
  name,
  rules,
  defaultValue = "",
  wrapperProps,
  options = [],
  isRequired,
  lenSplitLabel,
  w100,
  isHiddenOption = false,
  ...rest
}) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  const opts = useMemo(() => {
    if (t) {
      return options.map((opt) => ({ ...opt, label: t(opt.label) }));
    }
    return options;
  }, [options, t]);

  return (
    <FormControl
      maxW="100%"
      w={w100 ? "100%" : "auto"}
      isInvalid={error?.message}
      {...wrapperProps}
    >
      {label && (
        <FormLabel whiteSpace="nowrap" color="#000000">
          {label} {isRequired && <span style={{ color: "red" }}>*</span>}
        </FormLabel>
      )}
      <Select onChange={onChange} value={value} color="#000000" {...rest}>
        {isHiddenOption && (
          <option value="" disabled hidden>
            {""}
          </option>
        )}
        {opts?.length > 0 ? (
          opts?.map((item) => {
            return (
              <option key={item.value} value={item.value}>
                {splitEclipseCharacter(item.label, false, lenSplitLabel)}
              </option>
            );
          })
        ) : (
          <option
            disabled
            value="data-empty"
            style={{
              textAlign: "center",
            }}
          >
            NO DATA
          </option>
        )}
      </Select>
      {error?.message && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControl>
  );
};

export default FormSelect;
