import { Flex, Grid, Text } from "@chakra-ui/react";
import FormSelect from "components/form/FormSelect";
import { MACHINE_TYPE_OPTIONS } from "constants/constants";
import { FormProvider, useForm } from "react-hook-form";
import { STATE, MODE_TYPE } from "../MachineSettings/constants";

export default function ConditionMachineMap({ isLoading, setParams, params, listGroupLabel, listGamePlayType }) {
  const form = useForm();

  const { setValue, handleSubmit } = form;

  const onSubmit = (data) => {
    if (!isLoading) {
      const newParams = {
        ...params,
        ...data,
        page: 1,
      };
      setParams(newParams);
    }
  };

  return (
    <Flex
      gap="16px"
      flexWrap="wrap"
      direction="column"
      justifyContent="flex-start"
      w="100%"
      alignItems="flex-start"
    >
      <FormProvider {...form}>
        <form
          style={{
            maxWidth: "100%",
            width: "100%",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Flex w="100%" flexWrap="wrap" alignItems="flex-end" gap={4}>
            <FormSelect
              name="state"
              label="Filter by Machine State"
              defaultValue={""}
              w="220px"
              options={[{ label: "All", value: "" }, ...STATE]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("state", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
            <FormSelect
              name="machine_type"
              label="Filter by Booth Type"
              defaultValue=""
              w="220px"
              options={[{ label: "All", value: "" }, ...MACHINE_TYPE_OPTIONS]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("machine_type", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
            <FormSelect
              w="220px"
              name="gameplay_type_id"
              label="Filter by Gameplay Type"
              defaultValue={""}
              options={[{ label: "All", value: "" }, ...listGamePlayType]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("gameplay_type_id", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
            <FormSelect
              w="220px"
              name="group_label"
              label="Filter by Group"
              defaultValue={""}
              options={[{ label: "All", value: "" }, ...listGroupLabel]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("group_label", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
            <FormSelect
              w="220px"
              name="mode_type"
              label="Filter by Mode Type"
              defaultValue={""}
              options={[{ label: "All", value: "" }, ...MODE_TYPE]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("mode_type", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
          </Flex>
        </form>
      </FormProvider>
      <Text
        cursor="pointer"
        color="#1480FF"
        fontSize="14px"
        fontWeight="500"
        alignSelf="flex-end"
        onClick={() => {
          setValue("state", "");
          setValue("machine_type", "");
          setValue("mode_type", "");
          setValue("group_label", "");
          setValue("gameplay_type_id", "");
          handleSubmit(onSubmit)();
        }}
      >
        Reset
      </Text>
    </Flex>
  );
}
