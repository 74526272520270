import { Flex } from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { prizeSelector } from "stores/prize/selector";
import { TYPE_PRIZE } from "../../constants/constants";
import { MODE_TYPE_SHOW } from "views/Machine/MachineSettings/constants";

export default function ConditionMachine({ isLoading, setParams, params }) {
  const { t } = useTranslation("prize");
  const { listPrizeType, listPrizeModeType } = useSelector(prizeSelector);
  const [prizeTypes, setListPrizeTypes] = useState(() => "");
  const [listModeType, setListModeTypes] = useState(() => "");
  const form = useForm();
  const { setValue, handleSubmit } = form;

  useEffect(() => {
    if (listPrizeType?.length) {
      let arr = [{ label: "All", value: "" }];
      listPrizeType?.map((item) => {
        return (arr = [...arr, { label: TYPE_PRIZE[item], value: item }]);
      });
      setListPrizeTypes(() => arr);
    }
    if (listPrizeModeType?.length) {
      let arrMode = [{ label: "All", value: "" }];
      listPrizeModeType?.map((item) => {
        return (arrMode = [...arrMode, { label: MODE_TYPE_SHOW[item], value: item }]);
      });
      setListModeTypes(() => arrMode);
    }
  }, [listPrizeType, listPrizeModeType]);

  const onSubmit = (data) => {
    const newParams = {
      ...params,
      ...data,
      page: 1,
    };
    setParams(newParams);
  };

  return (
    <Flex gap={4} flexWrap="wrap" w="100%" alignItems="flex-end">
      <FormProvider {...form}>
        <form
          style={{
            maxWidth: "100%",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Flex w="100%" flexWrap="wrap" alignItems="flex-end" gap={4}>
            <FormInput
              w="270px"
              maxW="100%"
              name="keyword"
              label={t("search")}
              placeholder={t("placeholder_search")}
            />
            <FormSelect
              name="prize_type"
              label={t("filter_prize_type")}
              w="200px"
              options={[...prizeTypes]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("prize_type", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />

            <FormSelect
              name="mode_type"
              label='Filter by Allocation'
              w="200px"
              options={[...listModeType]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("mode_type", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />

          </Flex>
        </form>
      </FormProvider>
    </Flex>
  );
}
