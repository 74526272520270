import {
  FormLabel,
  Text,
  Grid,
  Image,
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from "@chakra-ui/react";
import { getDayLLLL } from "utils/time";
import ImageDefault from "assets/img/empty-image.png";

export default function DetailNotice({
  isOpen,
  onClose,
  dataDetail,
  categories
}) {
  const textColor = useColorModeValue("#000000", "white");

  const nameCategory = () => {
    const itemCategory = categories.find(
      (category) => category.id === dataDetail.categories[0]
    );
    return itemCategory ? itemCategory.name : 'Unknown';
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="1000px" overflowY="auto" maxHeight="90vh" overflow="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          DETAIL NOTICE
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px" height="calc(100% - 70px)" overflowY="auto">
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Public Date
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                {getDayLLLL(dataDetail?.publish_at * 1000)}
              </Text>
            </div>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Target Countries
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                {dataDetail?.restricted_countries.length}
              </Text>
            </div>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Category
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                {nameCategory()}
              </Text>
            </div>

            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Header
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                {dataDetail.header_en}
              </Text>
            </div>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Description
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
                dangerouslySetInnerHTML={{ __html: dataDetail.content_en }}
              />
            </div>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Banner (Ratio 1:6)
              </FormLabel>
              <Box w="300px" h="50px" backgroundSize="cover">
                <Image src={dataDetail?.banner_url_en || ImageDefault} w="100%" h="100%" />
              </Box>
            </div>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
