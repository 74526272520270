import * as yup from "yup";

const schemaMachine = () =>
  yup.object().shape({
    machine_id: yup
      .string()
      .trim()
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    status: yup.string().trim().required("Required"),
    gameplay_type_id: yup.string().trim().required("Required"),
    icon_url: yup.string().trim().required("Required"),
    machine_group: yup.string().trim().required("Required"),
    machine_number: yup.string().trim().required("Required"),
    machine_type: yup.string().required("Required"),
    mode_type: yup.string().notRequired(),
    direction: yup.string().trim().required("Required"),
    grasp_power: yup.string().when("machine_type", {
      is: (val) => val.includes("THREE_CLAWS"),
      then: () => yup.string().required("Required"),
      otherwise: () => yup.string().nullable(true),
    }),
    top_power: yup.string().when("machine_type", {
      is: (val) => val.includes("THREE_CLAWS"),
      then: () => yup.string().required("Required"),
      otherwise: () => yup.string().nullable(true),
    }),
    move_power: yup.string().when("machine_type", {
      is: (val) => val.includes("THREE_CLAWS"),
      then: () => yup.string().required("Required"),
      otherwise: () => yup.string().nullable(true),
    }),
    max_power: yup.string().when("machine_type", {
      is: (val) => val.includes("THREE_CLAWS"),
      then: () => yup.string().required("Required"),
      otherwise: () => yup.string().nullable(true),
    }),
    top_height: yup.string().when("machine_type", {
      is: (val) => val.includes("THREE_CLAWS"),
      then: () => yup.string().required("Required"),
      otherwise: () => yup.string().nullable(true),
    }),
    line_length: yup.string().when("machine_type", {
      is: (val) => val.includes("THREE_CLAWS"),
      then: () => yup.string().required("Required"),
      otherwise: () => yup.string().nullable(true),
    }),
    front_back_motor_speed: yup.string().when("machine_type", {
      is: (val) => val.includes("THREE_CLAWS"),
      then: () => yup.string().required("Required"),
      otherwise: () => yup.string().nullable(true),
    }),
    left_right_motor_speed: yup.string().when("machine_type", {
      is: (val) => val.includes("THREE_CLAWS"),
      then: () => yup.string().required("Required"),
      otherwise: () => yup.string().nullable(true),
    }),
    up_down_motor_speed: yup.string().when("machine_type", {
      is: (val) => val.includes("THREE_CLAWS"),
      then: () => yup.string().required("Required"),
      otherwise: () => yup.string().nullable(true),
    }),
  });

export { schemaMachine };
