import { containSpecialRegex } from "utils/regex";
import * as yup from "yup";

const schemaMachine = () =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    name_jp: yup
      .string()
      .trim()
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    description: yup
      .string()
      .trim()
      .max(2000, "Maximum limit of 2000 characters."),
    description_jp: yup
      .string()
      .trim()
      .max(2000, "Maximum limit of 2000 characters."),
    icon_url: yup
      .string()
      .trim()
      .required("Required"),
    status: yup
      .string()
      .trim()
      .required("Required"),
    time: yup
      .string()
      .trim()
      .required("Required"),
    payout: yup
      .string()
      .trim()
      .required("Required"),
  });

export default schemaMachine;
