import {
  Flex,
  FormLabel,
  Grid,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { getDetailMachineSettings } from "api/machine.api";
import CopyComponent from "components/Copy";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MACHINE_TYPE_OPTIONS, MODE_TYPE_SHOW, STATE_OPTIONS, STATE_SHOW } from "../constants";
import { renderDedicated } from "../help";
import { schemaMachine } from "./schema";

export default function MachineDetail({
  isOpen,
  onClose,
  dataDetail,
  listGamePlayType,
  gamesModeOptions,
}) {
  const [userDetail, setUserDetail] = useState({});
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const form = useForm({
    resolver: yupResolver(schemaMachine()),
  });

  const { reset } = form;

  const getDetailUser = async (id) => {
    try {
      const { data } = await getDetailMachineSettings(id);
      if (data?.success) {
        const detail = data?.data;
        setUserDetail(detail);
        reset({
          ...detail,
          deDicatedTo: renderDedicated(
            detail?.event_available,
            detail?.freeplay_available
          ),
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    if (dataDetail) {
      getDetailUser(dataDetail?.id);
    }
  }, [dataDetail?.id]);

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form">
          <Grid templateColumns="repeat(2, minmax(0, 1fr))" gap={8}>
            <div>
              <FormLabel color={textColor} fontWeight="700" fontSize="16px">
                {"Machine ID"}
              </FormLabel>
              <Text color={textColor} fontSize="16px" fontWeight="400">
                {userDetail?.crane_machine?.mac}
              </Text>
            </div>

            <div>
              <FormLabel color={textColor} fontWeight="700" fontSize="16px">
                {"Machine State"}
              </FormLabel>
              <Text color={textColor} fontSize="16px" fontWeight="400">
                {STATE_SHOW[userDetail?.state]}
              </Text>
            </div>

            <div>
              <FormLabel color={textColor} fontWeight="700" fontSize="16px">
                {"Gameplay Type "}
              </FormLabel>
              <Text color={textColor} fontSize="16px" fontWeight="400">
                {
                  listGamePlayType.find(
                    (item) => item?.value === userDetail?.gameplay_type_id
                  )?.label
                }
              </Text>
            </div>

            <div>
              <FormLabel color={textColor} fontWeight="700" fontSize="16px">
                {"Booth Type"}
              </FormLabel>
              <Text color={textColor} fontSize="16px" fontWeight="400">
                {
                  MACHINE_TYPE_OPTIONS.find(
                    (item) => item?.value === userDetail?.machine_type
                  )?.label
                }
              </Text>
            </div>

            <div>
              <FormLabel color={textColor} fontWeight="700" fontSize="16px">
                {"Dedicated To"}
              </FormLabel>
              <Text color={textColor} fontSize="16px" fontWeight="400">
                {MODE_TYPE_SHOW[userDetail?.mode_type]}
              </Text>
            </div>

            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <div>
                <FormLabel color={textColor} fontWeight="700" fontSize="16px">
                  {"Front camera ID"}
                </FormLabel>
                <Text color={textColor} fontSize="16px" fontWeight="400">
                  {userDetail?.crane_machine?.cam_front_id}
                </Text>
              </div>
              <div>
                <FormLabel color={textColor} fontWeight="700" fontSize="16px">
                  {"Side camera ID "}
                </FormLabel>
                <Text color={textColor} fontSize="16px" fontWeight="400">
                  {userDetail?.crane_machine?.cam_back_id}
                </Text>
              </div>
            </Grid>
            <div>
              <FormLabel color={textColor} fontWeight="700" fontSize="16px">
                {"Domain stream"}
              </FormLabel>
              <FormLabel
                color={textColor}
                ml={4}
                fontWeight="600"
                fontSize="16px"
              >
                {"Front camera"}
              </FormLabel>
              <Flex align="center" gap={4}>
                <Text
                  color={textColor}
                  ml={4}
                  fontSize="16px"
                  fontWeight="400"
                  w="calc(100% - 60px)"
                >
                  {userDetail?.crane_machine?.web_link_cam_front}
                </Text>
                <CopyComponent
                  dataCopy={userDetail?.crane_machine?.web_link_cam_front}
                />
              </Flex>
            </div>
            <div>
              <FormLabel color={textColor} fontWeight="700" fontSize="16px">
                &nbsp;
              </FormLabel>
              <FormLabel color={textColor} fontWeight="600" fontSize="16px">
                {"Side Camera"}
              </FormLabel>
              <Flex align="center" gap={4}>
                <Text
                  color={textColor}
                  fontSize="16px"
                  fontWeight="400"
                  w="calc(100% - 40px)"
                >
                  {userDetail?.crane_machine?.web_link_cam_back}
                </Text>
                <CopyComponent
                  dataCopy={userDetail?.crane_machine?.web_link_cam_back}
                />
              </Flex>
            </div>
            <div>
              <FormLabel color={textColor} fontWeight="700" fontSize="16px">
                {"Image"}
              </FormLabel>
              <Image
                src={userDetail?.icon_url}
                w="150px"
                h="150px"
                alt={userDetail?.id}
              />
            </div>
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent
        maxW={{
          base: "100%",
          md: "95%",
          xl: "1519px",
        }}
        overflowY="auto"
      >
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >{`MACHINE #${dataDetail?.crane_machine?.mac}`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody p="32px 64px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
        ></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
