import { Icon } from "@chakra-ui/icons";
import {
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { EditIcon } from "components/Icons/EditIcon";
import { ROLE_OPTIONS } from "constants/constants";
import { useSelector } from "react-redux";
import { userinfoSelector } from "stores/user/selector";

export default function RoleManagementRow({
  item,
  index,
  handleOpenEditModal,
  checkRule,
}) {
  const textColor = useColorModeValue("#000000", "white");
  const { userinfo } = useSelector(userinfoSelector)

  return (
    <>
      <Tr>
        <Td w="50px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {index}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {ROLE_OPTIONS[item?.name]}
          </Text>
        </Td>
        <Td>
          <Flex gap="10px">
            {((item?.role?.name === "SUPPER_ADMIN" && userinfo?.role?.name === "SUPPER_ADMIN") || item?.role?.name !== "SUPPER_ADMIN") && (
              <> 
                {checkRule("EDIT") && (
                  <Icon
                    onClick={() => {
                      handleOpenEditModal(item);
                    }}
                    fontSize="30px"
                    borderRadius="8px"
                    p="7px"
                    cursor="pointer"
                    bg="#1480FF"
                    as={EditIcon}
                  />
                )}
              </>
            )}
          </Flex>
        </Td>
      </Tr>
    </>
  );
}
