/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import { PRIZE_NAMESPACE } from 'stores/constant'
import {
  listPrizesApi,
  addPrizeApi,
  getPrizeByIdApi,
  editPrizeByIdApi,
  deletePrizeAllApi,
  listPrizeTypeApi,
  listPrizeModeTypeApi,
  listFeePrizeApi,
  listPrizeRarity,
  listPrizeTraits,
  listPrizesGameModeApi,
} from './action'
import { RARITY, TRAIT } from 'views/PrizeSettings/constant'

const name = PRIZE_NAMESPACE
const initialState = createInitialState()
const reducers = createReducers()
const extraReducers = createExtraReducers()
const prizeSlice = createSlice({ name, initialState, reducers, extraReducers })

// export const authActions = { ...slice.actions, ...extraActions };
export const prizeSliceReducer = prizeSlice.reducer

function createInitialState() {
  return {
    loadingPrize: false,
    statusDelete: false,
    errorPrizePrize: '',
    listPrizes: [],
    listPrizesGameMode: [],
    listPrizeType: [],
    listPrizeModeType: [],
    listPrizeRarity: [],
    listPrizeTraits: [],
    listPlayFee: [],
    prizeById: null,
  }
}

function createReducers() {
  return {}
}

function createExtraReducers() {
  return (builder) => {
    listPrizes()
    listPrizesGameMode()
    addPrize()
    getPrizeById()
    editPrizeById()
    deletePrizeById()
    getListPrizeType()
    getListPrizeModeType()
    getListPlayFees()
    getListPrizeRarity()
    getListPrizeTraits()

    function listPrizes() {
      builder
        .addCase(listPrizesApi.pending, (state) => {
          state.loadingPrize = true
        })
        .addCase(listPrizesApi.fulfilled, (state, action) => {
          state.loadingPrize = false
          state.errorPrize = ''
          if (action.payload) {
            const records = [...action.payload?.data?.records]?.map(
              (item) => {
                item.create_time =
                  Number.parseFloat(item?.create_time || 0) || null
                item.create_by =
                  Number.parseFloat(item?.create_by || 0) || null
                item.update_time =
                  Number.parseFloat(item?.update_time || 0) || null
                item.update_by =
                  Number.parseFloat(item?.update_by || 0) || null
                return item
              },
            )

            state.listPrizes = {
              records,
              total: action.payload?.data?.total,
              page: action.payload?.data?.page,
              limit: action.payload?.data?.limit,
              total_page: action.payload?.data?.total_page,
            }
          }
        })
        .addCase(listPrizesApi.rejected, (state, action) => {
          state.loadingPrize = false
          state.errorPrize = action.payload
        })
    }

    function listPrizesGameMode() {
      builder
        .addCase(listPrizesGameModeApi.pending, (state) => {
          state.loadingPrize = true
        })
        .addCase(listPrizesGameModeApi.fulfilled, (state, action) => {
          state.loadingPrize = false
          state.errorPrize = ''
          if (action.payload) {
            const records = [...action.payload?.data?.records]?.map(
              (item) => {
                item.create_time =
                  Number.parseFloat(item?.create_time || 0) || null
                item.create_by =
                  Number.parseFloat(item?.create_by || 0) || null
                item.update_time =
                  Number.parseFloat(item?.update_time || 0) || null
                item.update_by =
                  Number.parseFloat(item?.update_by || 0) || null
                return item
              },
            )

            state.listPrizesGameMode = {
              records,
              total: action.payload?.data?.total,
              page: action.payload?.data?.page,
              limit: action.payload?.data?.limit,
              total_page: action.payload?.data?.total_page,
            }
          }
        })
        .addCase(listPrizesGameModeApi.rejected, (state, action) => {
          state.loadingPrize = false
          state.errorPrize = action.payload
        })
    }

    function addPrize() {
      builder
        .addCase(addPrizeApi.pending, (state) => {
          state.loadingPrize = true
        })
        .addCase(addPrizeApi.fulfilled, (state, action) => {
          state.loadingPrize = false
          state.errorPrize = ''
        })
        .addCase(addPrizeApi.rejected, (state, action) => {
          state.loadingPrize = false
          state.errorPrize = action.payload
        })
    }

    function getPrizeById() {
      builder
        .addCase(getPrizeByIdApi.pending, (state) => {
          state.loadingPrize = true
          state.prizeById = null
        })
        .addCase(getPrizeByIdApi.fulfilled, (state, action) => {
          state.loadingPrize = false
          state.errorPrize = ''
          if (action.payload) {
            state.prizeById = action.payload?.data
          }
        })
        .addCase(getPrizeByIdApi.rejected, (state, action) => {
          state.loadingPrize = false
          state.errorPrize = action.payload
        })
    }

    function editPrizeById() {
      builder
        .addCase(editPrizeByIdApi.pending, (state) => {
          state.loadingPrize = true
        })
        .addCase(editPrizeByIdApi.fulfilled, (state, action) => {
          state.loadingPrize = false
          state.errorPrize = ''
        })
        .addCase(editPrizeByIdApi.rejected, (state, action) => {
          state.loadingPrize = false
          state.errorPrize = action.payload
        })
    }

    function deletePrizeById() {
      builder
        .addCase(deletePrizeAllApi.pending, (state, action) => {
          state.statusDelete = true
        })
        .addCase(deletePrizeAllApi.fulfilled, (state, action) => {
          state.statusDelete = false
          state.errorPrize = ''
        })
        .addCase(deletePrizeAllApi.rejected, (state, action) => {
          state.statusDelete = false
          state.errorPrize = action.payload
        })
    }

    function getListPrizeType() {
      builder
        .addCase(listPrizeTypeApi.pending, (state) => {
          state.listPrizeType = []
        })
        .addCase(listPrizeTypeApi.fulfilled, (state, action) => {
          state.errorPrize = ''
          if (action.payload) {
            state.listPrizeType = action.payload?.data
          }
        })
        .addCase(listPrizeTypeApi.rejected, (state, action) => {
          state.errorPrize = action.payload
        })
    }

    function getListPrizeModeType() {
      builder
        .addCase(listPrizeModeTypeApi.pending, (state) => {
          state.listPrizeModeType = []
        })
        .addCase(listPrizeModeTypeApi.fulfilled, (state, action) => {
          state.errorPrize = ''
          if (action.payload) {
            state.listPrizeModeType = action.payload?.data
          }
        })
        .addCase(listPrizeModeTypeApi.rejected, (state, action) => {
          state.errorPrize = action.payload
        })
    }

    function getListPrizeRarity() {
      builder
        .addCase(listPrizeRarity.pending, (state) => {
          state.listPrizeRarity = []
        })
        .addCase(listPrizeRarity.fulfilled, (state, action) => {
          state.errorPrize = ''
          if (action.payload) {
            const lisRarity = action.payload?.data?.map((item) => ({ label: RARITY[item], value: item }));
            state.listPrizeRarity = lisRarity
          }
        })
        .addCase(listPrizeRarity.rejected, (state, action) => {
          state.errorPrize = action.payload
        })
    }

    function getListPrizeTraits() {
      builder
        .addCase(listPrizeTraits.pending, (state) => {
          state.listPrizeTraits = []
        })
        .addCase(listPrizeTraits.fulfilled, (state, action) => {
          state.errorPrize = ''
          const lisTraits = action.payload?.data?.map((item) => ({ label: TRAIT[item], value: item }));
          if (action.payload) {
            state.listPrizeTraits = lisTraits
          }
        })
        .addCase(listPrizeTraits.rejected, (state, action) => {
          state.errorPrize = action.payload
        })
    }

    function getListPlayFees() {
      builder
        .addCase(listFeePrizeApi.pending, (state) => {
          state.listPlayFee = []
        })
        .addCase(listFeePrizeApi.fulfilled, (state, action) => {
          state.errorPrize = ''
          if (action.payload) {
            state.listPlayFee = action.payload?.data
          }
        })
        .addCase(listFeePrizeApi.rejected, (state, action) => {
          state.errorPrize = action.payload
        })
    }
  }
}
