import { useState } from "react";
import {
  Editable,
  EditableInput,
  EditablePreview,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { CASES_SKILL_POINT } from "../../constant";
import { MODE_TYPE_SHOW } from "views/Machine/MachineSettings/constants";

export default function LeaderBoardRow({
  item,
  index,
  handleChangePoint,
}) {
  const [dataFactor, setDataFactor] = useState({
    first_factor: item?.first_factor,
    second_factor_base: item?.second_factor_base,
    second_factor_multiplier: item?.second_factor_multiplier,
  })
  const handleChange = (newValue, type) => {
    if (newValue === '') {
      setDataFactor(prev => ({ ...prev, [type]: '0' }));
    } else if (/^\d*\.?\d+$/.test(newValue)) {
      setDataFactor(prev => ({ ...prev, [type]: newValue }));
    }
  };
  
  const textColor = useColorModeValue("#000000", "white");
  return (
    <Tr>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {index + 1}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {MODE_TYPE_SHOW[item?.mode_type]}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {CASES_SKILL_POINT[item?.cases]?.toUpperCase()}
        </Text>
      </Td>
      <Td>
        <Editable 
          display="flex" 
          alignItems="center" 
          width="auto" 
          defaultValue={item?.first_factor || "0"}
          value={dataFactor?.first_factor}
          onChange={(e) => handleChange(e, 'first_factor')}
          submitOnBlur={true}
          onSubmit={(e) => handleChangePoint(item.id, 'first_factor', parseFloat(e || 0))}
        >
          <EditablePreview />
          <EditableInput type="number" width="100px"/>
        </Editable>
      </Td>
      <Td>
        <Editable 
          display="flex" 
          alignItems="center" 
          width="auto" 
          defaultValue={item?.second_factor_base || "0"}
          value={dataFactor?.second_factor_base}
          onChange={(e) => handleChange(e, 'second_factor_base')}
          submitOnBlur={true}
          onSubmit={(e) => handleChangePoint(item.id, 'second_factor_base', parseFloat(e || 0))}
        >
          <EditablePreview />
          <EditableInput width="100px"/>
        </Editable>
      </Td>
      <Td>
        <Editable 
          display="flex" 
          alignItems="center" 
          width="auto" 
          defaultValue={item?.second_factor_multiplier || "0"}
          value={dataFactor?.second_factor_multiplier}
          onChange={(e) => handleChange(e, 'second_factor_multiplier')}
          submitOnBlur={true}
          onSubmit={(e) => handleChangePoint(item.id, 'second_factor_multiplier', parseFloat(e || 0))}
        >
          <EditablePreview />
          <EditableInput width="100px"/>
        </Editable>
      </Td>
    </Tr>
  );
}
