import {
  Box,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import NFTsCardHeader from "../components/panel/NFTsCardHeader";
import { useDispatch, useSelector } from "react-redux";
import { nftsSelector } from "stores/nfts/selector";
import { setIndexTabImport } from "stores/nfts/nftsReducer";
import NFTsWidgetImport from "../components/import/NFTsWidgetImport";

const TAB_NAMES = [
  // {
  //   name: "GENESIS",
  //   type: "GENESIS_NFT",
  // },
  {
    name: "HUNTER",
    type: "HUNTER_NFT",
  },
  {
    name: "GAUNTLET",
    type: "GAUNTLET_NFT",
  },
  {
    name: "BOUNTY BALL",
    type: "BOUNTY_BALL_NFT",
  },
];

export default function NftImportManagementPage() {
  const dispatch = useDispatch();
  const { indexTabImport } = useSelector(nftsSelector);

  const handleTabChange = (index) => dispatch(setIndexTabImport(index));

  const tabBg = useColorModeValue("gray.50", "gray.800");
  const activeTabBg = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const activeTabColor = useColorModeValue("blue.600", "blue.300");

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="24px">
        <NFTsCardHeader title="Import NFTs" />
        <Tabs
          isLazy
          onChange={handleTabChange}
          index={indexTabImport}
          variant="enclosed"
        >
          <TabList borderBottom={`1px solid ${borderColor}`}>
            {TAB_NAMES.map((tab) => (
              <Tab
                key={tab?.type}
                fontWeight="semibold"
                _selected={{
                  color: activeTabColor,
                  bg: activeTabBg,
                  borderBottom: "3px solid",
                  borderBottomColor: activeTabColor,
                  marginBottom: "-1px",
                }}
                _hover={{
                  color: activeTabColor,
                }}
                bg={tabBg}
                borderTopRadius="md"
                mr={1}
                py={3}
                px={5}
              >
                {tab?.name}
              </Tab>
            ))}
          </TabList>
          <Box mt={4}>
            <TabPanels>
              {TAB_NAMES.map((tab) => (
                <TabPanel key={tab?.type} p={4}>
                  <NFTsWidgetImport tabType={tab?.type} />
                </TabPanel>
              ))}
            </TabPanels>
          </Box>
        </Tabs>
      </Card>
    </Flex>
  );
}
