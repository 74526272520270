import { Icon } from "@chakra-ui/icons";
import {
  Flex,
  Image,
  Stack,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import { useMemo } from "react";
import { getChainIcon } from "views/NFTsManagement/utils/utils";
import { ViewIcon } from "components/Icons/ViewIcon";

export default function ItemsSendRow({
  machine,
  checkedIds,
  setCheckedIds,
}) {
  const textColor = useColorModeValue("#000000", "white");
  const isMachineChecked = useMemo(() => {
    return checkedIds.some((item) => item?.id === machine?.id);
  }, [machine?.id, checkedIds]);
  
  const handleCheckboxChange = () => {
    if (isMachineChecked) {
      setCheckedIds(checkedIds.filter((item) => item?.id !== machine?.id));
    } else {
      setCheckedIds([...checkedIds, {
        ...machine,
        grand_prize: true,
      }]);
    }
  };

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isMachineChecked}
          onChange={() => handleCheckboxChange()}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.id}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.name}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Image
            boxSize="30px"
            src={getChainIcon(machine?.chain_id).src}
            alt={getChainIcon(machine?.chain_id).alt}
          />
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Flex alignItems="center" gap="4px" flexWrap="wrap">
            {machine?.gacha_items?.map((gacha, index) => (
              index < 3 && <Text key={index} p="4px 6px" borderRadius="16px" bg="#95ff87">
                {gacha.id}
              </Text>
            ))}
            {machine?.gacha_items?.length > 3 && <Text>...</Text>}
          </Flex>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.weight}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Flex gap={6} align="center">
          <Icon
            fontSize="30px"
            borderRadius="8px"
            cursor="pointer"
            as={ViewIcon}
          />
        </Flex>
      </Td>
    </Tr>
  );
}
