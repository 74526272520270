import * as yup from "yup";

const schemaFreeplay = () =>
  yup.object().shape({
    play_fee_id: yup.string().trim().required("Required"),
    limit_plays: yup.string().trim().required("Required"),
    life_gauge: yup.string().trim().required("Required"),
    prize_items: yup.array().required("Required"),
  });

export default schemaFreeplay;
