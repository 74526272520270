import {
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { TYPE_PRIZE } from "constants/constants";
import moment from "moment";
import { getDayLLLL } from "utils/time";
import { MODE_TYPE_SHOW } from "views/Machine/MachineSettings/constants";
import { RESULT_VALUE } from "views/Users/PlayerManagement/constants";

export default function LeaderBoardRow({
  item,
  index
}) {
  const textColor = useColorModeValue("#000000", "white");

  const convertTime = (time) => {
    return moment(time * 1000).utc().format("DD MMM. YYYY HH:mm:ss");
  };

  return (
    <Tr>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {MODE_TYPE_SHOW[item?.mode_type]}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.opponent_partner_name}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {RESULT_VALUE[item?.status]}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {TYPE_PRIZE[item?.prize_type]}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {getDayLLLL(item?.start_play_time * 1000)}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {getDayLLLL(item?.end_play_time * 1000)}
        </Text>
      </Td>
    </Tr>
  );
}
