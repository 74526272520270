import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getDigitalFigure = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.DIGITAL_FIGURE}?${params}`);
};

export const getDetailDigitalFigure = (id) => {
  return http.get(`${END_POINT.DIGITAL_FIGURE}/${id}`);
};

export const uploadLinkFigure = (params) => {
  return http.post(`${END_POINT.DIGITAL_FIGURE}/upload-link`, params);
};

export const uploadLinkFigureDone = (params) => {
  return http.post(`${END_POINT.DIGITAL_FIGURE}/upload-done`, params);
};
