import CommonBg from "assets/img/card/common-bg.svg";
import UncommonBg from "assets/img/card/uncommon-bg.svg";
import RareBg from "assets/img/card/rare-bg.svg";
import LegendaryBg from "assets/img/card/legendary-bg.svg";
import EpicBg from "assets/img/card/epic-bg.svg";
import { RARITY } from "constants/constants";

export const getRarityStyle = (rarity) => {
  switch (rarity) {
    case RARITY.RARITY_UNCOMMON:
      return {
        iconBg: UncommonBg,
        bgRarity: "#008a17",
      };
    case RARITY.RARITY_RARE:
      return {
        iconBg: RareBg,
        bgRarity: "#005ddd",
      };
    case RARITY.RARITY_EPIC:
      return {
        iconBg: EpicBg,
        bgRarity: "#7612ff",
      };
    case RARITY.RARITY_LEGENDARY:
      return {
        iconBg: LegendaryBg,
        bgRarity: "#ffd32f",
      };
    default:
      return {
        iconBg: CommonBg,
        bgRarity: "#545f7b",
      };
  }
}; 