import { containSpecialUserNameJapanRegex } from "utils/regex";
import * as yup from "yup";

const schemaStandardEvent = () =>
  yup.object().shape({
    event_name: yup
      .string()
      .trim()
      .matches(containSpecialUserNameJapanRegex, "Name should not contain special characters")
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    event_name_jp: yup
      .string()
      .trim()
      .required("Required")
      .matches(containSpecialUserNameJapanRegex, "Name should not contain special characters")
      .max(255, "Maximum limit of 255 characters."),
    icon_url: yup
      .string()
      .trim()
      .required("Required"),
    win_point: yup
      .string()
      .max(6, "Maximum limit of 6 characters.")
      .required('Required')
      .test(
        'Is positive?', 
        'The number must be greater than or equal 0!', 
        (value) => value >= 0
      )
      .min(0),
    // pre_register_start_time: yup
    //   .date()
    //   .required(),
    event_start_time: yup
      .date()
      .required(),
    event_end_time: yup
      .date()
      .test(
        "is-greater-event_start_time",
        "Event end time must be after event start time",
        function (value) {
          const { event_start_time } = this.parent;
          return (
            event_start_time &&
            value &&
            new Date(value) > new Date(event_start_time)
          );
        }
      )
      .required(),
    // result_end_time: yup
    //   .date()
    //   .test(
    //     "is-greater-event_end_time",
    //     "Result end time must be after event end time",
    //     function (value) {
    //       const { event_end_time } = this.parent;
    //       return (
    //         event_end_time &&
    //         value &&
    //         new Date(value) > new Date(event_end_time)
    //       );
    //     }
    //   )
    //   .required(),
  });

export default schemaStandardEvent;
