import { ethers } from "ethers";
import { RPC_URL, CHAIN_NATIVE_TOKEN } from "constants/constants";

export const getProvider = (chainId) => {
  const rpcUrl = RPC_URL[chainId];

  if (!rpcUrl) {
    throw new Error(`Unsupported chain ID: ${chainId}`);
  }

  return new ethers.providers.JsonRpcProvider(rpcUrl);
};

export const getNativeToken = (chainId) => {
  return CHAIN_NATIVE_TOKEN[chainId] || "Unknown";
};

export const getContractABI = () => {
  return [
    "function transferFrom(address from, address to, uint256 tokenId)",
    "function ownerOf(uint256 tokenId) view returns (address owner)",
    "function balanceOf(address owner) view returns (uint256 balance)",
    "function safeTransferFrom(address from, address to, uint256 tokenId)",
    "function safeTransferFrom(address from, address to, uint256 tokenId, bytes data)",
    "function approve(address to, uint256 tokenId)",
    "function getApproved(uint256 tokenId) view returns (address operator)",
    "function setApprovalForAll(address operator, bool _approved)",
    "function isApprovedForAll(address owner, address operator) view returns (bool)"
  ];
};
