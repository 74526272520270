import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getOwnerNFTs = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.NFT_OWNER}?${params}`);
};

export const getOwnerSummary = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.NFT_OWNER_SUMMARY}?${params}`);
};

export const getDetailOwnerNFT = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.NFT_OWNER}/detail?${params}`);
};

export const getDetailOwnerNFTSummary = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.NFT_OWNER_SUMMARY}/detail?${params}`);
};

export const getAllActiveNFTs = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.NFT_OWNER_ACTIVE_NFTS}?${params}`);
};

export const importCSVOwnerNFT = (data) => {
  return http.post(END_POINT.NFT_OWNER_IMPORT_CSV, data, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
};

export const changeTaskStatusOwner = (p) => {
  const params = convertParams(omitParams(p));
  return http.put(`${END_POINT.NFT_OWNER_CHANGE_TASK_STATUS}?${params}`);
};
