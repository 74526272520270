import React from "react";
import {
  VStack,
  Text,
  Image,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Center,
  Flex,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import ModalBase from "components/Modal/ModalBase";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Paginate from "components/Paginate";
import GenesisPackageSummaryPanel from "../panel/GenesisPackageSummaryPanel";
import { useGenesisInPackageList } from "views/NFTsManagement/hooks/useGenesisInPackageList";
import { capitalize } from "lodash";
import { Icon, ViewIcon } from "@chakra-ui/icons";
import NFTDetailModal from "./NFTDetailModal";
import { NFT_MANAGEMENT_TYPE } from "views/NFTsManagement/constants";

export default function GenesisPackageDetailModal({
  selectedItem,
  isOpen,
  onClose,
}) {
  const textColor = useColorModeValue("#000000", "white");
  const {
    genesis,
    isLoading,
    totalPage,
    params,
    nftDetail,
    getNFTDetail,
    handleChangeLimit,
    handleChangePage,
  } = useGenesisInPackageList(selectedItem);
  

  const {
    isOpen: isOpenDetail,
    onOpen: onOpenDetail,
    onClose: onCloseDetail,
  } = useDisclosure();

  const handleOpenDetail = async (item) => {
    await getNFTDetail(item);
    onOpenDetail();
  };

  return (
    <>
      <ModalBase
        maxWContent={{
          base: "90%",
          sm: "80%",
          md: "70%",
          lg: "60%",
        }}
        isOpen={isOpen}
        onClose={onClose}
        showBtn={false}
        titleHeader={`Package: ${selectedItem?.name}`}
      >
        <VStack spacing={6} align="stretch">
          <GenesisPackageSummaryPanel summaryData={selectedItem} />
          {isLoading ? (
            <Center minH="200px">
              <LoadingSpinner />
            </Center>
          ) : (
            <>
              <Box>
                <Text fontWeight="bold">Genesis List</Text>
                <Table variant="simple">
                  <Thead color="black.600">
                    <Tr>
                      <Th>NO.</Th>
                      <Th>IMAGE</Th>
                      <Th>NAME</Th>
                      <Th>RARITY</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {genesis?.map((item, index) => (
                      <Tr key={index}>
                        <Td>
                          {(params.page - 1) * params?.limit + item?.index}
                        </Td>
                        <Td>
                          <Image
                            w="50px"
                            lazyLoad={true}
                            src={item?.image_url}
                            alt={item?.name}
                          />
                        </Td>
                        <Td>{item?.name}</Td>
                        <Td>{capitalize(item?.rarity)}</Td>
                        <Td>
                          <Flex gap={6}>
                            <Icon
                              onClick={() => handleOpenDetail(item)}
                              fontSize="16px"
                              cursor="pointer"
                              color={textColor}
                              as={ViewIcon}
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
              <Paginate
                page={params?.page}
                pageCount={totalPage}
                limit={params.limit}
                onChangeLimit={handleChangeLimit}
                handleChangePage={handleChangePage}
              />
            </>
          )}
        </VStack>
      </ModalBase>
      {isOpenDetail && (
        <NFTDetailModal
          nftMngType={NFT_MANAGEMENT_TYPE.GENESIS}
          item={nftDetail}
          isOpen={isOpenDetail}
          onClose={onCloseDetail}
        />
      )}
    </>
  );
}
