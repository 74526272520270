import {
  containSpecialRegex,
  containSpecialUserNameRegex,
  emailRegex,
  passwordRegex,
} from "utils/regex";
import * as yup from "yup";

const schemaAdminManagement = () =>
  yup.object().shape({
    email: yup
      .string()
      .trim()
      .required("Required")
      .max(40, "Email must be at most 30 characters")
      .matches(emailRegex, "Invalid email format"),
    username: yup
      .string()
      .trim()
      .required("Required")
      .max(255, "Username must be at most 30 characters")
      /* .matches(
        containSpecialUserNameRegex,
        "Username must not contain special characters"
      )
      .matches(
        /^(?=.*[a-zA-Z])[a-zA-Z0-9 ]*$/,
        "Username cannot be entirely numeric."
      ) */,
    password: yup
      .string()
      .trim()
      .required("Required")
      .max(30, "Password must be at most 30 characters")
      .matches(passwordRegex, "Password must be at least 8 characters"),
    role: yup.string().trim().required("Required"),
  });

const schemaAdminManagementEdit = (isEdit) =>
  isEdit === "role" ? 
    yup.object().shape({
      // password: yup
      //   .string()
      //   .trim()
      //   .required("Required")
      //   .max(30, "Password must be at most 30 characters")
      //   .matches(passwordRegex, "Password must be at least 8 characters"),
      // cpassword: yup
      //   .string()
      //   .trim()
      //   .required("Required")
      //   .max(30, "Password must be at most 30 characters")
      //   .matches(passwordRegex, "Password must be at least 8 characters")
      //   .oneOf([yup.ref("password")], "Passwords do not match"),
      role: yup.string().trim().required("Required"),
    }) : (
      yup.object().shape({
        password: yup
          .string()
          .trim()
          .required("Required")
          .max(30, "Password must be at most 30 characters")
          .matches(passwordRegex, "Password must be at least 8 characters"),
        cpassword: yup
          .string()
          .trim()
          .required("Required")
          .max(30, "Password must be at most 30 characters")
          .matches(passwordRegex, "Password must be at least 8 characters")
          .oneOf([yup.ref("password")], "Passwords do not match"),
      })
    )

// const schemaAdminManagementEditPass = () =>

export { schemaAdminManagementEdit, schemaAdminManagement };
