import { Flex } from "@chakra-ui/react";
import Card from "components/Card/Card";

import NFTsList from "../components/list/NFTsList";
import NFTsCardHeader from "../components/panel/NFTsCardHeader";
import NFTsSummaryPanel from "../components/panel/NFTsSummaryPanel";
import { NFT_MANAGEMENT_TYPE } from "../constants";

const NFT_MNG_TYPE = NFT_MANAGEMENT_TYPE.OWNER;

export default function NftOwnerManagementPage() {
  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="24px">
        {/* NFTsCardHeader */}
        <NFTsCardHeader title="Owner NFTs" nftMngType={NFT_MNG_TYPE}/>
        {/* NFTsSummaryPanel */}
        <NFTsSummaryPanel nftMngType={NFT_MNG_TYPE} />
        {/* NFTsList */}
        <NFTsList nftMngType={NFT_MNG_TYPE} />
      </Card>
    </Flex>
  );
}
