export const STATUS = {
  ACTIVE: "ACTIVE",
  NO_ACTIVE: "NO_ACTIVE",
  AVAILABLE: "Available",
  MAINTENANCE: "Maintenance",
  EVENT: "EVENT",
  PRIZE: "PRIZE",
  FREE: "FREE",
};

export const MACHINE_TYPE_OPTIONS = [
  { label: "2 Claws", value: "TWO_CLAWS" },
  { label: "3 Claws", value: "THREE_CLAWS" },
];

export const STATE_OPTIONS = [
  { label: "Maintenance", value: "NO_ACTIVE" },
  { label: "Available", value: "ACTIVE" },
];

export const DEDICATED_TO = {
  [STATUS.FREE]: "Free play",
  [STATUS.PRIZE]: "Get Prize",
  [STATUS.EVENT]: "Event",
};

export const DEDICATED_TO_OPTIONS = [
  { label: "Free play", value: STATUS.FREE },
  { label: "Get Prize", value: STATUS.PRIZE },
  { label: "Event", value: STATUS.EVENT },
];

export const STATUS_GAME_PLAY = [
  { label: "Active", value: STATUS.ACTIVE },
  { label: "No Active", value: STATUS.NO_ACTIVE },
];

export const CREATE_GAME_PLAY = {
  "GAMEPLAY_TYPE_IS_BEING_USED": "Game play type is being used",
  "NAME_IS_EXIST": "Name is exist",
  "TRANSLATION_NAME_ALREADY_EXISTS": "Translation name already exists"
}
