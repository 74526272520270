import {
  Flex,
  FormLabel,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import ButtonType from "components/Button";
import { FormProvider, useForm } from "react-hook-form";
// import { schemaTransferItems } from "../schema";
import FormInput from "components/form/FormInput";
import { SmallCloseIcon } from "@chakra-ui/icons";
import { transferResource } from "api/userManagement";

export default function ModalTransferCoin({
  isOpen,
  onClose,
  filterChecked,
  checkedIds,
  setCheckedIds,
  handleCreateOrUpdateSuccess,
  userDetail,
}) {
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");
  const form = useForm({
    defaultValues: {
      orbs: 1,
      b_coin: 1,
      spheres: 1,
      ap: 1,
      cp: 1,
    }
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = form;

  const onSubmit = async (data) => {
    const listIds = checkedIds?.map((check) => check?.id)
    const dataSubmit = {
        user_ids: userDetail?.id ? [userDetail?.id] : listIds,
        orb: data?.orbs || 0,
        bcoin: data?.b_coin || 0,
        sphere: data?.spheres || 0,
        ap: data?.ap || 0,
        cp: data?.cp || 0,
      };
    try {
      const res = await transferResource(dataSubmit);
      if (res?.data?.success) {
        toast({
          title: "Transfer Successfully!",
          description: "Transfer Successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setCheckedIds([])
        handleCreateOrUpdateSuccess()
        onClose()
      } else {
        toast({
          description: res?.data?.message || res?.data?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Text fontSize={16} fontWeight={600}>Receiver(s)</Text>
          {checkedIds?.length > 0 && (
            <Flex gap="4px" flexWrap="wrap" mt={4} pb={4} borderBottom="1px solid #eee">
              {checkedIds?.map((check, index) => (
                <Flex
                  key={index}
                  padding="4px 8px"
                  borderRadius="6px"
                  background="#c0c0c0"
                  alignItems="center"
                  gap="4px"
                >
                  <Text fontSize="14px" fontWeight="400">{check?.name}</Text>
                  <SmallCloseIcon onClick={() => filterChecked(check)}/>
                </Flex>
              ))}
            </Flex>
          )}
          {userDetail && (
            <Flex
              padding="4px 8px"
              borderRadius="6px"
              background="#c0c0c0"
              alignItems="center"
              gap="4px"
              width="max-content"
              mt={4}
            >
              <Text fontSize="14px" fontWeight="400">{userDetail?.name}</Text>
            </Flex>
          )}
          <Text fontSize={16} fontWeight={600} my={4}>Indicate the number of items</Text>
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <FormInput
              label="Number of B-Coins"
              name="b_coin"
              type="number"
              placeholder="Enter number"
              onBlur={(e) => {
                setValue("b_coin", Number(e?.target?.value) >= 0 ? e?.target?.value : 0)
              }}
            />
            <FormInput
              label="Number of Spheres"
              name="spheres"
              type="number"
              placeholder="Enter number"
              onBlur={(e) => {
                setValue("spheres", Number(e?.target?.value) >= 0 ? e?.target?.value : 0)
              }}
            />
            <FormInput
              label="Number of Orbs"
              name="orbs"
              type="number"
              placeholder="Enter number"
              onBlur={(e) => {
                setValue("orbs", Number(e?.target?.value) >= 0 ? e?.target?.value : 0)
              }}
            />
            <FormInput
              label="Number of Ap"
              name="ap"
              type="number"
              placeholder="Enter number"
              onBlur={(e) => {
                setValue("ap", Number(e?.target?.value) >= 0 ? e?.target?.value : 0)
              }}
            />
            <FormInput
              label="Number of Cp"
              name="cp"
              type="number"
              placeholder="Enter number"
              onBlur={(e) => {
                setValue("cp", Number(e?.target?.value) >= 0 ? e?.target?.value : 0)
              }}
            />
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="800px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          Transfer Items
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
            onClick={() => {
              handleSubmit(onSubmit);
            }}
          >
            SEND
          </ButtonType>
        </ModalFooter>
        {/* <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
            onClick={() => {
              handleSubmit(onSubmit);
            }}
          >
            SEND
          </ButtonType>
        </ModalFooter> */}
      </ModalContent>
    </Modal>
  );
}
