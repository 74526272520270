import React, { useEffect, useState } from "react";
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useColorModeValue,
} from "@chakra-ui/react";
import FilterAccordion from "../panel/FilterAccordion";
import { nftsSelector } from "stores/nfts/selector";
import { useSelector, useDispatch } from "react-redux";
import { setIndexTabFilter } from "stores/nfts/nftsReducer";
import { getGenesisPackageList } from "api/genesisNFTs.api";
import {
  DEFAULT_FILTER_PACKAGE_GENESIS_PARAMS,
  NFT_MANAGEMENT_TYPE,
  DEFAULT_FILTER_GENESIS_PARAMS,
  DEFAULT_FILTER_PARAMS,
} from "views/NFTsManagement/constants";

const FilterTabs = ({ dataTabs, nftMngType, onUpdateFilterParams }) => {
  const dispatch = useDispatch();
  const { indexTabFilter } = useSelector(nftsSelector);

  const [packageOptions, setPackageOptions] = useState([]);

  const tabBg = useColorModeValue("gray.50", "gray.800");
  const activeTabBg = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const activeTabColor = useColorModeValue("blue.600", "blue.300");

  const getGenesisPackages = async () => {
    const { data } = await getGenesisPackageList(
      DEFAULT_FILTER_PACKAGE_GENESIS_PARAMS
    );
    const options = data?.data?.records.map((record) => ({
      label: record?.name,
      value: record?.id,
    }));
    setPackageOptions([{ label: "All", value: "" }, ...options]);
  };

  useEffect(() => {
    if (nftMngType === NFT_MANAGEMENT_TYPE.GENESIS) {
      getGenesisPackages();
    }
  }, [nftMngType]);

  const handleTabChange = (index) => {
    dispatch(setIndexTabFilter(dataTabs[index].value));
    onUpdateFilterParams(
      nftMngType === NFT_MANAGEMENT_TYPE.GENESIS
        ? DEFAULT_FILTER_GENESIS_PARAMS
        : DEFAULT_FILTER_PARAMS
    );
  };

  return (
    <Tabs
      onChange={handleTabChange}
      index={dataTabs.findIndex((tab) => tab.value === indexTabFilter)}
      variant="enclosed"
      paddingBlock={"24px"}
    >
      <TabList borderBottom={`1px solid ${borderColor}`}>
        {dataTabs.map((tab) => (
          <Tab
            key={tab.value}
            fontWeight="semibold"
            _selected={{
              color: activeTabColor,
              bg: activeTabBg,
              borderBottom: "3px solid",
              borderBottomColor: activeTabColor,
              marginBottom: "-1px",
            }}
            _hover={{
              color: activeTabColor,
            }}
            bg={tabBg}
            borderTopRadius="md"
            mr={1}
            py={3}
            px={5}
          >
            {tab.name}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {dataTabs.map((tab) => (
          <TabPanel key={tab.value} isLazy lazyBehavior="unmount">
            <FilterAccordion
              nftMngType={nftMngType}
              packageOptions={packageOptions}
              onUpdateFilterParams={onUpdateFilterParams}
              key={tab?.value}
            />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default FilterTabs;
