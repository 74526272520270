import { containSpecialUserNameRegex, containSpecialUserNameJapanRegex } from "utils/regex";
import * as yup from "yup";

const schemaCreateGacha = () =>
  yup.object().shape({
    gacha_name: yup
      .string()
      .trim()
      .matches(containSpecialUserNameRegex, "Name should not contain special characters")
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    gacha_name_jp: yup
      .string()
      .trim()
      .required("Required")
      .matches(containSpecialUserNameJapanRegex, "Name should not contain special characters")
      .max(255, "Maximum limit of 255 characters."),
    description: yup
      .string()
      .trim()
      .required("Required")
      .max(2000, "Maximum limit of 2000 characters."),
    description_jp: yup
      .string()
      .trim()
      .required("Required")
      .max(2000, "Maximum limit of 2000 characters."),
    banner_image_url: yup
      .string()
      .trim()
      .required("Required"),
    slide_image_url: yup
      .string()
      .trim()
      .required("Required"),
    ceiling_value: yup
      .string()
      .required("Required"),
    free_orb_cost1: yup
      .string()
      .required("Required"),
    free_orb_limit_per_date1: yup
      .string()
      .required("Required"),
    free_orb_cost10: yup
      .string()
      .required("Required"),
    free_orb_limit_per_date10: yup
      .string()
      .required("Required"),
    paid_orb_cost1: yup
      .string()
      .required("Required"),
    paid_orb_limit_per_date1: yup
      .string()
      .required("Required"),
    paid_orb_cost10: yup
      .string()
      .required("Required"),
    paid_orb_limit_per_date10: yup
      .string()
      .required("Required"),
    rewards: yup
      .array()
      .min(1, 'Required'),
    start_time: yup
      .date()
      .required(),
    end_time: yup
      .date()
      .test(
        "is-greater-start_time",
        "Event end time must be after event start time",
        function (value) {
          const { start_time } = this.parent;
          return (
            start_time &&
            value &&
            new Date(value) > new Date(start_time)
          );
        }
      )
      .required(),
  });

export default schemaCreateGacha;
