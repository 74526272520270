import { Box, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'
import multipleUser from 'assets/img/multiple-user.png'
import EditIcon from 'assets/svg/edit.svg'
import PrizeIcon from 'assets/svg/prize-game-mode.svg'
import machineIcon from 'assets/svg/machine.svg'

export default function DetailEvent(props) {
  const { item, toggle } = props

  return (
    <Flex 
      w="auto"
      height="auto"
      p="8px" 
      borderRadius="8px"
      direction="column"
      columnGap="4px"
      bg="linear-gradient(160deg, #69EAE3 40%, #18A69E 98.37%)"
      minWidth="150px"
    >
      <Flex direction="column" gap={2}>
        <Flex alignItems="center" justifyContent="center">
          <Box width="150px"> {/* Thiết lập độ rộng cho Box */}
            <Text
              fontSize={{
                base: "16px",
              }}
              textAlign="center"
              fontWeight="700"
              isTruncated
            >
              {item?.name}
            </Text>
          </Box>
        </Flex>
        <Flex alignItems="center" justifyContent="center" gap={10}>
          <Flex flexDirection="column" alignItems="center">
            <Image
              src={multipleUser}
              w="28px"
              h="28px"
              alt={item?.id}
            />
            <Text fontSize="16px" fontWeight="700">{item?.waiting_players}</Text>
          </Flex>
        </Flex>
        <Flex
          p="2px 6px"
          w="100%"
          borderRadius="6px"
          textAlign="start"
          backgroundColor="#f82e38"
          color="#FFF"
          fontSize="14px"
          fontWeight="500"
          direction="column"
        >
          <Text>Lv. : 100-200</Text>
          <Text>Skill rank : E-A</Text>
          <Text>Arena Hunter: 200-400</Text>
        </Flex>
        <Box
          p="2px 6px"
          w="100%"
          borderRadius="6px"
          textAlign="center"
          backgroundColor="rgba(0, 0, 0, 0.4)"
          color="#FFF"
        >
          3 turns
        </Box>
        <Flex direction="row" gap="4px" borderRadius="6px">
          <Flex p="6px" background="#fff" borderRadius="6px">
            <Text fontSize="14px" fontWeight="500" color="#D32C35">150k BC</Text>
            <Text fontSize="14px" fontWeight="500"> /turn</Text>
          </Flex>
          <Flex p="6px" background="#fff" borderRadius="6px">
            <Text fontSize="14px" fontWeight="500" color="#D32C35">1 LG</Text>
            <Text fontSize="14px" fontWeight="500"> /turn</Text>
          </Flex>
        </Flex>
        <Flex alignItems="center" justifyContent="flex-end" gap="6px">
          <Flex onClick={() => toggle('listMachines', item)} w="30px" h="30px" borderRadius="8px" background="#000" alignItems="center" justifyContent="center" cursor="pointer">
            <Image src={machineIcon} alignSelf='center' justifySelf='center' />
          </Flex>
          <Flex onClick={() => toggle('listPrize', item)} w="30px" h="30px" borderRadius="8px" background="#000" alignItems="center" justifyContent="center" cursor="pointer">
            <Image src={PrizeIcon} alignSelf='center' justifySelf='center' />
          </Flex>
          <Flex w="30px" h="30px" borderRadius="8px" background="#000" alignItems="center" justifyContent="center" cursor="pointer">
            <Image src={EditIcon} alignSelf='center' justifySelf='center' />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
