import { containSpecialRegex } from "utils/regex";
import * as yup from 'yup'

const schemaNotice = () =>
  yup.object().shape({
    header_en: yup
      .string()
      .trim()
      .matches(containSpecialRegex, "Header should not contain special characters")
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    header_jp: yup
      .string()
      .trim()
      .matches(containSpecialRegex, "Header should not contain special characters")
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    type: yup
      .array()
      .of(yup.string())
      .min(1, 'Required'),
    restricted_countries: yup
      .array()
      .of(yup.string())
      .min(1, 'Required'),
    publish_at: yup
      .date()
      .required("Required")
      .test(
        "is-future-minute",
        "Public date must be in the future",
        function (value) {
          const now = new Date();
          now.setSeconds(0, 0);
          const inputTime = new Date(value);
          inputTime.setSeconds(0, 0);
          return inputTime.getTime() >= now.getTime();
        }
      ),
    banner_url_en: yup
      .string()
      .trim()
      .required("Required")
      .test(
        'is-valid-image',
        'The image must have a 6:1 aspect ratio and dimensions of 300x50.',
        async function (value) {
          if (!value) return false;
          try {
            const image = new Image();
            image.src = value;
            await new Promise((resolve, reject) => {
              image.onload = () => {
                const { width, height } = image;
                if (width / height === 6 && width === 300 && height === 50) {
                  resolve(true);
                } else {
                  reject(new Error('Invalid dimensions'));
                }
              };
              image.onerror = reject;
            });

            return true;
          } catch (err) {
            return false;
          }
        }
      ),
    banner_url_jp: yup
      .string()
      .trim()
      .required("Required")
      .test(
        'is-valid-image-jp',
        'The image must have a 6:1 aspect ratio and dimensions of 300x50.',
        async function (value) {
          if (!value) return false;
          try {
            const image = new Image();
            image.src = value;
            await new Promise((resolve, reject) => {
              image.onload = () => {
                const { width, height } = image;
                if (width / height === 6 && width === 300 && height === 50) {
                  resolve(true);
                } else {
                  reject(new Error('Invalid dimensions'));
                }
              };
              image.onerror = reject;
            });

            return true;
          } catch (err) {
            return false;
          }
        }
      ),
  });

export default schemaNotice
