import { convertParams, omitParams } from "utils/object";
import END_POINT from "./constants";
import http from "./http";

export function getPrize(params) {
  return http.get(`${END_POINT.GET_PRIZE}?${params}`);
}

export function getPrizeRarity() {
  return http.get(`${END_POINT.GET_PRIZE_RARITY}`);
}

export function getPrizeTraits() {
  return http.get(`${END_POINT.GET_PRIZE_TRAITS}`);
}

export function getPrizeGameMode(params) {
  return http.get(`${END_POINT.GET_PRIZE_GAME_MODE}?${params}`);
}

export function postPrize(data) {
  return http.post(END_POINT.POST_PRIZE, data);
}

export function getPrizeById(id) {
  return http.get(`${END_POINT.GET_PRIZE_BY_ID}/${id}`);
}

export function putPrize(id, data) {
  return http.put(`${END_POINT.PUT_PRIZE_BY_ID}/${id}`, data);
}

export function deletePrize(id) {
  return http.delete(`${END_POINT.DELETE_PRIZE_BY_ID}/${id}`);
}

export function deletePrizeAll(listId) {
  return http.delete(`${END_POINT.DELETE_PRIZE_BY_ID}`, listId);
}

export function typePrize() {
  return http.get(`${END_POINT.TYPES_PRIZE}`);
}

export function modeTypePrize() {
  return http.get(`${END_POINT.MODE_TYPES_PRIZE}`);
}

export function feePrize(type) {
  return http.get(`${END_POINT.PLAY_FEES}?payment_type=${type}&status=ACTIVE`);
}

export function getPrizeBountyBall(p) {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.SELECT_PRIZE_BOUNTY_BALL}?${params}`);
}

export function getPrizeGauntlet(p) {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.SELECT_PRIZE_GAUNTLET}?${params}`);
}

export function getPrizeTreasureBox(p) {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.TREASURE_BOX}?${params}`);
}

export function postUploadImage(data) {
  return http.post(`${END_POINT.POST_UPLOAD_IMAGE_PRIZE}`, data, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
}
