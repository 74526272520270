import { useCallback, useEffect, useState } from "react";
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import ItemsSendRow from "./row";
import ConditionLeaderBoard from "./action";
import ButtonType from "components/Button";
import CardHeader from "components/Card/CardHeader";
import { getListRewardContainer } from "api/gacha.api";
// import NFTDetailModal from "./NFTDetailModal";

export default function ModalSelectPrize({
  isOpen,
  onClose,
  setCheckedIds,
  checkedIds,
  setValue,
  watch,
}) {
  const labels = [
    "Id",
    "Name",
    "Chain",
    "SMALL REWARD CONTAINERS",
    "Weight",
    "",
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [nftDetail, setNftDetail] = useState()
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    from_display: "GACHA",
  });
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");

  const {
    isOpen: isOpenDetailItem,
    onOpen: onOpenDetailUser,
    onClose: onCloseDetailItem,
  } = useDisclosure();

  const handleOpenDetailUserModal = async (item) => {
    setNftDetail(item)
    onOpenDetailUser();
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };
  
  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const getEvent = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const data = await getListRewardContainer({
					...params,
					...newParams,
				});
        if (data?.data?.success) {
          const res = data?.data;
          setItems(res?.data?.records);
          setTotalPage(res?.data?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  useEffect(() => {
    getEvent();
  }, [getEvent]);

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      const itemsReward = items?.map((item) => ({
        ...item,
        grand_prize: true,
      }))
      setCheckedIds(itemsReward);
    } else {
      setCheckedIds([]);
    }
  };

  const handleClose = () => {
    setItems([])
    setCheckedIds([])
    setValue("rewards", [])
    onClose()
  }

  const handleSelect = () => {
    onClose()
    setValue("rewards", checkedIds)
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} closeOnOverlayClick={false} size="xxl">
      <ModalOverlay />
      <ModalContent maxW="900px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          Select large reward container(s)
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">
          <Flex flexDirection="column">
            <Card px="0px">
              <CardHeader display="flex" flexDirection="column" gap="12px" mb="24px">
                <ConditionLeaderBoard
                  isLoading={isLoading}
                  setParams={setParams}
                  params={params}
                />
              </CardHeader>
              <CardBody overflowX="auto">
                <TableCustom
                  labels={labels}
                  isLoading={isLoading}
                  dataRow={items}
                  isCheck
                  isChecked={items?.length > 0 && checkedIds.length === items?.length}
                  onChangeChecked={onChangeChecked}
                >
                  <>
                    {items?.map((item) => {
                      return (
                        <ItemsSendRow
                          key={item?.id}
                          machine={item}
                          setCheckedIds={setCheckedIds}
                          checkedIds={checkedIds}
                          watch={watch}
                          handleOpenDetailUserModal={handleOpenDetailUserModal}
                        />
                      );
                    })}
                  </>
                </TableCustom>
              </CardBody>
              <Paginate
                page={params?.page}
                pageCount={totalPage}
                limit={params.limit}
                onChangeLimit={onChangeLimit}
                handleChangePage={handleChangePage}
              />
            </Card>
          </Flex>
        </ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            m="auto"
            w="160px"
            h="46px"
            borderRadius="5px"
            disabled={!checkedIds?.length}
            onClick={handleSelect}
          >
            Select
          </ButtonType>
        </ModalFooter>
      </ModalContent>
      {/* {isOpenDetailItem && (
        <NFTDetailModal
          nftMngType={nftMngType}
          item={nftDetail}
          isOpen={isOpenDetailItem}
          onClose={onCloseDetailItem}
          chain={watch("chain")}
        />
      )} */}
    </Modal>
  );
}
