import React from "react";
import { Flex, Text, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import CardHeader from "components/Card/CardHeader";
import ButtonType from "components/Button";
import ManagePackageGenesisModal from "../modal/ManagePackageGenesisModal";
import PackageOrderHistoryModal from "../modal/PackageOrderHistoryModal";
import { NFT_MANAGEMENT_TYPE } from "views/NFTsManagement/constants";
import { useHistory } from "react-router-dom";

const NFTsCardHeader = ({ title, nftMngType }) => {
  const history = useHistory();
  const textColor = useColorModeValue("#000000", "white");
  const {
    isOpen: isOpenPackageOrderHistory,
    onOpen: onOpenPackageOrderHistory,
    onClose: onClosePackageOrderHistory,
  } = useDisclosure();

  const {
    isOpen: isOpenManagePackage,
    onOpen: onOpenManagePackage,
    onClose: onCloseManagePackage,
  } = useDisclosure();

  const onManagePackage = () => {
    onOpenManagePackage(true);
  };

  const onOpenMonthlyPayout = () => {
    history.push('/admin/nfts/owner-nfts-management/monthly-payout-settings')
  }

  const handleManageSuccess = () => {
    console.log("Manage success");
  };

  return (
    <>
      <CardHeader>
        <Flex
          w="100%"
          gap={4}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb="20px"
        >
          <Text color={textColor} fontSize="lg" fontWeight="bold">
            {title}
          </Text>
          {nftMngType === NFT_MANAGEMENT_TYPE.GENESIS && (
            <Flex gap={4}>
              <ButtonType
                text="Package Order History"
                fontSize="12px"
                sizeIcon="16px"
                onClick={onOpenPackageOrderHistory}
              />
              <ButtonType
                text="Manage Package"
                fontSize="12px"
                sizeIcon="16px"
                onClick={onManagePackage}
              />
            </Flex>
          )}
          {nftMngType === NFT_MANAGEMENT_TYPE.OWNER && (
            <ButtonType
              text="Monthly Payout Settings"
              fontSize="16px"
              fontWeight="500"
              onClick={onOpenMonthlyPayout}
            />
          )}
        </Flex>
      </CardHeader>
      {isOpenManagePackage && (
        <ManagePackageGenesisModal
          isOpen={isOpenManagePackage}
          onClose={onCloseManagePackage}
          onManageSuccess={handleManageSuccess}
        />
      )}
      {isOpenPackageOrderHistory && (
        <PackageOrderHistoryModal
          isOpen={isOpenPackageOrderHistory}
          onClose={onClosePackageOrderHistory}
        />
      )}
    </>
  );
};

export default NFTsCardHeader;
