import {
  Stack,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import { useMemo } from "react";
import {
  DIRECTION,
  STATE_SHOW,
} from "views/Machine/MachineSettings/constants";

const RowNftGacha = ({
  machine,
  checkedIds,
  setCheckedIds,
}) => {
  const textColor = useColorModeValue("#000000", "white");
  const isMachineChecked = useMemo(() => {
    return checkedIds.some((item) => item?.id === machine?.id);
  }, [machine?.id, checkedIds]);
  
  const handleCheckboxChange = () => {
    if (isMachineChecked) {
      setCheckedIds(checkedIds.filter((item) => item?.id !== machine?.id));
    } else {
      setCheckedIds([...checkedIds, {
        ...machine,
        related_type: "NON_NFT"
      }]);
    }
  };

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isMachineChecked}
          onChange={() => handleCheckboxChange()}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.id}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.name}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400} >
            {machine?.type}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400} >
            {machine?.value}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400} >
            {machine?.count}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400} >
            {machine?.weight}
          </Text>
        </Stack>
      </Td>
    </Tr>
  );
};

export default RowNftGacha;
