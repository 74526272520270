import {
  Box,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "components/form/FormInput";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import schemaMachine from "./schema";
import ButtonType from "components/Button";
import FormSelect from "components/form/FormSelect";
import { LIST_CHAIN_TESTNET } from "views/Users/UserManagement/constant";
import TableCustom from "components/Tables/CustomTable";
import RowRewardItems from "./row";
import ModalSelectReward from "./SelectReward";
import { createRewardNftGacha, getDetailRewardNftGacha, updateDetailRewardNftGacha } from "api/gacha.api";
import { LIST_ERROR_GACHA } from "views/GachaManagement/constants";

const TRANS_LABEL = {
	en: {
		name: "Container name",
		enterName: "Enter new gacha name",
		chain: "Chain",
		addReward: "Add reward items for the container",
    enterAddReward: "Add items",
    weight: "Container weight",
    enterWeight: "Enter the weight value",
	},
	jp: {
		name: "コンテナ名",
		enterName: "新しいガチャ名を入力",
		chain: "鎖",
		addReward: "コンテナの報酬アイテムを追加",
    enterAddReward: "アイテムを追加",
    weight: "コンテナの重量",
    enterWeight: "重量値を入力してください",
	},
};

const labels = [
  "Id",
  "Name",
  "Type",
  "Count",
  "Weight",
  "",
];

export default function CreateAndUpdateLargeReward({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
  listType,
}) {
  const [tabIndex, setTabIndex] = useState(0);
  const [trans, setTrans] = useState("en");
  const [checkedIds, setCheckedIds] = useState([]);
  const [rewardContainer, setRewardContainer] = useState(dataDetail)

  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const form = useForm({
    resolver: yupResolver(schemaMachine()),
  });
  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = form;

  const { 
    isOpen: openPrizes, 
    onOpen: onOpenPrizes, 
    onClose: onClosePrizes 
  } = useDisclosure();

  const disableWhenJP = useMemo(() => {
    return trans === "jp";
  }, [trans]);

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  const getDetailRewardContainer = async () => {
    try {
      const { data } = await getDetailRewardNftGacha(dataDetail?.id);
      setRewardContainer(data?.data)
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  }

  useEffect(() => {
    if (dataDetail) {
      getDetailRewardContainer(dataDetail?.id)
    } else {
      reset();
    }
  }, [dataDetail]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
    if (index === 1) {
      setTrans("jp");
    } else {
      setTrans("en");
    }
  };

  const transLabel = (label) => {
    return TRANS_LABEL[trans][label];
  };
  const onSubmit = async (data, e) => {
    const dataSubmit = {
      chain_id: data?.chain_id,
      name: data?.name,
      weight: data?.weight,
      gacha_items: checkedIds?.map((check) => ({
        ...check,
        id: check?.id,
        count: check?.count,
        weight: check?.weight,
        related_type: check?.related_type
      })),
      translations: [
        {
          lang_key: "EN",
          name: data?.name,
        },
        {
          lang_key: "JP",
          name: data?.name_jp,
        }
      ],
    }
    try {
      if (idEdit) {
        const res = await updateDetailRewardNftGacha(idEdit, dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Edited Large Reward Container successfully!.",
            description: "Edited Large Reward Container successfully!.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose()
        } else {
          toast({
            description: LIST_ERROR_GACHA[res?.data?.message] || LIST_ERROR_GACHA[res?.data?.messages[0]] || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      } else {
        const res = await createRewardNftGacha(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Create New Large Reward Container successfully!.",
            description: "Create New Large Reward Container successfully!.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose()
        } else {
          toast({
            description: LIST_ERROR_GACHA[res?.data?.message] || LIST_ERROR_GACHA[res?.data?.messages[0]] || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    if (rewardContainer?.id) {
      const itemJP = rewardContainer?.translations?.find(
        (item) => item?.lang_key === "JP"
      );
      const itemEN = rewardContainer?.translations?.find(
        (item) => item?.lang_key === "EN"
      );
      reset({
        name: rewardContainer?.name,
        name_jp: itemJP?.name,
        chain_id: rewardContainer?.chain_id,
        weight: rewardContainer?.weight,
        rewards: rewardContainer?.gacha_items,
      });
      setCheckedIds(rewardContainer?.gacha_items)
    } else {
      reset({
        chain_id: LIST_CHAIN_TESTNET[0]?.value,
        rewards: [],
      });
    }
  }, [reset, rewardContainer]);

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            mb={4}
            index={tabIndex}
            variant="unstyled"
            onChange={handleTabsChange}
          >
            <TabList gap={2}>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                English
              </Tab>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                Japanese
              </Tab>
            </TabList>
          </Tabs>
          <Flex direction="column" w="100%" gap={4}>
            <Grid templateColumns="repeat(1, 1fr)" gap={4}>
              <Flex direction="column" gap={4} overflow="auto">
                {trans === "en" ? (
                  <Box display="flex" flexDirection="column" gap="8px">
                    <FormInput
                      maxW="100%"
                      name="name"
                      label={transLabel("name")}
                      placeholder={transLabel("enterName")}
                      isRequired
                    />
                  </Box>
                ) : (
                  <Flex flexDirection="column" gap="8px">
                    <FormInput
                      maxW="100%"
                      name="name_jp"
                      label={transLabel("name")}
                      placeholder={transLabel("enterName")}
                      isRequired
                    />
                  </Flex>
                )}
                <>
                  <FormSelect
                    name="chain_id"
                    label={transLabel("chain")}
                    options={LIST_CHAIN_TESTNET}
                    onChange={(e) => {
                      setValue('chain_id', e.target.value)
                      setValue('rewards', [])
                      setCheckedIds([])
                    }}
                    disabled={disableWhenJP}
                  />
                  <FormInput
                    type="text"
                    label={transLabel("addReward")}
                    name="rewards"
                    placeholder="Select prize(s) for game mode"
                    onClick={() => onOpenPrizes()}
                    disabled={disableWhenJP}
                    value=""
                    autoComplete="off"
                    isRequired
                  />
                  <TableCustom
                    labels={labels}
                    dataRow={checkedIds}
                    isCheck={false}
                  >
                    <>
                      {checkedIds?.map((item) => {
                        return (
                          <RowRewardItems
                            key={item?.id}
                            machine={item}
                            checkedIds={checkedIds}
                            setCheckedIds={setCheckedIds}
                            setValue={setValue}
                          />
                        );
                      })}
                    </>
                  </TableCustom>
                  <FormInput
                    wrapperProps={{
                      w: "100%",
                    }}
                    type="number"
                    name="weight"
                    placeholder={transLabel("enterWeight")}
                    label={transLabel("weight")}
                    isRequired
                    disabled={disableWhenJP}
                  />
                </>
              </Flex>
            </Grid>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="700px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "Edit Large Reward Container" : "New Large Reward Container"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
      {openPrizes && (
        <ModalSelectReward
          isOpen={openPrizes}
          onClose={onClosePrizes}
          setCheckedIds={setCheckedIds}
          checkedIds={checkedIds}
          watch={watch}
          idModeDetail={idEdit ? idEdit : ""}
          setValue={setValue}
          listType={listType}
        />
      )}
    </Modal>
  );
}
