import {
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import BackIcon from "assets/svg/back-icon.svg";
import ButtonType from "components/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import FormInput from "components/form/FormInput";
import { CheckIcon } from "components/Icons/CheckIcon";
import { EditIcon } from "components/Icons/EditIcon";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { getOnlyDecimal, onlyNumber } from "utils/number";
import MonthlyPayoutRow from "views/NFTsManagement/components/tableRow/MonthlyPayoutRow";
import { MONTHLY_PAYOUT_SETTINGS } from "../owner-nfts-dummy";

const labels = ["MONTH", "PINK NFT", "GREEN NFT", "PURPLE NFT", ""];

export default function MonthlyPayoutSettingsPage() {
  const history = useHistory();
  const toast = useToast();
  const form = useForm();
  const textColor = useColorModeValue("#000000", "white");
  const [isLoading, setIsLoading] = useState(false);
  const [idRowEdit, setIdRowEdit] = useState(null);
  const [isEditNumOfMachine, setIsEditNumOfMachine] = useState(false);
  const [monthlyPayout, setMonthlyPayout] = useState(MONTHLY_PAYOUT_SETTINGS.data.records);

  const [params, setParams] = useState({
    total: 10,
    limit: 5,
    total_page: 2,
    page: 1,
  });

  const onBackToOwnerNftList = () => {
    history.push("/admin/nfts/owner-nfts-management");
  };

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  const handleChangeNumOfMachine = () => {
    try {
      const numOfMachine = onlyNumber(form?.getValues()?.machine);
      if (numOfMachine) {
        const res = {};
        setIsEditNumOfMachine(false);
        toast({
          description: res?.data?.message || res?.message,
          status: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleUpdateValueNft = (newData, prevData) => {
    try {
      const isChanged = JSON.stringify(newData) !== JSON.stringify(prevData);
      if (isChanged) {
        const res = {};
        setIdRowEdit(null);
        toast({
          description: res?.data?.message || res?.message,
          status: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="24px">
        <CardHeader>
          <Flex
            w="100%"
            justifyContent="space-between"
            alignItems="center"
            mb="20px"
          >
            <Flex gap={2}>
              <Image
                src={BackIcon}
                w="25px"
                h="25px"
                alt="back"
                cursor="pointer"
                onClick={onBackToOwnerNftList}
              />
              <Text color={textColor} fontSize="lg" fontWeight="bold">
                Monthly Payout Settings
              </Text>
            </Flex>
            <ButtonType
              text="Import CSV"
              fontSize="16px"
              fontWeight="500"
              // onClick={onOpenMonthlyPayout}
            />
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto" flexDirection="column" gap={10} mt={4}>
          <FormProvider {...form}>
            <form>
              <Flex alignItems={"flex-end"} gap={2}>
                <FormInput
                  w="300px"
                  name="machine"
                  label="Number of machine(s)"
                  defaultValue={20}
                  disabled={!isEditNumOfMachine}
                  onChange={(e) => {
                    form.setValue("machine", getOnlyDecimal(e.target.value));
                  }}
                />
                {isEditNumOfMachine ? (
                  <Icon
                    mb={1}
                    fontSize="30px"
                    borderRadius="8px"
                    cursor="pointer"
                    bg="#07CF84"
                    as={CheckIcon}
                    onClick={handleChangeNumOfMachine}
                  />
                ) : (
                  <Icon
                    mb={1}
                    fontSize="30px"
                    borderRadius="8px"
                    p="7px"
                    cursor="pointer"
                    bg="#1480FF"
                    as={EditIcon}
                    onClick={() => setIsEditNumOfMachine(true)}
                  />
                )}
              </Flex>
            </form>
          </FormProvider>

          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck={false}
            dataRow={monthlyPayout}
          >
            <>
              {monthlyPayout?.map((item) => {
                return (
                  <MonthlyPayoutRow
                    key={item?.id}
                    isEditMode={idRowEdit && idRowEdit === item.id}
                    handleOpenUpdateMode={() => setIdRowEdit(item.id)}
                    handleUpdateMonthlyPayout={(dataEdit) =>
                      handleUpdateValueNft(dataEdit, item)
                    }
                    data={item}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={params?.total_page}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </Card>
    </Flex>
  );
}
