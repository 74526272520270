import { Icon } from "@chakra-ui/icons";
import {
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import moment from "moment";
import { ViewIcon } from "components/Icons/ViewIcon";

export default function LeaderBoardRow({
  item,
}) {
  const textColor = useColorModeValue("#000000", "white");

  const convertTime = (time) => {
    return moment(time * 1000).utc().format("DD MMM. YYYY HH:mm:ss");
  };

  return (
    <Tr>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.username}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.current_free_ticket}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.current_bcoin1}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.current_bcoin1}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.current_bcoin1}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.current_bcoin1}
        </Text>
      </Td>
      <Td>
        <Icon
          fontSize="30px"
          borderRadius="8px"
          cursor="pointer"
          as={ViewIcon}
        />
      </Td>
    </Tr>
  );
}
