import {
  Flex,
  Icon,
  Image,
  Stack,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { DeleteIcon } from "components/Icons/DeleteIcon";
import { TOKEN_TYPE_LABEL } from "constants/constants";

const RowRewardItems = ({
  machine,
  setCheckedIds,
  checkedIds,
  setValue
}) => {
  const textColor = useColorModeValue("#000000", "white");

  const handleDelete = (item) => {
    const dataFilter = checkedIds?.filter((check) => check?.id !== item?.id)
    setCheckedIds(dataFilter)
    setValue("gacha_items", dataFilter)
  }

  return (
    <Tr>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.id}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.name}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {TOKEN_TYPE_LABEL[machine?.token_type] || TOKEN_TYPE_LABEL[machine?.related_type]}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.count}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.weight}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Flex gap={6} align="center">
          <Icon
            onClick={() => handleDelete(machine)}
            fontSize="30px"
            borderRadius="8px"
            cursor="pointer"
            as={DeleteIcon}
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default RowRewardItems;
