import {
  Flex,
  FormLabel,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createMachineSetting,
  getListCraneMachinesUnUsed,
  updateMachineSetting,
  getMachineNumber,
} from "api/machine.api";
import ButtonType from "components/Button";
import FormSelect from "components/form/FormSelect";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MACHINE_TYPE_OPTIONS, STATE, DIRECTION_RADIO, MODE_TYPE_SKILL, ERROR_MACHINE_SETTINGS } from "../constants";
import { schemaMachine } from "./schema";
import { useTranslation } from "react-i18next";
import { NUMBER } from "constants/enum";
import FormUpload from "components/form/FormUpload";
import FormInput from "components/form/FormInput";
import { uploadImageToServer } from "utils/uploadImage";
export default function MachineCreateAndUpdate({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
  listGamePlayType,
  // gamesModeOptions,
  listGroupLabel
}) {
  const { t } = useTranslation("prize");
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");
  const [machinesOptions, setMachinesOptions] = useState([]);
  const [listMachineNumber, setListMachineNumber] = useState([]);
  const [nameImage, setNameImage] = useState("");
  const form = useForm({
    resolver: yupResolver(schemaMachine()),
    defaultValues: {
      machine_id: dataDetail ? dataDetail?.crane_machine_id : null,
      machine_type: dataDetail ? dataDetail?.machine_type : MACHINE_TYPE_OPTIONS[0].value,
      mode_type: dataDetail ? dataDetail?.mode_type : MODE_TYPE_SKILL[0].value,
      gameplay_type_id: dataDetail ? dataDetail?.gameplay_type_id : listGamePlayType[0]?.value,
      grasp_power: dataDetail ? dataDetail?.machine_base_settings[0]?.current_param || 1 : 1,
      top_power: dataDetail ? dataDetail?.machine_base_settings[1]?.current_param || 1 : 1,
      move_power: dataDetail ? dataDetail?.machine_base_settings[2]?.current_param || 1 : 1,
      max_power: dataDetail ? dataDetail?.machine_base_settings[3]?.current_param || 1 : 1,
      top_height: dataDetail ? dataDetail?.machine_base_settings[4]?.current_param || 1 : 1,
      line_length: dataDetail ? dataDetail?.machine_base_settings[5]?.current_param || 10 : 10,
      front_back_motor_speed: dataDetail ? dataDetail?.machine_base_settings[6]?.current_param || 1 : 1,
      left_right_motor_speed: dataDetail ? dataDetail?.machine_base_settings[7]?.current_param || 1 : 1,
      up_down_motor_speed: dataDetail ? dataDetail?.machine_base_settings[8]?.current_param || 1 : 1,
      machine_group: dataDetail ? dataDetail?.group_label : null,
      machine_number: dataDetail ? dataDetail?.group_number?.toString() : null,
      icon_url: dataDetail ? dataDetail?.icon_url : null,
      status: dataDetail ? dataDetail?.state : STATE[0].value,
      direction: dataDetail ? dataDetail?.crane_machine?.direction : DIRECTION_RADIO[0].value,
    }
  });

  const {
    handleSubmit,
    // reset,
    setValue,
    watch,
    formState: { isSubmitting },
  } = form;

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  const getMachinesCrane = async () => {
    try {
      const { data } = await getListCraneMachinesUnUsed({
        page: 1,
        limit: 999999,
      });
      if (data?.success) {
        const list = data?.data;
        if (list && list?.length > 0) {
          const listOptions = list?.map((item) => {
            return {
              label: item?.mac,
              value: item?.id,
            };
          });
          setMachinesOptions(listOptions);
          if (dataDetail?.crane_machine_id) {
            setValue("machine_id", dataDetail?.crane_machine_id);
          } else {
            setValue(
              "machine_id",
              listOptions?.length > 0 ? listOptions[0].value : null
            );
          }
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleListModeByClaw = (e) => {
    // const modesByClaw = gamesModeOptions?.filter((mode) => mode?.gameplay_type?.id === e)
    // setListModeByClaw(modesByClaw)
    if (dataDetail) {
      // setValue("game_mode_id", dataDetail?.game_mode_id);
      setValue("gameplay_type_id", e);
    } else {
      // setValue("game_mode_id", modesByClaw[0]?.value);
      setValue("gameplay_type_id", e);
    }
  }

  useEffect(() => {
    getMachinesCrane();
    if (idEdit) {
      handleMachineNumber(watch('machine_group'))
    } else {
      handleMachineNumber(listGroupLabel[0]?.value)
    }
    // handleListModeByClaw(listGamePlayType[0].value)
  }, []);

  const onSubmit = async (data) => {
    let dataSubmit
    if (data?.machine_type === "TWO_CLAWS") {
      dataSubmit = {
        crane_machine_id: data?.machine_id,
        state: data?.status,
        machine_type: data?.machine_type,
        gameplay_type_id: data?.gameplay_type_id,
        mode_type: data?.mode_type,
        position_id: "1",
        icon_url: data?.icon_url,
        group_label: data?.machine_group,
        group_number: Number(data?.machine_number),
        direction: data?.direction,
      };
    } else {
      dataSubmit = {
        crane_machine_id: data?.machine_id,
        state: data?.status,
        machine_type: data?.machine_type,
        gameplay_type_id: data?.gameplay_type_id,
        mode_type: data?.mode_type,
        position_id: "1",
        icon_url: data?.icon_url,
        group_label: data?.machine_group,
        group_number: Number(data?.machine_number),
        direction: data?.direction,
        machine_base_settings: [
          {param_name: "GRASP_POWER", current_param: data?.grasp_power},
          {param_name: "TOP_POWER", current_param: data?.top_power},
          {param_name: "MOVE_POWER", current_param: data?.move_power},
          {param_name: "MAX_POWER", current_param: data?.max_power},
          {param_name: "TOP_HEIGHT", current_param: data?.top_height},
          {param_name: "LINE_LENGTH", current_param: data?.line_length},
          {param_name: "FRONT_BACK_MOTOR_SPEED", current_param: data?.front_back_motor_speed},
          {param_name: "LEFT_RIGHT_MOTOR_SPEED", current_param: data?.left_right_motor_speed},
          {param_name: "UP_DOWN_MOTOR_SPEED", current_param: data?.up_down_motor_speed},
        ]
      };
    }
    try {
      if (idEdit) {
        const res = await updateMachineSetting(dataDetail?.id, dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Machine updated.",
            description: "Machine updated.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
        } else {
          toast({
            description: ERROR_MACHINE_SETTINGS[res?.data?.message] || ERROR_MACHINE_SETTINGS[res?.data?.messages[0]] || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      } else {
        const res = await createMachineSetting(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Machine created.",
            description: "Machine created.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
        } else {
          toast({
            description: ERROR_MACHINE_SETTINGS[res?.data?.message] || ERROR_MACHINE_SETTINGS[res?.data?.messages[0]] || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

   const handleImageChange = async (event) => {
    const image = event[0];
    await uploadImageToServer(image, toast, setValue, "icon_url", setNameImage);
  };

   const handleDragImage = async (file) => {
    if (file.length > NUMBER.ONE) {
      toast({
        description: "The number of uploaded images is greater than one.",
        status: "error",
        position: "bottom-right",
      });
      return false;
    }
    const image = file && file[0];

    await uploadImageToServer(image, toast, setValue, "icon_url", setNameImage);
  };

  const deleteImage = () => {
    setValue('icon_url', null)
  }

  const handleMachineNumber = async (id) => {
    setValue("machine_group", id)
    try {
      const { data } = await getMachineNumber(id);
      if (data?.success) {
        if (data && data?.data?.length > 0) {
          const listOptions = data?.data?.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
          setListMachineNumber(listOptions)
          if (dataDetail?.group_label === id) {
            setValue("machine_number", dataDetail?.group_number?.toString())
          } else {
            setValue("machine_number", listOptions[0]?.value)
          }
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  }

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            {idEdit ? (
              <div>
                <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                  {"Machine ID "}
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Text
                  ml={2}
                  mt={4}
                  fontSize="16px"
                  fontWeight="400"
                  color={textColor}
                >
                  {dataDetail?.crane_machine?.mac}
                </Text>
              </div>
            ) : (
              <FormSelect
                label="MAC Address"
                name="machine_id"
                isRequired
                defaultValue={
                  machinesOptions?.length > 0 ? machinesOptions[0].value : null
                }
                options={machinesOptions}
              />
            )}
            <FormSelect
              name="status"
              label="Machine State"
              defaultValue={STATE[0].value}
              isRequired
              options={STATE}
            />
            <FormSelect
              name="gameplay_type_id"
              label="Gameplay Type"
              options={listGamePlayType}
              isRequired
              onChange={(e) => handleListModeByClaw(e.target.value)}
            />
            <Flex alignItems="flex-start" gap="16px" w="100%">
              <FormSelect
                w100={true} 
                name="machine_group"
                label="Machine Group"
                defaultValue={listGroupLabel?.length > 0 ? listGroupLabel[0]?.value : null}
                options={listGroupLabel}
                isRequired
                onChange={(e) => handleMachineNumber(e?.target?.value)}
              />
              <FormSelect
                w100={true}
                name="machine_number"
                label="Machine number"
                defaultValue={
                  listMachineNumber?.length > 0 ? listMachineNumber[0].value : null
                }
                options={idEdit && (dataDetail?.group_label === watch('machine_group')) ? [{ label: dataDetail?.group_number, value: dataDetail?.group_number }, ...listMachineNumber] : listMachineNumber}
                isRequired
              />
            </Flex>
            {/* {idEdit ? (
              <div>
                <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                  {"Booth Type "}
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Text
                  ml={2}
                  mt={4}
                  fontSize="16px"
                  fontWeight="400"
                  color={textColor}
                >
                  {MACHINE_TYPE_OPTIONS?.filter((option) => option?.value === dataDetail?.machine_type)[0]?.label}
                </Text>
              </div>
            ) : ( */}
              <FormSelect
                name="machine_type"
                label="Booth Type"
                defaultValue={MACHINE_TYPE_OPTIONS[0].value}
                isRequired
                options={MACHINE_TYPE_OPTIONS}
              />
            {/* )} */}
            <FormSelect
              name="mode_type"
              label="Dedicated to"
              defaultValue={MODE_TYPE_SKILL?.[0]?.value}
              options={MODE_TYPE_SKILL}
            />
            <FormSelect
              name="direction"
              label="Direction"
              defaultValue={DIRECTION_RADIO[0]?.value}
              isRequired
              options={DIRECTION_RADIO}
            />
            <FormUpload
              label="Select image file to upload"
              title="Image"
              name="icon_url"
              type='IMAGE'
              accept={"image/*"}
              textButton={nameImage}
              isRequired
              // textDescription={t("note_upload_image")}
              handleOnChange={(e) =>
                handleImageChange(e, t("label_background"))
              }
              handleDragImage={(val) =>
                handleDragImage(val, t("label_background"))
              }
              deleteImage={deleteImage}
            />
            {watch("machine_type") === "THREE_CLAWS" && (
              <>
                <Text fontSize={18} fontWeight={600}>BASIC PARAMS</Text>
                <Flex alignItems="flex-start" gap="16px" w="100%">
                  <FormInput
                    wrapperProps={{
                      w: "100%",
                    }}
                    label="Grasp Power"
                    name="grasp_power"
                    placeholder="Enter the value between 1-48"
                    isRequired
                    type="number"
                    min={1}
                    max={48}
                  />
                  <FormInput
                    wrapperProps={{
                      w: "100%",
                    }}
                    label="Top Power"
                    name="top_power"
                    placeholder="Enter the value between 1-48"
                    isRequired
                    type="number"
                    min={1}
                    max={48}
                  />
                </Flex>
                <Flex alignItems="flex-start" gap="16px" w="100%">
                  <FormInput
                    wrapperProps={{
                      w: "100%",
                    }}
                    label="Move Power"
                    name="move_power"
                    placeholder="Enter the value between 1-48"
                    isRequired
                    type="number"
                    min={1}
                    max={48}
                  />
                  <FormInput
                    wrapperProps={{
                      w: "100%",
                    }}
                    label="Max Power"
                    name="max_power"
                    placeholder="Enter the value between 1-48"
                    isRequired
                    type="number"
                    min={1}
                    max={48}
                  />
                </Flex>
                <Flex alignItems="flex-start" gap="16px" w="100%">
                  <FormInput
                    wrapperProps={{
                      w: "100%",
                    }}
                    label="Top Height"
                    name="top_height"
                    placeholder="Enter the value between 0-10"
                    isRequired
                    type="number"
                    min={0}
                    max={10}
                  />
                  <FormInput
                    wrapperProps={{
                      w: "100%",
                    }}
                    label="Line Length"
                    name="line_length"
                    placeholder="Enter the value between 10-35"
                    isRequired
                    type="number"
                    min={10}
                    max={35}
                  />
                </Flex>
                <Flex alignItems="flex-start" gap="16px" w="100%">
                  <FormInput
                    wrapperProps={{
                      w: "100%",
                    }}
                    label="Front-back Motor Speed"
                    name="front_back_motor_speed"
                    placeholder="Enter the value between 1-5"
                    isRequired
                    type="number"
                    min={1}
                    max={5}
                  />
                  <FormInput
                    wrapperProps={{
                      w: "100%",
                    }}
                    label="Left-right Motor Speed"
                    name="left_right_motor_speed"
                    placeholder="Enter the value between 1-5"
                    isRequired
                    type="number"
                    min={1}
                    max={5}
                  />
                </Flex>
                <Flex alignItems="flex-start" gap="16px" w="100%">
                  <FormInput
                    wrapperProps={{
                      w: "100%",
                    }}
                    label="Up-down Motor Speed"
                    name="up_down_motor_speed"
                    placeholder="Enter the value between 1-5"
                    isRequired
                    type="number"
                    min={1}
                    max={5}
                  />
                  <Flex width="100%"></Flex>
                </Flex>
              </>
            )}
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="800px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "EDIT MACHINE SETTING" : "NEW MACHINE SETUP"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
            onClick={() => {
              handleSubmit(onSubmit);
            }}
          >
            {idEdit ? "SAVE" : "ADD"}
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
