import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getContacts = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.CONTACT}?${params}`);
};

export const getDetailContact = (id) => {
  return http.get(`${END_POINT.CONTACT}/${id}`);
};

export const editContact = (id, data, option = {}) => {
  return http.put(`${END_POINT.CONTACT}/${id}`, data, option);
};
