import { useState } from "react";
import {
  Box,
  Flex,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import ButtonType from "components/Button";
import { CreatableSelect } from "chakra-react-select";
import { ERROR_WHITELIST } from "views/Event/EventManagement/constant";
import { addEmailIntoWhiteList } from "api/event.api";

export default function ModalAddWhiteList({
  isOpen,
  onClose,
  idDetail,
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");

  const handleChangeOptions = (data) => {
    setSelectedOption(data);
  };

  const handleAddWhitelist = async () => {
    const listUser = selectedOption?.map((check) => check?.value)
    try {
      const res = await addEmailIntoWhiteList(idDetail, listUser);
      if (res?.data?.success) {
        toast({
          title: "Add User To Whitelist Successfully.",
          description: "Add User To Whitelist Successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setSelectedOption([])
      } else {
        toast({
          description: ERROR_WHITELIST[res?.data?.message] || ERROR_WHITELIST[res?.data?.messages[0]],
          status: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xxl">
      <ModalOverlay />
      <ModalContent maxW="900px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          Add White List
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">
          <Flex flexDirection="column">
            <Card px="0px">
              <CardHeader display="flex" flexDirection="column" gap="12px" mb="24px">
                <Box>
                  <FormLabel>Search</FormLabel>
                  <CreatableSelect
                    isMulti
                    name="colors"
                    options={[]}
                    placeholder="Add user in whitelist"
                    closeMenuOnSelect={false}
                    onChange={handleChangeOptions}
                    value={selectedOption}
                    noOptionsMessage={() => null}
                  />
                </Box>
                <Flex justifyContent="center" gap="8px" mt={6}>
                  <ButtonType
                    text="Cancel"
                    fontSize="14px"
                    sizeIcon="16px"
                    onClick={() => {
                      onClose()
                      setSelectedOption([])
                    }}
                  />
                  <ButtonType
                    text="Add New"
                    fontSize="14px"
                    sizeIcon="16px"
                    disabled={!selectedOption?.length}
                    onClick={handleAddWhitelist}
                  />
                </Flex>
              </CardHeader>
            </Card>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
  