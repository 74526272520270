import {
  Flex,
  Icon,
  Image,
  Stack,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { ViewIcon } from "components/Icons/ViewIcon";
import { RARITY, TOKEN_TYPE_LABEL } from "constants/constants";
import { convertDateLocalLLLL } from "utils/time";
import { getChainIcon } from "views/NFTsManagement/utils/utils";

const RowNftGacha = ({
  item,
}) => {
  const textColor = useColorModeValue("#000000", "white");

  return (
  <Tr>
    <Td>
      <Stack direction="row" spacing="16px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.id}
        </Text>
      </Stack>
    </Td>
    <Td>
      <Stack direction="row" spacing="16px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.user_name}
        </Text>
      </Stack>
    </Td>
    <Td>
      <Stack direction="row" spacing="16px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
            {convertDateLocalLLLL(item?.draw_date * 1000)}
          </Text>
      </Stack>
    </Td>
    <Td>
      <Stack direction="row" spacing="16px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {TOKEN_TYPE_LABEL[item?.related_type]}
        </Text>
      </Stack>
    </Td>
    <Td>
      <Stack direction="row" spacing="16px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.name}
        </Text>
      </Stack>
    </Td>
    <Td>
      <Stack direction="row" spacing="16px">
        <Image
          width="80px"
          src={item?.image}
          alt={item?.id}
        />
      </Stack>
    </Td>
    <Td>
      <Stack direction="row" spacing="16px">
        {item?.chain_id ? (
          <Image
            boxSize="30px"
            src={getChainIcon(item?.chain_id).src}
            alt={getChainIcon(item?.chain_id).alt}
          />
        ) : (
          <></>
        )}
      </Stack>
    </Td>
    <Td>
      <Stack direction="row" spacing="16px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.rarity}
        </Text>
      </Stack>
    </Td>
    <Td>
      <Flex gap={6} align="center">
        <Icon
          fontSize="30px"
          borderRadius="8px"
          cursor="pointer"
          as={ViewIcon}
        />
      </Flex>
    </Td>
  </Tr>
  );
};

export default RowNftGacha;
