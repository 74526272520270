import { Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { deleteDetailRewardNftGacha } from "api/gacha.api";
import ButtonType from "components/Button";
import { useMemo } from "react";
import ModalConfirm from "components/Modal/ModalConfirm";
import { LIST_ERROR_GACHA } from "../constants";

export default function ActionNonNft({
  handleOpenCreateOrUpdate,
  checkedIds,
  setCheckedIds,
  setParams,
  params,
}) {
  const toast = useToast();

  const isHaveCheckedIds = useMemo(() => {
    return checkedIds?.length > 0;
  }, [checkedIds]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDelete = async () => {
    try {
      if (isHaveCheckedIds) {
        const listDelete = checkedIds?.map((nft) => nft?.id)
        const res = await deleteDetailRewardNftGacha(listDelete);
        if (res.data?.success) {
          setCheckedIds([]);
          toast({
            title: "Delete success.",
            description: "Delete success.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          const newParams = {
            ...params,
            page: 1,
          };
          setParams(newParams);
          onClose();
        } else {
          toast({
            description: LIST_ERROR_GACHA[res?.data?.message] || LIST_ERROR_GACHA[res?.data?.messages[0]] || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      onClose();
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Flex gap={4} flexWrap="wrap" flexDirection="column" w="100%" >
      <Flex alignItems="center" justifyContent="flex-end" flexWrap="wrap" gap={4} m="10px 0">
        <ButtonType
          text="NEW"
          w="120px"
          fontSize="12px"
          onClick={() => {
            handleOpenCreateOrUpdate();
          }}
        />
        <ButtonType
          text="DELETE"
          fontSize="12px"
          w="120px"
          btnType="primary-new-outline"
          disabled={!isHaveCheckedIds}
          onClick={onOpen}
        />
      </Flex>
      <ModalConfirm isOpen={isOpen} onClose={onClose} onSubmit={handleDelete} />
    </Flex>
  );
}
