export const TYPE_COIN = [
  { value: "BCOIN", label: "BCoin" },
  { value: "SPHERE", label: "Sphere" },
  { value: "ORB", label: "Orb" },
]

export const ERROR_NON_NFT = {
  "TYPE_AND_VALUE_IS_EXIST": "Type and value is exist",
}

export const LIST_GRAND_PRIZE = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
]

export const LIST_STATUS_GACHA = {
  ON_GOING: "On Going",
  INCOMING: "Incoming",
  TERMINATED: "Terminated"
}

export const LIST_KEY_STATUS_GACHA = {
  ON_GOING: "ON_GOING",
  INCOMING: "INCOMING",
  TERMINATED: "TERMINATED"
}

export const LIST_ERROR_GACHA = {
  "FILE_IS_NOT_CSV_FORMAT": "File is not csv format",
  "CSV_FILE_IS_EMPTY": "Csv file is empty",
  "REWARD_CONTAINER_HAS_BEEN_SET_IN_ACTIVE_GACHA": "Reward container has been set in active gacha",
  "REWARD_CONTAINER_NOT_FOUND": "Reward container not found",
  "REWARD_CONTAINER_HAS_BEEN_SET_IN_ANOTHER_GACHA": "Reward container has been set in another gacha",
  "GACHA_IS_ON_GOING": "Gacha is on going",
  "GACHA_START_TIME_AND_END_TIME_ARE_NOT_VALID": "Gacha start time and end time are not valid",
}
