import {
  Image,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { STATE_SHOW } from "views/Machine/MachineSettings/constants";

export default function LeaderBoardRow({
  item,
}) {
  const textColor = useColorModeValue("#000000", "white");

  return (
    <Tr>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.mac}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.group_label}-{item?.group_number}
        </Text>
      </Td>
      <Td>
        <Image w="60px" lazyLoad={true} src={item?.icon_url} alt="img-machine" />
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {STATE_SHOW[item?.state]}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {STATE_SHOW[item?.status]}
        </Text>
      </Td>
    </Tr>
  );
}
