import {
	Flex,
	useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import { useCallback, useEffect, useState } from "react";
import ConditionNonNft from "./condition";
import RowNftGacha from "./row";
import { getListNonNftGacha } from "api/gacha.api";


export default function TableRewardNonNft(props) {
  const { setCheckedIds, checkedIds } = props
  const [isLoading, setIsLoading] = useState(false);
  
  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const toast = useToast();
  const [machines, setMachines] = useState([]);
  
  const labels = [
    "Id",
    "Name",
    "Type",
    "Value",
    "Count",
    "Weight",
  ];
  
  const getListMachine = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getListNonNftGacha({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setMachines(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );
  
  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };
  
  useEffect(() => {
    getListMachine();
  }, [getListMachine]);
  
  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      const newMachine = machines?.map((machine) => ({
        ...machine,
        related_type: "NON_NFT"
      }))
      const r = newMachine.filter((elem) => !checkedIds.find(({ id }) => elem.id === id))
      setCheckedIds([...checkedIds, ...r]);
    } else {
      const uniqueArray1 = checkedIds.filter(item1 => 
        !machines.some(item2 => item2.id === item1.id)
      );
      setCheckedIds(uniqueArray1);
    }
  };
  
  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  return (
    <Flex flexDirection="column">
			<Card px="0px">
				<CardHeader mb="12px" display="flex" flexDirection="column" gap="20px">
					<ConditionNonNft
						isLoading={isLoading}
						setParams={setParams}
						params={params}
					/>
				</CardHeader>
				<CardBody overflowX="auto">
					<TableCustom
						labels={labels}
						isLoading={isLoading}
						isCheck
						isChecked={
							machines?.length > 0 && checkedIds?.length > 0 && machines.every(check => checkedIds.find(reward => reward.id === check.id))
						}
						onChangeChecked={onChangeChecked}
						dataRow={machines}
					>
						<>
							{machines?.map((item) => {
								return (
									<RowNftGacha
										key={item?.id}
										setCheckedIds={setCheckedIds}
										checkedIds={checkedIds}
										machine={item}
									/>
								);
							})}
						</>
					</TableCustom>
				</CardBody>
				<Paginate
					page={params?.page}
					pageCount={totalPage}
					limit={params.limit}
					onChangeLimit={onChangeLimit}
					handleChangePage={handleChangePage}
				/>
			</Card>
		</Flex>
  )
}
