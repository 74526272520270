import {
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { getNewEventSkillRank } from "api/event.api";
import ButtonType from "components/Button";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ListBatchSellHunter from "./listBatchSell";

export default function ModalDelete({
  isOpen,
  onClose,
}) {
  const [nftsChecked, setNFTsChecked] = useState([]);
  const toast = useToast();
  const form = useForm();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const LIMIT = 6;
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [totalPage, setTotalPage] = useState(1);

  const [hunterNFTs, setHunterNFTs] = useState([]);

  // const [params, setParams] = useState({
  //   limit: LIMIT,
  //   page: 1,
  //   "sale-status": "NEW",
  // });

  const getListHunterNFTs = useCallback(async () => {
    try {
      setIsLoadingMore(true);
      const { data } = await getNewEventSkillRank({
        page: 1,
        limit: 9999,
      });
      const list = data?.data?.records;
      if (list && list?.length > 0) {
        setHunterNFTs((prev) => {
          return prev?.concat(list);
        });
      }
      setTotalPage(data?.data?.total_page);
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoadingMore(false);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      getListHunterNFTs();
    } else {
      setHunterNFTs([]);
    }
  }, [getListHunterNFTs, isOpen]);

  const handleClose = () => {
    setNFTsChecked([]);
    onClose();
  };

  const onSubmit = async (data) => {
    console.log("🚀 ~ onSubmit ~ data:", data)
  };

  const renderForm = () => {
    return (
      <FormProvider {...form} w="100%">
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="list-sell-batch-nfts-hunter-form-id"
          style={{
            width: "100%",
            overflowX: "auto",
          }}
        >
          <ListBatchSellHunter
            data={hunterNFTs}
            nftsChecked={nftsChecked}
            setNFTsChecked={setNFTsChecked}
          />
        </form>
      </FormProvider>
    );
  };

  return (
    <>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={() => {
          handleClose();
        }}
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent maxW="685px" p="28px 24px">
          <ModalHeader
            w="100%"
            textAlign="center"
            margin="auto"
            p="16px 0"
          >
            <Text fontSize="24px" fontWeight="700">
              DELETE GAME MODE
            </Text>
            <Flex justifyContent="flex-end">
              <ButtonType
                text="Select All"
                btnType="primary-new-outline"
                fontSize="16px"
                w="140px"
                onClick={() => setNFTsChecked(hunterNFTs)}
              />
            </Flex>
            <Text fontSize="16px" fontWeight="500" mt="16px" textAlign="start">
              Select game mode(s) ({nftsChecked?.length})
            </Text>
          </ModalHeader>
          <ModalCloseButton top="30px" />
          <ModalBody p="0px">
            {isLoading ? (
              <Center minH="200px" m="auto">
                <LoadingSpinner />
              </Center>
            ) : (
              renderForm()
            )}
          </ModalBody>
          <ModalFooter w="100%" textAlign="center" margin="auto">
            <Flex
              w="100%"
              direction="column"
              alignItems="center"
              gap={4}
              pb="4px"
            >
              {/* {totalPage > params?.page && (
                <ButtonType
                  fontSize="16px"
                  fontWeight="500"
                  h="44px"
                  btnType="primary-new-outline"
                  borderRadius="30px"
                  boxShadow="none"
                  color="#000000"
                  p="12px 40px"
                  isLoading={isLoadingMore}
                  onClick={() => {
                    setParams((prev) => ({
                      ...prev,
                      limit: LIMIT,
                      page: prev?.page + 1,
                    }));
                  }}
                >
                  Load More
                </ButtonType>
              )} */}

              <Flex gap="16px" w="100%">
                <ButtonType
                  mt={4}
                  m="auto"
                  w="100%"
                  h="46px"
                  borderRadius="8px"
                  type="submit"
                  form="list-sell-batch-nfts-hunter-form-id"
                  fontSize="16px"
                  fontWeight="500"
                  disabled={nftsChecked?.length <= 0}
                  isLoading={isSubmitting}
                >
                  DELETE
                </ButtonType>
              </Flex>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
