import { useCallback, useEffect, useState } from "react";
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import TableCustom from "components/Tables/CustomTable";
import LeaderBoardRow from "./row";
import ButtonType from "components/Button";
import { getSkillPointApi, updateSkillPointApi } from "api/skillPoint.api";
import useWithToast from "hooks/useWithToast";

export default function ModalSkillPoint({
  isOpen,
  onClose,
}) {
  const labels = [
    "#",
    "Mode Type",
    "Cases",
    "1st Factor",
    "2nd Factor - Base",
    "2nd Factor - Multiplier",
  ];
  const toast = useToast();
  const { showToastSuccess } = useWithToast();
  const [rankDistribute, setRankDistribute] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const textColor = useColorModeValue("#000000", "white");

  const getSkillRank = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getSkillPointApi({
          page: 1,
          limit: 999999,
        });
        if (data?.success) {
          const res = data?.data;
          setRankDistribute(res?.records);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    getSkillRank();
  }, [getSkillRank]);

  const handleChangePoint = (id, field, value) => {
    setRankDistribute((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  
  const handleSaveRank = async () => {
    try {
      setIsLoading(true);
      const params = rankDistribute?.map((rank) => ({
        id: rank?.id,
        mode_type: rank?.mode_type,
        cases: rank?.cases,
        first_factor: rank?.first_factor,
        second_factor_base: rank?.second_factor_base,
        second_factor_multiplier: rank?.second_factor_multiplier,
      }))
      const { data } = await updateSkillPointApi(params);
      if (data?.success) {
        showToastSuccess({
          title: "Successfully Edited Rank Point Settings!",
        });
        getSkillRank();
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xxl">
      <ModalOverlay />
      <ModalContent maxW="900px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          Skill Point Settings
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">
          <Flex flexDirection="column">
            <Card px="0px" pt="0px">
              <CardBody overflowX="auto">
                <TableCustom
                  isCheck={false}
                  labels={labels}
                  dataRow={rankDistribute}
                >
                  <>
                    {rankDistribute?.map((item, index) => {
                      return (
                        <LeaderBoardRow
                          key={item?.id}
                          item={item}
                          index={index}
                          handleChangePoint={handleChangePoint}
                        />
                      );
                    })}
                  </>
                </TableCustom>
              </CardBody>
            </Card>
          </Flex>
        </ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            m="auto"
            w="160px"
            h="46px"
            borderRadius="5px"
            onClick={handleSaveRank}
            disabled={isLoading || !rankDistribute?.length}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
  