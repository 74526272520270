import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import NFTsCardHeader from "views/NFTsManagement/components/panel/NFTsCardHeader";
import ConsecutiveBonus from "./ConsecutiveBonus";
import CumulativeBonus from "./CumulativeBonus";
import StartDashBonus from "./StartDashBonus";


const TAB_NAMES = ["CONSECUTIVE", "CUMULATIVE"];

export default function LoginBonus() {
  const tabBg = useColorModeValue("gray.50", "gray.800");
  const activeTabBg = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const activeTabColor = useColorModeValue("blue.600", "blue.300");

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="24px">
        <NFTsCardHeader title="Login Bonus" />
        <Tabs
          isLazy
          variant="enclosed"
        >
          <TabList borderBottom={`1px solid ${borderColor}`}>
            {TAB_NAMES.map((name) => (
              <Tab
                key={name}
                fontWeight="semibold"
                _selected={{
                  color: activeTabColor,
                  bg: activeTabBg,
                  borderBottom: "3px solid",
                  borderBottomColor: activeTabColor,
                  marginBottom: "-1px",
                }}
                _hover={{
                  color: activeTabColor,
                }}
                bg={tabBg}
                borderTopRadius="md"
                mr={1}
                py={3}
                px={5}
              >
                {name}
              </Tab>
            ))}
          </TabList>
          <Box mt={4}>
            <TabPanels>
              <TabPanel padding="24px 0">
                <ConsecutiveBonus />
              </TabPanel>
              <TabPanel padding="24px 0">
                <CumulativeBonus />
              </TabPanel>
              {/* <TabPanel padding="24px 0">
                <StartDashBonus />
              </TabPanel> */}
            </TabPanels>
          </Box>
        </Tabs>
      </Card>
    </Flex>
  );
}
