import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { NFT_MANAGEMENT_TYPE } from "views/NFTsManagement/constants";
import ListNfts from "./listNfts";

const TAB_NFTS = ["Genesis", "Hunter", "Gauntlet", "Bounty Ball"];

export default function NftsUser({ userDetail }) {
  const tabBg = useColorModeValue("gray.50", "gray.800");
  const activeTabBg = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const activeTabColor = useColorModeValue("blue.600", "blue.300");

  return (
    <Box p="24px">
      <Text fontSize="20px" fontWeight="500">NFTs</Text>
      <Tabs
        isLazy
        variant="enclosed"
      >
        <TabList borderBottom={`1px solid ${borderColor}`}>
          {TAB_NFTS.map((name) => (
            <Tab
              key={name}
              fontWeight="semibold"
              _selected={{
                color: activeTabColor,
                bg: activeTabBg,
                borderBottom: "3px solid",
                borderBottomColor: activeTabColor,
                marginBottom: "-1px",
              }}
              _hover={{
                color: activeTabColor,
              }}
              bg={tabBg}
              borderTopRadius="md"
              mr={1}
              py={3}
              px={5}
              w="100%"
            >
              {name}
            </Tab>
          ))}
        </TabList>
        <Box mt={4}>
          <TabPanels>
            <TabPanel padding="24px 0">
              <ListNfts userDetail={userDetail} nftMngType={NFT_MANAGEMENT_TYPE.GENESIS}/>
            </TabPanel>
            <TabPanel padding="24px 0">
              <ListNfts userDetail={userDetail} nftMngType={NFT_MANAGEMENT_TYPE.HUNTER}/>
            </TabPanel>
            <TabPanel padding="24px 0">
              <ListNfts userDetail={userDetail} nftMngType={NFT_MANAGEMENT_TYPE.GAUNTLET}/>
            </TabPanel>
            <TabPanel padding="24px 0">
              <ListNfts userDetail={userDetail} nftMngType={NFT_MANAGEMENT_TYPE.BOUNTY_BALL}/>
            </TabPanel>
          </TabPanels>
        </Box>
      </Tabs>
    </Box>
  );
}
  