import { STATUS } from "constants/constants";

export const STATUS_OPTIONS = [
  { label: "Connected", value: STATUS.CONNECTED },
  { label: "Disconnected", value: STATUS.DISCONNECTED },
];

export const CHROMA_KEY_OPTIONS = [
  {
    label: "Enable",
    value: "true",
  },
  {
    label: "Disable",
    value: "false",
  },
];
