import { Box, Button, FormLabel } from '@chakra-ui/react'
import React from 'react'
import ReactQuill from 'react-quill'
import "../../assets/css/quill.css";
import backgroundIcon from "../../assets/img/background_color.png";

const CustomToolbar = () => (
  <Box
    id='toolbar'
    borderEndStartRadius='15px'
    borderBottomEndRadius='15px'
    borderTop='0px solid transparent !important'
    display='flex'
    justifyContent='flex-end'
    background="lightgray"
  >
    <select className="ql-background">
    </select>
    <Button
      display='flex !important'
      justifyContent='center !important'
      alignItems='center !important'
      className='ql-bold'></Button>
    <Button
      display='flex !important'
      justifyContent='center !important'
      alignItems='center !important'
      className='ql-underline'></Button>
      <Button
      display='flex !important'
      justifyContent='center !important'
      alignItems='center !important'
      className='ql-link'></Button>
  </Box>
)

export default class Editor extends React.Component {
  constructor(props) {
    super(props)
    this.state = { editorHtml: this.props.descriptions }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.descriptions !== this.props.descriptions) {
      this.setState({ editorHtml: this.props.descriptions });
    }
  }

  componentDidMount() {
    const backgroundPickerLabel = document.querySelector('.ql-background .ql-picker-label');
    if (backgroundPickerLabel) {
      backgroundPickerLabel.innerHTML = `
        <img
          src="${backgroundIcon}"
          alt="Background"
          style="width: 20px; height: 20px; display: inline-block; vertical-align: middle;"
        />
      `;
    }
  }

  handleChange(html) {
    this.props.setValue(this.props.name, html)
    this.setState({ editorHtml: html })
  }

  render() {
    return (
      <div className='text-editor'>
        <FormLabel>
          <label>{this.props.lable}</label>
          {this.props.isRequired && <span style={{ color: 'red' }}>*</span>}
        </FormLabel>
        <ReactQuill
          value={this.state.editorHtml}
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          modules={Editor.modules}
          className="quill-editor"
        />
        <CustomToolbar />
      </div>
    )
  }
}

Editor.modules = {
  toolbar: [
    [{ size: [] }],
    ['bold', 'underline'],
    ['link'],
    [{ background: [] }],
  ],
  clipboard: {
    matchVisual: false,
  },
}

Editor.modules = {
  toolbar: {
    container: '#toolbar',
  },
  clipboard: {
    matchVisual: false,
  },
}

Editor.formats = [
  'bold',
  'underline',
  'link',
  'background'
]
