import * as yup from "yup";

const schemaBonusPass = yup.object().shape({
  bonusPasses: yup.array()
    .of(
      yup.object().shape({
        price: yup.number()
          .min(0, "Number of price must be greater or equal to 0")
          .nullable(true)
          .max(9999, "Number of price must be at most 4 characters")
          .transform((_, val) => {
            if (val) {
              return Number(val) || Number(val) === 0 ? Number(val) : 0;
            }
            return 0;
          })
          .integer("Must be an integer"),
      })
    )
});


export default schemaBonusPass;
