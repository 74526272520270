import {
  Box,
  Divider,
  Flex,
  Grid,
  HStack,
  Image,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { capitalize } from "lodash";
import { useMemo } from "react";
import { trimCharacter } from "utils/string";
import {
  getAllocationLabel,
  getChainIcon,
} from "views/NFTsManagement/utils/utils";
import { getRarityStyle } from "utils/rarity";
import { getIconTrait } from "views/NFTsManagement/utils/utils";

const BountyBallDetail = ({ item, chain }) => {
  const textColor = useColorModeValue("gray.700", "white");

  const IconCard = useMemo(() => {
    return getRarityStyle(item?.rarity);
  }, [item?.rarity]);

  const gimmickName = item?.gimmick_model?.translation_gimmicks?.[0]?.name;

  return (
    <VStack spacing={4} align="stretch">
      <Grid templateColumns="1fr 2fr" gap={4}>
        <Box
          bgImage={IconCard.iconBg}
          position="relative"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundSize="contain"
          borderRadius="md"
          overflow="hidden"
        >
          <Image
            src={item?.image_url}
            alt={item?.nft_information?.name}
            objectFit="contain"
            w="60%"
            h="100%"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          />
        </Box>
        <VStack align="start" spacing={2}>
          <HStack>
            <Image
              boxSize="50px"
              src={getChainIcon(item?.nft_information?.chain_id || chain).src}
              alt={getChainIcon(item?.nft_information?.chain_id || chain).alt}
            />
            <Box paddingLeft={2}>
              <Box>
                <HStack align="start" spacing={30}>
                  <Text fontSize="sm" fontWeight="bold" color={textColor}>
                    Contract Address
                  </Text>
                  <Text fontWeight="bold" color="blue.500">
                    {trimCharacter(
                      item?.nft_information?.contract_address || item?.contract_address,
                      6,
                      4
                    )}
                  </Text>
                </HStack>
              </Box>
              <Box>
                <HStack align="start" spacing={30}>
                  <Text fontSize="sm" fontWeight="bold" color={textColor}>
                    Token ID {item?.nft_information?.token_id}
                  </Text>
                  <Text fontSize="sm" fontWeight="bold" color={textColor}>
                    Token Standard ERC-721
                  </Text>
                </HStack>
              </Box>
            </Box>
          </HStack>
          <Text fontWeight="bold" color={textColor}>
            Owner:{" "}
            <Text
              as="span"
              color={item?.user_model?.name ? "black" : "red.500"}
            >
              {item?.user_model?.name || "Admin"}
            </Text>
          </Text>
          <Divider />
          <Text fontSize="xl" fontWeight="bold" color="blue.500">
            {item?.nft_information?.name}
          </Text>
          <Text fontWeight="bold" color={textColor}>
            Rarity:{" "}
            <Text as="span" color={IconCard.bgRarity} fontWeight="bold">
              {capitalize(item?.rarity)}
            </Text>
          </Text>
          <Text fontWeight="bold" color={textColor}>
            {item?.trait ? (
              <Flex gap={2} align="center">
                <Text>Trait: </Text>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  bg="rgba(0,0,0,0.4)"
                  padding="6px 16px"
                  borderRadius="16px"
                >
                  <Image w="40px" src={getIconTrait(item?.trait)} alt="icon" />
                </Box>
              </Flex>
            ) : (
              ""
            )}
          </Text>
          <Text fontWeight="bold" color={textColor}>
            Energy gauge: {item?.current_energy_gauge || 0}/{item?.rarity_model?.max_energy_gauge || 0}
          </Text>
          <Text fontWeight="bold" color={textColor}>
            Gimmick: {gimmickName}
          </Text>
          <Divider />
          <Text fontWeight="bold" color={textColor}>
            Allocation:{" "}
            <Text as="span" color="blue.500">
              {getAllocationLabel(item?.allocation)}
            </Text>
          </Text>
          <Divider />
        </VStack>
      </Grid>
    </VStack>
  );
};

export default BountyBallDetail;
