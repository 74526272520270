import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getAdminsApi = (p = {}) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.GET_ADMIN}?${params}`);
};

export const createAdminApi = (data) => {
  return http.post(`${END_POINT.CREATE_ADMIN}`, data);
};

export const updateAdminApi = (data) => {
  return http.put(`${END_POINT.UPDATE_ADMIN}`, data);
};

export const updateRoleAdminApi = (id, data) => {
  return http.put(`${END_POINT.CREATE_ADMIN}/${id}/${END_POINT.CHANGE_ROLE}`, data);
};

export const updatePassword = (data) => {
  return http.post(`${END_POINT.UPDATE_ADMIN_PASSWORD}`, data);
};

export const updateBlockAdminApi = (data) => {
  return http.put(`${END_POINT.BLOCK_ADMIN}`, data);
};

export const updateUnblockAdminApi = (data) => {
  return http.put(`${END_POINT.UNBLOCK_ADMIN}`, data);
};
