import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
// Assets
import { yupResolver } from "@hookform/resolvers/yup";
import SwitchType from "components/Switch";
import { StorageKeys } from "constants/enum";
import { useEffect, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { loginReducerApi, userinfoReducerApi } from "stores/user/action";
import { userinfoSelector } from "stores/user/selector";
import { clearLocalStorage, getLocalStorage } from "utils/storage";
import { loginValidate } from "./schema";

function SignIn() {
  // Chakra color mode
  const titleColor = useColorModeValue("#000000", "teal.200");
  const { t } = useTranslation("auth");
  const history = useHistory();
  const dispatch = useDispatch();
  const textColor = useColorModeValue("gray.400", "white");
  const [rememberMe, setRememberMe] = useState(false);
  const { loading, token, error } = useSelector(userinfoSelector);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(loginValidate()),
  });

  useLayoutEffect(() => {
    clearLocalStorage(StorageKeys.TOKEN);
  }, []);

  useEffect(() => {
    if (getLocalStorage(StorageKeys.TOKEN) && getLocalStorage(StorageKeys.TOKEN) !== "undefined") {
      dispatch(userinfoReducerApi());
      history.push("/dashboard");
    }
  }, [token]);

  const onSubmit = async (values) => {
    const { email, password } = values;
    await dispatch(loginReducerApi({ email, password, rememberMe }));
  };

  return (
    <Flex position="relative" mb="40px">
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{ sm: "100px", md: "0px" }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: "none" }}
          mx="auto"
          w={{ base: "100%", md: "50%", lg: "42%" }}
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            p="48px"
            mt={{ md: "150px", lg: "80px" }}
          >
            <Heading color={titleColor} fontSize="32px" mb="10px">
              {t("welcome_back")}
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColor}
              fontWeight="bold"
              fontSize="14px"
            >
              {t("input_form_sign")}
            </Text>

            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl
                isInvalid={!!errors?.email}
                errortext={errors?.email?.message}
                pb="4"
              >
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  {t("email")}
                </FormLabel>
                <Input
                  borderRadius="15px"
                  fontSize="sm"
                  placeholder="Your email adress"
                  size="lg"
                  type="email"
                  name="email"
                  id="email"
                  {...register("email")}
                />
                <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={!!errors?.password?.message}
                errortext={errors?.password?.message}
                pb="4"
              >
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  {t("password")}
                </FormLabel>
                <Input
                  borderRadius="15px"
                  fontSize="sm"
                  placeholder="Your password"
                  size="lg"
                  type="password"
                  id="password"
                  name="password"
                  {...register("password")}
                />
                <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
              </FormControl>
              <Text mb="12px" ms="4px" color="red.400" fontSize="14px">
                {error && error?.messages[0]}
              </Text>

              <FormControl display="flex" alignItems="center" mb="24px">
                <SwitchType
                  id="remember-login"
                  me="10px"
                  isChecked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                <FormLabel htmlFor="remember-login" mb="0" fontWeight="normal">
                  {t("remember_me")}
                </FormLabel>
              </FormControl>

              <Button
                fontSize="sm"
                type="submit"
                w="100%"
                h="45"
                mb="20px"
                color="white"
                backgroundImage="linear-gradient(90deg, #FFC515 0%, #F79325 100%)"
                boxShadow="0 4px 7px -1px rgba(0,0,0,.11),0 2px 4px -1px rgba(0,0,0,.07)"
                mt="20px"
                _hover={{
                  transform: "scale(1.02)",
                }}
                _active={{
                  backgroundImage: "linear-gradient(90deg, #FFC515 0%, #F79325 100%)",
                }}
                isLoading={loading}
                // disabled={!!errors.email || !!errors.password}
                // onClick={handleSubmit(onSubmit)}
              >
                {t("sign_in")}
              </Button>
            </form>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SignIn;
