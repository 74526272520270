import ModalBase from "components/Modal/ModalBase";
import GenesisPackageList from "../list/GenesisPackageList";

export default function ManagePackageGenesisModal({ isOpen, onClose }) {
  return (
    <>
      <ModalBase
        maxWContent={{
          base: "90%",
          sm: "90%",
          md: "90%",
        }}
        isOpen={isOpen}
        onClose={onClose}
        showBtn={false}
        titleHeader="Packages"
      >
        <GenesisPackageList />
      </ModalBase>
    </>
  );
}
