import { Checkbox, Flex, Stack, FormControl } from "@chakra-ui/react"
import React, { useMemo, useState } from "react"
import { Controller } from "react-hook-form";

export default function CheckboxCommon(props) {
  const { watch, setValue, listRule, control } = props

  const allChecked = listRule?.rules?.every((data) => watch(`${data.id}`) === true)
  
  const isIndeterminate = listRule?.rules?.some((data) => watch(`${data.id}`) === true) && !allChecked

  const handleSelectAll = () => {
    const dataChecked = listRule?.rules?.every((data) => watch(`${data.id}`) === true)
    if (dataChecked) {
      listRule?.rules?.filter((data) => {
        setValue(`${data.id}`, false)
      })
    } else {
      listRule?.rules?.filter((data) => {
        setValue(`${data.id}`, true)
      })
    }
  }
  
  return (
    <Flex direction="column" gap={4}>
      <Checkbox
        isChecked={allChecked}
        isIndeterminate={isIndeterminate}
        onChange={handleSelectAll}
      >
        {listRule?.rule_group}
      </Checkbox>
      <Stack pl={6} spacing={1}>
        {listRule?.rules?.map((data, index) => (
          <Controller
            key={index}
            control={control}
            defaultValue={watch(`${data.id}`)}
            name={`${data?.id}`}
            render={({ field: { onChange, value, ref } }) => {
              return (
                <Checkbox
                  key={index}
                  isChecked={value}
                  onChange={onChange}
                  ref={ref}
                >
                  {data?.name}
                </Checkbox>
              )
            }}
          />
        ))}
      </Stack>
    </Flex>
  )
}