import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Flex,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import ItemsSendRow from "./row";
import ButtonType from "components/Button";
import { CreatableSelect } from "chakra-react-select";
import { getLeaderboardStandard, leaderboardIncludeUsers, leaderboardExcludeUsers, getLeaderboardStandardExcluded } from "api/event.api";
import useWithToast from "hooks/useWithToast";

export default function LeaderBoardException({
  isOpen,
  onClose,
  dataDetail,
}) {
  const labels = [
    "User",
    "Register At",
  ];
  const { showToastSuccess } = useWithToast();
  const [checkedIds, setCheckedIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [itemExcludes, setItemExclude] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");

  const handleChangeOptions = (data) => {
    setSelectedOption(data);
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };
  
  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const getEvent = useCallback(
    async () => {
      try {
        setIsLoading(true);
        setCheckedIds([]);
        const { data } = await getLeaderboardStandard(dataDetail?.id, {
          page: 1,
          limit: 999999,
        });
        if (data?.success) {
          const res = data?.data;
          const filterData = res?.records?.map((record) => ({
            ...record,
            label: record?.user?.email,
            value: record?.user?.id
          }))
          setItemExclude(filterData);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  const getListExclude = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        setCheckedIds([]);
        const { data } = await getLeaderboardStandardExcluded(dataDetail?.id, {
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setItems(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );


  useEffect(() => {
    getEvent();
    getListExclude();
  }, []);

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setCheckedIds(items);
    } else {
      setCheckedIds([]);
    }
  };

  const handleAddNew = async () => {
    try {
      setIsLoading(true);
      const params = selectedOption?.map((item) => item?.value)
      const { data } = await leaderboardExcludeUsers(dataDetail?.id, params);
      if (data?.success) {
        setSelectedOption([])
        getListExclude()
        showToastSuccess({
          title: "Exclude successfully!",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } 
  }

  const handleRemove = async () => {
    try {
      setIsLoading(true);
      const params = checkedIds?.map((item) => item?.value)
      const { data } = await leaderboardIncludeUsers(dataDetail?.id, params);
      if (data?.success) {
        setCheckedIds([])
        getListExclude()
        showToastSuccess({
          title: "Include successfully!",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } 
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xxl">
      <ModalOverlay />
      <ModalContent maxW="900px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          Leaderboard Exceptions
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">
          <Flex flexDirection="column">
            <Card px="0px">
              <CardHeader display="flex" flexDirection="column" gap="12px" mb="24px">
                {/* <Flex justifyContent="flex-end" gap="8px">
                  <ButtonType
                    text="CSV"
                    fontSize="14px"
                    sizeIcon="16px"
                    iconType="download"
                    disabled={!items?.length}
                    // onClick={onDownload}
                  />
                  <ButtonType
                    text="Import CSV"
                    fontSize="14px"
                    sizeIcon="16px"
                    btnType="primary-new-outline"
                    // onClick={onDownload}
                  />
                </Flex> */}
                <Box>
                  <FormLabel>Search</FormLabel>
                  <CreatableSelect
                    isMulti
                    name="colors"
                    options={itemExcludes || []}
                    placeholder="Add users"
                    closeMenuOnSelect={false}
                    onChange={handleChangeOptions}
                    value={selectedOption}
                  />
                </Box>
                <Flex justifyContent="flex-end" gap="8px">
                  <ButtonType
                    text="Add New"
                    fontSize="14px"
                    sizeIcon="16px"
                    disabled={!selectedOption?.length}
                    onClick={handleAddNew}
                  />
                  <ButtonType
                    text="Remove"
                    fontSize="14px"
                    sizeIcon="16px"
                    disabled={!checkedIds?.length}
                    onClick={handleRemove}
                  />
                </Flex>
              </CardHeader>
              <CardBody overflowX="auto">
                <TableCustom
                  labels={labels}
                  isLoading={isLoading}
                  dataRow={items}
                  isCheck
                  isChecked={
                    items?.length > 0 && checkedIds.length === items?.length
                  }
                  onChangeChecked={onChangeChecked}
                >
                  <>
                    {items?.map((item) => {
                      return (
                        <ItemsSendRow
                          key={item?.id}
                          item={item}
                          setCheckedIds={setCheckedIds}
                          checkedIds={checkedIds}
                        />
                      );
                    })}
                  </>
                </TableCustom>
              </CardBody>
              <Paginate
                page={params?.page}
                pageCount={totalPage}
                limit={params.limit}
                onChangeLimit={onChangeLimit}
                handleChangePage={handleChangePage}
              />
            </Card>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
  