import {
  Box,
  Divider,
  Flex,
  Grid,
  HStack,
  Image,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { capitalize } from "lodash";
import CommonBg from "assets/img/card/common-bg.svg";
import UncommonBg from "assets/img/card/uncommon-bg.svg";
import RareBg from "assets/img/card/rare-bg.svg";
import LegendaryBg from "assets/img/card/legendary-bg.svg";
import EpicBg from "assets/img/card/epic-bg.svg";
import { RARITY } from "constants/constants";
import { useMemo } from "react";
import { trimCharacter } from "utils/string";
import {
  getAllocationLabel,
  getChainIcon,
} from "views/NFTsManagement/utils/utils";
import { getIconTrait } from "views/NFTsManagement/utils/utils";

const TreasureBoxDetail = ({ item, chain }) => {
  const textColor = useColorModeValue("gray.700", "white");

  const IconCard = useMemo(() => {
    const rarity = item?.rarity;
    let iconCard = {
      iconBg: CommonBg,
      bgRarity: "#545f7b",
    };
    if (rarity === RARITY.RARITY_UNCOMMON) {
      iconCard = {
        iconBg: UncommonBg,
        bgRarity: "#008a17",
      };
    } else if (rarity === RARITY.RARITY_RARE) {
      iconCard = {
        iconBg: RareBg,
        bgRarity: "#005ddd",
      };
    } else if (rarity === RARITY.RARITY_EPIC) {
      iconCard = {
        iconBg: EpicBg,
        bgRarity: "#7612ff",
      };
    }
    if (rarity === RARITY.RARITY_LEGENDARY) {
      iconCard = {
        iconBg: LegendaryBg,
        bgRarity: "#ffd32f",
      };
    }

    return iconCard;
  }, [item?.rarity]);

  return (
    <VStack spacing={4} align="stretch">
      <Grid templateColumns="1fr 1.5fr" gap={4}>
        <Box
          bgImage={IconCard.iconBg}
          position="relative"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundSize="contain"
          borderRadius="md"
          overflow="hidden"
        >
          <Image
            src={item?.image_closed}
            alt={item?.name}
            objectFit="contain"
            w="60%"
            h="100%"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          />
        </Box>
        <VStack align="start" spacing={2}>
          <Text color={"blue.600"} fontSize="20px" fontWeight="500">{item?.name}</Text>
          <Flex alignItems="center" gap="6px">
            <Flex fontWeight="bold" gap="2px" color={textColor}>
              Rank:
              <Text color={"red.500"} as="span" >
                {item?.treasure_box_rank}
              </Text>
            </Flex>
            <Flex fontWeight="bold" gap="2px" color={textColor}>
              Allocation Rate:
              <Text color={"red.500"} as="span" >
                {item?.allocation_rate}
              </Text>
            </Flex>
          </Flex>
          <Flex fontWeight="bold" gap="2px" color={textColor}>
            Opening cost:{" "}
            <Text color={"red.500"} as="span" >
              {item?.opening_cost}
            </Text>
            {item?.opening_cost_currency}
          </Flex>
          <Divider />
          <Text fontWeight="bold" color={textColor}>
            Probability to up rank
          </Text>
          <Text fontWeight="bold" color={textColor}>
            Up 1 rank: {" "}
            <Text as="span" color="red.500">
              {item?.up1_rank_chance}%
            </Text>
          </Text>
          <Text fontWeight="bold" color={textColor}>
            Up 2 rank: {" "}
            <Text as="span" color="red.500">
              {item?.up2_rank_chance}%
            </Text>
          </Text>
          <Text fontWeight="bold" color={textColor}>
            Up 3 rank: {" "}
            <Text as="span" color="red.500">
              {item?.up3_rank_chance}%
            </Text>
          </Text>
          <Text fontWeight="bold" color={textColor}>
            Up 4 rank: {" "}
            <Text as="span" color="red.500">
              {item?.up4_rank_chance}%
            </Text>
          </Text>
          <Text fontWeight="bold" color={textColor}>
            Up 5 rank: {" "}
            <Text as="span" color="red.500">
              {item?.up5_rank_chance}%
            </Text>
          </Text>
          <Divider />
        </VStack>
      </Grid>
    </VStack>
  );
};

export default TreasureBoxDetail;
