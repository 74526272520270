import {
  Editable,
  EditableInput,
  EditablePreview,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";

export default function LeaderBoardRow({
  item,
  index,
  handleChangeRank,
}) {
  const textColor = useColorModeValue("#000000", "white");
  const [dataDist, setDataDist] = useState(item?.rank_percentage || 0)

  const handleChange = (newValue) => {
    if (newValue === '') {
      setDataDist('0');
    } else if (/^\d*\.?\d+$/.test(newValue)) {
      setDataDist(newValue);
    }
  };

  return (
    <Tr>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {index + 1}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.title_skill_rank}
        </Text>
      </Td>
      <Td>
        <Editable 
          display="flex" 
          alignItems="center" 
          width="auto" 
          defaultValue={item?.rank_percentage}
          value={dataDist}
          submitOnBlur={true}
          onChange={handleChange}
          onSubmit={(e) => {
            handleChangeRank(item.id, 'rank_percentage', parseFloat(e))
          }}
        >
          <EditablePreview />
          <EditableInput type="number" width="auto"/>
          <Text pl="3px">%</Text>
        </Editable>
      </Td>
    </Tr>
  );
}
