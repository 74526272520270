/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "@chakra-ui/react";
import ButtonType from "components/Button";

function ModalBase({
  isOpen,
  onClose,
  children,
  maxWContent = "787px",
  titleHeader = "",
  isSubmitting = false,
  showBtn = true,
  textBtn = "SAVE",
  form = "",
  handleSubmit,
  styleBtn,
  isShowFooter = true,
  ...rest
}) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW={maxWContent} overflowY="auto">
          <ModalHeader
            borderBottom="1px solid #EEEFF2"
            w="100%"
            textAlign="center"
            margin="auto"
            color="#000000"
          >
            {titleHeader}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody minW="600px" w="100%" {...rest}>
            {children}
          </ModalBody>
          {isShowFooter && (
            <ModalFooter
              borderTop="1px solid #EEEFF2"
              w="100%"
              textAlign="center"
              margin="auto"
            >
              {showBtn && (
                <ButtonType
                  mt={4}
                  m="auto"
                  w="159px"
                  h="46px"
                  btnType="primary"
                  fontSize="16px"
                  borderRadius="5px"
                  type="submit"
                  form={form}
                  isLoading={isSubmitting}
                  onClick={handleSubmit}
                  {...styleBtn}
                >
                  {textBtn}
                </ButtonType>
              )}
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalBase;
