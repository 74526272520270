import {
  Box,
  Button,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createGamePlayType, updateGamePlayType } from "api/gameplayType.api";
import FormInput from "components/form/FormInput";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import schemaMachine from "./schema";
import ButtonType from "components/Button";
import FormTextAria from "components/form/FormTextAria";
import FormUpload from "components/form/FormUpload";
import FormSelect from "components/form/FormSelect";
import { NUMBER } from "constants/enum";
import { uploadImageToServer } from "utils/uploadImage";
import { CREATE_GAME_PLAY, STATUS_GAME_PLAY } from "../constants";
import { useTranslation } from "react-i18next";
import { getOnlyDecimal } from "utils/number";

const TRANS_LABEL = {
	en: {
		name: "Name",
		enterName: "Enter Name",
		image: "Image",
		note_upload_image: "Currently support .png , .jpg, less than 5mb",
		label_background: "Background Image",
		time: "Time Limit",
		enterTime: "Enter time limit in seconds",
		payout: "Payout Rate",
		enterPayout: "Enter the payout rage (e.g., 0.3)",
		description: "Description",
		enterDesc: "Enter short description",
		status: "Status",
	},
	jp: {
		name: "名前",
		enterName: "名前を入力",
		image: "画像",
    note_upload_image: "現在、.png、.jpg（5MB未満）をサポートしています",
		label_background: "背景画像",
		time: "時間制限",
		enterTime: "制限時間を秒単位で入力",
		payout: "支払い率",
		enterPayout: "支払い範囲を入力します（例：0.3）",
		description: "説明",
		enterDesc: "簡単な説明を入力してください",
		status: "ステータス",
	},
};

export default function MachineCreateAndUpdate({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
}) {
  const { t } = useTranslation("prize");
  const [tabIndex, setTabIndex] = useState(0);
  const [trans, setTrans] = useState("en");
  const [nameImage, setNameImage] = useState("");
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const form = useForm({
    resolver: yupResolver(schemaMachine()),
    defaultValues: {
      name: dataDetail ? dataDetail?.translations[0]?.name : '',
      name_jp: dataDetail ? dataDetail?.translations[1]?.name : '',
      description: dataDetail ? dataDetail?.translations[0]?.description : '',
      description_jp: dataDetail ? dataDetail?.translations[1]?.description : '',
      icon_url: dataDetail ? dataDetail?.image_url : '',
      status: dataDetail ? dataDetail?.status : STATUS_GAME_PLAY[0].value,
      time: dataDetail ? dataDetail?.duration : '',
      payout: dataDetail ? dataDetail?.payout : '',
    }
  });
  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = form;

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
    if (index === 1) {
      setTrans("jp");
    } else {
      setTrans("en");
    }
  };

  const transLabel = (label) => {
    return TRANS_LABEL[trans][label];
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const dataSubmit = {
      name: data?.name,
      duration: data?.time,
      payout: data?.payout,
      image_url: data?.icon_url,
      status: data.status,
      translations: [
        {
          lang_key: "EN",
          name: data?.name,
          description: data?.description,
        },
        {
          lang_key: "JP",
          name: data?.name_jp,
          description: data?.description_jp,
        }
      ]
    };
    try {
      if (idEdit) {
        const res = await updateGamePlayType(dataDetail?.id, dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Game Play updated.",
            description: "Game Play updated.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
        } else {
          toast({
            description: CREATE_GAME_PLAY[res?.data?.message] || CREATE_GAME_PLAY[res?.data?.messages[0]],
            status: "error",
            position: "bottom-right",
          });
        }
      } else {
        const res = await createGamePlayType(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Game Play created.",
            description: "Game Play created.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
        } else {
          toast({
            description: CREATE_GAME_PLAY[res?.data?.message] || CREATE_GAME_PLAY[res?.data?.messages[0]],
            status: "error",
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleImageChange = async (event, type) => {
    const image = event[0];
    await uploadImageToServer(image, toast, setValue, "icon_url", setNameImage);
  };

   const handleDragImage = async (file, type) => {
    if (file.length > NUMBER.ONE) {
      toast({
        description: "The number of uploaded images is greater than one.",
        status: "error",
        position: "bottom-right",
      });
      return false;
    }
    const image = file && file[0];
    await uploadImageToServer(image, toast, setValue, "icon_url", setNameImage);
  };

  const deleteImage = () => {
    setValue('icon_url', null)
  }

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            mb={4}
            index={tabIndex}
            variant="unstyled"
            onChange={handleTabsChange}
          >
            <TabList gap={2}>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                English
              </Tab>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                Japanese
              </Tab>
            </TabList>
          </Tabs>
          <Flex direction="column" w="100%" gap={4}>
            <Grid emplateColumns="repeat(1, 1fr)" gap={4}>
              <Flex direction="column" gap={4}>
                {trans === "en" ? (
                  <Box display="flex" flexDirection="column" gap="8px">
                    <FormInput
                      maxW="100%"
                      name="name"
                      label={transLabel("name")}
                      placeholder={transLabel("enterName")}
                      isRequired
                    />
                    <FormUpload
                      label="Select image file to upload"
                      title="Image"
                      name="icon_url"
                      type='IMAGE'
                      accept={"image/*"}
                      textButton={nameImage}
                      handleOnChange={(e) =>
                        handleImageChange(e)
                      }
                      handleDragImage={(val) =>
                        handleDragImage(val)
                      }
                      deleteImage={() => deleteImage()}
                      isRequired
                    />
                    <Flex alignItems="flex-start" gap="16px" w="100%">
                      <FormInput
                        wrapperProps={{
                          w: "100%",
                        }}
                        w="100%"
                        name="time"
                        maxLength={30}
                        placeholder={transLabel("enterTime")}
                        label={transLabel("time")}
                        isRequired
                        onChange={(e) => {
                          setValue("time", getOnlyDecimal(e.target.value));
                        }}
                      />
                      <FormInput
                        wrapperProps={{
                          w: "100%",
                        }}
                        w="100%"
                        name="payout"
                        maxLength={30}
                        placeholder={transLabel("enterPayout")}
                        label={transLabel("payout")}
                        isRequired
                        onChange={(e) => {
                          setValue("payout", getOnlyDecimal(e.target.value));
                        }}
                      />
                    </Flex>
                    <FormTextAria
                      label={transLabel("description")}
                      placeholder={transLabel("enterDesc")}
                      name="description"
                      h="120px"
                    />
                    <FormSelect
                      name="status"
                      label={transLabel("status")}
                      defaultValue={STATUS_GAME_PLAY[0].value}
                      options={STATUS_GAME_PLAY}
                      isRequired
                    />
                  </Box>
                ) : (
                  <Flex flexDirection="column" gap="8px">
                    <FormInput
                      maxW="100%"
                      name="name_jp"
                      label={transLabel("name")}
                      placeholder={transLabel("enterName")}
                      isRequired
                    />
                    <FormUpload
                      label="Select image file to upload"
                      title={transLabel("image")}
                      name="icon_url"
                      type='IMAGE'
                      accept={"image/*"}
                      textButton={nameImage}
                      handleOnChange={(e) =>
                        handleImageChange(e)
                      }
                      handleDragImage={(val) =>
                        handleDragImage(val)
                      }
                      deleteImage={() => deleteImage()}
                      showError={false}
                    />
                    <Flex alignItems="flex-start" gap="16px" w="100%">
                      <FormInput
                        wrapperProps={{
                          w: "100%",
                        }}
                        w="100%"
                        name="time"
                        type="number"
                        placeholder={transLabel("enterTime")}
                        label={transLabel("time")}
                        disabled
                      />
                      <FormInput
                        wrapperProps={{
                          w: "100%",
                        }}
                        w="100%"
                        name="payout"
                        type="number"
                        placeholder={transLabel("enterPayout")}
                        label={transLabel("payout")}
                        disabled
                      />
                    </Flex>
                    <FormTextAria
                      label={transLabel("description")}
                      placeholder={transLabel("enterDesc")}
                      name="description_jp"
                      h="120px"
                    />
                    <FormSelect
                      name="status"
                      label={transLabel("status")}
                      defaultValue={STATUS_GAME_PLAY[0].value}
                      options={STATUS_GAME_PLAY}
                      disabled
                    />
                  </Flex>
                )}
              </Flex>
            </Grid>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="700px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "EDIT GAME TYPE" : "NEW GAME TYPE"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
