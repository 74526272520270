import { Box, Flex, Text } from '@chakra-ui/react';
import FormDatePicker from 'components/form/FormDatePicker';
import FormInput from 'components/form/FormInput';
import FormLabelComponent from 'components/form/FormLabel';
import FormTextAria from 'components/form/FormTextAria';
import FormUpload from 'components/form/FormUpload';
import React from 'react'
import { OPTION_SALE } from '../constant';
import FormSelect from 'components/form/FormSelect';
import { TYPE_PRIZE } from 'constants/constants';
import { MODE_TYPE_SHOW } from 'views/Machine/MachineSettings/constants';

export default function FormJp(props) {
  const { 
    transLabel, 
    disableWhenJP, 
    handleImageChange, 
    handleDragImage, 
    deleteImage, 
    nameImage,
    setValue,
    idEdit,
    dateFormat,
    watch,
    listModeType,
    prizeTypes,
    getChainOptions,
    listFee,
  } = props
  
  return (
    <Box display="flex" flexDirection="column" gap="8px">
      <FormInput
        maxW="100%"
        name="name_jp"
        label={transLabel("name")}
        placeholder={transLabel("enterName")}
        isRequired
      />
      <Flex flexDirection="column" gap="16px" w="100%">
        <Flex gap="12px" w="100%">
          <FormSelect
            wrapperProps={{
              w: "100%"
            }}
            name="prize_type"
            label={transLabel("prizeType")}
            defaultValue={prizeTypes[0]?.value}
            options={prizeTypes}
            isRequired
            disabled={disableWhenJP}
          />
          {watch("prize_type") === "BOUNTY_BALL" ? (
            <FormSelect
              wrapperProps={{
                w: "100%"
              }}
              name="chain"
              label={transLabel("chain")}
              defaultValue={getChainOptions[0]?.value}
              options={getChainOptions}
              isRequired
              disabled={disableWhenJP}
            />
          ) : (
            <Flex w="100%"></Flex>
          )}
        </Flex>
        <Flex gap="12px" w="100%">
          <FormSelect
            wrapperProps={{
              w: "100%"
            }}
            name="allocation"
            label={transLabel("allocation")}
            defaultValue={listModeType[0]?.value}
            options={listModeType}
            disabled={disableWhenJP}
            isRequired
          />
          <FormSelect
            wrapperProps={{
              w: "100%"
            }}
            name="play_fee_id"
            label={transLabel("playFee")}
            defaultValue={listFee[0]?.value}
            options={listFee}
            isRequired
            disabled={disableWhenJP}
          />
        </Flex>
      </Flex>
      <FormInput
        maxW="100%"
        name="prize_items"
        value=""
        label={transLabel("prizeItems")}
        placeholder={transLabel("enterPrizeItems")}
        isRequired
        disabled={disableWhenJP}
        autocomplete="off"
      />
      <FormTextAria
        label={transLabel("description")}
        placeholder={transLabel("enterDesc")}
        name="description_jp"
        defaultValue=''
        h="100px"
      />
      <FormUpload
        label="Select image file to upload"
        title={transLabel("prizeImage")}
        name="prize_img"
        type='IMAGE'
        accept={"image/*"}
        textButton={nameImage}
        isRequired
        handleOnChange={(e) =>
          handleImageChange(e, "prize_img")
        }
        handleDragImage={(val) =>
          handleDragImage(val, "prize_img")
        }
        deleteImage={() => deleteImage("prize_img")}
        disabled={disableWhenJP}
      />
      <FormInput
        maxW="100%"
        name="assist_gauge_limit"
        type="number"
        label={transLabel("assistGauge")}
        placeholder={transLabel("enterValue")}
        isRequired
        min={0}
        disabled={disableWhenJP}
      />
      <FormUpload
        label="Select image file to upload"
        title={transLabel("bgImage")}
        name="bg_img"
        type='IMAGE'
        accept={"image/*"}
        textButton={nameImage}
        isRequired
        handleOnChange={(e) =>
          handleImageChange(e, "bg_img")
        }
        handleDragImage={(val) =>
          handleDragImage(val, "bg_img")
        }
        deleteImage={() => deleteImage("bg_img")}
        disabled={disableWhenJP}
      />
      <Text fontSize="16px" fontWeight="500">{transLabel("salePeriods")}</Text>
      <Flex flexDirection="column" gap="12px" border="1px solid #ccc" borderRadius="8px" p="12px">
        <FormSelect
          wrapperProps={{
            w: "100%"
          }}
          name="sale"
          label={transLabel("sale")}
          options={OPTION_SALE}
          onChange={(e) => {
            setValue("sale", e.target.value);
          }}
          disabled={disableWhenJP}
        />
        <Flex direction="column" gap="8px" w="100%">
          <FormLabelComponent
            m="0px"
            title={transLabel("start")}
            isRequired
          />
          <FormDatePicker
            name="start"
            showTimeSelect
            dateFormat={dateFormat}
            h="40px"
            w="100%"
            disabled={disableWhenJP}
          />
        </Flex>
        <Flex direction="column" gap="8px" w="100%">
          <FormLabelComponent
            m="0px"
            title={transLabel("end")}
            isRequired
          />
          <FormDatePicker
            name="end"
            showTimeSelect
            dateFormat={dateFormat}
            h="40px"
            w="100%"
            disabled={disableWhenJP}
          />
        </Flex>
      </Flex>
    </Box>
  )
}
