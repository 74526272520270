export const StatisticsIcon = (props) => (
  <svg
    width={20}
    height={19}
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.75039 18.6609C4.6086 18.1431 1.35495 14.8895 0.837694 10.7487C0.797153 10.4288 1.0471 10.1449 1.37071 10.1449H3.54207C3.80437 10.1449 4.02785 10.3343 4.07193 10.5924C4.48952 13.0654 6.43215 15.0086 8.90675 15.4267C9.16486 15.4707 9.35424 15.6942 9.35424 15.9565V18.1279C9.3542 18.4474 9.07722 18.6998 8.75039 18.6609ZM3.54225 8.92726H1.37088C1.0471 8.92726 0.797368 8.64319 0.837873 8.32344C1.40655 3.76769 5.29919 0.332031 9.89162 0.332031C11.9318 0.332031 13.8661 0.99723 15.4855 2.25579C15.7431 2.45674 15.7624 2.83353 15.5359 3.06001L14.0009 4.59555C13.8152 4.78178 13.5235 4.80488 13.3094 4.65325C12.3016 3.93504 11.1197 3.55524 9.89158 3.55524C7.00151 3.55524 4.55366 5.6264 4.07208 8.47977C4.02803 8.73784 3.80455 8.92726 3.54225 8.92726ZM10.5719 18.1284V15.957C10.5719 15.6947 10.7612 15.4713 11.0193 15.4272C13.8727 14.9456 15.9439 12.4978 15.9439 9.60768C15.9439 8.37956 15.5641 7.19761 14.8459 6.18982C14.6937 5.9763 14.7179 5.68356 14.9036 5.49837L16.4391 3.96337C16.6627 3.73979 17.0407 3.75397 17.2433 4.01373C18.5019 5.63321 19.1671 7.56746 19.1671 9.60764C19.1671 14.2001 15.7314 18.0927 11.1757 18.6614C11.1001 18.6709 11.0233 18.6642 10.9505 18.6418C10.8777 18.6193 10.8105 18.5817 10.7534 18.5312C10.6963 18.4808 10.6506 18.4188 10.6193 18.3494C10.588 18.2799 10.5718 18.2046 10.5719 18.1284Z"
      fill="black"
    />
    <path
      d="M7.84986 7.34989C7.84986 7.05321 7.60933 6.81268 7.31265 6.81268H6.23825C5.94157 6.81268 5.70105 7.05321 5.70105 7.34989V11.6475C5.70105 11.9442 5.94157 12.1847 6.23825 12.1847H7.31265C7.60933 12.1847 7.84986 11.9442 7.84986 11.6475V7.34989ZM11.0731 11.6475V6.27548C11.0731 5.9788 10.8325 5.73828 10.5359 5.73828H9.46146C9.16478 5.73828 8.92426 5.9788 8.92426 6.27548V11.6475C8.92426 11.9442 9.16478 12.1847 9.46146 12.1847H10.5359C10.8326 12.1847 11.0731 11.9442 11.0731 11.6475ZM14.2963 11.6475V9.49869C14.2963 9.20201 14.0558 8.96149 13.7591 8.96149H12.6847C12.388 8.96149 12.1475 9.20201 12.1475 9.49869V11.6475C12.1475 11.9442 12.388 12.1847 12.6847 12.1847H13.7591C14.0558 12.1847 14.2963 11.9442 14.2963 11.6475Z"
      fill="black"
    />
  </svg>
);
